import React from 'react';

import PageWithTabs from '../../components/PageWithTabs/PageWithTabs';

import RoleList from '../Role/RoleList';
import RoleDetails from '../Role/RoleDetails';
import UserList from './User/UserList';
import UserDetails from './User/UserDetails';
import CustomDates from './CustomDates/CustomDates';

export default function Finance(props) {
  const entities = [{
    id: 'users',
    path: 'partners',
    name: 'Partners',
    can: 'people:users:view',
    components: [{
      path: '/people/partners/create',
      component: UserDetails,
    }, {
      path: '/people/partners/:id',
      component: UserDetails,
    }, {
      path: '/people/partners',
      component: UserList,
    }],
  }, {
    id: 'calendar',
    path: 'calendar',
    name: 'Work Calendar',
    can: 'people:customDates:view',
    components: [{
      path: '/people/calendar',
      component: CustomDates,
    }],
  }, {
    id: 'role',
    path: 'roles',
    name: 'Roles',
    can: 'people:roles:view',
    components: [{
      path: '/:circleId/roles/create',
      component: RoleDetails,
    }, {
      path: '/:circleId/roles/:id',
      component: RoleDetails,
    }, {
      path: '/:circleId/roles',
      component: RoleList,
    }],
  }];

  return (
    <PageWithTabs basePath="people" entities={entities} {...props} />
  );
}
