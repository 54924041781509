import React, { useState, useEffect } from 'react';

import {
  statusOptions,
} from 'services/payroll';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import Checkbox from '@material-ui/core/Checkbox';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';

import CustomSelect from 'components/CustomSelect/CustomSelect';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles(() => ({
  checkbox: {
    marginTop: '24px',
  },
  padding: {
    padding: '0px 9px 0 9px',
  },
}));

export default function PayrollFilters({
  paymentMethods, paymentTargets, onChange, storage,
}) {
  const classes = useStyles();

  const [filters, setFilters] = useState({
    paymentMethodId: null,
    paymentTargetId: null,
    status: null,
    paymentFinalAmount: false,
  });

  const [paymentMethodOptions, setPaymentMethodOptions] = useState([]);

  const [paymentTargetOptions, setPaymentTargetOptions] = useState([]);

  useEffect(() => {
    const data = {};

    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        data[key] = filters[key];
      }
    });

    onChange(data);
  }, [filters]);

  useEffect(() => {
    const options = paymentMethods.map((method) => ({
      id: method._id,
      name: method.name,
    }));

    setPaymentMethodOptions(options);
  }, [paymentMethods]);

  useEffect(() => {
    const options = paymentTargets.map((target) => ({
      id: target._id,
      name: target.name,
    }));

    setPaymentTargetOptions(options);
  }, [paymentTargets]);

  useEffect(() => {
    if (storage && paymentMethodOptions.length && paymentTargetOptions.length) {
      const filters = storage.getValue('payrollFilters');

      if (filters) {
        setFilters(filters);
      }
    }
  }, [paymentMethodOptions, paymentTargetOptions]);

  const handleFilterChange = (filter, value) => {
    const data = { ...filters };
    data[filter] = value;

    setFilters(data);

    if (storage) {
      storage.setValue('payrollFilters', data);
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={3}>
        <CustomSelect
          label="Payment From"
          value={filters.paymentMethodId}
          options={paymentMethodOptions}
          onChange={(value) => handleFilterChange('paymentMethodId', value)}
          selectAll
          selectAllLabel="All Payment From"
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={3}>
        <CustomSelect
          label="Payment To"
          value={filters.paymentTargetId}
          options={paymentTargetOptions}
          onChange={(value) => handleFilterChange('paymentTargetId', value)}
          selectAll
          selectAllLabel="All Payment To"
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={3}>
        <CustomSelect
          label="Status"
          value={filters.status}
          options={statusOptions}
          selectAll
          selectAllLabel="All Statuses"
          translateOptions
          onChange={(value) => handleFilterChange('status', value)}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={3}>
        <FormControlLabel
          className={classes.checkbox}
          control={(
            <Checkbox
              checked={filters.paymentFinalAmount}
              onChange={(e) => handleFilterChange('paymentFinalAmount', e.target.checked)}
              color="primary"
              className={classes.padding}
            />
            )}
          label="Records with 0 amount"
        />
      </GridItem>
    </GridContainer>
  );
}
