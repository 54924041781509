import React, { useState, useEffect, useContext } from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import {
  formatUserName,
  getUser,
} from 'services/user';

import {
  getUserContracts,
  createUserContract,
  updateUserContract,
  deleteUserContract,
  getUserContractCurrencies,
} from 'services/userContract';

import {
  formatDate,
  parseInputDate,
  formatInputDate,
} from 'services/date';

import {
  getProjects,
} from 'services/project';

import {
  can,
} from 'services/permission';

import {
  formatMoney,
  getDefaultCurrency,
  getContractTypes,
  formatContractType,
} from 'services/finance';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody';
import Button from 'components/CustomButtons/Button.js';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { ConsoleContext } from 'services/context';
import ConfirmContractRate from '../components/ConfirmContractRate';

const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: '15px',
  },
  formControl: {
    minWidth: '100%',
  },
  checkboxMargin: {
    paddingTop: '10px',
  },
}));

export default function UserContractDetails(props) {
  const { userId } = props.match.params;

  useEffect(() => {
    async function fetchData() {
      showLoader(true);

      try {
        const user = await getUser(userId);

        const contracts = await getUserContracts({ userId });
        const projects = await getProjects();

        setContracts(contracts);
        setUser(user);
        setProjects(projects);

        const userProjects = projects.filter((project) => project.members.find((member) => member.userId._id === userId));

        setUserProjects(userProjects);
      } catch (error) {
        showError(error);
      }

      setCanEdit(await can('finance:userContracts:edit'));

      showLoader(false);
    }

    fetchData();
  }, [userId]);

  const classes = useStyles();

  const {
    showError, showSuccess, showLoader, showConfirm, t,
  } = useContext(ConsoleContext);

  const [user, setUser] = useState();

  const [contracts, setContracts] = useState([]);

  const [projects, setProjects] = useState([]);

  const [userProjects, setUserProjects] = useState([]);

  const [activeContract, setActiveContract] = useState();

  const [openAddDialog, setOpenAddDialog] = useState(false);

  const [canEdit, setCanEdit] = useState(false);

  const [contractRateConfirm, setContractRateConfirm] = useState();

  const handleAddContract = () => {
    setActiveContract({
      userId,
      startAt: '',
      endAt: '',
      type: 'monthly',
      taxByCompany: false,
      currency: getDefaultCurrency(),
      monthlyRate: '',
      hourlyRate: '',
      projectId: '',
    });

    setOpenAddDialog(true);
  };

  const handleEditContract = (contract) => {
    setActiveContract({
      ...contract,
      startAt: formatInputDate(contract.startAt),
      endAt: contract.endAt ? formatInputDate(contract.endAt) : '',
      monthlyRate: contract.monthlyRate ? contract.monthlyRate : '',
      hourlyRate: contract.hourlyRate ? contract.hourlyRate : '',
      projectId: contract.projectId ? contract.projectId : '',
      currency: contract.currency ? contract.currency : getDefaultCurrency(),
    });

    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  const handleAddDialogSubmit = async () => {
    const data = { ...activeContract };

    data.startAt = parseInputDate(data.startAt);
    data.endAt = data.endAt ? parseInputDate(data.endAt) : null;
    data.monthlyRate = data.monthlyRate ? Number(data.monthlyRate) : null;
    data.hourlyRate = data.hourlyRate ? Number(data.hourlyRate) : null;
    data.projectId = data.projectId ? data.projectId : null;

    confirmRates(data, async () => {
      const changedContracts = [...contracts];
      let newContract;

      try {
        if (data._id) {
          newContract = await updateUserContract(data);

          const index = changedContracts.findIndex((item) => item._id === newContract._id);
          changedContracts[index] = newContract;

          showSuccess('Contract updated');
        } else {
          const newContract = await createUserContract(data);

          changedContracts.push(newContract);

          showSuccess('Contract created');
        }

        setContracts(changedContracts);
        setOpenAddDialog(false);
      } catch (e) {
        showError(e);
      }
    });
  };

  const handleContractChange = (field, value) => {
    const data = { ...activeContract };
    data[field] = value;

    setActiveContract(data);
  };

  const handleDeleteContract = async (contract) => {
    showConfirm(async () => {
      await deleteUserContract(contract);

      const changedContracts = [...contracts];

      const index = changedContracts.findIndex((item) => item._id === contract._id);
      changedContracts.splice(index, 1);

      setContracts(changedContracts);

      showSuccess('Contract deleted');
    });
  };

  const confirmRates = (contract, callback) => {
    setContractRateConfirm((<ConfirmContractRate contract={contract} onConfirm={callback} />));
  };

  const renderProjectName = (projectId) => {
    const project = projects.find((project) => project._id === projectId);

    if (!project) {
      return '-';
    }

    return project.name;
  };

  return (
    <div>
      <GridContainer>
        { user ? (
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info">
                <h4>{t('{{name}} Contracts', { name: formatUserName(user) })}</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  { canEdit ? (
                    <GridItem xs={12} sm={12} md={12}>
                      <Button color="primary" style={{ float: 'right' }} onClick={handleAddContract}>
                        +
                        {t('Create Contract')}
                      </Button>
                    </GridItem>
                  ) : null }
                  <GridItem xs={12} sm={12} md={12}>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>{t('Start Date')}</TableCell>
                            <TableCell>{t('End Date')}</TableCell>
                            <TableCell>{t('Contract Type')}</TableCell>
                            <TableCell>{t('Monthly Rate')}</TableCell>
                            <TableCell>{t('Hourly Rate')}</TableCell>
                            <TableCell>{t('Tax Compensation')}</TableCell>
                            <TableCell>{t('Project')}</TableCell>
                            { canEdit ? (<TableCell />) : null }
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {contracts.map((contract) => (
                            <TableRow key={contract._id}>
                              <TableCell>{formatDate(contract.startAt)}</TableCell>
                              <TableCell>{formatDate(contract.endAt)}</TableCell>
                              <TableCell>{t(formatContractType(contract.type))}</TableCell>
                              <TableCell>{contract.monthlyRate ? formatMoney(contract.monthlyRate, contract.currency) : '-'}</TableCell>
                              <TableCell>{contract.hourlyRate ? formatMoney(contract.hourlyRate, contract.currency) : '-'}</TableCell>
                              <TableCell>{t(contract.taxByCompany ? 'Yes' : 'No')}</TableCell>
                              <TableCell>{renderProjectName(contract.projectId)}</TableCell>

                              { canEdit ? (
                                <TableCell style={{ whiteSpace: 'nowrap' }}>
                                  <Button onClick={() => handleEditContract(contract)}>{t('Edit')}</Button>
                                  <IconButton className={classes.margin} onClick={() => handleDeleteContract(contract)}>
                                    <DeleteIcon fontSize="small" />
                                  </IconButton>
                                </TableCell>
                              ) : null }
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        ) : null }
      </GridContainer>
      { activeContract ? (
        <Dialog
          maxWidth="xs"
          fullWidth
          open={openAddDialog}
          onClose={handleCloseAddDialog}
        >
          <DialogTitle>{t(activeContract._id ? 'Edit Contract' : 'Create Contract')}</DialogTitle>
          <DialogContent>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <FormControl className={classes.formControl}>
                  <InputLabel>
                    {t('Currency')}
                    {' '}
                    *
                  </InputLabel>
                  <Select
                    disabled={!!activeContract._id}
                    fullWidth
                    value={activeContract.currency}
                    onChange={(e) => handleContractChange('currency', e.target.value)}
                  >
                    { getUserContractCurrencies().map((currency) => (
                      <MenuItem key={currency} value={currency}>
                        { currency }
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer className={classes.grid}>
              <GridItem xs={12} sm={12} md={6}>
                <TextField
                  required
                  fullWidth
                  label={t('Start Date')}
                  type="date"
                  value={activeContract.startAt}
                  onChange={(e) => handleContractChange('startAt', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <TextField
                  fullWidth
                  label={t('End Date')}
                  type="date"
                  value={activeContract.endAt}
                  onChange={(e) => handleContractChange('endAt', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer className={classes.grid}>
              <GridItem xs={12} sm={12} md={6}>
                <FormControl className={classes.formControl}>
                  <InputLabel>
                    {t('Type')}
                    {' '}
                    *
                  </InputLabel>
                  <Select
                    fullWidth
                    value={activeContract.type}
                    onChange={(e) => handleContractChange('type', e.target.value)}
                  >

                    { getContractTypes().map((type, index) => (
                      <MenuItem key={`type-${type.id}`} value={type.id}>
                        {t(type.name)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>
              { activeContract && activeContract.type === 'monthly' ? (
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    style={{ whiteSpace: 'nowrap' }}
                    required
                    fullWidth
                    label={`${t('Monthly Rate')}, ${activeContract.currency}`}
                    value={activeContract.monthlyRate}
                    onChange={(e) => handleContractChange('monthlyRate', e.target.value)}
                  />
                </GridItem>
              ) : (
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    style={{ whiteSpace: 'nowrap' }}
                    required
                    fullWidth
                    label={`${t('Hourly Rate')}, ${activeContract.currency}`}
                    value={activeContract.hourlyRate}
                    onChange={(e) => handleContractChange('hourlyRate', e.target.value)}
                  />
                </GridItem>
              )}

            </GridContainer>
            <GridContainer className={classes.grid}>
              <GridItem xs={12} sm={12} md={6}>
                <FormControlLabel
                  className={classes.checkboxMargin}
                  control={(
                    <Checkbox
                      checked={activeContract.taxByCompany}
                      onChange={(e) => handleContractChange('taxByCompany', e.target.checked)}
                      name="taxByCompany"
                      color="primary"
                    />
                  )}
                  label={t('Tax Compensation')}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <FormControl className={classes.formControl}>
                  <InputLabel>{t('Project')}</InputLabel>
                  <Select
                    fullWidth
                    value={activeContract.projectId}
                    onChange={(e) => handleContractChange('projectId', e.target.value)}
                  >
                    <MenuItem value="">{t('All Projects')}</MenuItem>

                    { userProjects.map((project) => (
                      <MenuItem key={project._id} value={project._id}>
                        { project.name }
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAddDialog}>
              {t('Cancel')}
            </Button>
            <Button onClick={handleAddDialogSubmit} color="primary">
              {t('Save')}
            </Button>
          </DialogActions>
        </Dialog>
      ) : null }

      { contractRateConfirm }
    </div>
  );
}
