import React, { useState, useEffect, useContext } from 'react';

import {
  createCandidateInterview,
} from 'services/candidate';

import {
  formatUserName,
} from 'services/user';

import {
  getInterviewOptions,
} from 'services/opportunity';

import { ConsoleContext } from 'services/context';

import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Button from 'components/CustomButtons/Button';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: '15px',
  },
}));

export default function ArrangeInterviewDialog({
  candidate,
  open,
  users,
  opportunities,
  onClose,
  onSave,
}) {
  const classes = useStyles();

  const { showError, showSuccess, t } = useContext(ConsoleContext);

  const [openDialog, setOpenDialog] = useState(open);

  const [interviewers, setInterviewers] = useState([]);

  const [interview, setInterview] = useState();

  const [availableOpportunities, setAvailableOpportunities] = useState([]);

  useEffect(() => {
    setInterview({
      candidateId: candidate._id,
      opportunityIds: availableOpportunities.map((opportunity) => opportunity._id),
      type: '',
      interviewerId: '',
    });

    setOpenDialog(open);
  }, [open, candidate, availableOpportunities]);

  useEffect(() => {
    const availableOpportunities = opportunities.filter((opportunity) => {
      if (opportunity.status !== 'active') {
        return false;
      }

      const opportunityCandidate = opportunity.candidates.find((item) => (item.id === candidate._id));

      return Boolean(opportunityCandidate);
    });

    setAvailableOpportunities(availableOpportunities);
  }, [opportunities]);

  useEffect(() => {
    const interviewers = users.map((user) => ({
      id: user._id,
      name: formatUserName(user),
    }));

    setInterviewers(interviewers);
  }, [users]);

  const handleCloseDialog = () => {
    onClose();
  };

  const handleChange = (field, value) => {
    const data = { ...interview };

    data[field] = value;

    setInterview(data);
  };

  const handleOpportunityIdsChange = (id, checked) => {
    const data = { ...interview };

    const index = data.opportunityIds.findIndex((opportunityId) => opportunityId === id);

    if (checked && (index === -1)) {
      data.opportunityIds.push(id);
    } else if (!checked && (index > -1)) {
      data.opportunityIds.splice(index, 1);
    }

    setInterview(data);
  };

  const handleSubmit = async () => {
    const data = { ...interview };

    try {
      const newInterview = await createCandidateInterview(candidate._id, data);

      showSuccess('Interview arranged');
      onSave(newInterview);
    } catch (e) {
      showError(e);
    }
  };

  if (!interview) {
    return null;
  }

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={openDialog}
      onClose={handleCloseDialog}
    >
      <DialogTitle>{`Arrange Interview for ${candidate.name}`}</DialogTitle>
      <DialogContent>
        { availableOpportunities.length ? (
          <>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <strong>Opportunities</strong>
              </GridItem>
            </GridContainer>

            { availableOpportunities.map((opportunity) => (
              <GridContainer key={opportunity._id}>
                <GridItem xs={12} sm={12} md={12}>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={Boolean(interview.opportunityIds.find((id) => id === opportunity._id))}
                        onChange={(e) => handleOpportunityIdsChange(opportunity._id, e.target.checked)}
                        color="primary"
                      />
                    )}
                    label={opportunity.name}
                  />
                </GridItem>
              </GridContainer>
            )) }

            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomSelect
                  required
                  label="Type"
                  value={interview.type}
                  options={getInterviewOptions()}
                  onChange={(value) => handleChange('type', value)}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <CustomSelect
                  required
                  label="Interviewer"
                  value={interview.interviewerId}
                  options={interviewers}
                  onChange={(value) => handleChange('interviewerId', value)}
                />
              </GridItem>
            </GridContainer>
          </>
        ) : 'No opportunities available' }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>
          {t('Cancel')}
        </Button>

        { availableOpportunities.length ? (
          <Button onClick={handleSubmit} color="info">
            {t('Save')}
          </Button>
        ) : null }
      </DialogActions>
    </Dialog>
  );
}
