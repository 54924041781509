import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

import {
  getClient,
} from 'services/client';

import {
  getInvoices,
} from 'services/invoice';

import {
  formatDate,
  formatDateTime,
} from 'services/date';

import {
  formatMoney,
} from 'services/finance';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody';
import Button from 'components/CustomButtons/Button.js';

import { ConsoleContext } from 'services/context';

import PagedTable from 'components/PagedTable/PagedTable';
import InvoiceStatus from './components/InvoiceStatus';
import ClientInvoiceReport from './components/ClientInvoiceReport';

const useStyles = makeStyles((theme) => ({
}));

export default function ClientInvoiceList(props) {
  const { clientId } = props.match.params;

  const classes = useStyles();

  const {
    showError, showLoader, can, t,
  } = useContext(ConsoleContext);

  const [client, setClient] = useState();

  const [rows, setRows] = useState([]);

  const [canEdit, setCanEdit] = useState(false);

  const columns = [
    { id: 'key', label: 'Invoice Number' },
    { id: 'billingStartAt', label: 'Start Date' },
    { id: 'billingEndAt', label: 'End Date' },
    { id: 'totalAmount', label: 'Total Amount' },
    { id: 'payedAmount', label: 'Payed Amount' },
    { id: 'status', label: 'Status' },
    { id: 'statusChangedAt', label: 'Changed Status At' },
  ];

  useEffect(() => {
    (async () => {
      showLoader(true);

      try {
        const client = await getClient(clientId);

        const invoices = await getInvoices({ clientId });

        const rows = invoices.map((invoice) => ({
          id: invoice._id,
          key: (<Link to={`${clientId}/${invoice._id}`}>{invoice.key}</Link>),
          status: (<InvoiceStatus status={invoice.status} />),
          billingStartAt: formatDate(invoice.billingStartAt),
          billingEndAt: formatDate(invoice.billingEndAt),
          totalAmount: (invoice.totalAmount || invoice.totalAmount === 0) ? formatMoney(invoice.totalAmount, invoice.currency) : '-',
          payedAmount: (invoice.payedAmount || invoice.payedAmount === 0) ? formatMoney(invoice.payedAmount, invoice.currency) : '-',
          statusChangedAt: renderChangeStatusDate(invoice),
        }));

        setRows(rows);
        setClient(client);
      } catch (error) {
        showError(error);
      }

      setCanEdit(await can('finance:invoices:edit'));

      showLoader(false);
    })();
  }, [clientId]);

  const renderChangeStatusDate = (invoice) => formatDateTime(invoice[`${invoice.status}At`]);

  return (
    <>
      <GridContainer>
        { client ? (
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info">
                <h4>{t('{{client.companyName}} Invoices', { client })}</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  { canEdit ? (
                    <GridItem xs={12} sm={12} md={12}>
                      <Link to={`${clientId}/create`}>
                        <Button color="primary" style={{ float: 'right' }}>
                          +
                          {t('Create Invoice')}
                        </Button>
                      </Link>
                    </GridItem>
                  ) : null }

                  <GridItem xs={12} sm={12} md={12}>
                    <PagedTable
                      columns={columns}
                      rows={rows}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        ) : null }
      </GridContainer>
      <GridContainer>
        { client ? (
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4>{t('Billing Report')}</h4>
              </CardHeader>
              <CardBody>
                <ClientInvoiceReport clientId={clientId} />
              </CardBody>
            </Card>
          </GridItem>
        ) : null }
      </GridContainer>
    </>
  );
}
