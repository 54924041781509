import {
  API_URL,
  LOGIN_URL,
  LOGOUT_URL,
} from '../utils/apiRouting';

import { get, post } from '../utils/http';

import { loadUserPermissions } from './permission';

let user;
let serverHost;

export async function loadUser() {
  try {
    const response = await get(API_URL);

    user = response.user;
    serverHost = response.host;

    await loadUserPermissions(user);

    user.id = user._id;

    delete user._id;

    return user;
  } catch (e) {
    throw new Error('Not Authorized');
  }
}

export async function login(email, password) {
  let errorMessage;

  if (!email) {
    errorMessage = 'Введите имейл';
  } else if (!password) {
    errorMessage = 'Введите пароль';
  }

  if (errorMessage) {
    throw Error(errorMessage);
  }

  return post(LOGIN_URL, { email, password });
}

export async function logout() {
  return post(LOGOUT_URL);
}

export function getServerHost() {
  return serverHost;
}
