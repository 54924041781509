import React, { useState, useEffect, useContext } from 'react';

import {
  getCandidateRejectedReasonOptions,
  updateOpportunityCandidateStatus,
} from 'services/opportunity';

import { ConsoleContext } from 'services/context';

import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from 'components/CustomDialog/CustomDialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Button from 'components/CustomButtons/Button';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: '15px',
  },
}));

export default function CandidateRejectDialog({
  opportunity, opportunityCandidate, open, onClose, onSave,
}) {
  const classes = useStyles();

  const { showError, showSuccess, t } = useContext(ConsoleContext);

  const [openDialog, setOpenDialog] = useState(open);

  const [changedReason, setChangedReason] = useState();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setOpenDialog(open);

    setChangedReason({
      rejectedReason: '',
      rejectedReasonNote: '',
    });
  }, [open]);

  const handleCloseDialog = () => {
    onClose();
  };

  const handleChange = (field, value) => {
    const data = { ...changedReason };

    data[field] = value;

    setChangedReason(data);
  };

  const handleSubmit = async () => {
    const data = { ...changedReason };

    data.id = opportunityCandidate.id;
    data.status = 'rejected';

    setLoading(true);

    try {
      const changedCandidate = await updateOpportunityCandidateStatus(opportunity._id, data);

      showSuccess('Candidate rejected');

      onSave(changedCandidate);
    } catch (e) {
      showError(e);
    }

    setLoading(false);
  };

  if (!changedReason) {
    return null;
  }

  return (
    <Dialog
      loading={loading}
      maxWidth="xs"
      fullWidth
      open={openDialog}
    >
      <DialogTitle>Reject Reason</DialogTitle>
      <DialogContent>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CustomSelect
              required
              label="Reason"
              value={changedReason.rejectedReason}
              options={getCandidateRejectedReasonOptions()}
              onChange={(value) => handleChange('rejectedReason', value)}
            />
          </GridItem>
        </GridContainer>

        <GridContainer className={classes.grid}>
          <GridItem xs={12} sm={12} md={12}>
            <TextField
              fullWidth
              label="Comment"
              multiline
              rows={2}
              variant="outlined"
              value={changedReason.rejectedReasonNote}
              onChange={(e) => handleChange('rejectedReasonNote', e.target.value)}
            />
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>
          {t('Cancel')}
        </Button>

        <Button onClick={handleSubmit} color="info">
          {t('Save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
