import React from 'react';
import clsx from 'clsx';
import { Switch, Route, Redirect } from 'react-router-dom';
// creates a beautiful scrollbar
import 'perfect-scrollbar/css/perfect-scrollbar.css';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import Navbar from 'components/Navbars/Navbar';
import Footer from 'components/Footer/Footer';
import Sidebar from 'components/Sidebar/Sidebar';

import routes from 'routes.js';

import { loadUser, logout } from 'services/session';
import { getLocalStorage, getSessionStorage } from 'services/storage';

import styles from 'assets/jss/material-dashboard-react/layouts/console';

import bgImage from 'assets/img/sidebar-2.jpg';
import logo from 'assets/img/apple-icon.png';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useTranslation, Trans } from 'react-i18next';

import { ConsoleContext } from 'services/context';

import {
  can,
} from 'services/permission';

const useStyles = makeStyles(styles);

export default function Console({ ...rest }) {
  // styles
  const classes = useStyles();

  const { t } = useTranslation();

  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const image = bgImage;
  const color = 'blue';
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [loggedIn, setLoggedIn] = React.useState();
  const [errorMessage, setErrorMessage] = React.useState('');
  const [successMessage, setSuccessMessage] = React.useState('');
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [confirmDialogMessage, setConfirmDialogMessage] = React.useState('');
  const [confirmDialogCallback, setConfirmDialogCallback] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [sessionUser, setSessionUser] = React.useState();
  const [menuIsExtended, setMenuIsExtended] = React.useState();
  const storage = getLocalStorage('sidebar');

  React.useEffect(() => {
    const menuIsExtended = storage.getValue('menuIsExtended', true);
    setMenuIsExtended(menuIsExtended);
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    async function checkLoggedIn() {
      try {
        const user = await loadUser();

        setSessionUser(user);

        setLoggedIn(true);
      } catch (e) {
        setLoggedIn(false);
      }
    }

    checkLoggedIn();

    /* if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    } */
    window.addEventListener('resize', resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      /* if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      } */
      window.removeEventListener('resize', resizeFunction);
    };
  }, []);

  const showError = (error) => {
    let message = '';

    if (typeof error === 'string') {
      message = t(error);
    } else if (typeof error === 'object') {
      if (error.message) {
        message = t(error.message);
      } else if (error.errors) {
        message = (error.errors.map((value, index) => <div key={index}>{t(value.msg)}</div>));
      }
    }

    setErrorMessage(message || t('Unknown Error'));
    setLoading(false);
  };

  const showSuccess = (message) => {
    setSuccessMessage(t(message));
  };

  const showConfirm = (callback, message = t('Are you sure?')) => {
    setConfirmDialogMessage(message);
    setOpenConfirmDialog(true);

    setConfirmDialogCallback(() => callback);
  };

  const showLoader = (loading) => {
    setLoading(loading);
  };

  const triggerLogout = async () => {
    try {
      await logout();

      showError('Unable to logout');
    } catch (e) {
      setLoggedIn(false);
    }
  };

  const handleConfirm = () => {
    confirmDialogCallback();
    setOpenConfirmDialog(false);
  };

  const handleSaveExtended = (state) => {
    storage.setValue('menuIsExtended', state);
    setMenuIsExtended(state);
  };

  const handleConfirmDialogCancel = () => {
    setOpenConfirmDialog(false);
  };

  const showPageTitle = (title, basePath) => {
    title = t(title);

    if (basePath) {
      const baseRoute = routes.find((route) => route.path === `/${basePath}`);

      if (baseRoute) {
        title += ` | ${t(baseRoute.name)}`;
      }
    }

    title += ' | Fluvius ERP';

    document.title = title;
  };

  if (loggedIn === false) {
    return (<Redirect to="/login" />);
  } if (!loggedIn) {
    return (
      <Backdrop className={classes.backdrop} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <div className={classes.wrapper}>
      <ConsoleContext.Provider value={{
        showError,
        showSuccess,
        showConfirm,
        showLoader,
        triggerLogout,
        sessionUser,
        can,
        t,
        Trans,
        getSessionStorage,
        showPageTitle,
      }}
      >
        <Sidebar
          routes={routes}
          logoText="Fluvius ERP"
          logo={logo}
          image={image}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color={color}
          extendedSidebar={menuIsExtended}
          onSaveExtended={handleSaveExtended}
          {...rest}
        />
        <div
          className={clsx({
            [classes.mainPanel]: menuIsExtended,
            [classes.mainPanelClose]: !menuIsExtended,
          })}
          ref={mainPanel}
        >
          <Navbar
            routes={routes}
            handleDrawerToggle={handleDrawerToggle}
            {...rest}
          />
          <div className={classes.content}>
            <div className={classes.container}>

              <Switch>
                {routes.map((prop, key) => (
                  <Route
                    path={prop.layout + prop.path}
                    component={prop.component}
                    key={key}
                  />
                ))}
                <Redirect to="/dashboard" />
              </Switch>
            </div>
          </div>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={!!errorMessage}
            autoHideDuration={3000}
            onClose={() => setErrorMessage('')}
          >
            <Alert elevation={6} variant="filled" severity="error">
              {errorMessage}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={!!successMessage}
            autoHideDuration={3000}
            onClose={() => setSuccessMessage('')}
          >
            <Alert elevation={6} variant="filled" severity="success">
              {successMessage}
            </Alert>
          </Snackbar>
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={openConfirmDialog}
          >
            <DialogTitle id="confirmation-dialog-title">{t('Confirm')}</DialogTitle>
            <DialogContent dividers>
              {confirmDialogMessage}
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleConfirmDialogCancel} color="primary">
                {t('Cancel')}
              </Button>
              <Button onClick={handleConfirm} color="primary">
                {t('Ok')}
              </Button>
            </DialogActions>
          </Dialog>
          <Footer />
          <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      </ConsoleContext.Provider>
    </div>
  );
}
