import React, { useState, useEffect, useContext } from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import {
  getProjectAllocation,
  createProjectAllocation,
  updateProjectAllocation,
  deleteProjectAllocation,
} from 'services/project';

import { formatDate, parseInputDate, formatInputDate } from 'services/date';

import { can } from 'services/permission';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Button from 'components/CustomButtons/Button';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { ConsoleContext } from 'services/context';

const useStyles = makeStyles((theme) => ({
  checkboxMargin: {
    marginLeft: '-3px',
    marginTop: '20px',
  },
  allocationRoot: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
}));

export default function ProjectDetails({
  project, users, savedMembers, onAllocationChange,
}) {
  const projectId = project._id;

  useEffect(() => {
    (async () => {
      try {
        const userAllocation = {};

        const projectAllocation = await getProjectAllocation(projectId);

        if (projectAllocation.length) {
          projectAllocation.forEach((allocation) => {
            if (!userAllocation[allocation.userId]) {
              userAllocation[allocation.userId] = [];
            }

            userAllocation[allocation.userId].push(allocation);
          });
        }

        setCanEdit(await can('production:projects:edit'));

        setUserAllocation(userAllocation);
      } catch (error) {
        showError(error);
      }
    })();
  }, [projectId]);

  const classes = useStyles();

  const { showError, showSuccess, showConfirm } = useContext(ConsoleContext);

  const [canEdit, setCanEdit] = useState(false);

  const [userAllocation, setUserAllocation] = useState({});

  const [openAllocationDialog, setOpenAllocationDialog] = useState(false);

  const [activeAllocation, setActiveAllocation] = useState({
    startAt: '',
    endAt: '',
    billable: false,
    dailyHours: '',
  });

  const handleAddAllocation = (member) => {
    setActiveAllocation({
      userId: member.userId,
      startAt: '',
      endAt: '',
      billable: false,
      dailyHours: '',
    });

    setOpenAllocationDialog(true);
  };

  const handleEditAllocation = (allocation) => {
    setActiveAllocation({
      ...allocation,
      startAt: formatInputDate(allocation.startAt),
      endAt: allocation.endAt ? formatInputDate(allocation.endAt) : '',
      dailyHours: allocation.dailyHours || '',
    });

    setOpenAllocationDialog(true);
  };

  const handleCloseAllocationDialog = () => {
    setOpenAllocationDialog(false);
  };

  const handleAllocationSubmit = async () => {
    const allocation = { ...activeAllocation };

    if (!allocation.startAt || !allocation.dailyHours) {
      return;
    }

    const data = {
      projectId,
      userId: allocation.userId,
      startAt: parseInputDate(allocation.startAt),
      endAt: allocation.endAt ? parseInputDate(allocation.endAt) : null,
      dailyHours: Number(allocation.dailyHours),
      billable: allocation.billable,
    };

    let newAllocation;
    const changedUserAllocation = { ...userAllocation };

    try {
      if (allocation._id) {
        data._id = allocation._id;
        newAllocation = await updateProjectAllocation(data);

        const index = changedUserAllocation[newAllocation.userId].findIndex((item) => item._id === allocation._id);
        changedUserAllocation[newAllocation.userId][index] = newAllocation;

        showSuccess('Allocation updated');
      } else {
        newAllocation = await createProjectAllocation(data);

        if (!changedUserAllocation[newAllocation.userId]) {
          changedUserAllocation[newAllocation.userId] = [];
        }

        changedUserAllocation[newAllocation.userId].push(newAllocation);

        showSuccess('Allocation added');
      }

      setUserAllocation(changedUserAllocation);

      if (onAllocationChange) {
        onAllocationChange(changedUserAllocation);
      }

      setOpenAllocationDialog(false);
    } catch (e) {
      showError(e);
    }
  };

  const handleAllocationChange = (field, value) => {
    const data = { ...activeAllocation };
    data[field] = value;

    setActiveAllocation(data);
  };

  const handleDeleteAllocation = async (allocation) => {
    showConfirm(async () => {
      const changedUserAllocation = { ...userAllocation };

      await deleteProjectAllocation(allocation);

      const index = changedUserAllocation[allocation.userId].findIndex((item) => item._id === allocation._id);
      changedUserAllocation[allocation.userId].splice(index, 1);

      setUserAllocation(changedUserAllocation);

      showSuccess('Allocation deleted');
    });
  };

  const formatUserName = (user) => {
    if (!user) {
      return '-';
    }

    return `${user.firstName} ${user.lastName}`;
  };

  function formatMemberName(member) {
    const user = users.find((user) => user._id === member.userId);

    return formatUserName(user);
  }

  return (
    <>
      { savedMembers.map((member) => (
        <GridContainer key={member.userId}>
          <GridItem xs={12} sm={12} md={12}>
            <h4>
              {formatMemberName(member)}
              { canEdit ? (
                <IconButton onClick={() => handleAddAllocation(member)}><AddIcon /></IconButton>
              ) : null }
            </h4>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                    <TableCell>Hours/day</TableCell>
                    <TableCell>Billable</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  { userAllocation[member.userId] && userAllocation[member.userId].map((allocation) => (
                    <TableRow key={allocation._id}>
                      <TableCell>{formatDate(allocation.startAt)}</TableCell>
                      <TableCell>{formatDate(allocation.endAt)}</TableCell>
                      <TableCell>{allocation.dailyHours}</TableCell>
                      <TableCell>{allocation.billable ? 'yes' : 'no'}</TableCell>
                      <TableCell>
                        { canEdit ? (
                          <>
                            <Button onClick={() => handleEditAllocation(allocation)}>Edit</Button>
                            <IconButton
                              aria-label="delete"
                              className={classes.margin}
                              onClick={() => handleDeleteAllocation(allocation)}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </>
                        ) : null }
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </GridItem>
        </GridContainer>
      ))}

      <Dialog
        maxWidth="xs"
        fullWidth
        open={openAllocationDialog}
        onClose={handleCloseAllocationDialog}
      >
        <DialogTitle>Add Allocation</DialogTitle>
        <DialogContent className={classes.allocationRoot}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                required
                fullWidth
                label="Start Date"
                type="date"
                value={activeAllocation.startAt}
                onChange={(e) => handleAllocationChange('startAt', e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                label="End Date"
                type="date"
                value={activeAllocation.endAt}
                onChange={(e) => handleAllocationChange('endAt', e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                required
                label="Hours per day"
                defaultValue={activeAllocation.dailyHours}
                onChange={(e) => handleAllocationChange('dailyHours', e.target.value)}
              />
            </GridItem>
            {project.billable && (
              <GridItem>
                <FormControlLabel
                  className={classes.checkboxMargin}
                  control={(
                    <Checkbox
                      checked={activeAllocation.billable}
                      onChange={(e) => handleAllocationChange('billable', e.target.checked)}
                      name="billable"
                      color="primary"
                    />
                  )}
                  label="Billable"
                />
              </GridItem>
            )}
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAllocationDialog}>
            Cancel
          </Button>
          <Button onClick={handleAllocationSubmit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
