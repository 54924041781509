import {
  PNL_REPORT_URL,
} from '../utils/apiRouting';

import {
  get,
} from '../utils/http';

export async function getClientRevenueReport(year, month) {
  return get(`${PNL_REPORT_URL}/client-revenue/${year}/${month}`);
}
