import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

import {
  LOCALE_URL,
} from '../utils/apiRouting';

import {
  get, post, put,
} from '../utils/http';

import localeMessageKeys from './localeMessageKeys';

const DEFAULT_LOCALE = 'en';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(Backend)
  .init({
    lng: getCurrentLocaleCode(),

    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    fallbackLng: false,

    backend: {
      loadPath: `${LOCALE_URL}/messages/{{lng}}`,
    },
  });

export async function getLocales() {
  return get(LOCALE_URL);
}

export async function getLocale(id) {
  return get(`${LOCALE_URL}/${id}`);
}

export async function updateLocale(locale) {
  return put(`${LOCALE_URL}/${locale._id}`, locale);
}

export async function createLocale(locale) {
  return post(LOCALE_URL, locale);
}

export function getCurrentLocaleCode() {
  const locale = localStorage.getItem('localeCode');

  return locale || DEFAULT_LOCALE;
}

export function setCurrentLocaleCode(code) {
  localStorage.setItem('localeCode', code);

  i18n.changeLanguage(code);
}

export {
  i18n,
  DEFAULT_LOCALE,
  localeMessageKeys,
};
