import React from 'react';

import PageWithTabs from '../../components/PageWithTabs/PageWithTabs';
import RoleList from '../Role/RoleList';
import RoleDetails from '../Role/RoleDetails';
import OpportunityList from './Opportunity/OpportunityList';
import OpportunityDetails from './Opportunity/OpportunityDetails';
import CandidateList from './Candidate/CandidateList';
import CandidateDetails from './Candidate/CandidateDetails';
import PartnerCompanyList from './PartnerCompany/PartnerCompanyList';
import PartnerCompanyDetails from './PartnerCompany/PartnerCompanyDetails';

export default function Sales(props) {
  const entities = [{
    id: 'role',
    path: 'roles',
    name: 'Roles',
    can: 'sales:roles:view',
    components: [{
      path: '/:circleId/roles/create',
      component: RoleDetails,
    }, {
      path: '/:circleId/roles/:id',
      component: RoleDetails,
    }, {
      path: '/:circleId/roles',
      component: RoleList,
    }],
  }, {
    id: 'opportunities',
    path: 'opportunities',
    name: 'Opportunities',
    can: 'sales:opportunities:view',
    components: [{
      path: '/sales/opportunities/create',
      component: OpportunityDetails,
    }, {
      path: '/sales/opportunities/:id/candidates/:candidateId',
      component: OpportunityDetails,
    }, {
      path: '/sales/opportunities/:id',
      component: OpportunityDetails,
    }, {
      path: '/sales/opportunities',
      component: OpportunityList,
    }],
  }, {
    id: 'candidates',
    path: 'candidates',
    name: 'Candidates',
    can: 'sales:candidates:view',
    components: [{
      path: '/sales/candidates/:id',
      component: CandidateDetails,
    }, {
      path: '/sales/candidates',
      component: CandidateList,
    }],
  }, {
    id: 'partner-companies',
    path: 'partner-companies',
    name: 'Partner Companies',
    can: 'sales:partnerCompanies:view',
    components: [{
      path: '/sales/partner-companies/create',
      component: PartnerCompanyDetails,
    }, {
      path: '/sales/partner-companies/:id',
      component: PartnerCompanyDetails,
    }, {
      path: '/sales/partner-companies',
      component: PartnerCompanyList,
    }],
  }];

  return (
    <PageWithTabs basePath="sales" entities={entities} {...props} />
  );
}
