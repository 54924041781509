import React from 'react';

import {
  formatCandidateStatus,
} from 'services/candidate';

import {
  formatDate,
} from 'services/date';

import { makeStyles } from '@material-ui/core/styles';

import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  alert: {
    display: 'inline-block',

    '& .MuiAlert-message': {
      padding: 0,
    },
  },
}));

export default function CandidateStatus({ candidate }) {
  const classes = useStyles();

  const { status, archivedAt } = candidate;

  const colors = {
    available: 'success',
    archived: 'error',
  };

  return (
    <Alert
      icon={false}
      color={colors[status]}
      className={classes.alert}
    >
      { formatCandidateStatus(status) }

      { archivedAt ? `: ${formatDate(archivedAt)}` : null }
    </Alert>
  );
}
