import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from 'components/CustomButtons/Button.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { formatUserName } from 'services/user';

const useStyles = makeStyles((theme) => ({
}));

export default function ContractIssuesDialog({ project, issues, onClose }) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const clientId = project.clientId._id;

  useEffect(() => {
    setOpen(true);
  }, [project]);

  const formatMemberName = (memberId) => {
    const member = project.members.find((member) => member.userId._id === memberId);

    if (!member) {
      return '-';
    }

    return formatUserName(member.userId);
  };

  const handleClose = () => {
    setOpen(false);

    if (onClose) {
      onClose();
    }
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        {project.name}
        {' '}
        Contract Issues
      </DialogTitle>
      <DialogContent>
        { issues.noClientContractDays.length ? (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h4>Missing Client Contracts:</h4>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Partner</TableCell>
                      <TableCell>Missing Dates</TableCell>
                      <TableCell>&nbsp;</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { issues.noClientContractDays.map(({ userId, days }) => (
                      <TableRow key={`client-${userId}`}>
                        <TableCell>{ formatMemberName(userId) }</TableCell>
                        <TableCell>{ days.join(', ') }</TableCell>
                        <TableCell>
                          <Link to={`/finance/client-contracts/${clientId}`} target="_blank">
                            <Button color="primary" size="sm">Fix it</Button>
                          </Link>
                        </TableCell>
                      </TableRow>
                    )) }
                  </TableBody>
                </Table>
              </TableContainer>
            </GridItem>
          </GridContainer>
        ) : null }

        { issues.noUserContractDays.length ? (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h4>Missing Partner Contracts</h4>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Partner</TableCell>
                      <TableCell>Missing Dates</TableCell>
                      <TableCell>&nbsp;</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { issues.noUserContractDays.map(({ userId, days }) => (
                      <TableRow key={`user-${userId}`}>
                        <TableCell>{ formatMemberName(userId) }</TableCell>
                        <TableCell>{ days.join(', ') }</TableCell>
                        <TableCell>
                          <Link to={`/finance/partner-contracts/${userId}`} target="_blank">
                            <Button color="primary" size="sm">Fix it</Button>
                          </Link>
                        </TableCell>
                      </TableRow>
                    )) }
                  </TableBody>
                </Table>
              </TableContainer>
            </GridItem>
          </GridContainer>
        ) : null }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
