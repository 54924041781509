import {
  getExperienceYearsOptions,
  getEnglishLevelOptions,
} from 'services/opportunity';

import {
  OPPORTUNITY_TEMPLATE_URL,
} from '../utils/apiRouting';

import {
  get, post, put,
} from '../utils/http';

export async function createTemplate(data) {
  return post(OPPORTUNITY_TEMPLATE_URL, data);
}

export async function updateTemplate(data) {
  return put(`${OPPORTUNITY_TEMPLATE_URL}/${data._id}`, data);
}

export async function getTemplates() {
  return get(OPPORTUNITY_TEMPLATE_URL);
}

export function findTemplateByPosition(templates, position, techStack = [], selectedTechnologies = [], englishLevel) {
  const findTemplate = (position) => templates.find((t) => t.position === position);
  const formatTechStack = (techStack) => (techStack ? techStack.join('\n') : '');

  const formatRequirements = (techStack, requirements, undefinedTechStack, englishLevel) => {
    let requirementsText = '';

    if (techStack.length) {
      requirementsText += `${formatTechStack(techStack)}\n`;
    }
    if (requirements !== '') {
      requirementsText += `${requirements}\n`;
    }
    if (undefinedTechStack.length) {
      requirementsText += `${formatTechStack(undefinedTechStack)}\n`;
    }
    if (englishLevel && englishLevel !== '') {
      requirementsText += englishLevel;
    }
    return requirementsText;
  };

  const formatRequirementsAndTechStack = (techStack, undefinedTechStack, englishLevel, requirements) => (
    requirements
      ? techStack.length || englishLevel
        ? formatRequirements(techStack, requirements, undefinedTechStack, englishLevel)
        : requirements
      : ''
  );

  const getNameAndExperienceForTechStackById = (stack) => {
    const experienceYearsList = getExperienceYearsOptions();
    const techStackName = techStack.find((technology) => technology._id.toString() === stack.id).name;
    const techStackExperience = experienceYearsList.find((experience) => experience.id.toString() === stack.experienceYears.toString()).name;

    return {
      techStackExperience,
      techStackName,
    };
  };

  let requirements = '';
  let responsibilities = '';
  if (templates.length) {
    const template = findTemplate(position);
    if (template) {
      requirements = template.requirements;
      responsibilities = template.responsibilities;
    }
  }

  const techStackList = [];
  const techStackListWithUndefinedExperience = [];
  if (selectedTechnologies.length || techStack.length) {
    selectedTechnologies.forEach((stack) => {
      if (stack.id && stack.experienceYears) {
        const { techStackExperience, techStackName } = getNameAndExperienceForTechStackById(stack);
        if (techStackExperience === 'undefined') {
          techStackListWithUndefinedExperience.push(`Experience with ${techStackName} will be a plus;`);
        } else {
          techStackList.push(`${techStackExperience} of experience with ${techStackName};`);
        }
      }

      return null;
    });
  }

  let englishLevelText;
  if (englishLevel) {
    englishLevelText = `${getEnglishLevelOptions().find((level) => level.id === englishLevel).name} or higher English level.`;
  }

  return {
    requirements: formatRequirementsAndTechStack(techStackList, techStackListWithUndefinedExperience, englishLevelText, requirements),
    responsibilities,
  };
}
