import React from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody';

import JiraAccess from './components/JiraAccess';

const useStyles = makeStyles((theme) => ({
}));

export default function JiraIntegration() {
  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <h4>Jira Access</h4>
          </CardHeader>
          <CardBody>
            <JiraAccess />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
