import React, { useState, useEffect, useContext } from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import {
  getCustomDates,
  createCustomDate,
  deleteCustomDate,
} from 'services/customDates';

import {
  formatDate,
  parseInputDate,
} from 'services/date';

import { can } from 'services/permission';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Button from 'components/CustomButtons/Button.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { ConsoleContext } from 'services/context';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '100%',
  },
  addDateComment: {
    marginTop: '15px',
  },
}));

export default function CustomDates(props) {
  useEffect(() => {
    async function fetchData() {
      showLoader(true);

      setCanEdit(await can('people:customDates:edit'));

      try {
        const dates = await getCustomDates();

        setDates(dates);

        const rows = dates.map((item) => ({ ...item }));

        setRows(rows);
      } catch (error) {
        showError(error);
      }

      showLoader(false);
    }

    fetchData();
  }, []);

  const classes = useStyles();

  const {
    showError, showSuccess, showConfirm, showLoader,
  } = useContext(ConsoleContext);

  const [canEdit, setCanEdit] = useState(false);

  const [dates, setDates] = useState([]);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [errorMessage, setErrorMessage] = useState('');

  const [rows, setRows] = useState([]);

  const [activeDate, setActiveDate] = useState({
    date: '',
    type: 'holiday',
  });

  const [openAddDialog, setOpenAddDialog] = useState(false);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  const handleAddDate = () => {
    setActiveDate({
      date: '',
      type: 'holiday',
    });

    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  const handleAddDialogSubmit = async () => {
    const data = { ...activeDate };

    if (!data.date) {
      return;
    }

    data.date = parseInputDate(data.date);

    try {
      const newDate = await createCustomDate(data);

      const changedRows = [{ ...newDate }, ...rows];

      setRows(changedRows);
      setOpenAddDialog(false);

      showSuccess('Date added');
    } catch (e) {
      showError(e);
    }
  };

  const handleDateChange = (field, value) => {
    const data = { ...activeDate };
    data[field] = value;

    setActiveDate(data);
  };

  const handleDeleteDate = async (date) => {
    showConfirm(async () => {
      try {
        await deleteCustomDate(date);
      } catch (e) {
        showError(e);
        return;
      }

      const changedRows = [...rows];

      const index = changedRows.findIndex((item) => item._id === date._id);
      changedRows.splice(index, 1);

      setRows(changedRows);
      setPage(0);

      showSuccess('Date deleted');
    });
  };

  const types = [
    { id: 'holiday', name: 'Holiday' },
    { id: 'work', name: 'Work' },
  ];

  return (
    <div>
      <GridContainer>
        { canEdit ? (
          <GridItem xs={12} sm={12} md={12}>
            <Button color="primary" style={{ float: 'right' }} onClick={handleAddDate}>+ Create Date</Button>
          </GridItem>
        ) : null }
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Type</TableCell>
                          <TableCell>Comment</TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                          <TableRow key={row._id}>
                            <TableCell>{formatDate(row.date)}</TableCell>
                            <TableCell>{row.type}</TableCell>
                            <TableCell>{row.comment}</TableCell>
                            <TableCell>
                              { canEdit ? (
                                <IconButton className={classes.margin} onClick={() => handleDeleteDate(row)}>
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              ) : null }
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[20, 50, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Dialog
        maxWidth="xs"
        fullWidth
        open={openAddDialog}
        onClose={handleCloseAddDialog}
      >
        <DialogTitle>Add Date</DialogTitle>
        <DialogContent className={classes.addDialogRoot}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                required
                fullWidth
                label="Date"
                type="date"
                value={activeDate.date}
                onChange={(e) => handleDateChange('date', e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <FormControl className={classes.formControl}>
                <InputLabel>Type</InputLabel>
                <Select
                  fullWidth
                  value={activeDate.type}
                  onChange={(e) => handleDateChange('type', e.target.value)}
                >

                  { types.map((type, index) => (
                    <MenuItem key={`type-${type.id}`} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                fullWidth
                className={classes.addDateComment}
                id="outlined-multiline-static"
                label="Comment"
                multiline
                rows={4}
                variant="outlined"
                onChange={(e) => handleDateChange('comment', e.target.value)}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddDialog}>
            Cancel
          </Button>
          <Button onClick={handleAddDialogSubmit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
