import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

// @material-ui/core components

import {
  getUsers,
  formatUserName,
} from 'services/user';

import {
  getUserContracts,
} from 'services/userContract';

import {
  formatDate,
} from 'services/date';

import {
  formatMoney,
  formatContractType,
} from 'services/finance';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';

import PagedTable from 'components/PagedTable/PagedTable';

import { ConsoleContext } from 'services/context';

import UserContractFilters from './components/UserContractFilters';

export default function UserContractList() {
  const { showError, showLoader, t } = useContext(ConsoleContext);

  const [rows, setRows] = useState([]);
  const [users, setUsers] = useState([]);
  const [userContracts, setUserContracts] = useState([]);
  const [filters, setFilters] = useState({
    status: 'active',
  });

  const columns = [
    { id: 'name', label: 'Partner' },
    { id: 'contractStartAt', label: 'Start Date' },
    { id: 'contractEndAt', label: 'End Date' },
    { id: 'contractType', label: 'Contract Type' },
    { id: 'contractMonthlyRate', label: 'Monthly Rate' },
    { id: 'contractHourlyRate', label: 'Hourly Rate' },
    { id: 'contractTaxByCompany', label: 'Tax Compensation' },
  ];

  useEffect(() => {
    (async () => {
      showLoader(true);

      try {
        const fetchedUsers = await getUsers();
        const fetchedContracts = await getUserContracts();

        setUsers(fetchedUsers);
        setUserContracts(fetchedContracts);
      } catch (error) {
        showError(error);
      }

      showLoader(false);
    })();
  }, []);

  useEffect(() => {
    if (filters.status && users.length) {
      const filteredUsers = users.filter(
        (user) => ((filters.status === 'active' && (user.status === 'active' || !user.status))
        ) || (user.status === 'archived' && filters.status === 'archived'),
      );

      const rows = filteredUsers.map((user) => {
        const contract = findUserContract(user, userContracts);

        return {
          id: user._id,
          name: (<Link to={`partner-contracts/${user._id}`}>{formatUserName(user)}</Link>),
          contractStartAt: contract ? formatDate(contract.startAt) : '-',
          contractEndAt: contract && contract.endAt ? formatDate(contract.endAt) : '-',
          contractType: contract ? t(formatContractType(contract.type)) : '-',
          contractMonthlyRate: contract && contract.monthlyRate ? formatMoney(contract.monthlyRate, contract.currency) : '-',
          contractHourlyRate: contract && contract.hourlyRate ? formatMoney(contract.hourlyRate, contract.currency) : '-',
          contractTaxByCompany: contract ? t(contract.taxByCompany ? 'Yes' : 'No') : '-',
        };
      });

      setRows(rows);
    }
  }, [users, userContracts, filters]);

  const handleFiltersChange = (newFilters) => {
    setFilters(newFilters);
  };

  const findUserContract = (user, contracts) => {
    let lastEndAt;
    let lastContract;

    let contract = contracts.find((contract) => {
      if (contract.userId !== user._id) {
        return null;
      }

      if (contract.endAt && (!lastEndAt || moment(contract.endAt).isAfter(lastEndAt))) {
        lastEndAt = moment(contract.endAt);
        lastContract = contract;
      }

      return !contract.endAt || !moment(contract.endAt).isBefore(moment(), 'day');
    });

    if (!contract && lastContract) {
      contract = lastContract;
    }

    return contract;
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <UserContractFilters
                    onChange={handleFiltersChange}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <PagedTable columns={columns} rows={rows} />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
