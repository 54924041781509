import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import {
  formatDate,
} from 'services/date';

import {
  formatUserNameById,
} from 'services/user';

import { ConsoleContext } from 'services/context';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import PagedTable from 'components/PagedTable/PagedTable';

import PartnerCompanyContacts from '../../PartnerCompany/components/PartnerCompanyContacts';
import PartnerCompanyStatus from './PartnerCompanyStatus';

export default function PartnerCompanyList({ opportunity, users }) {
  const { showLoader } = useContext(ConsoleContext);

  const [rows, setRows] = useState([]);

  const columns = [
    { id: 'name', label: 'Company Name' },
    { id: 'contacts', label: 'Contacts' },
    { id: 'manager', label: 'Domestic Partner' },
    { id: 'status', label: 'Status' },
    { id: 'addedAt', label: 'Added At' },
  ];

  useEffect(() => {
    if (!users.length || !opportunity.partnerCompanies || !opportunity.partnerCompanies.length) {
      return;
    }

    const rows = opportunity.partnerCompanies
      .sort((a, b) => {
        const statusPriority = {
          contacted: 0,
          added: 1,
          received_resume: 2,
          not_responding: 3,
          no_candidates: 4,
          ignore: 5,
        };

        if (statusPriority[a.status] === statusPriority[b.status]) {
          return moment.utc(a.addedAt) > moment.utc(b.addedAt) ? -1 : 1;
        }
        return statusPriority[a.status] < statusPriority[b.status] ? -1 : 1;
      })
      .map((opportunityCompany) => {
        const {
          company: {
            _id: id,
            contacts,
            managerId,
          },
          addedAt,
        } = opportunityCompany;

        return {
          id,
          manager: formatUserNameById(managerId, users),
          name: renderName(opportunityCompany.company),
          contacts: renderContacts(contacts),
          status: renderStatus(opportunityCompany),
          addedAt: formatDate(addedAt),
        };
      });

    setRows(rows);
  }, [opportunity, users]);

  const renderName = (company) => (<Link to={`/sales/partner-companies/${company._id}`}>{company.name}</Link>);

  const renderContacts = (contacts) => (<PartnerCompanyContacts contacts={contacts} />);

  const renderStatus = (opportunityCompany) => (
    <PartnerCompanyStatus
      opportunity={opportunity}
      opportunityCompany={opportunityCompany}
      onLoading={(loading) => showLoader(loading)}
    />
  );

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <PagedTable
            containerProps={{ style: { maxHeight: '600px' } }}
            tableProps={{ stickyHeader: true, style: { tableLayout: 'fixed' } }}
            columns={columns}
            rows={rows}
          />
        </GridItem>
      </GridContainer>
    </>
  );
}
