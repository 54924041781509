import {
  JIRA_URL,
} from '../utils/apiRouting';

import {
  get, post, put,
} from '../utils/http';

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

async function getSyncJob(id) {
  return get(`${JIRA_URL}/sync-jobs/${id}`);
}

export async function syncLogs(year, month) {
  const response = await post(`${JIRA_URL}/sync-jobs`, { year, month });

  const { _id: jobId } = response.job;

  const updateStatus = async () => {
    await delay(1000);

    let job = await getSyncJob(jobId);

    if (!job.lastFinishedAt) {
      job = await updateStatus();
    }

    return job;
  };

  return updateStatus();
}

export async function syncProjectLogs(year, month, projectId) {
  return post(`${JIRA_URL}/sync-project-logs`, { year, month, projectId });
}

export async function getLastSyncJob(year, month) {
  return get(`${JIRA_URL}/last-sync-job/${year}/${month}`);
}

export async function getAccesses() {
  return get(`${JIRA_URL}/accesses`);
}

export async function createAccess(access) {
  return post(`${JIRA_URL}/accesses`, access);
}

export async function updateAccess(access) {
  return put(`${JIRA_URL}/accesses/${access._id}`, access);
}
