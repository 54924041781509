import React, { useState, useEffect, useContext } from 'react';
import clone from 'just-clone';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import {
  getClient,
} from 'services/client';

import {
  getProjects,
  getProjectAllocation,
} from 'services/project';

import {
  getClientContracts,
  createClientContract,
  updateClientContract,
  deleteClientContract,
  getClientContractCurrencies,
  getClientHolidayConfig,
  getPreviousHolidayContract,
} from 'services/clientContract';

import {
  formatDate,
  parseInputDate,
  formatInputDate,
} from 'services/date';

import {
  can,
} from 'services/permission';

import {
  getDefaultCurrency,
  formatMoney,
  getContractTypes,
  formatContractType,
} from 'services/finance';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Button from 'components/CustomButtons/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Alert from '@material-ui/lab/Alert';

import { ConsoleContext } from 'services/context';
import ConfirmContractRate from '../components/ConfirmContractRate';
import ClientHolidayContractInfo from './components/ClientHolidayContractInfo';

const useStyles = makeStyles(() => ({
  grid: {
    marginTop: '15px',
  },
  formControl: {
    minWidth: '100%',
  },
  checkboxMargin: {
    marginTop: '20px',
  },
  allocation: {
    marginTop: '10px',

    '& .MuiAlert-message': {
      padding: '9px 0 10px 0',
    },
  },
  checkboxLabelSize: {
    fontSize: '13px',
  },
}));

export default function ClientContractDetails(props) {
  const { clientId } = props.match.params;

  const classes = useStyles();

  const {
    showError, showSuccess, showLoader, showConfirm, t, Trans,
  } = useContext(ConsoleContext);

  const [client, setClient] = useState();

  const [projects, setProjects] = useState([]);

  const [contracts, setContracts] = useState({});

  const [activeContract, setActiveContract] = useState();

  const [activeContractAllocation, setActiveContractAllocation] = useState({});

  const [previousHolidayContract, setPreviousHolidayContract] = useState();

  const [holidayConfig, setHolidayConfig] = useState();

  const [activeContractAllocationLoading, setActiveContractAllocationLoading] = useState(false);

  const [openAddDialog, setOpenAddDialog] = useState(false);

  const [canEdit, setCanEdit] = useState(false);

  const [contractRateConfirm, setContractRateConfirm] = useState();

  const [activeHolidayContract, setActiveHolidayContract] = useState();

  const [openHolidayContractInfo, setOpenHolidayContractInfo] = useState(false);

  useEffect(() => {
    async function fetchData() {
      showLoader(true);

      try {
        const client = await getClient(clientId);

        const projects = await getProjects({ clientId });

        const clientContracts = await getClientContracts({ clientId });

        const clientHolidayConfig = await getClientHolidayConfig();

        const contracts = {};

        projects.forEach((project) => {
          contracts[project._id] = {};

          project.members.forEach((member) => {
            const memberId = member.userId._id;

            contracts[project._id][memberId] = clientContracts.filter((contract) => contract.projectId === project._id
                && contract.userId === memberId);
          });
        });

        setHolidayConfig(clientHolidayConfig);
        setContracts(contracts);
        setProjects(projects);
        setClient(client);
      } catch (error) {
        showError(error);
      }

      setCanEdit(await can('finance:clientContracts:edit'));

      showLoader(false);
    }

    fetchData();
  }, [clientId]);

  useEffect(() => {
    if (activeContract && activeContract.holidayBillable) {
      const { projectId, userId } = activeContract;

      setPreviousHolidayContract(getPreviousHolidayContract(activeContract, contracts[projectId][userId]));
    }

    if (!activeContract || activeContract._id || !activeContract.userId) {
      return;
    }

    const { projectId, userId } = activeContract;

    if (activeContractAllocation && (userId === activeContractAllocation.userId)) {
      return;
    }

    (async () => {
      setActiveContractAllocationLoading(true);

      const allocation = await getProjectAllocation(projectId, { userId, billable: true });

      const activeAllocation = allocation.length ? allocation.pop() : null;

      setActiveContractAllocation(activeAllocation);
      setActiveContractAllocationLoading(false);
    })();
  }, [activeContract]);

  const handleAddContract = (project) => {
    let userId = '';

    if (project.members.length) {
      userId = project.members[0].userId._id;
    }

    setActiveContract({
      clientId,
      projectId: project._id,
      userId,
      currency: getDefaultCurrency(),
      startAt: '',
      endAt: '',
      type: 'hourly',
      monthlyRate: '',
      hourlyRate: '',
      dailyHours: '',
      excludeFromPrePayment: false,
      holidayBillable: false,
      holidayContractEditable: true,
      holidayContract: {
        startAt: '',
        dayOffLimit: '',
        vacationLimit: '',
      },
    });

    setOpenAddDialog(true);
  };

  const handleEditContract = (contract) => {
    const data = {
      ...contract,
      startAt: formatInputDate(contract.startAt),
      endAt: contract.endAt ? formatInputDate(contract.endAt) : '',
      monthlyRate: contract.monthlyRate ? contract.monthlyRate : '',
      hourlyRate: contract.hourlyRate ? contract.hourlyRate : '',
      dailyHours: contract.dailyHours ? contract.dailyHours : '',
      currency: contract.currency ? contract.currency : getDefaultCurrency(),
      holidayContractEditable: !contract.holidayContractId,
    };

    if (contract.holidayBillable) {
      data.holidayContract = {
        ...data.holidayContract,
        startAt: formatInputDate(contract.holidayContract.startAt),
      };
    } else {
      data.holidayContract = {
        startAt: '',
        dayOffLimit: '',
        vacationLimit: '',
      };
    }

    setActiveContract(data);
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  const handleAddDialogSubmit = async () => {
    const data = clone(activeContract);

    if (!data.userId) {
      return;
    }

    data.startAt = parseInputDate(data.startAt);
    data.endAt = data.endAt ? parseInputDate(data.endAt) : null;
    data.monthlyRate = data.monthlyRate ? Number(data.monthlyRate) : null;
    data.hourlyRate = data.hourlyRate ? Number(data.hourlyRate) : null;
    data.dailyHours = data.dailyHours ? Number(data.dailyHours) : null;

    if (data.type !== 'monthly') {
      data.dailyHours = null;
    }

    if (data.holidayContract && data.holidayContract.startAt) {
      data.holidayContract.startAt = parseInputDate(data.holidayContract.startAt);
    }

    confirmRates(data, async () => {
      const changedContracts = { ...contracts };
      const userContracts = changedContracts[data.projectId][data.userId];
      let newContract;

      try {
        if (data._id) {
          newContract = await updateClientContract(data);

          const index = userContracts.findIndex((item) => item._id === newContract._id);
          userContracts[index] = newContract;

          showSuccess('Contract updated');
        } else {
          const newContract = await createClientContract(data);

          userContracts.push(newContract);

          showSuccess('Contract created');
        }

        setContracts(changedContracts);
        setOpenAddDialog(false);
      } catch (e) {
        showError(e);
      }
    });
  };

  const handleContractChange = (field, value) => {
    const data = { ...activeContract };

    if (field === 'holidayBillable') {
      if (value) {
        data.holidayContract.startAt = data.startAt || '';
        data.holidayContract.dayOffLimit = holidayConfig.defaults.dayOff;
        data.holidayContract.vacationLimit = holidayConfig.defaults.vacation;
      } else {
        data.holidayContractId = null;
        data.holidayContractEditable = true;
      }
    }

    if ((field === 'startAt') && data.holidayBillable) {
      data.holidayContract.startAt = value;
    }

    data[field] = value;

    setActiveContract(data);
  };

  const handleDeleteContract = async (contract) => {
    showConfirm(async () => {
      try {
        await deleteClientContract(contract);
      } catch (e) {
        showError(e);
        return;
      }

      const changedContracts = { ...contracts };

      const userContracts = changedContracts[contract.projectId][contract.userId];

      const index = userContracts.findIndex((item) => item._id === contract._id);
      userContracts.splice(index, 1);

      setContracts(changedContracts);

      showSuccess('Contract deleted');
    });
  };

  const handleHolidayContractChange = (field, value) => {
    const data = { ...activeContract };

    data.holidayContract[field] = value;

    setActiveContract(data);
  };

  const handleOpenHolidayContractInfo = (holidayContract) => {
    setActiveHolidayContract(holidayContract);
    setOpenHolidayContractInfo(true);
  };
  const handleCloseHolidayContractInfo = () => {
    setOpenHolidayContractInfo(false);
  };

  const confirmRates = (contract, callback) => {
    setContractRateConfirm((<ConfirmContractRate contract={contract} onConfirm={callback} />));
  };

  const getProjectMembers = (projectId) => {
    const project = projects.find((project) => project._id === projectId);

    return project.members.map((member) => member.userId);
  };

  const handleUseAllocation = () => {
    const { startAt, endAt } = activeContractAllocation;

    const data = {
      ...activeContract,
      startAt: formatInputDate(startAt),
      endAt: endAt ? formatInputDate(endAt) : '',
    };

    setActiveContract(data);
  };

  const handleUsePreviousHolidayContract = () => {
    setActiveContract({
      ...activeContract,
      holidayContractId: previousHolidayContract._id,
      holidayContract: {
        ...previousHolidayContract,
        startAt: formatInputDate(previousHolidayContract.startAt),
      },
      holidayContractEditable: false,
    });
  };

  const renderPreviousHolidayContract = () => {
    if (!activeContract || activeContract.holidayContractId || !previousHolidayContract) {
      return null;
    }

    return (
      <Alert
        icon={false}
        className={classes.allocation}
        severity="info"
        action={(
          <Button size="sm" onClick={() => handleUsePreviousHolidayContract()}>
            {t('Use')}
          </Button>
        )}
      >
        {t('Previous Billable Holidays')}
        {': '}
        { formatDate(previousHolidayContract.startAt) }
        {', '}
        {' '}
        {previousHolidayContract.dayOffLimit}
        {' '}
        /
        {' '}
        {previousHolidayContract.vacationLimit}

      </Alert>
    );
  };

  const renderActiveContractAllocation = () => {
    if (!activeContract || activeContract._id) {
      return null;
    }

    let message;

    if (activeContractAllocationLoading) {
      message = 'Loading allocation';
    } else if (!activeContractAllocation) {
      message = 'No allocation';
    }

    if (message) {
      return (<Alert icon={false} severity="info" className={classes.allocation}>{t(message)}</Alert>);
    }

    const { startAt, endAt } = activeContractAllocation;

    return (
      <Alert
        icon={false}
        className={classes.allocation}
        severity="info"
        action={(
          <Button size="sm" onClick={() => handleUseAllocation()}>
            {t('Use dates')}
          </Button>
        )}
      >
        {t('Allocation')}
        {': '}
        { formatDate(startAt) }
        {' '}
        -
        { endAt ? formatDate(endAt) : '...' }
      </Alert>
    );
  };

  const formatRate = (contract, type) => (contract[type] ? formatMoney(contract[type], contract.currency) : '-');

  const renderHolidayContract = (contract) => {
    if (contract.holidayBillable && contract.holidayContract) {
      return (
        <a
          href="#"
          onClick={() => handleOpenHolidayContractInfo(contract.holidayContract)}
        >
          {formatDate(contract.holidayContract.startAt)}
          {', '}
          {contract.holidayContract.dayOffLimit}
          {' / '}
          {contract.holidayContract.vacationLimit}
        </a>
      );
    }

    return 'No';
  };

  return (
    <div>
      <GridContainer>
        { client ? (
          <>
            <GridItem xs={12} sm={12} md={12}>
              <h3>{t('{{name}} Contracts', { name: client.companyName })}</h3>
            </GridItem>
            { projects.length
              ? projects.map((project) => (
                <GridItem xs={12} sm={12} md={12} key={project._id}>
                  <Card>
                    <CardHeader color="success">
                      <h4>{t('Project {{projectName}}', { projectName: project.name })}</h4>
                    </CardHeader>
                    <CardBody>
                      <GridContainer>
                        { canEdit ? (
                          <GridItem xs={12} sm={12} md={12}>
                            { project.members.length ? (
                              <>
                                <Button color="primary" style={{ float: 'right' }} onClick={() => handleAddContract(project)}>
                                  {'+ '}
                                  {t('Create Contract')}
                                </Button>
                              </>
                            ) : t('No members. Can\'t create a contract')}
                          </GridItem>
                        ) : null }
                        <GridItem xs={12} sm={12} md={12}>
                          { project.members.map((member) => (
                            <GridContainer key={member._id}>
                              <GridItem xs={12} sm={12} md={12}>
                                <h4>
                                  {member.userId.firstName}
                                  {' '}
                                  {member.userId.lastName}
                                </h4>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={12}>
                                { contracts[project._id][member.userId._id].length ? (
                                  <TableContainer>
                                    <Table>
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>{t('Start Date')}</TableCell>
                                          <TableCell>{t('End Date')}</TableCell>
                                          <TableCell>{t('Contract Type')}</TableCell>
                                          <TableCell>{t('Hourly Rate')}</TableCell>
                                          <TableCell>{t('Monthly Rate')}</TableCell>
                                          <TableCell>{t('Hours/Day')}</TableCell>
                                          <TableCell>{t('Prepayment')}</TableCell>
                                          <TableCell>{t('Billable Holidays')}</TableCell>
                                          { canEdit ? (<TableCell />) : null }
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {contracts[project._id][member.userId._id].map((contract) => (
                                          <TableRow key={contract._id}>
                                            <TableCell>{formatDate(contract.startAt)}</TableCell>
                                            <TableCell>{formatDate(contract.endAt)}</TableCell>
                                            <TableCell>{t(formatContractType(contract.type))}</TableCell>
                                            <TableCell>{formatRate(contract, 'hourlyRate')}</TableCell>
                                            <TableCell>{formatRate(contract, 'monthlyRate')}</TableCell>
                                            <TableCell>{contract.dailyHours || '-'}</TableCell>
                                            <TableCell>{t(contract.excludeFromPrePayment ? 'No' : 'Yes')}</TableCell>
                                            <TableCell>
                                              {renderHolidayContract(contract)}
                                            </TableCell>

                                            { canEdit ? (
                                              <TableCell style={{ whiteSpace: 'nowrap' }}>
                                                <Button onClick={() => handleEditContract(contract)}>{t('Edit')}</Button>
                                                <IconButton className={classes.margin} onClick={() => handleDeleteContract(contract)}>
                                                  <DeleteIcon fontSize="small" />
                                                </IconButton>
                                              </TableCell>
                                            ) : null }
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                ) : t('No contracts') }
                              </GridItem>
                            </GridContainer>
                          ))}
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </GridItem>
              )) : (
                <GridItem xs={12} sm={12} md={12}>
                  <Card>
                    <CardBody>
                      { t('No projects') }
                    </CardBody>
                  </Card>
                </GridItem>
              )}
          </>
        ) : null }
      </GridContainer>
      { activeContract ? (
        <Dialog
          maxWidth="xs"
          fullWidth
          open={openAddDialog}
          onClose={handleCloseAddDialog}
        >
          <DialogTitle>{t(activeContract._id ? 'Edit Contract' : 'Add Contract')}</DialogTitle>
          <DialogContent>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <FormControl className={classes.formControl}>
                  <InputLabel>
                    {t('Partner')}
                    {' '}
                    *
                  </InputLabel>
                  <Select
                    disabled={!!activeContract._id}
                    fullWidth
                    value={activeContract.userId}
                    onChange={(e) => handleContractChange('userId', e.target.value)}
                  >

                    { getProjectMembers(activeContract.projectId).map((member) => (
                      <MenuItem key={member._id} value={member._id}>
                        {member.firstName}
                        {' '}
                        {member.lastName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <FormControl className={classes.formControl}>
                  <InputLabel>
                    {t('Currency')}
                    {' '}
                    *
                  </InputLabel>
                  <Select
                    disabled={!!activeContract._id}
                    fullWidth
                    value={activeContract.currency}
                    onChange={(e) => handleContractChange('currency', e.target.value)}
                  >

                    { getClientContractCurrencies().map((currency) => (
                      <MenuItem key={currency} value={currency}>
                        { currency }
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                { renderActiveContractAllocation() }
              </GridItem>
            </GridContainer>
            <GridContainer className={classes.grid}>
              <GridItem xs={12} sm={12} md={6}>
                <TextField
                  required
                  fullWidth
                  label={t('Start Date')}
                  type="date"
                  value={activeContract.startAt}
                  onChange={(e) => handleContractChange('startAt', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <TextField
                  fullWidth
                  label={t('End Date')}
                  type="date"
                  value={activeContract.endAt}
                  onChange={(e) => handleContractChange('endAt', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer className={classes.grid}>
              <GridItem xs={12} sm={12} md={6}>
                <FormControl className={classes.formControl}>
                  <InputLabel>
                    {t('Type')}
                    {' '}
                    *
                  </InputLabel>
                  <Select
                    fullWidth
                    value={activeContract.type}
                    onChange={(e) => handleContractChange('type', e.target.value)}
                  >

                    { getContractTypes().map((type) => (
                      <MenuItem key={`type-${type.id}`} value={type.id}>
                        {t(type.name)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>
              { activeContract && activeContract.type === 'monthly' ? (
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    style={{ whiteSpace: 'nowrap' }}
                    required
                    fullWidth
                    label={`${t('Monthly Rate')}, ${activeContract.currency}`}
                    value={activeContract.monthlyRate}
                    onChange={(e) => handleContractChange('monthlyRate', e.target.value)}
                  />
                </GridItem>
              ) : (
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    style={{ whiteSpace: 'nowrap' }}
                    required
                    fullWidth
                    label={`${t('Hourly Rate')}, ${activeContract.currency}`}
                    value={activeContract.hourlyRate}
                    onChange={(e) => handleContractChange('hourlyRate', e.target.value)}
                  />
                </GridItem>
              )}
            </GridContainer>
            { activeContract && activeContract.type === 'monthly' ? (
              <GridContainer className={classes.grid}>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    fullWidth
                    label={t('Hours/Day')}
                    value={activeContract.dailyHours}
                    onChange={(e) => handleContractChange('dailyHours', e.target.value)}
                    helperText={t("Leave empty if it's 8 hours a day")}
                  />
                </GridItem>
              </GridContainer>
            ) : null }
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <FormControlLabel
                  className={classes.checkboxMargin}
                  control={(
                    <Checkbox
                      checked={activeContract.excludeFromPrePayment}
                      onChange={(e) => handleContractChange('excludeFromPrePayment', e.target.checked)}
                      name="excludeFromPrePayment"
                      color="primary"
                    />
                        )}
                  label={(
                    <Trans i18nKey="Exclude from <1>Prepayment</1>">
                      <span className={classes.checkboxLabelSize}>
                        Exclude from
                        <strong> Prepayment</strong>
                      </span>
                    </Trans>
                        )}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <FormControlLabel
                  className={classes.checkboxMargin}
                  control={(
                    <Checkbox
                      checked={activeContract.holidayBillable}
                      onChange={(e) => handleContractChange('holidayBillable', e.target.checked)}
                      name="holidayBillable"
                      color="primary"
                    />
                        )}
                  label={(
                    <Trans i18nKey="Day-offs and Vacation billable by Client">
                      <span className={classes.checkboxLabelSize}>
                        Day-offs and Vacation billable
                        <strong> by Client</strong>
                      </span>
                    </Trans>
                        )}
                />
              </GridItem>
            </GridContainer>

            {activeContract.holidayBillable ? (
              <>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    { renderPreviousHolidayContract() }
                  </GridItem>
                </GridContainer>
                <GridContainer className={classes.grid}>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      fullWidth
                      type="number"
                      label={t('Billable Day-offs')}
                      disabled={!activeContract.holidayContractEditable}
                      value={activeContract.holidayContract.dayOffLimit}
                      onChange={(e) => handleHolidayContractChange('dayOffLimit', e.target.value)}
                      helperText={t(`min - 0, max - ${holidayConfig.limits.dayOff}`)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      fullWidth
                      type="number"
                      label={t('Billable Vacation Days')}
                      disabled={!activeContract.holidayContractEditable}
                      value={activeContract.holidayContract.vacationLimit}
                      onChange={(e) => handleHolidayContractChange('vacationLimit', e.target.value)}
                      helperText={t(`min - 0, max - ${holidayConfig.limits.vacation}`)}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer className={classes.grid}>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      required
                      fullWidth
                      label={t('Start Date')}
                      disabled={!activeContract.holidayContractEditable || Boolean(previousHolidayContract)}
                      type="date"
                      value={activeContract.holidayContract.startAt}
                      onChange={(e) => handleHolidayContractChange('startAt', e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer className={classes.grid}>
                  <GridItem xs={12} sm={12} md={12}>
                    <div style={{ fontSize: '13px' }}>
                      Holiday contract will start after 3 months from the Start Date
                    </div>
                  </GridItem>
                </GridContainer>

              </>
            )

              : null}

          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAddDialog}>
              {t('Cancel')}
            </Button>
            <Button onClick={handleAddDialogSubmit} color="primary">
              {t('Save')}
            </Button>
          </DialogActions>
        </Dialog>
      ) : null }

      { contractRateConfirm }

      <ClientHolidayContractInfo
        open={openHolidayContractInfo}
        onClose={handleCloseHolidayContractInfo}
        holidayContract={activeHolidayContract}
      />
    </div>
  );
}
