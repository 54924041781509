import React, { useContext, useState, useEffect } from 'react';

import { ConsoleContext } from 'services/context';

import {
  createPayrollPaymentMethod,
  updatePayrollPaymentMethod,
  deletePayrollPaymentMethod,
} from 'services/payroll';

import {
  formatMoney,
} from 'services/finance';

import { makeStyles } from '@material-ui/core/styles';

import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Button from 'components/CustomButtons/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles(() => ({
  cardButton: {
    position: 'absolute',
    right: '10px',
    top: '10px',
  },
  grid: {
    marginTop: '15px',
  },
  table: {
    '& td': {
      whiteSpace: 'nowrap',
    },
  },
}));

export default function PaymentMethodSummary({
  payroll, paymentMethods, onAdd, onChange, onDelete,
}) {
  const classes = useStyles();

  const {
    showSuccess, showError, t, can, showConfirm,
  } = useContext(ConsoleContext);

  const [activeMethod, setActiveMethod] = useState({
    name: '',
  });

  const [rows, setRows] = useState([]);

  const [totalAmount, setTotalAmount] = useState(0);

  const [totalPayedAmount, setTotalPayedAmount] = useState(0);

  const [openDialog, setOpenDialog] = useState(false);

  const [canEdit, setCanEdit] = useState(false);

  const unknownPaymentMethod = '_unknown';

  const columns = [
    { id: 'name', label: t('Name') },
    { id: 'partnerCount', label: t('Partners') },
    { id: 'amount', label: `${t('Amount')}, ${payroll.paymentCurrency}` },
    { id: 'payedAmount', label: `${t('Payed Amount')}, ${payroll.paymentCurrency}` },
  ];

  useEffect(() => {
    (async () => {
      setCanEdit(await can('finance:payrollPaymentMethod:edit'));
    })();
  }, []);

  useEffect(() => {
    if (!paymentMethods.length) {
      return;
    }

    const stats = calcMethodStats(paymentMethods, payroll);

    let total = 0;
    let totalPayed = 0;

    Object.values(stats).forEach((item) => {
      total += item.amount;
      totalPayed += item.payedAmount;
    });

    setTotalAmount(total);
    setTotalPayedAmount(totalPayed);

    setRows(prepareRows(paymentMethods, stats));
  }, [paymentMethods, payroll]);

  const prepareRows = (paymentMethods, stats) => {
    const rows = [];

    const prepareRow = (method, stats) => {
      const { _id, name } = method;
      const { amount, partnerCount, payedAmount } = stats;

      return {
        _id,
        name,
        amount,
        payedAmount,
        partnerCount,
      };
    };

    paymentMethods.forEach((method) => {
      if (!stats[method._id]) {
        return;
      }

      rows.push(prepareRow(method, stats[method._id]));
    });

    if (stats[unknownPaymentMethod]) {
      rows.push(prepareRow(
        {
          _id: unknownPaymentMethod,
          name: '-',
        },
        stats[unknownPaymentMethod],
      ));
    }

    return rows

      .sort((a, b) => (a.amount >= b.amount ? -1 : 1))
      .map((row) => ({
        ...row,
        amount: formatMoney(row.amount, false),
        payedAmount: formatMoney(row.payedAmount, false),
      }));
  };

  const calcMethodStats = (paymentMethods, payroll) => {
    const stats = {
      [unknownPaymentMethod]: {
        amount: 0,
        payedAmount: 0,
        partnerCount: 0,
      },
    };

    paymentMethods.forEach((method) => {
      stats[method._id] = {
        amount: 0,
        payedAmount: 0,
        partnerCount: 0,
      };
    });

    payroll.records
      .filter((record) => record.paymentFinalAmount > 0 || record.noContractDates.length !== 0)
      .forEach((record) => {
        let { paymentMethodId } = record;

        if (!paymentMethodId) {
          paymentMethodId = unknownPaymentMethod;
        }

        const item = stats[paymentMethodId];

        if (!item) {
          return;
        }

        const amount = record.paymentFinalAmount;

        item.amount += amount;
        item.partnerCount++;

        if (record.status === 'completed') {
          item.payedAmount += amount;
        }
      });

    return stats;
  };

  const handleMethodChange = (field, value) => {
    const data = { ...activeMethod };

    data[field] = value;

    setActiveMethod(data);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleAddMethod = () => {
    setActiveMethod({
      name: '',
    });

    setOpenDialog(true);
  };

  const handleEditMethod = (methodId) => {
    const method = paymentMethods.find((method) => method._id === methodId);

    setActiveMethod({ ...method });
    setOpenDialog(true);
  };

  const handleDeleteMethod = (methodId) => {
    const method = paymentMethods.find((method) => method._id === methodId);

    showConfirm(async () => {
      try {
        await deletePayrollPaymentMethod(method);

        showSuccess('Payment From deleted');

        onDelete(method);
      } catch (e) {
        showError(e);
      }
    });
  };

  const handleSubmit = async () => {
    const data = { ...activeMethod };

    try {
      if (data._id) {
        const changedMethod = await updatePayrollPaymentMethod(data);
        onChange(changedMethod);
        showSuccess('Payment From updated');
      } else {
        const newMethod = await createPayrollPaymentMethod(data);
        showSuccess('Payment From added');
        onAdd(newMethod);
      }

      setOpenDialog(false);
    } catch (e) {
      showError(e);
    }
  };

  return (
    <>
      <Card>
        <CardHeader color="warning">
          <h4>{t('Payment From')}</h4>
          { canEdit ? (
            <Button
              size="sm"
              className={classes.cardButton}
              onClick={handleAddMethod}
            >
              +
              {' '}
              {t('Add')}
            </Button>
          ) : null }
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              { rows.length ? (
                <TableContainer>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        { columns.map((column) => (
                          <TableCell key={column.id}>
                            {column.label}
                          </TableCell>
                        )) }

                        { canEdit ? (<TableCell>&nbsp;</TableCell>) : null }
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      { rows.map((row) => (
                        <TableRow key={row._id}>
                          { columns.map((column) => (
                            <TableCell key={column.id}>{ row[column.id] }</TableCell>
                          )) }

                          { canEdit ? (
                            <TableCell style={{ textAlign: 'right' }}>
                              { row._id !== unknownPaymentMethod ? (
                                <>
                                  <IconButton onClick={() => handleEditMethod(row._id)}>
                                    <EditIcon fontSize="small" titleAccess={t('Edit')} />
                                  </IconButton>
                                  <IconButton onClick={() => handleDeleteMethod(row._id)}>
                                    <DeleteIcon fontSize="small" titleAccess={t('Delete')} />
                                  </IconButton>
                                </>
                              ) : null }
                            </TableCell>
                          ) : null }
                        </TableRow>
                      )) }

                      <TableRow>
                        <TableCell colSpan={2}>
                          <strong>{t('Total Amount')}</strong>
                        </TableCell>
                        <TableCell>
                          <strong>{formatMoney(totalAmount, false)}</strong>
                        </TableCell>
                        <TableCell>
                          <strong>{formatMoney(totalPayedAmount, false)}</strong>
                        </TableCell>

                        { canEdit ? (<TableCell />) : null }
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : t('No payment methods') }
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>

      <Dialog
        maxWidth="xs"
        fullWidth
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>{t('Add Payment From')}</DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                fullWidth
                required
                label={t('Name')}
                value={activeMethod.name}
                onChange={(e) => handleMethodChange('name', e.target.value)}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>
            {t('Cancel')}
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {t('Save')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
