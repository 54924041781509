export const API_URL = '/api/';

export const LOGIN_URL = '/api/login';
export const LOGOUT_URL = '/api/logout';
export const PROJECT_URL = '/api/projects';
export const USER_URL = '/api/users';
export const CIRCLE_URL = '/api/circles';
export const ROLE_URL = '/api/roles';
export const CLIENT_URL = '/api/clients';
export const CUSTOM_DATES_URL = '/api/custom-dates';
export const USER_CUSTOM_DATES_URL = '/api/user-custom-dates';
export const JIRA_URL = '/api/jira';
export const PERMISSION_URL = '/api/permissions';
export const USER_CONTRACT_URL = '/api/user-contracts';
export const CLIENT_CONTRACT_URL = '/api/client-contracts';
export const CLIENT_HOLIDAY_CONFIG_URL = '/api/client-contracts/holiday-config';
export const CLIENT_HOLIDAY_CONTRACT = '/api/client-contracts/holiday-contracts';
export const SUMMARY_PROFIT_URL = '/api/summary-profit';
export const INVOICE_URL = '/api/invoices';
export const LOCALE_URL = '/api/locales';
export const CURRENCY_EXCHANGE_RATES_URL = '/api/currency-exchange-rates';
export const PAYROLL_URL = '/api/payroll';
export const PNL_REPORT_URL = '/api/pnl-report';
export const OPPORTUNITY_URL = '/api/opportunities';
export const OPPORTUNITY_MANAGERS_URL = '/api/opportunities/managers';
export const OPPORTUNITY_TEMPLATE_URL = '/api/opportunity-templates';
export const TECH_STACK_URL = '/api/tech-stack';
export const CANDIDATE_URL = '/api/candidates';
export const PARTNER_COMPANY_URL = '/api/partner-companies';
export const FINMAP_URL = '/api/finmap';
