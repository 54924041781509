import React, {
  useState, useEffect, useContext, Fragment,
} from 'react';

import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

import {
  formatCommunicationEfficiency,
  formatEnglishLevel,
  formatInterviewType,
} from 'services/opportunity';

import {
  deleteCandidateInterview,
  formatTechStackLevel,
  getCandidateInterviews,
} from 'services/candidate';

import {
  formatTechStack,
} from 'services/techStack';

import {
  formatUserName,
  getUsers,
} from 'services/user';

import { ConsoleContext } from 'services/context';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Table from 'components/Table/Table';
import Button from 'components/CustomButtons/Button';

const useStyles = makeStyles(() => ({
  grid: {
    marginTop: '15px',
  },
  alert: {
    display: 'inline-block',

    '& .MuiAlert-message': {
      padding: 0,
    },
  },
}));

export default function CandidateInterviewResults({
  opportunity, opportunityCandidate, techStack, onLoading,
}) {
  const classes = useStyles();

  const { showError, showSuccess, can } = useContext(ConsoleContext);

  const [requiredInterviews, setRequiredInterviews] = useState([]);

  const [users, setUsers] = useState([]);

  const [canEdit, setCanEdit] = useState(false);

  useEffect(() => {
    (async () => {
      setCanEdit(await can('sales:opportunities:edit'));

      const users = await getUsers();

      setUsers(users);
    })();
  }, []);

  useEffect(() => {
    onLoading(true);

    (async () => {
      try {
        const interviews = await getCandidateInterviews(opportunityCandidate.id);

        const requiredInterviews = opportunity.requiredInterviews.map((type) => {
          const existingInterviews = interviews.filter((interview) => interview.type === type);

          existingInterviews.forEach((interview) => {
            interview.opportunityFeedback = interview.feedback.opportunities.find((item) => item.id === opportunity._id);
          });

          return {
            type,
            interviews: existingInterviews,
          };
        });

        setRequiredInterviews(requiredInterviews);
      } catch (e) {
        showError(e);
      }

      onLoading(false);
    })();
  }, [opportunityCandidate]);

  const handleDeletePendingInterview = async (interviewId) => {
    try {
      await deleteCandidateInterview(opportunityCandidate.id, interviewId);

      const remainingInterviews = requiredInterviews.map((requiredInterview) => ({
        ...requiredInterview,
        interviews: requiredInterview.interviews.filter((el) => el.id !== interviewId),
      }));

      setRequiredInterviews(remainingInterviews);

      showSuccess('Interview successfully deleted');
    } catch (e) {
      showError(e);
    }
  };

  const formatInterviewer = (interviewerId, users) => {
    const user = users.find((item) => item._id === interviewerId);

    return formatUserName(user);
  };

  const renderTechStack = (interviewTechStack, techStack) => {
    if (!interviewTechStack.length) {
      return null;
    }

    const tableHead = ['Technology', 'Level', 'Comment'];

    const tableData = interviewTechStack.map((item) => [
      formatTechStack(item.id, techStack),
      formatTechStackLevel(item.level),
      item.comment,
    ]);

    return (<Table tableHead={tableHead} tableData={tableData} style={{ marginTop: 0 }} />);
  };

  const renderAdditionalTechStack = (additionalTechStack) => {
    if (!additionalTechStack) {
      return '-';
    }

    return (
      <ul>
        { additionalTechStack.split('\n').map((item, key) => (
          <Fragment key={key}>
            { item ? (
              <li>{item}</li>
            ) : null }
          </Fragment>
        )) }
      </ul>
    );
  };

  const formatBoolean = (value) => (value === true ? 'Yes' : (value === false ? 'No' : '-'));

  if (!opportunity.requiredInterviews.length) {
    return (
      <>
        Position has no interviews required
      </>
    );
  }

  if (!requiredInterviews.length) {
    return null;
  }

  return (
    <>
      { requiredInterviews.map((item, key) => (
        <GridContainer key={key} className={key > 0 ? classes.grid : null}>
          <GridItem xs={12} sm={12} md={12}>
            { key > 0 ? (
              <>
                <hr />
                <br />
              </>
            ) : null }

            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <strong>{ formatInterviewType(item.type) }</strong>
              </GridItem>
            </GridContainer>

            { item.interviews && item.interviews.length ? (
              <>
                { item.interviews.map((interview) => (
                  <div key={interview.id} className={classes.grid}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <strong>Interviewer:</strong>
                        {' '}
                        { formatInterviewer(interview.interviewerId, users) }
                      </GridItem>
                    </GridContainer>

                    { interview.status === 'completed' ? (
                      <>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <strong>Recommend:</strong>
                            {' '}
                            { interview.opportunityFeedback ? (
                              <Alert
                                icon={false}
                                color={interview.opportunityFeedback.recommend ? 'success' : 'error'}
                                className={classes.alert}
                              >
                                { formatBoolean(interview.opportunityFeedback.recommend) }
                              </Alert>
                            ) : '-' }
                          </GridItem>
                        </GridContainer>

                        { interview.type === 'tech' ? (
                          <>
                            <GridContainer className={classes.grid}>
                              <GridItem xs={12} sm={12} md={12}>
                                { renderTechStack(interview.feedback.techStack, techStack) }
                              </GridItem>
                            </GridContainer>

                            <GridContainer className={classes.grid}>
                              <GridItem xs={12} sm={12} md={12}>
                                <strong>Additional Tech Skills:</strong>
                                { renderAdditionalTechStack(interview.feedback.additionalTechStack) }
                              </GridItem>
                            </GridContainer>
                          </>
                        ) : interview.type === 'english' ? (
                          <>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={12}>
                                <strong>Level: </strong>
                                { formatEnglishLevel(interview.feedback.englishLevel) }
                              </GridItem>
                            </GridContainer>

                            <GridContainer>
                              <GridItem xs={12} sm={12} md={12}>
                                <strong>Communication Efficiency: </strong>
                                { formatCommunicationEfficiency(interview.feedback.communicationEfficiency) }
                              </GridItem>
                            </GridContainer>
                          </>
                        ) : interview.type === 'soft_skills' ? (
                          <>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={12}>
                                <strong>Time to Transfer: </strong>
                                { interview.feedback.transferInfo || '-' }
                              </GridItem>
                            </GridContainer>

                            <GridContainer>
                              <GridItem xs={12} sm={12} md={12}>
                                <strong>Has Other Offers: </strong>
                                { formatBoolean(interview.feedback.hasOtherOffers) }
                              </GridItem>
                            </GridContainer>

                            <GridContainer>
                              <GridItem xs={12} sm={12} md={12}>
                                <strong>Has FOP: </strong>
                                { formatBoolean(interview.feedback.hasFop) }
                              </GridItem>
                            </GridContainer>

                            { interview.feedback.hasFop === false ? (
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                  <strong>Ready to open FOP: </strong>
                                  { formatBoolean(interview.feedback.readyToOpenFop) }
                                </GridItem>
                              </GridContainer>
                            ) : null }
                          </>
                        ) : null }

                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <strong>Summary: </strong>
                            { interview.feedback.summary }
                          </GridItem>
                        </GridContainer>

                        { interview.feedback && interview.feedback.callRecordingLink ? (
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                              <strong>Call Recording: </strong>
                              <a href={interview.feedback.callRecordingLink} target="_blank" rel="noreferrer">
                                {interview.feedback.callRecordingLink}
                              </a>
                            </GridItem>
                          </GridContainer>
                        ) : null }

                        { interview.opportunityFeedback && interview.opportunityFeedback.comment ? (
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                              <strong>Opportunity Comment: </strong>
                              { interview.opportunityFeedback.comment }
                            </GridItem>
                          </GridContainer>
                        ) : null }
                      </>
                    ) : (
                      <>
                        <span>Waiting for </span>
                        <Link to={`/public/interviews/${interview._id}`} target="_blank">feedback</Link>
                        { canEdit && (
                        <div>
                          <Button
                            type="button"
                            color="danger"
                            size="sm"
                            onClick={() => handleDeletePendingInterview(interview._id)}
                          >
                            Remove
                          </Button>
                        </div>
                        )}
                      </>
                    )}
                  </div>
                ))}
              </>
            ) : 'No interview arranged' }
          </GridItem>
        </GridContainer>
      )) }
    </>
  );
}
