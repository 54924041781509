import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

import {
  getClientInvoiceReport,
} from 'services/invoice';

import {
  formatMoney,

  getDefaultCurrency,
} from 'services/finance';

import {
  formatDate,
} from 'services/date';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';

import { ConsoleContext } from 'services/context';

import TableReport from 'components/TableReport/TableReport';

const useStyles = makeStyles((theme) => ({
}));

export default function ClientInvoiceList({ clientId }) {
  const classes = useStyles();

  const { showError, t } = useContext(ConsoleContext);

  const [rows, setRows] = useState([]);

  const currency = getDefaultCurrency();

  const columns = [
    { id: 'key', label: t('Invoice Number') },
    { id: 'billingStartAt', label: t('Start Date') },
    { id: 'billingEndAt', label: t('End Date') },
    { id: 'serviceCompensation', label: `${t('Service Compensation')}, ${currency}` },
    { id: 'discount', label: `${t('Discount')}, ${currency}` },
    { id: 'additionalAmount', label: `${t('Additional Amount')}, ${currency}` },
    { id: 'lastInvoiceDebt', label: `${t('Previous Payment Debt')}, ${currency}` },
    { id: 'billingPeriodAmount', label: `PP ${t('Billing Period Amount')}, ${currency}` },
    { id: 'totalAmount', label: `${t('Total Amount')}, ${currency}` },
    { id: 'payedAmount', label: `${t('Payed Amount')}, ${currency}` },
  ];

  useEffect(() => {
    (async () => {
      try {
        const report = await getClientInvoiceReport(clientId);

        const rows = report.invoices.map((invoice) => ({
          id: invoice._id,
          key: (<Link to={`${clientId}/${invoice._id}`}>{invoice.key}</Link>),
          billingStartAt: formatDate(invoice.billingStartAt),
          billingEndAt: formatDate(invoice.billingEndAt),
          serviceCompensation: formatMoney(invoice.serviceCompensation, false),
          discount: formatMoney(invoice.discount, false),
          additionalAmount: formatMoney(invoice.additionalAmount, false),
          lastInvoiceDebt: formatMoney(invoice.lastInvoiceDebt, false),
          billingPeriodAmount: formatMoney(invoice.billingPeriodAmount, false),
          totalAmount: formatMoney(invoice.totalAmount, false),
          payedAmount: (invoice.payedAmount || invoice.payedAmount === 0) ? formatMoney(invoice.payedAmount, false) : '-',
        }));

        setRows(rows);
      } catch (error) {
        showError(error);
      }
    })();
  }, [clientId]);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <TableReport
          columns={columns}
          rows={rows}
        />
      </GridItem>
    </GridContainer>
  );
}
