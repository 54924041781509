import React, { useState, useEffect, useContext } from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter.js';
import Button from 'components/CustomButtons/Button.js';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';

import { ConsoleContext } from 'services/context';

import {
  getLocale,
  updateLocale,
  localeMessageKeys,
  i18n,
} from 'services/locale';

const useStyles = makeStyles((theme) => ({
  messages: {
    '& td': {
      borderBottom: 'none',
    },
  },
}));

export default function PermissionDetails(props) {
  const { localeId } = props.match.params;

  const classes = useStyles();

  const {
    showError, showSuccess, showConfirm, showLoader, t,
  } = useContext(ConsoleContext);

  const [locale, setLocale] = useState();

  const [messages, setMessages] = useState({});

  useEffect(() => {
    (async () => {
      showLoader(true);

      try {
        const locale = await getLocale(localeId);

        setMessages(parseMessages(locale));
        setLocale(locale);
      } catch (error) {
        showError(error);
      }

      showLoader(false);
    })();
  }, [localeId]);

  const handleMessageChange = (key, value) => {
    setMessages({ ...messages, [key]: value });
  };

  const handleSubmit = async () => {
    const changedMessages = [];

    Object.keys(messages).forEach((key) => {
      const value = messages[key];

      if (value) {
        changedMessages.push({ key, value });
      }
    });

    try {
      await updateLocale({ ...locale, messages: changedMessages });

      showSuccess('Locale updated');

      i18n.reloadResources();
    } catch (e) {
      showError(e);
    }
  };

  const parseMessages = (locale) => {
    const messages = {};

    locale.messages.forEach((message) => {
      messages[message.key] = message.value;
    });

    return messages;
  };

  const getCompletedPercentage = (messages) => {
    const total = Object.values(localeMessageKeys).length;
    const completed = Object.values(localeMessageKeys).filter((key) => Boolean(messages[key])).length;

    return Math.round(completed / total * 100);
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        { locale ? (
          <Card>
            <CardHeader color="success">
              <h4>{t('{{locale.name}} Messages', { locale })}</h4>
              {t('Completed {{percent}}%', { percent: getCompletedPercentage(messages) })}
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        { localeMessageKeys.map((key) => (
                          <TableRow key={key} className={classes.messages}>
                            <TableCell>
                              { key }
                            </TableCell>
                            <TableCell>
                              <TextField
                                fullWidth
                                value={messages[key] || ''}
                                onChange={(e) => handleMessageChange(key, e.target.value)}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button color="primary" onClick={handleSubmit}>Update</Button>
            </CardFooter>
          </Card>
        ) : null}
      </GridItem>
    </GridContainer>
  );
}
