import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import {
  getUserCustomDates,
  createUserCustomDate,
  deleteUserCustomDate,
  getRemainingBillableHolidays,
} from 'services/user';

import {
  formatDate,
  parseInputDate,
} from 'services/date';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Button from 'components/CustomButtons/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { ConsoleContext } from 'services/context';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: '100%',
  },
  addDateComment: {
    marginTop: '15px',
  },
  checkboxMargin: {
    marginTop: '20px',
  },
}));

export default function UserCustomDates({ userId }) {
  const classes = useStyles();

  const { showError, showSuccess, showConfirm } = useContext(ConsoleContext);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [rows, setRows] = useState([]);

  const [initRowsLoaded, setInitRowsLoaded] = useState(false);

  const [activeDate, setActiveDate] = useState({
    date: '',
    endDate: '',
    type: 'holiday',
    billableByCompany: true,
  });

  const [remainingHolidays, setRemainingHolidays] = useState({});

  const [openAddDialog, setOpenAddDialog] = useState(false);

  useEffect(() => {
    if (!initRowsLoaded) {
      return;
    }

    (async () => {
      const remainder = await getRemainingBillableHolidays(userId);
      setRemainingHolidays(remainder);
    })();
  }, [rows.length, initRowsLoaded]);

  useEffect(() => {
    (async () => {
      await fetchData();

      setInitRowsLoaded(true);
    })();
  }, [userId]);

  const fetchData = async () => {
    try {
      const dates = await getUserCustomDates(userId);

      const rows = dates.map((item) => ({ ...item }));
      setRows(rows);
    } catch (error) {
      showError(error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleAddDate = () => {
    setActiveDate({
      date: '',
      endDate: '',
      type: 'holiday',
      billableByCompany: true,
    });

    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  const handleAddDialogSubmit = async () => {
    const data = { ...activeDate };

    if (!data.date) {
      return;
    }

    data.date = parseInputDate(data.date);

    const endDate = data.endDate ? parseInputDate(data.endDate) : moment(data.date);
    delete data.endDate;

    const dates = [];

    for (let m = moment(data.date); !m.isAfter(endDate, 'day'); m.add(1, 'days')) {
      dates.push(moment(m));
    }

    if (!dates.length) {
      showError('Select a valid date range');
      return;
    }

    if (data.type === 'vacation') {
      data.vacation = true;
      data.type = 'holiday';
    } else if (data.type === 'dayOffSpecial') {
      data.type = 'holiday';
      data.dayOffSpecial = true;
      data.billableByCompany = true;
    }

    try {
      const results = [];

      while (dates.length) {
        const date = dates.shift();
        const result = await createUserCustomDate(userId, { ...data, date }); // eslint-disable-line
        results.push(result);
      }

      const changedRows = [...results.reverse(), ...rows];

      setRows(changedRows);
      setOpenAddDialog(false);

      showSuccess('Date(s) added');
    } catch (e) {
      showError(e);

      await fetchData();
    }
  };

  const handleDateChange = (field, value) => {
    const data = { ...activeDate };
    data[field] = value;

    setActiveDate(data);
  };

  const handleDeleteDate = async (date) => {
    showConfirm(async () => {
      try {
        await deleteUserCustomDate(userId, date);
      } catch (e) {
        showError(e);
        return;
      }

      const changedRows = [...rows];

      const index = changedRows.findIndex((item) => item._id === date._id);
      changedRows.splice(index, 1);

      setRows(changedRows);
      setPage(0);

      showSuccess('Date deleted');
    });
  };

  const types = [
    { id: 'vacation', name: 'Vacation' },
    { id: 'holiday', name: 'Day Off' },
    { id: 'dayOffSpecial', name: 'Day Off Special' },
    { id: 'work', name: 'Work' },
  ];

  const formatType = (row) => {
    let { type } = row;
    const { vacation, dayOffSpecial } = row;

    if (vacation) {
      type = 'vacation';
    } else if (dayOffSpecial) {
      type = 'dayOffSpecial';
    }

    return types.find((item) => item.id === type).name;
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={9}>
          <GridContainer>
            { remainingHolidays.yearStartAt ? (
              <>
                <GridItem xs={12} sm={12} md={4}>
                  <Alert icon={false} color="info">
                    <strong>Start of Year</strong>
                    {': '}
                    {remainingHolidays.yearStartAt ? formatDate(remainingHolidays.yearStartAt) : ''}
                  </Alert>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <Alert icon={false} color="info">
                    <strong>Remaining Vacation Days</strong>
                    {': '}
                    {remainingHolidays.vacation || 0}
                  </Alert>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <Alert icon={false} color="info">
                    <strong>Remaining Days off</strong>
                    {': '}
                    {remainingHolidays.daysOff || 0}
                  </Alert>
                </GridItem>
              </>
            ) : (
              <GridItem xs={12} sm={12} md={6}>
                <Alert icon={false} color="warning">
                  No billable holidays available for the current capacity
                </Alert>
              </GridItem>
            ) }
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={3} container justify="flex-end">
          <Button color="primary" style={{ float: 'right' }} onClick={handleAddDate}>+ Add Date(s)</Button>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Comment</TableCell>
                  <TableCell>Billable By Company</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                  <TableRow key={row._id}>
                    <TableCell>{formatDate(row.date)}</TableCell>
                    <TableCell>{formatType(row)}</TableCell>
                    <TableCell>{row.comment}</TableCell>
                    <TableCell>{row.billableByCompany ? 'yes' : 'no'}</TableCell>
                    <TableCell>
                      <IconButton className={classes.margin} onClick={() => handleDeleteDate(row)}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[20, 50, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </GridItem>
      </GridContainer>
      <Dialog
        maxWidth="xs"
        fullWidth
        open={openAddDialog}
        onClose={handleCloseAddDialog}
      >
        <DialogTitle>Add Date(s)</DialogTitle>
        <DialogContent className={classes.addDialogRoot}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                required
                fullWidth
                label="Start Date"
                type="date"
                value={activeDate.date}
                onChange={(e) => handleDateChange('date', e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                label="End Date"
                type="date"
                value={activeDate.endDate}
                onChange={(e) => handleDateChange('endDate', e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <FormControl className={`${classes.formControl} ${classes.addDateComment}`}>
                <InputLabel>Type</InputLabel>
                <Select
                  fullWidth
                  value={activeDate.type}
                  onChange={(e) => handleDateChange('type', e.target.value)}
                >

                  {types.map((type) => (
                    <MenuItem key={`type-${type.id}`} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
            { activeDate.type !== 'dayOffSpecial' ? (
              <GridItem xs={12} sm={12} md={7}>
                <FormControlLabel
                  className={classes.checkboxMargin}
                  control={(
                    <Checkbox
                      checked={activeDate.billableByCompany}
                      onChange={(e) => handleDateChange('billableByCompany', e.target.checked)}
                      color="primary"
                    />
                  )}
                  label="Billable by Company"
                />
              </GridItem>
            ) : null }
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                fullWidth
                className={classes.addDateComment}
                id="outlined-multiline-static"
                label="Comment"
                multiline
                rows={4}
                variant="outlined"
                required={activeDate.type === 'dayOffSpecial'}
                onChange={(e) => handleDateChange('comment', e.target.value)}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddDialog}>
            Cancel
          </Button>
          <Button onClick={handleAddDialogSubmit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
