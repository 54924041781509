import React, {
  useState, useEffect, useContext, Fragment, useRef,
} from 'react';

import moment from 'moment';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import {
  getProjectMonthlySummary,
} from 'services/project';

import {
  formatHours,
} from 'services/date';

import { can } from 'services/permission';

import { ConsoleContext } from 'services/context';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import MonthPicker from 'components/MonthPicker/MonthPicker';
import ProjectLogsSync from './ProjectLogsSync';
import ProjectLogsExport from './ProjectLogsExport';
import ProjectLogEditDialog from './ProjectLogEditDialog';
import ProjectLogsDialog from './ProjectLogsDialog';
import ProjectOvertimeDialog from './ProjectOvertimeDialog';

const useStyles = makeStyles(() => ({
  timesheetTable: {
    '& th, & td': {
      border: '1px solid #d4d5d5',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      padding: '6px 0!important',
      overflow: 'hidden',
    },
    '& table': {
      tableLayout: 'fixed',
    },
  },
  timesheetToday: {
    backgroundColor: '#11b5c9',
    color: '#fff',
  },
  cellLog: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.6,
    },
  },
}));

export default function ProjectLogs({
  project, formatMemberName, savedMembers, userAllocation,
}) {
  const classes = useStyles();

  const { showError, getSessionStorage } = useContext(ConsoleContext);

  const [canEdit, setCanEdit] = useState(false);

  const [date, setDate] = useState({
    year: 0,
    month: 0,
  });

  const [summary, setSummary] = useState();

  const [days, setDays] = useState([]);

  const [openLogsDialog, setOpenLogsDialog] = useState(false);

  const [activeDate, setActiveDate] = useState({
    date: null,
    userId: null,
    billable: null,
  });

  const [activeLog, setActiveLog] = useState({});

  const [openAddDialog, setOpenAddDialog] = useState(false);

  const [activeOvertime, setActiveOvertime] = useState({});

  const [openOvertimeDialog, setOpenOvertimeDialog] = useState(false);

  const [total, setTotal] = useState({});

  const storage = getSessionStorage('production');

  const bgColors = {
    plan: '#ffe599',
    logBillable: '#efefef',
    logNonBillable: '#d9d9d9',
    logBillableUndertime: '#ff0202',
    logBillableOverdue: '#fcff04',
    overtimeBillable: '#fff',
    overtimeNonBillable: '#f3f3f3',
    fact: '#b6d7a9',
  };

  const tableRef = useRef(null);

  useEffect(() => {
    const { year, month } = date;

    if (!year) {
      return;
    }

    const startDate = moment.utc(`${year} ${month + 1} 1`, 'YYYY M D');
    const endDate = moment.utc(startDate).endOf('month');

    const days = [];

    for (let d = moment.utc(startDate); d < endDate; d.add(1, 'days')) {
      days.push({
        date: moment.utc(d),
        name: d.format('ddd D'),
      });
    }

    setDays(days);

    async function fetchData() {
      try {
        const summary = await getProjectMonthlySummary(project._id, year, month);

        const groupedSummary = {};

        summary.forEach((data) => {
          const { userId } = data;
          const date = moment.utc(data.date).format('D');

          if (!groupedSummary[userId]) {
            groupedSummary[userId] = {};
          }

          groupedSummary[userId][date] = data;
        });

        setCanEdit(await can('production:logs:edit'));

        setSummary(groupedSummary);
        updateTotal(groupedSummary);
      } catch (e) {
        showError(e);
      }
    }

    fetchData();
  }, [date, savedMembers.length, userAllocation]);

  useEffect(() => {
    if (!tableRef.current) {
      return;
    }

    const today = moment.utc();

    const currentDate = moment.utc(`${date.year}-${date.month + 1}`, 'YYYY-MM');

    if (!currentDate.isSame(today, 'month')) {
      tableRef.current.scrollLeft = 0;
      return;
    }

    const dayIndex = today.format('D') - 1;

    const width = tableRef.current.offsetWidth;
    const left = dayIndex * 150 - Math.round(width / 2) + 75;

    tableRef.current.scrollLeft = left;
  }, [date, tableRef.current]);

  const handleMonthChange = async ({ year, month }) => {
    setDate({ year, month });
  };

  const handleShowLogs = async (userId, date, billable, overtimeValue) => {
    setActiveDate({
      projectId: project._id, date, userId, billable, overtimeValue,
    });
    setOpenLogsDialog(true);
  };

  const handleCloseLogsDialog = () => {
    setOpenLogsDialog(false);
  };

  const handleAddLog = async ({ date, billable, userId }) => {
    setOpenLogsDialog(false);

    setActiveLog({
      workedAt: date,
      billable,
      userId,
      projectId: project._id,
    });

    setOpenAddDialog(true);
  };

  const handleEditLog = async (log) => {
    setOpenLogsDialog(false);
    setActiveLog(log);
    setOpenAddDialog(true);
  };

  const handleDeleteLog = async (log) => {
    setOpenLogsDialog(false);

    updateActiveDate(-1 * formatHours(log.timeSpentSeconds));
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  const handleLogUpdate = async (updatedLog) => {
    const isNew = !activeLog._id;

    const oldHours = activeLog.timeSpentSeconds ? formatHours(activeLog.timeSpentSeconds) : 0;

    const changedDates = [];

    if (!isNew) {
      changedDates.push({
        date: activeDate.date,
        billable: activeDate.billable,
        increaseHours: -1 * oldHours,
      });
    }

    setOpenAddDialog(false);

    changedDates.push({
      date: updatedLog.workedAt,
      billable: updatedLog.billable,
      increaseHours: formatHours(updatedLog.timeSpentSeconds),
    });

    // update summary
    const newSummary = { ...summary };
    const { userId } = updatedLog;

    if (!newSummary[userId]) {
      newSummary[userId] = {};
    }

    changedDates.forEach(({ date, billable, increaseHours }) => {
      const day = moment.utc(date).format('D');

      if (!newSummary[userId][day]) {
        newSummary[userId][day] = {
          planBillableHours: 0,
          logBillableHours: 0,
          logNonBillableHours: 0,
          overtimeBillableHours: 0,
          overtimeNonBillableHours: 0,
        };
      }

      const summaryDay = newSummary[userId][day];

      if (billable) {
        summaryDay.logBillableHours = (summaryDay.logBillableHours || 0) + increaseHours;
      } else {
        summaryDay.logNonBillableHours = (summaryDay.logNonBillableHours || 0) + increaseHours;
      }
    });

    setSummary(newSummary);

    updateTotal(newSummary);
  };

  const handleAddOvertime = () => {
    setActiveOvertime({
      date: activeDate.date,
      projectId: project._id,
      userId: activeDate.userId,
      billableByClient: activeDate.billable,
    });

    setOpenLogsDialog(false);
    setOpenOvertimeDialog(true);
  };

  const handleCloseOvertimeDialog = () => {
    setOpenOvertimeDialog(false);
  };

  const handleEditOvertime = (item) => {
    setOpenLogsDialog(false);

    setActiveOvertime(item);

    setOpenOvertimeDialog(true);
  };

  const handleDeleteOvertime = (item) => {
    setOpenLogsDialog(false);

    updateActiveDate(0, -1 * formatHours(item.timeSpentSeconds));
  };

  const handleOvertimeUpdate = async (updatedOvertime) => {
    const isNew = !activeOvertime._id;

    let hours = formatHours(updatedOvertime.timeSpentSeconds);
    const oldHours = activeOvertime.timeSpentSeconds ? formatHours(activeOvertime.timeSpentSeconds) : 0;

    if (!isNew) {
      hours -= oldHours;
    }

    setOpenOvertimeDialog(false);

    updateActiveDate(0, hours, updatedOvertime.rateByCompany);
  };

  const handleLogsSync = () => {
    setDate({ ...date });
  };

  const updateActiveDate = (increaseHours, increaseOvertimeHours = 0, overtimeRateByCompany) => {
    const { date, userId, billable } = activeDate;

    const newSummary = { ...summary };

    const day = moment.utc(date).format('D');

    if (!newSummary[userId]) {
      newSummary[userId] = {};
    }

    if (!newSummary[userId][day]) {
      newSummary[userId][day] = {
        planBillableHours: 0,
        logBillableHours: 0,
        logNonBillableHours: 0,
        overtimeBillableHours: 0,
        overtimeNonBillableHours: 0,
      };
    }

    const summaryDay = newSummary[userId][day];

    if (billable) {
      summaryDay.logBillableHours = (summaryDay.logBillableHours || 0) + increaseHours;
      summaryDay.overtimeBillableHours = (summaryDay.overtimeBillableHours || 0) + increaseOvertimeHours;

      if (overtimeRateByCompany) {
        summaryDay.overtimeBillableRateByCompany = overtimeRateByCompany;
      }
    } else {
      summaryDay.logNonBillableHours = (summaryDay.logNonBillableHours || 0) + increaseHours;
      summaryDay.overtimeNonBillableHours = (summaryDay.overtimeNonBillableHours || 0) + increaseOvertimeHours;

      if (overtimeRateByCompany) {
        summaryDay.overtimeNonBillableRateByCompany = overtimeRateByCompany;
      }
    }

    setSummary(newSummary);

    updateTotal(newSummary);
  };

  const updateTotal = (summary) => {
    const total = {};

    Object.keys(summary).forEach((userId) => {
      if (!total[userId]) {
        total[userId] = {
          planBillable: 0,
          planNonBillable: 0,
          factBillable: 0,
          factNonBillable: 0,
          overtimeBillableX10: 0,
          overtimeBillableX15: 0,
          overtimeNonBillableX10: 0,
          overtimeNonBillableX15: 0,
        };
      }

      const userTotal = total[userId];

      Object.keys(summary[userId]).forEach((date) => {
        const summaryDay = summary[userId][date];

        userTotal.planBillable += summaryDay.planBillableHours || 0;
        userTotal.planNonBillable += summaryDay.planNonBillableHours || 0;
        userTotal.factBillable += summaryDay.logBillableHours || 0;
        userTotal.factNonBillable += summaryDay.logNonBillableHours || 0;

        if (summaryDay.overtimeBillableHours) {
          if (summaryDay.overtimeBillableRateByCompany === 1) {
            userTotal.overtimeBillableX10 += summaryDay.overtimeBillableHours || 0;
          } else {
            userTotal.overtimeBillableX15 += summaryDay.overtimeBillableHours || 0;
          }
        }

        if (summaryDay.overtimeNonBillableHours) {
          if (summaryDay.overtimeNonBillableRateByCompany === 1) {
            userTotal.overtimeNonBillableX10 += summaryDay.overtimeNonBillableHours || 0;
          } else {
            userTotal.overtimeNonBillableX15 += summaryDay.overtimeNonBillableHours || 0;
          }
        }
      });
    });

    setTotal(total);
  };

  const renderTimeCell = (userId, date, type, billable) => {
    const day = date.format('D');

    if (!summary[userId] || !summary[userId][day]) {
      return (
        <TableCell>-</TableCell>
      );
    }

    const summaryDay = summary[userId][day];

    const { holiday } = summaryDay;

    let value;
    let bgColor;
    let title = '';
    let className;
    let onClick = () => {};

    switch (type) {
      case 'plan': {
        if (holiday) {
          value = '';
        } else {
          value = summaryDay.planBillableHours || 0;

          if ((type === 'plan') && summaryDay.planNonBillableHours) {
            value += `/${summaryDay.planNonBillableHours}`;
          }

          value = value ? (<strong>{value}</strong>) : 0;
        }

        bgColor = bgColors.plan;
        className = classes.cellPlan;
        break;
      }

      case 'log': {
        className = classes.cellLog;

        const field = billable ? 'logBillableHours' : 'logNonBillableHours';

        value = summaryDay[field] || 0;

        const overtimeValue = billable ? summaryDay.overtimeBillableHours : summaryDay.overtimeNonBillableHours;

        if (holiday && !value && !overtimeValue) {
          value = '';
        }

        bgColor = billable ? bgColors.logBillable : bgColors.logNonBillable;

        if (value || overtimeValue) {
          if (billable && value < summaryDay.planBillableHours) {
            bgColor = bgColors.logBillableOverdue;
          }
          title = 'Click to view logs';

          value = (
            <strong>
              {value}
              {overtimeValue ? (
                <span style={{ color: 'red' }}>
                  (
                  {overtimeValue}
                  )
                </span>
              ) : null}
            </strong>
          );
          onClick = () => handleShowLogs(userId, date, billable, overtimeValue);
        } else {
          if (billable && summaryDay.planBillableHours && date.isBefore(moment.utc(), 'day')) {
            bgColor = bgColors.logBillableUndertime;
          }

          if (canEdit) {
            title = 'Click to add logs';
            onClick = () => {
              const activeDate = { date, userId, billable };

              setActiveDate(activeDate);
              handleAddLog(activeDate);
            };
          } else {
            className = '';
          }
        }
        break;
      }

      default:
        break;
    }

    return (
      <TableCell
        className={className}
        title={title}
        style={{ backgroundColor: bgColor }}
        onClick={onClick}
      >
        {value}
      </TableCell>
    );
  };

  const checkCurrentDay = (day) => moment.utc(day.date).isSame(moment.utc(), 'day');

  const daySpan = project.billable ? 3 : 2;
  const dayWidth = 50 * daySpan;
  const summarySpan = project.billable ? 8 : 4;
  const summaryWidth = project.billable ? 450 : 225;

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={2}>
          <MonthPicker
            storage={storage}
            onChange={handleMonthChange}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={10}>
          <div style={{ float: 'right' }}>
            <ProjectLogsSync
              project={project}
              date={date}
              onSync={handleLogsSync}
            />

            {project.billable && (
              <div style={{ marginLeft: '10px', display: 'inline-block' }}>
                <ProjectLogsExport
                  project={project}
                  date={date}
                />
              </div>
            )}
          </div>
        </GridItem>
      </GridContainer>
      <br />
      { summary ? (
        <GridContainer>
          <GridItem xs={12} sm={12} md={3}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ borderColor: '#fff' }}>&nbsp;</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderColor: '#fff' }}>&nbsp;</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Member</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { savedMembers.map((member) => (
                    <TableRow key={member.userId}>
                      <TableCell>{formatMemberName(member)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <TableContainer className={classes.timesheetTable} ref={tableRef}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={days.length * daySpan} width={`${dayWidth * days.length}px`}>&nbsp;</TableCell>
                    <TableCell colSpan={summarySpan} width={`${summaryWidth}px`}>Summary</TableCell>
                  </TableRow>
                  <TableRow>
                    {days.map((day) => (
                      <TableCell
                        key={day.name}
                        colSpan={daySpan}
                        width={`${dayWidth}px`}
                        className={checkCurrentDay(day) ? classes.timesheetToday : null}
                      >
                        {day.name}
                      </TableCell>
                    ))}

                    <TableCell colSpan={project.billable ? 2 : 1} style={{ backgroundColor: bgColors.plan }}>Plan</TableCell>
                    <TableCell colSpan={project.billable ? 2 : 1} style={{ backgroundColor: bgColors.fact }}>Fact</TableCell>

                    {project.billable && (
                      <TableCell
                        colSpan={2}
                        title="Over Billable"
                        style={{ backgroundColor: bgColors.overtimeBillable }}
                      >
                        Over B
                      </TableCell>
                    )}

                    <TableCell
                      colSpan={2}
                      title="Over Non Billable"
                      style={{ backgroundColor: bgColors.overtimeNonBillable }}
                    >
                      Over NB
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    {days.map((day) => (
                      <Fragment key={day.name}>
                        <TableCell style={{ backgroundColor: bgColors.plan }} title="Billable/Non Billable Plan">P</TableCell>

                        {project.billable && (
                          <TableCell style={{ backgroundColor: bgColors.logBillable }} title="Billable">B</TableCell>
                        )}

                        <TableCell style={{ backgroundColor: bgColors.logNonBillable }} title="NonBillable">NB</TableCell>
                      </Fragment>
                    ))}

                    {project.billable && (
                      <TableCell style={{ backgroundColor: bgColors.plan }} title="Billable">B</TableCell>
                    )}

                    <TableCell style={{ backgroundColor: bgColors.plan }} title="Non Billable">NB</TableCell>

                    {project.billable && (
                      <TableCell style={{ backgroundColor: bgColors.fact }} title="Billable">B</TableCell>
                    )}
                    <TableCell style={{ backgroundColor: bgColors.fact }} title="Non Billable">NB</TableCell>

                    {project.billable && (
                      <>
                        <TableCell style={{ backgroundColor: bgColors.overtimeBillable }}>x1.5</TableCell>
                        <TableCell style={{ backgroundColor: bgColors.overtimeBillable }}>x1</TableCell>
                      </>
                    )}

                    <TableCell style={{ backgroundColor: bgColors.overtimeNonBillable }}>x1.5</TableCell>
                    <TableCell style={{ backgroundColor: bgColors.overtimeNonBillable }}>x1</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { savedMembers.map((member) => (
                    <TableRow key={member.userId}>
                      {days.map((day) => (
                        <Fragment key={day.name}>
                          {renderTimeCell(member.userId, day.date, 'plan')}
                          {project.billable && renderTimeCell(member.userId, day.date, 'log', true)}
                          {renderTimeCell(member.userId, day.date, 'log', false)}
                        </Fragment>
                      ))}

                      { total[member.userId] ? (
                        <>
                          {project.billable && (
                            <TableCell style={{ backgroundColor: bgColors.plan }}>{total[member.userId].planBillable}</TableCell>
                          )}
                          <TableCell style={{ backgroundColor: bgColors.plan }}>{total[member.userId].planNonBillable}</TableCell>

                          {project.billable && (
                            <TableCell style={{ backgroundColor: bgColors.fact }}>{total[member.userId].factBillable}</TableCell>
                          )}
                          <TableCell style={{ backgroundColor: bgColors.fact }}>{total[member.userId].factNonBillable}</TableCell>

                          {project.billable && (
                            <>
                              <TableCell style={{ backgroundColor: bgColors.overtimeBillable }}>
                                {total[member.userId].overtimeBillableX15}
                              </TableCell>
                              <TableCell style={{ backgroundColor: bgColors.overtimeBillable }}>
                                {total[member.userId].overtimeBillableX10}
                              </TableCell>
                            </>
                          )}

                          <TableCell style={{ backgroundColor: bgColors.overtimeNonBillable }}>
                            {total[member.userId].overtimeNonBillableX15}
                          </TableCell>
                          <TableCell style={{ backgroundColor: bgColors.overtimeNonBillable }}>
                            {total[member.userId].overtimeNonBillableX10}
                          </TableCell>
                        </>
                      ) : (<TableCell colSpan={summarySpan}>&nbsp;</TableCell>)}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </GridItem>
        </GridContainer>
      ) : null }

      <ProjectLogsDialog
        open={openLogsDialog}
        formatMemberName={formatMemberName}
        {...activeDate}
        canEdit={canEdit}
        onClose={handleCloseLogsDialog}
        onAddLog={handleAddLog}
        onEditLog={handleEditLog}
        onDeleteLog={handleDeleteLog}
        onAddOvertime={handleAddOvertime}
        onEditOvertime={handleEditOvertime}
        onDeleteOvertime={handleDeleteOvertime}
      />

      <ProjectLogEditDialog
        open={openAddDialog}
        log={activeLog}
        formatMemberName={formatMemberName}
        project={project}
        onClose={handleCloseAddDialog}
        onSave={handleLogUpdate}
      />

      <ProjectOvertimeDialog
        open={openOvertimeDialog}
        overtime={activeOvertime}
        billable={activeDate.billable}
        formatMemberName={formatMemberName}
        onClose={handleCloseOvertimeDialog}
        onSave={handleOvertimeUpdate}
      />
    </>
  );
}
