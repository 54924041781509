import React from 'react';

import PageWithTabs from '../../components/PageWithTabs/PageWithTabs';
import RoleList from '../Role/RoleList';
import RoleDetails from '../Role/RoleDetails';
import ClientList from './Client/ClientList';
import ClientDetails from './Client/ClientDetails';

export default function General(props) {
  const entities = [{
    id: 'client',
    path: 'clients',
    name: 'Clients',
    can: 'general:clients:view',
    components: [{
      path: '/general/clients/create',
      component: ClientDetails,
    }, {
      path: '/general/clients/:id',
      component: ClientDetails,
    }, {
      path: '/general/clients',
      component: ClientList,
    }],
  }, {
    id: 'role',
    path: 'roles',
    name: 'Roles',
    can: 'general:roles:view',
    components: [{
      path: '/:circleId/roles/create',
      component: RoleDetails,
    }, {
      path: '/:circleId/roles/:id',
      component: RoleDetails,
    }, {
      path: '/:circleId/roles',
      component: RoleList,
    }],
  }];

  return (
    <PageWithTabs basePath="general" entities={entities} {...props} />
  );
}
