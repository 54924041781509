import {
  ROLE_URL,
} from '../utils/apiRouting';

import {
  get, post, put, formatQuery,
} from '../utils/http';

export async function getRoles(query = {}) {
  return get(ROLE_URL + formatQuery(query));
}

export async function getRole(id) {
  return get(`${ROLE_URL}/${id}`);
}

export async function createRole(role) {
  return post(ROLE_URL, role);
}

export async function updateRole(role) {
  return put(`${ROLE_URL}/${role._id}`, role);
}
