import React, { useState, useEffect, useContext } from 'react';

import {
  formatDate,
} from 'services/date';

import {
  getUsers,
  formatUserName,
} from 'services/user';

import {
  formatTechStackList,
  getTechStack,
} from 'services/techStack';

import {
  formatCandidateRate,
  formatCandidateStatus,
  getCandidates,
  updateCandidateStatus,
  getCandidateOpportunities,
} from 'services/candidate';

import {
  formatEnglishLevel,
} from 'services/opportunity';

import { ConsoleContext } from 'services/context';

// @material-ui/core components

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import Button from 'components/CustomButtons/Button';
import PagedTable from 'components/PagedTable/PagedTable';
import CandidateFilters from './components/CandidateFilters';
import CandidateEditDialog from './components/CandidateEditDialog';
import CandidateName from './components/CandidateName';
import AddToOpportunityDialog from './components/AddToOpportunityDialog';

export default function CandidateList() {
  const {
    showError, can, getSessionStorage,
  } = useContext(ConsoleContext);

  const [canEdit, setCanEdit] = useState(false);

  const [techStack, setTechStack] = useState([]);

  const [users, setUsers] = useState([]);

  const [opportunities, setOpportunities] = useState([]);

  const [filters, setFilters] = useState();

  const [openCandidateDialog, setOpenCandidateDialog] = useState(false);

  const [activeCandidate, setActiveCandidate] = useState();

  const [loadRows, setLoadRows] = useState();

  const [candidateForAdditionalPosition, setCandidateForAdditionalPosition] = useState();

  const storage = getSessionStorage('sales');

  const columns = [
    { id: 'name', label: 'Candidate' },
    { id: 'status', label: 'Status' },
    { id: 'rate', label: 'Rate' },
    { id: 'techStack', label: 'Tech Stack' },
    { id: 'englishLevel', label: 'English Level' },
    { id: 'cvLink', label: 'CV' },
    { id: 'recruiter', label: 'Recruiter' },
    { id: 'createdAt', label: 'Added At' },
    { id: 'action', label: 'Action' },
  ];

  useEffect(() => {
    (async () => {
      setCanEdit(await can('sales:candidates:edit'));

      try {
        const techStack = await getTechStack();
        const users = await getUsers();

        setTechStack(techStack);
        setUsers(users);
      } catch (e) {
        showError(e);
      }
    })();
  }, []);

  useEffect(() => {
    if (!filters || !users.length || !techStack.length) {
      return;
    }

    populateLoadRows();
  }, [filters, users, techStack]);

  const prepareRows = (candidates, users) => {
    const rows = candidates.map((candidate) => {
      const {
        _id,
        recruiterId,
        createdAt,
        status,
      } = candidate;

      return {
        id: _id,
        status: formatCandidateStatus(status),
        name: renderCandidateName(candidate),
        recruiter: formatRecruiterName(recruiterId, users),
        rate: formatCandidateRate(candidate),
        techStack: formatTechStackList(candidate.techStack, techStack),
        englishLevel: formatEnglishLevel(candidate.englishLevel),
        cvLink: renderCvLink(candidate),
        createdAt: formatDate(createdAt),
        action: renderButtonAddToPosition(candidate),
      };
    });

    return rows;
  };

  const handleAddToOpportunity = async (candidate) => {
    const opportunitiesData = await getCandidateOpportunities(candidate._id);
    setOpportunities(opportunitiesData);
    setCandidateForAdditionalPosition(candidate);
  };

  const renderButtonAddToPosition = (candidate) => (
    <Button color="info" style={{ width: '100%' }} onClick={() => handleAddToOpportunity(candidate)}>
      + Position
    </Button>
  );

  const handleSaveOpportunity = async () => {
    await updateCandidateStatus(candidateForAdditionalPosition._id, 'available');
    setOpportunities([]);
    setCandidateForAdditionalPosition(null);
    populateLoadRows();
  };

  const handleCancelOpportunity = () => {
    setCandidateForAdditionalPosition(null);
  };

  const populateLoadRows = () => {
    const loadRows = async (page, limit) => {
      const result = await getCandidates({ ...filters, page: page + 1, limit });

      const rows = prepareRows(result.docs, users);

      return { rows, totalItems: result.totalDocs };
    };

    setLoadRows(() => loadRows);
  };

  const handleFiltersChange = (filters) => {
    setFilters(filters);
  };

  const handleAdd = () => {
    setActiveCandidate({
      position: 'developer',
    });

    setOpenCandidateDialog(true);
  };

  const handleAddCancel = () => {
    setOpenCandidateDialog(false);
  };

  const handleSave = () => {
    setOpenCandidateDialog(false);

    populateLoadRows();
  };

  const renderCandidateName = (candidate) => (<CandidateName candidate={candidate} link />);

  const renderCvLink = (candidate) => {
    const { cvLink, cvLinkPrepared } = candidate;

    return (
      <>
        { cvLink ? (<a href={cvLink} target="_blank" rel="noreferrer">Download</a>) : '-' }

        { cvLinkPrepared ? (
          <>
            {' | '}
            <a href={cvLinkPrepared} target="_blank" rel="noreferrer">Internal</a>
          </>
        ) : null }
      </>
    );
  };

  const formatRecruiterName = (recruiterId, users) => {
    const recruiter = users.find((user) => user._id === recruiterId);

    return formatUserName(recruiter);
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={8}>
                  <CandidateFilters
                    storage={storage}
                    onChange={handleFiltersChange}
                    techStack={techStack}
                  />
                </GridItem>

                { canEdit ? (
                  <GridItem xs={12} sm={12} md={4}>
                    <Button color="info" style={{ float: 'right' }} onClick={handleAdd}>+ Add Candidate</Button>
                  </GridItem>
                ) : null }
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <PagedTable
                    containerProps={{ style: { maxHeight: '600px' } }}
                    tableProps={{ stickyHeader: true, style: { tableLayout: 'fixed' } }}
                    columns={columns}
                    loadRows={loadRows}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <CandidateEditDialog
        candidate={activeCandidate}
        open={openCandidateDialog}
        techStack={techStack}
        onSave={handleSave}
        onClose={handleAddCancel}
      />
      <AddToOpportunityDialog
        candidate={candidateForAdditionalPosition}
        assignedOpportunities={opportunities}
        open={!!candidateForAdditionalPosition}
        onSave={handleSaveOpportunity}
        onClose={handleCancelOpportunity}
      />
    </>
  );
}
