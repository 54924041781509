import React, { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { ConsoleContext } from 'services/context';
import { getFilterSummary } from '../../../../services/profitReport';

const useStyles = makeStyles(() => ({
  cellLastRow: {
    borderBottom: '1px solid black!important',
  },
  cellLastColumn: {
    borderRight: '1px solid black!important',
  },
  table: {
    '& th, & td': {
      border: '1px solid #d4d5d5',
      textAlign: 'center',
      overflow: 'hidden',
    },
    '& th': {
      padding: '6px 2px!important',
    },
    '& td': {
      padding: '6px 0!important',
    },
    '& table': {
      tableLayout: 'fixed',
    },
    maxHeight: '600px',
  },
  totalRow: {
    backgroundColor: '#ccc',
  },
}));

const prepareSummary = (summary, options = {}) => {
  let filteredSummary = getFilterSummary(summary);

  if (options.excludeClientId) {
    filteredSummary = filteredSummary.filter((p) => p.clientId !== options.excludeClientId);
  }

  const groupedSummary = {};
  function prepareRow(item, userId, projectId) {
    if (!groupedSummary[userId]) {
      groupedSummary[userId] = {};
    }

    if (!groupedSummary[userId][projectId]) {
      groupedSummary[userId][projectId] = {
        planRevenue: 0,
        factRevenue: 0,
        factOvertimeRevenue: 0,
        planBillableCosts: 0,
        factBillableCosts: 0,
        planNonBillableCosts: 0,
        factNonBillableCosts: 0,
        factOvertimeCosts: 0,
        planProfit: 0,
        factProfit: 0,
      };
    }

    const row = groupedSummary[userId][projectId];

    Object.keys(row).forEach((field) => {
      row[field] += item[field];
    });
  }

  filteredSummary.forEach((item) => {
    const { userId, projectId } = item;

    prepareRow(item, userId, projectId);
    prepareRow(item, userId, 'total');
    prepareRow(item, 'total', 'total');
  });

  Object.keys(groupedSummary).forEach((userId) => {
    Object.keys(groupedSummary[userId]).forEach((projectId) => {
      const row = groupedSummary[userId][projectId];

      const {
        planRevenue,
        factRevenue,
        planBillableCosts,
        factBillableCosts,
        planNonBillableCosts,
        factNonBillableCosts,
      } = row;

      row.planGrossMargin = planRevenue ? (1 - (planBillableCosts + planNonBillableCosts) / planRevenue) : 0;
      row.factGrossMargin = factRevenue ? (1 - (factBillableCosts + factNonBillableCosts) / factRevenue) : 0;
    });
  });

  return groupedSummary;
};

const prepareMembers = (projects, summary) => {
  const groupedMembers = {};

  projects.forEach((project) => {
    const projectId = project._id;

    project.members.forEach((member) => {
      const userId = member.userId._id;

      if (!groupedMembers[userId]) {
        groupedMembers[userId] = {
          ...member.userId,
          projects: [],
        };
      }

      if (summary[userId] && summary[userId][projectId]) {
        groupedMembers[userId].projects.push(project);
      }
    });
  });

  return Object
    .values(groupedMembers)
    .filter((member) => member.projects.length > 0)
    .sort((a, b) => (a.firstName >= b.firstName ? 1 : -1));
};

export default function UserReport({
  summary, projects, minGrossMargin, excludeClientId = '',
}) {
  const classes = useStyles();
  const { t } = useContext(ConsoleContext);

  summary = prepareSummary(summary, { excludeClientId });
  const members = prepareMembers(projects, summary);

  const formatMargin = (number) => `${formatNumber(number * 100)}%`;

  const bgColors = {
    plan: '#ffe599',
    fact: '#b6d7a9',
  };

  const columns = [
    { id: 'planRevenue', name: 'Revenue Plan', color: bgColors.plan },
    { id: 'factRevenue', name: 'Revenue Fact', color: bgColors.fact },
    { id: 'factOvertimeRevenue', name: 'Revenue for Overtime', color: bgColors.fact },
    { id: 'planBillableCosts', name: 'Billable Direct Costs Plan', color: bgColors.plan },
    { id: 'factBillableCosts', name: 'Billable Direct Costs Fact', color: bgColors.fact },
    { id: 'planNonBillableCosts', name: 'Non Billable Direct Costs Plan', color: bgColors.plan },
    { id: 'factNonBillableCosts', name: 'Non Billable Direct Costs Fact', color: bgColors.fact },
    { id: 'factOvertimeCosts', name: 'Costs for Overtime Fact', color: bgColors.fact },
    { id: 'planProfit', name: 'GP Plan', color: bgColors.plan },
    { id: 'factProfit', name: 'GP Fact', color: bgColors.fact },
    {
      id: 'planGrossMargin', name: 'GPM Plan', color: bgColors.plan, format: formatMargin,
    },
    {
      id: 'factGrossMargin', name: 'GPM Fact', color: bgColors.fact, format: formatMargin,
    },
  ];

  function formatMemberName(member) {
    return `${member.firstName} ${member.lastName}`;
  }

  const formatNumber = (number) => Math.round(number * 100) / 100;

  const renderSummaryCell = (userId, projectId, field, format) => {
    const isTotal = (projectId === 'total');

    let value = '-';

    const style = {};

    if (summary[userId] && summary[userId][projectId]) {
      value = summary[userId][projectId][field];

      if (!summary[userId][projectId][field] && summary[userId][projectId][field] !== 0) {
        value = '-';
      } else {
        if ((field === 'planGrossMargin' || field === 'factGrossMargin') && (value !== 0)) {
          if (value < 0) {
            style.backgroundColor = '#d70202';
          } else if (value < minGrossMargin) {
            style.backgroundColor = '#11b5c9';
          }
        }

        if ((field === 'planProfit' || field === 'factProfit') && value < 0) {
          style.color = 'red';
        }

        value = format ? format(summary[userId][projectId][field]) : formatNumber(summary[userId][projectId][field]);

        value = isTotal ? (<strong>{value}</strong>) : value;
      }
    }

    return (<TableCell key={field} style={style}>{value}</TableCell>);
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <TableContainer className={classes.table}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '150px' }}>
                  {t('Partner')}
                  /
                  {t('Project')}
                  <br />
                  USD
                </TableCell>
                { columns.map((column) => (
                  <TableCell key={column.id} style={{ backgroundColor: column.color }}>{t(column.name)}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell><strong>Total</strong></TableCell>
                { columns.map((column) => renderSummaryCell('total', 'total', column.id, column.format)) }
              </TableRow>
              { members.map((member) => (
                <Fragment key={member._id}>
                  <TableRow className={classes.totalRow}>
                    <TableCell>
                      <Link to={`partner-contracts/${member._id}`} title={t('Click to view Partner Contracts')}>
                        <strong>{formatMemberName(member)}</strong>
                      </Link>
                    </TableCell>

                    { columns.map((column) => renderSummaryCell(member._id, 'total', column.id, column.format)) }
                  </TableRow>
                  { member.projects.map((project) => (
                    <TableRow key={project._id}>
                      <TableCell>
                        &nbsp;
                        {project.name}
                      </TableCell>

                      { columns.map((column) => renderSummaryCell(member._id, project._id, column.id, column.format)) }
                    </TableRow>
                  ))}
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </GridItem>
    </GridContainer>
  );
}
