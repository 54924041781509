import React, { useContext, useEffect, useState } from 'react';

import moment from 'moment';

import {
  formatMoney,
  formatNumber,
} from 'services/finance';

import {
  formatUserName,
} from 'services/user';

import {
  formatBonusType,
} from 'services/payroll';

import { ConsoleContext } from 'services/context';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Alert from '@material-ui/lab/Alert';
import Button from 'components/CustomButtons/Button';
import { CSVLink } from 'react-csv';

const useStyles = makeStyles((theme) => ({
  actions: {
    marginTop: '15px',
    textAlign: 'center',
  },
  table: {
    '& td': {
      whiteSpace: 'nowrap',
    },
  },
}));

export default function PayrollRecordPaymentDetails({ record, renderPaymentTarget }) {
  const classes = useStyles();

  const { t } = useContext(ConsoleContext);

  const { currency, paymentCurrency } = record;

  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  const [preventDownloadError, setPreventDownloadError] = useState();

  useEffect(() => {
    if (!record || !record._id) {
      return;
    }

    const rows = [];

    const {
      _id,
      user,
      userId,
      startAt,
      endAt,
      paymentDate,
      status,
      currency,
      paymentCurrency,
      monthlyRate,
      hourlyRate,
      workedHours,
      billableHolidayHours,
      nonBillableHolidayHours,
      holidayCompensationHours,
      holidayCompensationAmount,
      billableHours,
      overtimeHours,
      overtimeAmount,
      bonuses,
      bonusTotal,
      billableAmount,
      prepayments,
      prepaymentTotal,
      previousRecordDebt,
      totalAmount,
      paymentTotalAmount,
      paymentTaxCompensation,
      paymentFinalAmount,
      contractRecords,
    } = record;

    let recordOvertimes = [];

    if (contractRecords) {
      if ((contractRecords.length > 1)) {
        contractRecords.forEach((contractRecord) => {
          const {
            contractId,
            startAt,
            endAt,
            monthlyRate,
            hourlyRate,
            workedHours,
            billableHours,
            overtimeHours,
            overtimes,
            overtimeAmount,
            billableHolidayHours,
            nonBillableHolidayHours,
            billableAmount,
          } = contractRecord;

          rows.push({
            id: contractId,
            billingPeriod: formatBillingPeriod(startAt, endAt),
            monthlyRate: formatMoney(monthlyRate, false),
            hourlyRate: formatMoney(hourlyRate, false),
            workedHours: formatNumber(workedHours),
            billableHolidayHours: formatNumber(billableHolidayHours - nonBillableHolidayHours),
            billableHours: formatNumber(billableHours),
            overtimeHours: formatOvertimes(overtimeHours, overtimes),
            overtimeAmount: formatMoney(overtimeAmount, false),
            billableAmount: formatMoney(billableAmount, false),
            paymentTarget: '-',
            holidayCompensationHours: '-',
            holidayCompensationAmount: '-',
            prepaymentTotal: '-',
            bonusTotal: '-',
            totalAmount: '-',
            paymentExchangeRate: '-',
            paymentTotalAmount: '-',
            paymentTaxCompensation: '-',
            paymentFinalAmount: '-',
            previousRecordDebt: '-',
          });
        });

        rows.push({
          id: '_total',
          billingPeriod: t('Total'),
        });
      } else if (contractRecords[0]) {
        recordOvertimes = contractRecords[0].overtimes;
      }
    }

    const row = {
      id: _id,
      billingPeriod: formatBillingPeriod(startAt, endAt),
      monthlyRate: formatMoney(monthlyRate, false),
      hourlyRate: formatMoney(hourlyRate, false),
      workedHours: formatNumber(workedHours),
      billableHolidayHours: formatNumber(billableHolidayHours - nonBillableHolidayHours),
      holidayCompensationHours: formatNumber(holidayCompensationHours),
      holidayCompensationAmount: formatMoney(holidayCompensationAmount, false),
      billableHours: formatNumber(billableHours),
      overtimeHours: formatOvertimes(overtimeHours, recordOvertimes),
      overtimeAmount: formatMoney(overtimeAmount, false),
      paymentTarget: renderPaymentTarget(record),
      bonusTotal: formatMoney(bonusTotal, false),
      billableAmount: formatMoney(billableAmount, false),
      prepaymentTotal: formatMoney(prepaymentTotal, false),
      totalAmount: formatMoney(totalAmount, false),
      paymentExchangeRate: formantPaymentExchangeRate(record),
      paymentTotalAmount: formatMoney(paymentTotalAmount, false),
      previousRecordDebt: formatMoney(previousRecordDebt, false),
      paymentTaxCompensation: formatMoney(paymentTaxCompensation, false),
      paymentFinalAmount: formatMoney(paymentFinalAmount, false),
    };

    bonuses.forEach((bonus) => {
      row[`bonus${bonus.type}`] = formatMoney(bonus.amount, false);
    });

    rows.push(row);

    setRows(rows);
    setColumns(prepareColumns(record));

    checkCanDownload(record);
  }, [record]);

  const prepareColumns = (record) => {
    let columns = [
      { key: 'billingPeriod', label: t('Billing Period') },
      { key: 'paymentTarget', label: t('Payment Method') },
      { key: 'monthlyRate', label: `${t('Monthly Rate')}, ${currency}` },
      { key: 'hourlyRate', label: `${t('Hourly Rate')}, ${currency}` },
      { key: 'billableHolidayHours', label: t('Billable Dayoff Hours') },
      { key: 'overtimeHours', label: t('Overtime Hours') },
      { key: 'overtimeAmount', label: `${t('Overtime Amount')}, ${currency}` },
      { key: 'workedHours', label: t('Worked Hours') },
      { key: 'billableHours', label: t('Billable Hours') },
      { key: 'billableAmount', label: `${t('Billable Amount')}, ${currency}` },
      { key: 'holidayCompensationHours', label: t('Holiday Compensation Hours') },
      { key: 'holidayCompensationAmount', label: `${t('Holiday Compensation Amount')}, ${currency}` },
      { key: 'prepaymentTotal', label: `${t('Prepayment')}, ${currency}` },
      { key: 'previousRecordDebt', label: `${t('Previous Payment Debt')}, ${currency}` },
    ];

    if (record.bonuses && record.bonuses.length) {
      record.bonuses.forEach((bonus) => {
        columns.push({
          key: `bonus${bonus.type}`,
          label: `${t('Bonus {{type}}', { type: t(formatBonusType(bonus.type)) })}, ${currency}`,
        });
      });
    } else {
      columns.push({
        key: 'bonusTotal',
        label: `${t('Bonuses')}, ${currency}`,
      });
    }

    columns = [
      ...columns,
      ...[{
        key: 'totalAmount',
        label: `${t('Total Amount')}, ${currency}`,
      }, {
        key: 'paymentExchangeRate',
        label: `${t('Exchange Rates')}`,
      }, {
        key: 'paymentTotalAmount',
        label: `${t('Total Amount')}, ${paymentCurrency}`,
      }, {
        key: 'paymentTaxCompensation',
        label: `${t('Tax Compensation')}, ${paymentCurrency}`,
      }, {
        key: 'paymentFinalAmount',
        label: `${t('Payment Amount')}, ${paymentCurrency}`,
      }],
    ];

    return columns;
  };

  const formatBillingPeriod = (startAt, endAt) => {
    const startDate = moment.utc(startAt).format('D');
    const endDate = moment.utc(endAt).format('D.MM.YYYY');

    return `${startDate} - ${endDate}`;
  };

  const formatOvertimes = (overtimeHours, overtimes) => {
    if (!overtimeHours) {
      return 0;
    }

    let overtimeX10 = 0;
    let overtimeX15 = 0;

    overtimes.forEach(({ rateByCompany, hours }) => {
      if (rateByCompany === 1) {
        overtimeX10 += hours;
      } else if (rateByCompany === 1.5) {
        overtimeX15 += hours;
      }
    });

    const overtimeList = [];

    if (overtimeX15) {
      overtimeList.push(`${overtimeX15} x 1.5`);
    }

    if (overtimeX10) {
      overtimeList.push(`${overtimeX10} x 1.0`);
    }

    return formatNumber(overtimeHours) + (overtimeList.length ? ` = ${overtimeList.join(' + ')}` : '');
  };

  const formantPaymentExchangeRate = (record) => {
    const {
      currency,
      paymentCurrency,
      currencyExchangeRates,
    } = record;

    const exchangeRate = formatMoney(currencyExchangeRates[currency], paymentCurrency, 4);
    return `1 ${currency} = ${exchangeRate}`;
  };

  const getExportFileName = (record) => {
    const date = moment(record.startAt).format('MM.YYYY');

    return `Receipt.${formatUserName(record.user)}.${date}.csv`;
  };

  const checkCanDownload = (record) => {
    let message = null;

    if (!record.paymentTargetId) {
      message = 'Payment To should be specified in order to download CSV';
    } else if (record.noContractDates && record.noContractDates.length) {
      message = 'Add missing Partner Contracts in order to download CSV';
    }

    setPreventDownloadError(message);
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <h4>{t('Payroll Record Details')}</h4>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  { columns.map((column) => (
                    <TableCell key={column.key}>
                      {column.label}
                    </TableCell>
                  )) }
                </TableRow>
              </TableHead>
              <TableBody>
                { rows.map((row) => (
                  <TableRow key={row.id}>
                    { columns.map((column) => (
                      <TableCell key={column.key}>
                        { row[column.key] }
                      </TableCell>
                    )) }
                  </TableRow>
                )) }
              </TableBody>
            </Table>
          </TableContainer>
        </GridItem>
      </GridContainer>

      <GridContainer className={classes.actions}>
        <GridItem xs={12} sm={12} md={12}>
          { preventDownloadError ? (
            <Alert color="error" icon={false}>
              {t(preventDownloadError)}
            </Alert>
          ) : (
            <CSVLink
              data={rows}
              headers={columns}
              filename={getExportFileName(record)}
            >
              <Button color="success">{t('Download')}</Button>
            </CSVLink>
          ) }
        </GridItem>
      </GridContainer>
    </>
  );
}
