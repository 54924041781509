import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import Button from 'components/CustomButtons/Button.js';
import PagedTable from 'components/PagedTable/PagedTable';

import { ConsoleContext } from 'services/context';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

import {
  getLocales,
  createLocale,
} from 'services/locale';

const useStyles = makeStyles((theme) => ({
}));

export default function LocaleList(props) {
  const classes = useStyles();

  const { showError, showSuccess, showLoader } = useContext(ConsoleContext);

  const [rows, setRows] = useState([]);

  const [activeLocale, setActiveLocale] = useState({
    name: '',
    code: '',
  });

  const [openAddDialog, setOpenAddDialog] = useState(false);

  const columns = [
    { id: 'name', label: 'Name' },
    { id: 'code', label: 'Code' },
  ];

  useEffect(() => {
    (async () => {
      showLoader(true);

      try {
        const locales = await getLocales();

        const rows = locales.map((item) => ({
          id: item._id,
          name: (<Link to={`locales/${item._id}`}>{item.name}</Link>),
          code: item.code,
        }));

        setRows(rows);
      } catch (error) {
        showError(error);
      }

      showLoader(false);
    })();
  }, []);

  const handleAddLocale = () => {
    setActiveLocale({
      name: '',
      code: '',
    });

    setOpenAddDialog(true);
  };

  const handleLocaleChange = (field, value) => {
    setActiveLocale({ ...activeLocale, [field]: value });
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  const handleAddDialogSubmit = async () => {
    try {
      const newLocale = await createLocale(activeLocale);

      setRows([...rows, formatRow(newLocale)]);

      setOpenAddDialog(false);
      showSuccess('Locale created');
    } catch (e) {
      showError(e);
    }
  };

  const formatRow = (locale) => ({
    id: locale._id,
    name: (<Link to={`locales/${locale._id}`}>{locale.name}</Link>),
    code: locale.code,
  });

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Button color="primary" style={{ float: 'right' }} onClick={handleAddLocale}>+ Add Locale</Button>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <PagedTable columns={columns} rows={rows} />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Dialog
        maxWidth="xs"
        fullWidth
        open={openAddDialog}
        onClose={handleCloseAddDialog}
      >
        <DialogTitle>Add Locale</DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                required
                fullWidth
                label="Name"
                value={activeLocale.name}
                onChange={(e) => handleLocaleChange('name', e.target.value)}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                required
                fullWidth
                label="Code"
                value={activeLocale.code}
                onChange={(e) => handleLocaleChange('code', e.target.value)}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddDialog}>
            Cancel
          </Button>
          <Button onClick={handleAddDialogSubmit} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
