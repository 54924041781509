import React, { useState, useEffect, useContext } from 'react';

import { ConsoleContext } from 'services/context';

import {
  getCandidateStatusOptions,
  formatCandidateStatus,
  formatCandidateRejectedReason,
} from 'services/opportunity';

import { makeStyles } from '@material-ui/core/styles';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Button from 'components/CustomButtons/Button';

const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: '15px',
  },
}));

export default function CandidateStatus({
  opportunity, opportunityCandidate, onSave, onLoading,
}) {
  const classes = useStyles();

  const {
    showError, showSuccess, t, can,
  } = useContext(ConsoleContext);

  const [changedStatus, setChangedStatus] = useState();

  const [canEdit, setCanEdit] = useState(false);

  useEffect(() => {
    (async () => {
      setCanEdit(await can('sales:opportunities:edit'));
    })();
  }, []);

  const handleChangeStatus = () => {
    setChangedStatus(opportunityCandidate.status);
  };

  const handleChange = (value) => {
    setChangedStatus(value);
  };

  const handleCancel = () => {
    setChangedStatus(null);
  };

  const handleSubmit = async () => {
    onSave(changedStatus);

    setChangedStatus(null);
  };

  return (
    <>
      { changedStatus ? (
        <>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CustomSelect
                required
                label="Next Status"
                value={changedStatus}
                options={getCandidateStatusOptions()}
                onChange={(value) => handleChange(value)}
              />
            </GridItem>
          </GridContainer>

          <GridContainer className={classes.grid}>
            <GridItem xs={12} sm={12} md={12}>
              <Button color="info" size="sm" onClick={handleSubmit}>Save</Button>

              <Button size="sm" onClick={handleCancel}>Cancel</Button>
            </GridItem>
          </GridContainer>
        </>
      ) : (
        <>
          { formatCandidateStatus(opportunityCandidate.status) }

          { (opportunityCandidate.status === 'rejected') && opportunityCandidate.rejectedReason ? (
            <>
              {'. '}
              { formatCandidateRejectedReason(opportunityCandidate.rejectedReason) }

              { opportunityCandidate.rejectedReasonNote ? `: ${opportunityCandidate.rejectedReasonNote}` : null }
            </>
          ) : null }

          {' '}

          { canEdit ? (
            <Button color="info" size="sm" onClick={handleChangeStatus} style={{ margin: 0 }}>
              Change
            </Button>
          ) : null }
        </>
      )}
    </>
  );
}
