import React, { useState, useEffect, useContext } from 'react';
import clone from 'just-clone';

import {
  getExperienceYearsOptions,
  createCandidate,
  updateCandidate,
  uploadCV,
  parseCV,
  getContactTypeOptions,
} from 'services/candidate';

import {
  getEnglishLevelOptions,
  getPositionOptions,
} from 'services/opportunity';

import {
  parseInputNumber,
} from 'services/finance';

import { ConsoleContext } from 'services/context';

import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from 'components/CustomDialog/CustomDialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Button from 'components/CustomButtons/Button';
import TextField from '@material-ui/core/TextField';
import CustomSelect from 'components/CustomSelect/CustomSelect';

import { DropzoneArea } from 'material-ui-dropzone';

import { removeTechStacksDuplicate } from 'services/techStack';
import TechStackSelect from '../../components/TechStackSelect';

const useStyles = makeStyles(() => ({
  grid: {
    marginTop: '15px',
  },
  dropZone: {
    minHeight: '160px',
  },
  title: {
    marginBottom: 0,
  },
}));

export default function CandidateEditDialog({
  candidate, open, onClose, onSave, techStack,
}) {
  const classes = useStyles();

  const { showError, showSuccess, t } = useContext(ConsoleContext);

  const [loading, setLoading] = useState(false);

  const [openDialog, setOpenDialog] = useState(open);

  const [changedCandidate, setChangedCandidate] = useState();

  const [file, setFile] = useState();

  const [cvLink, setCvLink] = useState();

  const rateTypeOptions = [
    { id: 'hourly', name: 'Hourly' },
    { id: 'monthly', name: 'Monthly' },
  ];

  useEffect(() => {
    setChangedCandidate({
      name: '',
      cvLink: '',
      cvLinkPrepared: '',
      techStack: [],
      englishLevel: '',
      rateType: '',
      rateAmount: '',
      contacts: {
        type: '',
        phone: '',
        telegramId: '',
        skypeId: '',
      },
      preparedCvLink: '',
      location: '',
      position: '',
      ...clone(candidate),
    });

    setOpenDialog(open);
  }, [open, candidate]);

  const handleCloseDialog = () => {
    onClose();
  };

  const handleChange = (field, value) => {
    const data = clone(changedCandidate);

    data[field] = value;

    setChangedCandidate(data);
  };

  const handleContactsChange = (field, value) => {
    const data = clone(changedCandidate);

    data.contacts[field] = value;

    setChangedCandidate(data);
  };

  const handleSubmit = async () => {
    const data = clone(changedCandidate);

    data.rateAmount = parseInputNumber(data.rateAmount);

    if (data.contacts.telegram) {
      data.contacts.telegram = data.contacts.telegram.replace('@', '');
    }

    setLoading(true);

    try {
      if (cvLink) {
        data.cvLink = cvLink;
      } else if (file) {
        const uploadResult = await uploadCV(file);
        data.cvLink = uploadResult.link;
        setCvLink(data.cvLink);
      }

      let savedCandidate;

      if (changedCandidate._id) {
        savedCandidate = await updateCandidate(data);

        showSuccess('Candidate updated');
      } else {
        savedCandidate = await createCandidate(data);

        showSuccess('Candidate created');
      }

      onSave(savedCandidate);
    } catch (e) {
      showError(e);
    }

    setLoading(false);
  };

  const handleFileUpload = async (files) => {
    setCvLink(null);
    setFile(files[0] || null);
    if (files[0]) {
      setLoading(true);
      const tech = await parseCV(files[0]);
      const data = clone(changedCandidate);

      data.techStack = [...data.techStack, ...tech];
      data.techStack = removeTechStacksDuplicate(data.techStack);
      setChangedCandidate(data);
      setLoading(false);
    }
  };

  if (!changedCandidate) {
    return null;
  }

  return (
    <Dialog
      loading={loading}
      maxWidth="xs"
      fullWidth
      open={openDialog}
    >
      <DialogTitle>{changedCandidate._id ? 'Edit Candidate' : 'Add Candidate'}</DialogTitle>
      <DialogContent>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <TextField
              fullWidth
              label="Name"
              required
              value={changedCandidate.name}
              onChange={(e) => handleChange('name', e.target.value)}
            />
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <CustomSelect
              required
              label="English Level"
              value={changedCandidate.englishLevel}
              options={getEnglishLevelOptions()}
              onChange={(value) => handleChange('englishLevel', value)}
            />
          </GridItem>
        </GridContainer>

        <GridContainer className={classes.grid}>
          <GridItem xs={12} sm={12} md={6}>
            <CustomSelect
              required
              label="Rate Type"
              value={changedCandidate.rateType}
              options={rateTypeOptions}
              onChange={(value) => handleChange('rateType', value)}
            />
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <TextField
              required
              fullWidth
              label="Rate Amount ($)"
              value={changedCandidate.rateAmount}
              onChange={(e) => handleChange('rateAmount', e.target.value)}
            />
          </GridItem>
        </GridContainer>

        <GridContainer className={classes.grid}>
          <GridItem xs={12} sm={12} md={6}>
            <CustomSelect
              required
              label="Position"
              value={changedCandidate.position}
              options={getPositionOptions()}
              onChange={(value) => handleChange('position', value)}
            />
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <TextField
              required
              fullWidth
              label="Location"
              value={changedCandidate.location}
              onChange={(e) => handleChange('location', e.target.value)}
            />
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <h4 className={classes.title}>CV</h4>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <TextField
              fullWidth
              label="Internal CV Link"
              value={changedCandidate.cvLinkPrepared}
              onChange={(e) => handleChange('cvLinkPrepared', e.target.value)}
            />
          </GridItem>
        </GridContainer>

        <GridContainer className={classes.grid}>
          <GridItem xs={12} sm={12} md={12}>
            <DropzoneArea
              dropzoneClass={classes.dropZone}
              acceptedFiles={['.docx', '.doc', '.pdf']}
              filesLimit={1}
              maxFileSize={10000000}
              disableRejectionFeedback
              dropzoneText="Upload CV in DOCX, DOC, PDF"
              getFileLimitExceedMessage={() => 'Can upload only 1 file'}
              getFileAddedMessage={() => 'CV uploaded'}
              getFileRemovedMessage={() => 'CV removed'}
              onChange={handleFileUpload}
            />
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <h4 className={classes.title}>Tech Stack</h4>
          </GridItem>
        </GridContainer>

        <GridContainer className={classes.grid}>
          <GridItem xs={12} sm={12} md={12}>
            <TechStackSelect
              techStack={techStack}
              values={changedCandidate.techStack}
              experienceOptions={getExperienceYearsOptions()}
              onChange={(values) => handleChange('techStack', values)}
            />
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <h4 className={classes.title}>Contacts</h4>
          </GridItem>
        </GridContainer>

        <GridContainer className={classes.grid}>
          <GridItem xs={12} sm={12} md={6}>
            <CustomSelect
              required
              label="Type"
              value={changedCandidate.contacts.type}
              options={getContactTypeOptions()}
              onChange={(value) => handleContactsChange('type', value)}
            />
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <TextField
              fullWidth
              label="Phone"
              value={changedCandidate.contacts.phone}
              onChange={(e) => handleContactsChange('phone', e.target.value)}
            />
          </GridItem>
        </GridContainer>

        <GridContainer className={classes.grid}>
          <GridItem xs={12} sm={12} md={6}>
            <TextField
              fullWidth
              label="Telegram ID"
              value={changedCandidate.contacts.telegram}
              onChange={(e) => handleContactsChange('telegram', e.target.value)}
            />
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <TextField
              fullWidth
              label="Skype ID"
              value={changedCandidate.contacts.skype}
              onChange={(e) => handleContactsChange('skype', e.target.value)}
            />
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>
          {t('Cancel')}
        </Button>
        <Button onClick={handleSubmit} color="info">
          {t('Save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
