import {
  SUMMARY_PROFIT_URL,
} from '../utils/apiRouting';

import {
  get,
} from '../utils/http';

export async function getSummaryProfit(year, month) {
  return get(`${SUMMARY_PROFIT_URL}/${year}/${month}`);
}

export function getFilterSummary(summary) {
  return summary.filter(
    (p) => (
      p.factBillableCosts !== 0
    || p.factNonBillableCosts !== 0
    || p.factNonBillableCosts !== 0
    || p.factOvertimeCosts !== 0
    || p.factProfit !== 0
    || p.factRevenue !== 0
    || p.planBillableByClientHours !== 0
    || p.planBillableCosts !== 0
    || p.planNonBillableCosts !== 0
    || p.planProfit !== 0
    || p.planRevenue !== 0
    )
        || (
          p.noUserContractDays.length
        || p.noClientContractDays.length
        ),
  );
}

export function getFilterSummaryForFluviusCosts(summary) {
  return summary.filter(
    (p) => (p.factOvertimeCosts !== 0 || p.planNonBillableCosts !== 0 || p.factNonBillableCosts !== 0) || p.noUserContractDays.length,
  );
}
