import React, {
  useState, useContext, Fragment,
} from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Tooltip from 'components/Tooltip/Tooltip';
import { ConsoleContext } from 'services/context';
import { Link } from 'react-router-dom';
import ContractIssuesDialog from './ContractIssuesDialog';
import { getFilterSummaryForFluviusCosts } from '../../../../services/profitReport';
import { formatNumber } from '../../../../services/finance';
import { formatUserName } from '../../../../services/user';

const useStyles = makeStyles(() => ({
  table: {
    '& th, & td': {
      border: '1px solid #d4d5d5', textAlign: 'center', overflow: 'hidden',
    },
    '& th': {
      padding: '6px 2px!important',
    },
    '& td': {
      padding: '6px 0!important',
    },
    '& table': {
      tableLayout: 'fixed',
    },
    maxHeight: '600px',
  },
  totalRow: {
    backgroundColor: '#ccc',
  },
}));

const prepareSummary = (summary, options = {}) => {
  const groupedSummary = {};
  let filteredSummary = getFilterSummaryForFluviusCosts(summary);

  if (options.fluviusId) {
    filteredSummary = filteredSummary.filter((p) => p.clientId === options.fluviusId);
  }

  function prepareRow(item, userId, projectId) {
    if (!groupedSummary[userId]) {
      groupedSummary[userId] = {};
    }

    if (!groupedSummary[userId][projectId]) {
      groupedSummary[userId][projectId] = {
        planNonBillableCosts: 0,
        factNonBillableCosts: 0,
        factOvertimeCosts: 0,
      };
    }

    const row = groupedSummary[userId][projectId];

    Object.keys(row).forEach((field) => {
      row[field] += item[field];
    });
  }

  filteredSummary.forEach((item) => {
    const { userId, projectId } = item;

    prepareRow(item, userId, projectId);
    prepareRow(item, userId, 'total');
    prepareRow(item, 'total', 'total');
  });

  return groupedSummary;
};

const prepareMembers = (projects, summary) => {
  const groupedMembers = {};

  projects.forEach((project) => {
    const projectId = project._id;

    project.members.forEach((member) => {
      const userId = member.userId._id;

      if (!groupedMembers[userId]) {
        groupedMembers[userId] = {
          ...member.userId,
          projects: [],
        };
      }

      if (summary[userId] && summary[userId][projectId]) {
        groupedMembers[userId].projects.push(project);
      }
    });
  });

  return Object
    .values(groupedMembers)
    .filter((member) => member.projects.length > 0)
    .sort((a, b) => (a.planProfit >= b.planProfit ? -1 : 1));
};

const prepareContractIssues = (summary) => {
  const issues = {};

  summary.forEach((item) => {
    const {
      projectId, userId, noUserContractDays,
    } = item;

    if (!issues[projectId]) {
      issues[projectId] = {
        noClientContractDays: [], noUserContractDays: [],
      };
    }

    if (noUserContractDays && noUserContractDays.length) {
      issues[projectId].noUserContractDays.push({ userId, days: noUserContractDays });
    }
  });

  return issues;
};

export default function FluviusCosts({ summary, projects, fluviusId = '' }) {
  const classes = useStyles();

  const { t } = useContext(ConsoleContext);

  const contractIssues = prepareContractIssues(summary);
  summary = prepareSummary(summary, { fluviusId });

  const clients = prepareMembers(projects, summary);

  const [contractIssuesDialog, setContractIssuesDialog] = useState();

  const columns = [{
    id: 'planNonBillableCosts', name: 'Costs Plan',
  }, {
    id: 'factNonBillableCosts', name: 'Costs Fact',
  }, {
    id: 'factOvertimeCosts', name: 'Costs For Overtime Fact',
  }];

  const renderSummaryCell = (clientId, projectId, field, format) => {
    const isTotal = (projectId === 'total');

    let value = '-';

    const style = {};

    if (summary[clientId] && summary[clientId][projectId]) {
      value = summary[clientId][projectId][field];

      if (!value && value !== 0) {
        value = '-';
      } else {
        value = format ? format(value) : formatNumber(value);

        value = isTotal ? (<strong>{value}</strong>) : value;
      }
    }

    return (<TableCell key={field} style={style}>{value}</TableCell>);
  };

  const renderProjectCell = (project, issues, memberId) => {
    const style = {};
    let tooltip;

    if (issues) {
      const checkUserContractDays = issues.noUserContractDays.some((item) => item.userId === memberId);
      if (issues.noUserContractDays.length && checkUserContractDays) {
        style.backgroundColor = 'red';

        tooltip = 'Click to resolve issues';
      }
    }

    const handleClick = () => {
      setContractIssuesDialog({ project, issues });
    };

    return (
      <TableCell style={style}>
        {tooltip ? (
          <Tooltip title={t(tooltip)} arrow>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div onClick={handleClick} style={{ cursor: 'pointer' }}>{project.name}</div>
          </Tooltip>
        ) : project.name}
      </TableCell>
    );
  };

  const handleCloseContractIssuesDialog = () => {
    setContractIssuesDialog(null);
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <TableContainer className={classes.table}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '150px' }}>
                    {t('Partner')}
                    /
                    {t('Project')}
                    <br />
                    USD
                  </TableCell>
                  { columns.map((column) => (
                    <TableCell
                      key={column.id}
                      style={{ backgroundColor: column.color }}
                    >
                      {t(column.name)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell><strong>Total</strong></TableCell>
                  { columns.map((column) => renderSummaryCell('total', 'total', column.id, column.format)) }
                </TableRow>
                { clients.map((member) => (
                  <Fragment key={member._id}>
                    <TableRow className={classes.totalRow}>
                      <TableCell>
                        <Link to={`partner-contracts/${member._id}`} title={t('Click to view Partner Contracts')}>
                          <strong>{formatUserName(member)}</strong>
                        </Link>
                      </TableCell>

                      { columns.map((column) => renderSummaryCell(member._id, 'total', column.id, column.format)) }
                    </TableRow>
                    { member.projects.map((project) => (
                      <TableRow key={project._id}>
                        {renderProjectCell(project, contractIssues[project._id], member._id)}
                        { columns.map((column) => renderSummaryCell(member._id, project._id, column.id, column.format)) }
                      </TableRow>
                    ))}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </GridItem>
      </GridContainer>

      {contractIssuesDialog
                && <ContractIssuesDialog {...contractIssuesDialog} onClose={handleCloseContractIssuesDialog} />}
    </>
  );
}
