import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import {
  getRoles,
} from 'services/role';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { ConsoleContext } from 'services/context';

const useStyles = makeStyles((theme) => ({
}));

export default function PermissionList(props) {
  useEffect(() => {
    async function fetchData() {
      showLoader(true);

      try {
        const roles = await getRoles();

        setRoles(roles);

        const rows = roles.map((item) => ({
          id: item._id,
          name: item.name,
          permissions: item.permissions.length ? item.permissions.join(', ') : '-',
        }));

        setRows(rows);
      } catch (error) {
        showError(error);
      }

      showLoader(false);
    }

    fetchData();
  }, []);

  const classes = useStyles();

  const { showError, showSuccess, showLoader } = useContext(ConsoleContext);

  const [roles, setRoles] = useState([]);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [rows, setRows] = useState([]);

  const columns = [
    { id: 'name', label: 'Title' },
    { id: 'permissions', label: 'Permissions' },
  ];

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => {
                            switch (column.id) {
                              default:
                                return (
                                  <TableCell
                                    key={column.id}
                                    style={column.width ? { width: column.width } : {}}
                                  >
                                    {column.label}
                                  </TableCell>
                                );
                            }
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                          <TableRow key={row.id}>
                            {columns.map((column) => {
                              const value = row[column.id];

                              switch (column.id) {
                                case 'name':
                                  return (
                                    <TableCell key={column.id}>
                                      <Link to={`permissions/${row.id}`}>{value}</Link>
                                    </TableCell>
                                  );
                                  break;

                                default:
                                  return (
                                    <TableCell key={column.id}>
                                      { value }
                                    </TableCell>
                                  );
                                  break;
                              }
                            })}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[20, 50, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
