import React, {
  useState, useEffect, useContext,
} from 'react';
import { Redirect } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import {
  projectStatuses,
  getProject,
  createProject,
  updateProject,
  getProjectManagers,
} from 'services/project';

import { getClients, isClientActive } from 'services/client';

import { getUsers, isUserActive } from 'services/user';

import { getRoles } from 'services/role';

import { formatDate } from 'services/date';

import { getAccesses } from 'services/jira';

import { can } from 'services/permission';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Button from 'components/CustomButtons/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import CardFooter from 'components/Card/CardFooter';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { ConsoleContext } from 'services/context';
import ProjectLogs from './components/ProjectLogs';
import ProjectAllocation from './components/ProjectAllocation';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '100%',
  },
  memberName: {
    marginTop: '20px',
  },
  memberFormControl: {
    marginTop: '10px',
  },
  memberDelete: {
    marginTop: '10px',
  },
  projectCheckboxMargin: {
    marginTop: '10px',
  },
  checkboxMargin: {
    marginLeft: '-3px',
    marginTop: '20px',
  },
  allocationRoot: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  grid: {
    marginTop: '15px',
  },
}));

export default function ProjectDetails(props) {
  const { id } = props.match.params;

  useEffect(() => {
    async function fetchData() {
      showLoader(true);

      try {
        let project = {};

        if (id) {
          project = await getProject(id);
        }

        project = {
          clientId: '',
          name: '',
          members: [],
          status: 'pending',
          billable: false,
          managerId: '',
          ...project,
          jiraId: project.jiraId ? project.jiraId : '',
          jiraAccessId: project.jiraAccessId ? project.jiraAccessId : '',
        };

        const clients = await getClients();
        const users = await getUsers();
        const roles = await getRoles({ circleId: 'production' });
        const jiraAccesses = await getAccesses();
        const projectManagers = await getProjectManagers(project.managerId || null);

        setClients(clients);
        setUsers(users);
        setRoles(roles);
        setJiraAccesses(jiraAccesses);
        setProjectManagers(projectManagers);

        setMemberUsers(getUsersWithRoles(users, roles));
        setSavedMembers(getSavedMembers(project));

        let timeTracking = 'manual';
        if (project.jiraId) {
          timeTracking = 'jira';
        } else if (project.autoLogBillablePlan) {
          timeTracking = 'auto';
        }
        setTimeTracking(timeTracking);

        setCanEditProject(await can('production:projects:edit'));
        setCanViewLogs(await can('production:logs:view'));

        setProject(project);
      } catch (error) {
        showError(error);
      }

      showLoader(false);
    }

    fetchData();
  }, [id]);

  const classes = useStyles();

  const {
    showError, showSuccess, showConfirm, showLoader,
  } = useContext(ConsoleContext);

  const [canEditProject, setCanEditProject] = useState(false);

  const [canViewLogs, setCanViewLogs] = useState(false);

  const [project, setProject] = useState();

  const [clients, setClients] = useState([]);

  const [users, setUsers] = useState([]);

  const [roles, setRoles] = useState([]);

  const [savedMembers, setSavedMembers] = useState([]);

  const [newMember, setNewMember] = useState({
    userId: '',
    roleIds: [],
  });

  const [memberUsers, setMemberUsers] = useState([]);

  const [userAllocation, setUserAllocation] = useState({});

  const [redirectId, setRedirectId] = useState('');

  const [jiraAccesses, setJiraAccesses] = useState([]);

  const [timeTracking, setTimeTracking] = useState('manual');

  const [editMode, setEditMode] = useState(!id);

  const [projectManagers, setProjectManagers] = useState([]);

  const timeTrackingOptions = [
    { id: 'manual', name: 'Manual' },
    { id: 'jira', name: 'JIRA' },
    { id: 'auto', name: 'Auto Log Billable' },
  ];

  const handleValueChange = (field, value) => {
    const data = { ...project };
    data[field] = value;

    setProject(data);
  };

  const handleSubmit = async () => {
    const data = { ...project };

    let newProject;

    if (!data.managerId) {
      delete data.managerId;
    }

    data.autoLogBillablePlan = (timeTracking === 'auto');

    if (timeTracking === 'jira') {
      if (!data.jiraId) {
        showError('JIRA ID or Key required');
        return;
      }
    } else {
      data.jiraId = null;
      data.jiraAccessId = null;
    }

    try {
      if (project._id) {
        newProject = await updateProject(data);

        showSuccess('Project updated');

        setProject({
          clientId: '',
          name: '',
          members: [],
          status: 'pending',
          billable: false,
          jiraId: '',
          jiraAccessId: '',
          managerId: '',
          ...newProject,
        });

        setSavedMembers(getSavedMembers(newProject));

        setEditMode(false);
      } else {
        newProject = await createProject(data);

        showSuccess('Project created');

        setRedirectId(newProject._id);
      }
    } catch (e) {
      showError(e);
    }
  };

  const handleNewMemberChange = (field, value) => {
    const data = { ...newMember };
    data[field] = value;

    if (field === 'userId') {
      data.roleIds = [];
    }

    setNewMember(data);
  };

  const handleMemberChange = (member, field, value) => {
    const members = [...project.members];

    const changedMember = members.find((item) => item.userId === member.userId);

    changedMember[field] = value;

    setProject({ ...project, members });
  };

  const handleAddMember = () => {
    const members = [...project.members];

    if (newMember.userId && newMember.roleIds.length) {
      members.push(newMember);

      setProject({ ...project, members });

      setNewMember({
        userId: '',
        roleIds: [],
      });
    }
  };

  const handleDeleteMember = (member) => {
    showConfirm(() => {
      if (userAllocation[member.userId] && userAllocation[member.userId].length) {
        showError('Remove member allocations first');
        return;
      }

      const members = [...project.members];

      const index = members.findIndex((item) => item.userId === member.userId);

      members.splice(index, 1);

      setProject({ ...project, members });
    });
  };

  const renderProjectInfo = (project, users, clients) => {
    const columns = [
      { id: 'managerName', label: 'Manager' },
      { id: 'clientName', label: 'Client' },
      { id: 'accountManagerName', label: 'Account Manager' },
      { id: 'statusName', label: 'Status' },
      { id: 'loggingType', label: 'Logging Type' },
      { id: 'createdAt', label: 'Created Date' },
    ];

    const client = clients.find((client) => client._id === project.clientId);

    const projectManager = project.managerId ? users.find((user) => user._id === project.managerId) : null;
    const accountManager = client.managerId ? users.find((user) => user._id === client.managerId._id) : null;

    const info = {
      statusName: projectStatuses.find((status) => status.id === project.status).name,
      clientName: client.companyName,
      accountManagerName: formatUserName(accountManager),
      managerName: formatUserName(projectManager),
      createdAt: formatDate(project.createdAt),
      loggingType: project.autoLogBillablePlan ? 'Auto Log Billable' : (project.jiraId ? 'JIRA' : 'Manual'),
    };

    const membersColumns = [
      { id: 'name', label: 'Name' },
      { id: 'roles', label: 'Roles' },
    ];

    const membersInfo = project.members
      .filter((member) => !member.disabled)
      .map((member) => {
        const memberRoles = member.roleIds.map((roleId) => roles.find((role) => role._id === roleId));

        return {
          id: member._id,
          name: formatMemberName(member),
          roles: memberRoles.map((role) => role.name).join(', '),
        };
      });

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id}>
                      {column.label}
                    </TableCell>
                  ))}
                  { canEditProject ? (
                    <TableCell />
                  ) : null }
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  { columns.map((column) => {
                    const value = info[column.id];

                    return (
                      <TableCell key={column.id}>
                        { value }
                      </TableCell>
                    );
                  }) }

                  { canEditProject ? (
                    <TableCell>
                      <Button onClick={() => setEditMode(true)}>Edit</Button>
                    </TableCell>
                  ) : null }
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <h4>Members</h4>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          { membersInfo.length ? (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {membersColumns.map((column) => (
                      <TableCell key={column.id}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  { membersInfo.map((member) => (
                    <TableRow key={member.id}>
                      { membersColumns.map((column) => {
                        const value = member[column.id];

                        return (
                          <TableCell key={column.id}>
                            { value }
                          </TableCell>
                        );
                      }) }
                    </TableRow>
                  )) }
                </TableBody>
              </Table>
            </TableContainer>
          ) : 'No members' }

        </GridItem>
      </GridContainer>
    );
  };

  const formatUserName = (user) => {
    if (!user) {
      return '-';
    }

    return `${user.firstName} ${user.lastName}`;
  };

  const formatMemberName = (member) => {
    const user = users.find((user) => user._id === member.userId);

    return formatUserName(user);
  };

  function getSavedMembers(project) {
    return project.members.filter((member) => member._id && !member.disabled);
  }

  function getUsersWithRoles(users, roles) {
    return users
      .filter((user) => isUserActive(user))
      .filter((user) => roles.find((role) => role.userIds.find((roleUser) => roleUser._id === user._id)));
  }

  function getMemberRoles(memberId) {
    if (!memberId) {
      return [];
    }

    return roles.filter((role) => role.userIds.find((user) => user._id === memberId));
  }

  const memberDeleteClickHandler = (member) => () => {
    handleDeleteMember(member);
  };

  return (
    <div>
      { redirectId ? <Redirect to={`/production/projects/${redirectId}`} /> : ''}
      <GridContainer>
        { project ? (
          <GridItem xs={12} sm={12} md={12}>
            { editMode ? (
              <Card>
                <CardHeader color="success">
                  <h4>{project._id ? 'Edit Project' : 'Create Project'}</h4>
                  {project._id ? project.name : ''}
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                      <TextField
                        fullWidth
                        required
                        label="Name"
                        value={project.name}
                        onChange={(e) => handleValueChange('name', e.target.value)}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <FormControl className={classes.formControl}>
                        <InputLabel>Client *</InputLabel>
                        <Select
                          value={project.clientId}
                          onChange={(e) => handleValueChange('clientId', e.target.value)}
                        >
                          { clients
                            .filter((client) => isClientActive(client) || client._id === project.clientId)
                            .map((client) => (
                              <MenuItem key={`client-${client._id}`} value={client._id}>
                                {client.companyName}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <FormControl className={classes.formControl}>
                        <InputLabel>Status *</InputLabel>
                        <Select
                          value={project.status}
                          onChange={(e) => handleValueChange('status', e.target.value)}
                        >

                          { projectStatuses.map((status) => (
                            <MenuItem key={`status-${status.id}`} value={status.id}>
                              {status.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <FormControl className={classes.formControl}>
                        <InputLabel>Manager</InputLabel>
                        <Select
                          value={project.managerId}
                          onChange={(e) => handleValueChange('managerId', e.target.value)}
                        >
                          { projectManagers
                            .map((user) => (
                              <MenuItem key={`user-${user._id}`} value={user._id}>
                                {`${user.firstName} ${user.lastName}`}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <FormControlLabel
                        className={classes.projectCheckboxMargin}
                        control={(
                          <Checkbox
                            checked={project.billable}
                            onChange={(e) => handleValueChange('billable', e.target.checked)}
                            name="billable"
                            color="primary"
                          />
                        )}
                        label="Billable"
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer className={classes.grid}>
                    <GridItem xs={12} sm={12} md={2}>
                      <FormControl className={classes.formControl}>
                        <InputLabel>Logging Type</InputLabel>
                        <Select
                          value={timeTracking}
                          onChange={(e) => setTimeTracking(e.target.value)}
                        >

                          { timeTrackingOptions.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>

                    { timeTracking === 'jira' ? (
                      <>
                        <GridItem xs={12} sm={12} md={2}>
                          <TextField
                            fullWidth
                            required
                            label="JIRA ID or Key"
                            value={project.jiraId}
                            onChange={(e) => handleValueChange('jiraId', e.target.value)}
                          />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={3}>
                          <FormControl className={classes.formControl}>
                            <InputLabel>JIRA Access *</InputLabel>
                            <Select
                              value={project.jiraAccessId}
                              onChange={(e) => handleValueChange('jiraAccessId', e.target.value)}
                            >

                              { jiraAccesses.map((access) => (
                                <MenuItem key={`access-${access._id}`} value={access._id}>
                                  {access.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>
                      </>
                    ) : null }
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={2}>
                      <h3>Members</h3>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={2}>
                      <FormControl className={classes.formControl}>
                        <InputLabel>User *</InputLabel>
                        <Select
                          value={newMember.userId}
                          onChange={(e) => handleNewMemberChange('userId', e.target.value)}
                        >

                          { memberUsers
                            .filter((user) => !project.members.find((member) => member.userId === user._id))
                            .map((user) => (
                              <MenuItem key={`user-${user._id}`} value={user._id}>
                                {`${user.firstName} ${user.lastName}`}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <FormControl className={classes.formControl}>
                        <InputLabel>Roles *</InputLabel>
                        <Select
                          disabled={!newMember.userId}
                          multiple
                          value={newMember.roleIds}
                          renderValue={(selected) => selected.map((roleId) => roles.find((role) => role._id === roleId).name).join(', ')}
                          onChange={(e) => handleNewMemberChange('roleIds', e.target.value)}
                        >
                          { getMemberRoles(newMember.userId).map((role) => (
                            <MenuItem key={`role-${role._id}`} value={role._id}>
                              <ListItemText primary={role.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <Button color="info" onClick={() => handleAddMember()}>+ Add Member</Button>
                    </GridItem>
                  </GridContainer>
                  { project.members.map((member) => (
                    <GridContainer key={member.userId}>
                      <GridItem xs={12} sm={12} md={2}>
                        <div className={classes.memberName}>{formatMemberName(member)}</div>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <FormControl className={`${classes.formControl} ${classes.memberFormControl}`}>
                          <InputLabel>Roles *</InputLabel>
                          <Select
                            multiple
                            value={member.roleIds}
                            renderValue={(selected) => selected.map((roleId) => roles.find((role) => role._id === roleId).name).join(', ')}
                            onChange={(e) => handleMemberChange(member, 'roleIds', e.target.value)}
                          >
                            { getMemberRoles(member.userId).map((role) => (
                              <MenuItem key={`role-${role._id}`} value={role._id}>
                                <ListItemText primary={role.name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={2}>
                        <FormControlLabel
                          className={classes.projectCheckboxMargin}
                          control={(
                            <Checkbox
                              checked={member.disabled}
                              onChange={(e) => handleMemberChange(member, 'disabled', e.target.checked)}
                              name="disabled"
                              color="primary"
                            />
                          )}
                          label="Disabled"
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={1}>
                        <IconButton aria-label="delete" className={classes.memberDelete} onClick={memberDeleteClickHandler(member)}>
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </GridItem>
                    </GridContainer>
                  )) }
                </CardBody>
                <CardFooter>
                  <Button color="primary" onClick={handleSubmit}>{project._id ? 'Update' : 'Create'}</Button>
                </CardFooter>
              </Card>
            ) : (
              <Card>
                <CardHeader color="success">
                  <h4>{project.name}</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      { renderProjectInfo(project, users, clients) }
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            )}
          </GridItem>
        ) : null }
      </GridContainer>
      { project && project._id && savedMembers.length ? (
        <>
          { canViewLogs ? (
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="info">
                    <h4>Time Report</h4>
                  </CardHeader>
                  <CardBody>
                    <ProjectLogs
                      project={project}
                      formatMemberName={formatMemberName}
                      savedMembers={savedMembers}
                      userAllocation={userAllocation}
                    />
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          ) : null }
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="warning">
                  <h4>Member Allocation</h4>
                </CardHeader>
                <CardBody>
                  <ProjectAllocation
                    project={project}
                    users={users}
                    savedMembers={savedMembers}
                    onAllocationChange={(allocation) => setUserAllocation(allocation)}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </>
      ) : null }
    </div>
  );
}
