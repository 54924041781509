import React, { useContext, useEffect, useState } from 'react';

import {
  formatMoney,
  formatNumber,
} from 'services/finance';

import {
  formatInvoiceDateRange,
} from 'services/invoice';

import {
  getUsers,
} from 'services/user';

import {
  formatDate,
} from 'services/date';

import { ConsoleContext } from 'services/context';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import { CSVLink } from 'react-csv';

import Button from 'components/CustomButtons/Button';

import InvoiceDebtLogsDialog from './InvoiceDebtLogsDialog';

const useStyles = makeStyles((theme) => ({
  actions: {
    marginTop: '15px',
    textAlign: 'center',
  },
}));

export default function InvoicePaymentDetails({ invoice, client }) {
  const classes = useStyles();

  const { t } = useContext(ConsoleContext);

  const columns = [
    { key: 'id', label: '#' },
    { key: 'description', label: 'Description' },
    { key: 'paymentType', label: 'Pre- or Post- payment' },
    { key: 'period', label: 'Period' },
    { key: 'hours', label: 'Hours' },
    { key: 'amount', label: `Amount, ${invoice.currency}` },
    { key: 'debtLogs', label: '' },
  ];

  const [rows, setRows] = useState([]);

  const [users, setUsers] = useState([]);

  const [openLogsDialog, setOpenLogsDialog] = useState(false);

  const [debtItem, setDebtItem] = useState();

  useEffect(() => {
    (async () => {
      const users = await getUsers();

      const userNames = {};

      users.forEach((user) => {
        userNames[user._id] = `${user.firstName} ${user.lastName[0].toUpperCase()}.`;
      });

      const rows = [];
      let index = 0;

      if (invoice.details && invoice.details.length) {
        invoice.details.forEach((item) => {
          index++;

          rows.push({
            id: index,
            description: `Software development services, ${userNames[item.userId]}`,
            paymentType: item.prepayment ? 'prepayment' : 'postpayment',
            period: formatInvoiceDateRange(item.startAt, item.endAt),
            amount: formatMoney(item.amount, false),
            hours: item.hours ? formatNumber(item.hours) : '',
          });
        });
      }

      let remainingInvoiceDebt = invoice.lastInvoiceDebt || 0;

      if (invoice.lastInvoiceDebtDetails && invoice.lastInvoiceDebtDetails.length) {
        invoice.lastInvoiceDebtDetails.reverse().forEach((item) => {
          index++;

          rows.push({
            id: index,
            description: `Software development services, ${userNames[item.userId]}`,
            period: item.startAt ? formatInvoiceDateRange(item.startAt, item.endAt) : null,
            amount: formatMoney(item.amount, false),
            hours: item.hours ? formatNumber(item.hours) : '',
            debtLogs: (
              <a href="#" onClick={(e) => handleOpenLogsDialog(e, item)}>
                {t('details')}
              </a>
            ),
          });

          remainingInvoiceDebt -= item.amount;
        });
      }

      const period = formatInvoiceDateRange(invoice.billingStartAt, invoice.billingEndAt);

      const fields = [{
        id: 'additionalAmount',
        name: 'Additional Amount',
      }, {
        id: 'serviceCompensation',
        name: 'Service Compensation',
      }, {
        id: 'discount',
        name: 'Discount',
      }, {
        id: 'lastInvoiceDebt',
        name: 'Debt from the previous payment',
      }];

      fields.forEach((field) => {
        let amount = invoice[field.id];

        if (field.id === 'lastInvoiceDebt') {
          amount = remainingInvoiceDebt;
        }

        if (amount) {
          index++;

          const comment = invoice[`${field.id}Comment`];

          rows.push({
            id: index,
            description: field.name + (comment ? `, ${comment}` : ''),
            period: field.id === 'lastInvoiceDebt' ? '' : period,
            amount: formatMoney(amount, false),
            paymentType: '',
          });
        }
      });

      rows.push({
        id: '',
        description: 'Total to pay',
        period: '',
        amount: formatMoney(invoice.totalAmount, false),
        paymentType: '',
      });

      setUsers(users);
      setRows(rows);
    })();
  }, [invoice]);

  const getExportFileName = (invoice, client) => `Invoice.${invoice.key}.${client.companyName}.${formatDate(invoice.billingStartAt)}-${formatDate(invoice.billingEndAt)}.csv`;

  const getExportHeaders = (columns) => columns.filter((column) => column.key !== 'debtLogs');

  const handleOpenLogsDialog = (e, item) => {
    e.preventDefault();

    setDebtItem(item);
    setOpenLogsDialog(true);
  };

  const handleCloseLogsDialog = () => {
    setOpenLogsDialog(false);
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <h4>{t('Invoice Details')}</h4>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  { columns.map((column) => (
                    <TableCell key={column.key}>
                      {column.label}
                    </TableCell>
                  )) }
                </TableRow>
              </TableHead>
              <TableBody>
                { rows.map((row) => (
                  <TableRow key={row.id}>
                    {columns.map((column) => (
                      <TableCell key={column.key}>
                        { row[column.key] }
                      </TableCell>
                    ))}
                  </TableRow>
                )) }
              </TableBody>
            </Table>
          </TableContainer>
        </GridItem>
      </GridContainer>

      <GridContainer className={classes.actions}>
        <GridItem xs={12} sm={12} md={12}>
          <CSVLink
            data={rows}
            headers={getExportHeaders(columns)}
            filename={getExportFileName(invoice, client)}
          >
            <Button color="success">{t('Download')}</Button>
          </CSVLink>
        </GridItem>
      </GridContainer>

      <InvoiceDebtLogsDialog
        open={openLogsDialog}
        invoice={invoice}
        debtItem={debtItem}
        users={users}
        onClose={handleCloseLogsDialog}
      />
    </>
  );
}
