import React from 'react';

import PageWithTabs from '../../components/PageWithTabs/PageWithTabs';

import RoleList from '../Role/RoleList';
import RoleDetails from '../Role/RoleDetails';
import ProjectList from './Project/ProjectList';
import ProjectDetails from './Project/ProjectDetails';
import ProjectReport from './ProjectReport/ProjectReport';

export default function Finance(props) {
  const entities = [{
    id: 'project',
    path: 'projects',
    name: 'Projects',
    can: 'production:projects:view',
    components: [{
      path: '/production/projects/create',
      component: ProjectDetails,
    }, {
      path: '/production/projects/:id',
      component: ProjectDetails,
    }, {
      path: '/production/projects',
      component: ProjectList,
    }],
  }, {
    id: 'report',
    path: 'report',
    name: 'Report',
    can: 'production:report:view',
    components: [{
      path: '/production/report',
      component: ProjectReport,
    }],
  }, {
    id: 'role',
    path: 'roles',
    name: 'Roles',
    can: 'production:roles:view',
    components: [{
      path: '/:circleId/roles/create',
      component: RoleDetails,
    }, {
      path: '/:circleId/roles/:id',
      component: RoleDetails,
    }, {
      path: '/:circleId/roles',
      component: RoleList,
    }],
  }];

  return (
    <PageWithTabs basePath="production" entities={entities} {...props} />
  );
}
