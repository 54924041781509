import {
  CLIENT_URL,
} from '../utils/apiRouting';

import {
  get, post, put, formatQuery,
} from '../utils/http';

export async function getClients(query = {}) {
  return get(CLIENT_URL + formatQuery(query));
}

export async function getClient(id) {
  return get(`${CLIENT_URL}/${id}`);
}

export async function createClient(client) {
  return post(CLIENT_URL, client);
}

export async function updateClient(client) {
  return put(`${CLIENT_URL}/${client._id}`, client);
}

export async function getManagers(currentManagerId = null) {
  const params = currentManagerId ? { currentManagerId } : {};
  return get(`${CLIENT_URL}/managers${formatQuery(params)}`);
}

export async function updateClientStatus(clientId, status) {
  return put(`${CLIENT_URL}/${clientId}/status`, { status });
}

export function getClientStatusOptions() {
  return [
    {
      id: 'active',
      name: 'Active',
    },
    {
      id: 'archived',
      name: 'Archived',
    },
  ];
}

export function filterActiveClients(clients) {
  return clients.filter((client) => isClientActive(client));
}

export function isClientActive(client) {
  return client.status === 'active' || !client.status;
}
