import React, { useContext, useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Button from 'components/CustomButtons/Button.js';
import FunctionsIcon from '@material-ui/icons/Functions';

import { ConsoleContext } from 'services/context';

import {
  getClientContractCurrencies,
} from 'services/clientContract';

import {
  convertCurrency,
  parseInputNumber,
} from 'services/finance';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '100%',
  },
}));

export default function CurrencyConverter({ currencyTo, exchangeRates, onApply }) {
  const classes = useStyles();

  const { showError, t } = useContext(ConsoleContext);

  const [amount, setAmount] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [result, setResult] = useState('');

  const [currencies, setCurrencies] = useState([]);
  const [currency, setCurrency] = useState('');

  useEffect(() => {
    const currencies = getClientContractCurrencies().filter((currency) => currency !== currencyTo);

    setCurrencies(currencies);
    setCurrency(currencies[0]);
  }, [currencyTo]);

  useEffect(() => {
    setResult(calcResult(amount, currency));
  }, [amount, currency]);

  const handleOpen = () => {
    setAmount('');
    setResult('');
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCurrencyChange = (currency) => {
    setCurrency(currency);
  };

  const handleAmountChange = (amount) => {
    setAmount(amount);
  };

  const handleApply = () => {
    onApply(calcResult(amount, currency));
    setOpenDialog(false);
  };

  const calcResult = (amount, currency) => {
    amount = parseInputNumber(amount);

    if (!amount) {
      return '';
    }

    return convertCurrency(amount, currency, currencyTo, exchangeRates);
  };

  return (
    <>
      <IconButton onClick={() => handleOpen()} title={t('Calculate in {{currency}}', { currency: currencyTo })}>
        <FunctionsIcon />
      </IconButton>
      <Dialog
        maxWidth="xs"
        fullWidth
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>{t('Convert to {{currency}}', { currency: currencyTo })}</DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={2}>
              <FormControl className={classes.formControl}>
                <InputLabel>{t('Currency')}</InputLabel>
                <Select
                  fullWidth
                  value={currency}
                  onChange={(e) => handleCurrencyChange(e.target.value)}
                >

                  { currencies.map((currency) => (
                    <MenuItem key={currency} value={currency}>
                      { currency }
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={5}>
              <TextField
                fullWidth
                value={amount}
                label={`${t('Amount')}, ${currency}`}
                onChange={(e) => handleAmountChange(e.target.value)}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={5}>
              <TextField
                disabled
                fullWidth
                value={result}
                label={`${t('Result')}, ${currencyTo}`}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>
            {t('Cancel')}
          </Button>
          <Button onClick={handleApply} color="primary">
            {t('Apply')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
