import React, { useState, useEffect, useContext } from 'react';

import { ConsoleContext } from 'services/context';

import {
  formatTechStack,
  getTechStack,
} from 'services/techStack';

import {
  getPublicOpportunity,
  formatEnglishLevel,
  formatExperienceYears,
  formatPosition,
} from 'services/opportunity';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import Table from 'components/Table/Table';

import OpportunityProject from './components/OpportunityProject';

const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: '15px',
  },
}));

export default function OpportunityDetails(props) {
  const { id } = props.match.params;

  const classes = useStyles();

  const {
    showLoader, showError, showSuccess, showConfirm, t, showPageTitle,
  } = useContext(ConsoleContext);

  const [opportunity, setOpportunity] = useState();

  const [techStack, setTechStack] = useState([]);

  const [fields, setFields] = useState();

  const columns = [
    { id: 'position', name: 'Position' },
    { id: 'englishLevel', name: 'English Level' },
    { id: 'requirements', name: 'Requirements' },
    { id: 'responsibilities', name: 'Responsibilities' },
    { id: 'techStack', name: '' },
  ];

  useEffect(() => {
    (async () => {
      showLoader(true);

      try {
        let opportunity = {};

        const techStack = await getTechStack();

        if (id) {
          opportunity = await getPublicOpportunity(id);
        }

        setTechStack(techStack);
        setOpportunity(opportunity);

        showPageTitle(`Vacancy "${opportunity.name}"`);
      } catch (e) {
        showError(e);
      }
    })();

    showLoader(false);
  }, [id]);

  useEffect(() => {
    if (opportunity) {
      const {
        position,
        englishLevel,
        requirements,
        responsibilities,
      } = opportunity;

      setFields({
        position: formatPosition(position),
        englishLevel: formatEnglishLevel(englishLevel),
        requirements: formatRequirements(requirements),
        responsibilities: formatRequirements(responsibilities),
        techStack: renderTechStack(opportunity.techStack, techStack),
      });
    }
  }, [opportunity]);

  const renderTechStack = (opportunityTechStack, techStack) => {
    if (!opportunityTechStack.length) {
      return null;
    }

    const tableHead = ['Technology', 'Experience'];

    const tableData = opportunityTechStack.map((item) => [
      formatTechStack(item.id, techStack),
      formatExperienceYears(item.experienceYears),
    ]);

    return (<Table tableHead={tableHead} tableData={tableData} style={{ marginTop: 0 }} />);
  };

  const formatRequirements = (requirements) => {
    if (!requirements || !requirements.length) {
      return '-';
    }

    return (
      <ul>
        { requirements.map((item, key) => (
          <li key={key}>{item}</li>
        )) }
      </ul>
    );
  };

  if (!fields) {
    return null;
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardBody>
            <h4>
              Vacancy "
              {opportunity.name}
              "
            </h4>

            { columns.map((column) => (
              <GridContainer key={column.id} className={classes.grid}>
                <GridItem xs={12} sm={12} md={6}>
                  { column.name ? (
                    <strong>
                      {column.name}
                      :
                      {' '}
                    </strong>
                  ) : null }
                  {fields[column.id]}
                </GridItem>
              </GridContainer>
            )) }

            <h4>Project</h4>

            <OpportunityProject project={opportunity.project} />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
