import React, { useState, useEffect, useContext } from 'react';

import {
  formatCandidateRate,
  formatExperienceYears,
} from 'services/candidate';

import {
  formatEnglishLevel,
  getStatusDateField,
  updateOpportunityCandidateStatus,
} from 'services/opportunity';

import {
  formatTechStack,
} from 'services/techStack';

import {
  formatDateTime,
} from 'services/date';

import {
  formatUserNameById,
} from 'services/user';

import { ConsoleContext } from 'services/context';

import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from 'components/CustomDialog/CustomDialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import GetAppIcon from '@material-ui/icons/GetApp';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Button from 'components/CustomButtons/Button';

import Table from 'components/Table/Table';

import CandidateInterviewResults from './CandidateInterviewResults';
import CandidateClientInterviewResults from './CandidateClientInterviewResults';
import CandidateState from './CandidateState';
import CandidateStatus from './CandidateStatus';
import CandidateRejectDialog from './CandidateRejectDialog';
import CandidateStatusInfo from '../../Candidate/components/CandidateStatus';
import CandidateName from '../../Candidate/components/CandidateName';
import CandidateArrangeInterview from './CandidateArrangeInterview';

const useStyles = makeStyles(() => ({
  grid: {
    marginTop: '15px',
  },
  interviewResults: {
    maxHeight: '300px',
    overflowY: 'auto',
  },
}));

export default function CandidateDialog({
  open, opportunity, opportunityCandidate, onClose, onSave, techStack, users,
}) {
  const classes = useStyles();

  const { showError, showSuccess, t } = useContext(ConsoleContext);

  const [fields, setFields] = useState();

  const [loading, setLoading] = useState(false);

  const [openRejectDialog, setOpenRejectDialog] = useState(false);

  const [openArrangeInterview, setOpenArrangeInterview] = useState(false);

  const [changedOpportunityCandidate, setChangedOpportunityCandidate] = useState();

  const columns = [
    { id: 'status', name: 'Status' },
    { id: 'statusChangedDate', name: 'Status Changed' },
    { id: 'addedAt', name: 'Date Added' },
    { id: 'recruiter', name: 'Recruiter' },
    { id: 'state', name: 'Note' },
    { id: 'name', name: 'Candidate' },
    { id: 'rate', name: 'Rate' },
    { id: 'englishLevel', name: 'English Level' },
    { id: 'location', name: 'Location' },
    { id: 'createdAt', name: 'Date Created' },
    { id: 'techStack', name: '' },
    { id: 'cvLink', name: '' },
  ];

  useEffect(() => {
    setChangedOpportunityCandidate(opportunityCandidate);
  }, [opportunityCandidate]);

  useEffect(() => {
    if (!open || !opportunity || !opportunityCandidate) {
      return;
    }

    const { candidate } = opportunityCandidate;

    const {
      englishLevel,
      recruiterId,
      location,
      createdAt,
    } = candidate;

    setFields({
      status: renderStatus(opportunityCandidate),
      statusChangedDate: formatStatusChangeDate(opportunityCandidate),
      name: renderCandidateName(candidate),
      rate: formatCandidateRate(candidate),
      englishLevel: formatEnglishLevel(englishLevel),
      techStack: renderTechStack(candidate),
      addedAt: formatDateTime(opportunityCandidate.addedAt),
      recruiter: formatUserNameById(recruiterId, users),
      state: renderState(),
      cvLink: renderCvLink(candidate),
      location: location || '-',
      createdAt: formatDateTime(createdAt),
    });
  }, [opportunity, opportunityCandidate]);

  const handleCloseDialog = () => {
    onClose();
  };

  const handleChangeStatus = async (status) => {
    if (status === 'rejected') {
      setOpenRejectDialog(true);
      return;
    }

    const data = { ...opportunityCandidate };
    data.status = status;

    try {
      const changedCandidate = await updateOpportunityCandidateStatus(opportunity._id, data);

      const messages = {
        interview: 'Candidate approved for internal interview',
        client_interview: 'Candidate approved for client interview',
      };

      showSuccess(messages[status] || 'Candidate status changed');

      onSave(changedCandidate);
    } catch (e) {
      showError(e);
    }
  };

  const handleRejectSave = (changedCandidate) => {
    onSave(changedCandidate);
  };

  const handleClientInterviewFeedbackSave = async (feedback) => {
    const changedCandidate = { ...opportunityCandidate };

    if (!changedCandidate.clientInterviewFeedback) {
      changedCandidate.clientInterviewFeedback = [];
    }

    changedCandidate.clientInterviewFeedback.push(feedback);

    onSave(changedCandidate, false);
  };

  const handleLoading = (loading) => {
    setLoading(loading);
  };

  const handleCandidateStateUpdate = ({ state, stateNote }) => {
    const changedCandidate = { ...opportunityCandidate };

    changedCandidate.state = state;
    changedCandidate.stateNote = stateNote;

    onSave(changedCandidate, false);
  };

  const handleCloseRejectDialog = () => {
    setOpenRejectDialog(false);
  };

  const renderCandidateName = (candidate) => (
    <CandidateName candidate={candidate} link={{ target: '_blank' }} />
  );

  const renderStatus = (opportunityCandidate) => (
    <CandidateStatus
      opportunity={opportunity}
      opportunityCandidate={opportunityCandidate}
      onSave={handleChangeStatus}
    />
  );

  const formatStatusChangeDate = (opportunityCandidate) => {
    const dateField = getStatusDateField(opportunityCandidate.status);

    return formatDateTime(opportunityCandidate[dateField]);
  };

  const renderTechStack = (candidate) => {
    if (!candidate.techStack.length) {
      return null;
    }

    const tableHead = ['Technology', 'Experience'];

    const tableData = candidate.techStack.map((item) => [
      formatTechStack(item.id, techStack),
      formatExperienceYears(item.experienceYears),
    ]);

    return (<Table tableHead={tableHead} tableData={tableData} style={{ marginTop: 0 }} />);
  };

  const renderCvLink = (candidate) => (
    <GridContainer className={classes.grid}>
      <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
        {candidate.cvLink ? (
          <Button
            type="button"
            color="success"
            href={candidate.cvLink}
            target="_blank"
          >
            <GetAppIcon />
            Download CV
          </Button>
        ) : null}

        {candidate.cvLinkPrepared ? (
          <Button
            type="button"
            color="info"
            href={candidate.cvLinkPrepared}
            target="_blank"
            style={{ marginLeft: '10px' }}
          >
            <OpenInNewIcon />
            Open Internal CV
          </Button>
        ) : null}
      </GridItem>
    </GridContainer>
  );

  const renderState = () => (
    <CandidateState
      opportunity={opportunity}
      opportunityCandidate={opportunityCandidate}
      onSave={handleCandidateStateUpdate}
      onLoading={handleLoading}
    />
  );

  if (!open || !opportunityCandidate || !fields) {
    return null;
  }

  const handleArrangeInterview = () => {
    setChangedOpportunityCandidate({ ...changedOpportunityCandidate });
  };

  return (
    <>
      <Dialog
        maxWidth="lg"
        fullWidth
        open={open}
        loading={loading}
        onClose={handleCloseDialog}
      >
        <DialogTitle>
          {`Candidate for ${opportunity.name}`}
          {' '}
          <CandidateStatusInfo candidate={opportunityCandidate.candidate} />
        </DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              {columns.map((column) => (
                <GridContainer key={column.id} className={column.id === 'name' ? classes.grid : null}>
                  <GridItem xs={12} sm={12} md={12}>
                    {column.name ? (
                      <strong>
                        {column.name}
                        :
                        {' '}
                      </strong>
                    ) : null}
                    {fields[column.id]}
                  </GridItem>
                </GridContainer>
              ))}
            </GridItem>

            <GridItem xs={12} sm={12} md={4}>
              <GridContainer direction="row" alignItems="flex-start">
                <GridItem xs={12} sm={12} md={12}>
                  <h4 style={{ marginTop: 0, display: 'inline-block' }}>Internal Interview Results</h4>
                  {!openArrangeInterview && (
                    <Button color="warning" size="sm" onClick={() => setOpenArrangeInterview(true)} style={{ margin: 0, marginLeft: 10 }}>
                      Arrange
                    </Button>
                  )}
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CandidateArrangeInterview
                    open={openArrangeInterview}
                    opportunity={opportunity}
                    opportunityCandidate={opportunityCandidate}
                    onClose={() => setOpenArrangeInterview(false)}
                    users={users}
                    onLoading={handleLoading}
                    onArrange={handleArrangeInterview}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer className={classes.interviewResults}>
                <GridItem xs={12} sm={12} md={12}>
                  <CandidateInterviewResults
                    opportunity={opportunity}
                    opportunityCandidate={changedOpportunityCandidate}
                    techStack={techStack}
                    onLoading={handleLoading}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>

            <GridItem xs={12} sm={12} md={4}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4 style={{ marginTop: 0 }}>Client Interview Results</h4>
                </GridItem>
              </GridContainer>

              <GridContainer className={classes.interviewResults}>
                <GridItem xs={12} sm={12} md={12}>
                  <CandidateClientInterviewResults
                    opportunity={opportunity}
                    opportunityCandidate={opportunityCandidate}
                    onFeedbackSave={handleClientInterviewFeedbackSave}
                    onLoading={handleLoading}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          {opportunityCandidate.status === 'added' ? (
            <>
              {opportunity.requiredInterviews.length ? (
                <Button onClick={() => handleChangeStatus('interview')} color="info">
                  {t('Approve for Internal Interview')}
                </Button>
              ) : (
                <Button onClick={() => handleChangeStatus('client_interview')} color="info">
                  {t('Approve for Client Interview')}
                </Button>
              )}

              <Button onClick={() => handleChangeStatus('rejected')} color="danger">
                {t('Reject')}
              </Button>
            </>
          ) : opportunityCandidate.status === 'interview' ? (
            <>
              <Button onClick={() => handleChangeStatus('client_interview')} color="info">
                {t('Approve for Client Interview')}
              </Button>

              <Button onClick={() => handleChangeStatus('rejected')} color="danger">
                {t('Reject')}
              </Button>
            </>
          ) : opportunityCandidate.status === 'client_interview' ? (
            <>
              <Button onClick={() => handleChangeStatus('offer')} color="info">
                {t('Send Offer')}
              </Button>

              <Button onClick={() => handleChangeStatus('rejected')} color="danger">
                {t('Reject')}
              </Button>
            </>
          ) : opportunityCandidate.status === 'offer' ? (
            <>
              <Button onClick={() => handleChangeStatus('hired')} color="info">
                {t('Accepted Offer')}
              </Button>

              <Button onClick={() => handleChangeStatus('rejected')} color="danger">
                {t('Rejected')}
              </Button>
            </>
          ) : null}

          <Button onClick={handleCloseDialog}>
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>

      <CandidateRejectDialog
        opportunity={opportunity}
        opportunityCandidate={opportunityCandidate}
        open={openRejectDialog}
        onClose={handleCloseRejectDialog}
        onSave={handleRejectSave}
      />
    </>
  );
}
