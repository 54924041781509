import React from 'react';

// core components
import PageWithTabs from '../../components/PageWithTabs/PageWithTabs';
import PermissionList from './Permission/PermissionList';
import PermissionDetails from './Permission/PermissionDetails';
import JiraIntegration from './JiraIntegration/JiraIntegration';
import LocaleList from './Locale/LocaleList';
import LocaleMessages from './Locale/LocaleMessages';

export default function Admin(props) {
  const entities = [{
    id: 'permissions',
    path: 'permissions',
    name: 'Permissions',
    can: 'admin:permissions:edit',
    components: [{
      path: '/admin/permissions/:roleId',
      component: PermissionDetails,
    }, {
      path: '/admin/permissions',
      component: PermissionList,
    }],
  }, {
    id: 'jira',
    path: 'jira',
    name: 'JIRA',
    can: 'admin:jiraAccess:edit',
    components: [{
      path: '/admin/jira',
      component: JiraIntegration,
    }],
  }, {
    id: 'locales',
    path: 'locales',
    name: 'Lang',
    can: 'admin:locales:edit',
    components: [{
      path: '/admin/locales/:localeId',
      component: LocaleMessages,
    }, {
      path: '/admin/locales',
      component: LocaleList,
    }],
  }];

  return (
    <PageWithTabs basePath="admin" entities={entities} {...props} />
  );
}
