import React, { useState, useEffect, useContext } from 'react';

import { ConsoleContext } from 'services/context';

import {
  formatCandidateState,
  getCandidateStateOptions,
  updateOpportunityCandidateState,
  deleteOpportunityCandidateState,
} from 'services/opportunity';

import { makeStyles } from '@material-ui/core/styles';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Button from 'components/CustomButtons/Button';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: '15px',
  },
}));

export default function CandidateState({
  opportunity, opportunityCandidate, onSave, onLoading,
}) {
  const classes = useStyles();

  const {
    showError, showSuccess, t, can,
  } = useContext(ConsoleContext);

  const [state, setState] = useState();
  const [stateNote, setStateNote] = useState();

  const [changedState, setChangedState] = useState();
  const [canEdit, setCanEdit] = useState(false);

  useEffect(() => {
    (async () => {
      setCanEdit(await can('sales:opportunities:edit'));
    })();
  }, []);

  useEffect(() => {
    const { state, stateNote } = opportunityCandidate;

    setState(state);
    setStateNote(stateNote);
  }, [opportunityCandidate]);

  const handleChangeState = () => {
    setChangedState({
      state: state || '',
      stateNote: stateNote || '',
    });
  };

  const handleChange = (field, value) => {
    const data = { ...changedState };

    data[field] = value;

    setChangedState(data);
  };

  const handleCancel = () => {
    setChangedState(null);
  };

  const handleSubmit = async () => {
    const data = { ...changedState };

    onLoading(true);

    try {
      await updateOpportunityCandidateState(opportunity._id, opportunityCandidate.id, data);

      showSuccess('Note updated');
      setChangedState(null);
      onSave(data);
    } catch (e) {
      showError(e);
    }

    onLoading(false);
  };

  const handleRemove = async () => {
    onLoading(true);

    try {
      await deleteOpportunityCandidateState(opportunity._id, opportunityCandidate.id);

      showSuccess('Note removed');
      setChangedState(null);
      onSave({ state: null, stateNote: null });
    } catch (e) {
      showError(e);
    }

    onLoading(false);
  };

  return (
    <>
      { changedState ? (
        <>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CustomSelect
                required
                label="Option"
                value={changedState.state}
                options={getCandidateStateOptions()}
                onChange={(value) => handleChange('state', value)}
              />
            </GridItem>
          </GridContainer>

          <GridContainer className={classes.grid}>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                fullWidth
                label="Comment"
                multiline
                rows={2}
                variant="outlined"
                value={changedState.stateNote}
                onChange={(e) => handleChange('stateNote', e.target.value)}
              />
            </GridItem>
          </GridContainer>

          <GridContainer className={classes.grid}>
            <GridItem xs={12} sm={12} md={12}>
              <Button color="info" size="sm" onClick={handleSubmit}>Save</Button>

              <Button size="sm" onClick={handleCancel}>Cancel</Button>

              { state ? (
                <Button color="danger" size="sm" onClick={handleRemove} style={{ float: 'right' }}>
                  Remove Note
                </Button>
              ) : null }
            </GridItem>
          </GridContainer>
        </>
      ) : (
        <>
          { state ? (
            <>
              { formatCandidateState(state) }
            </>
          ) : '-' }

          { stateNote ? `: ${stateNote}` : null }

          {' '}

          { canEdit ? (
            <Button color="info" size="sm" onClick={handleChangeState} style={{ margin: 0 }}>
              Change
            </Button>
          ) : null }
        </>
      )}
    </>
  );
}
