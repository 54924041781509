import React, { useState, useEffect, useContext } from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import {
  getUserCapacity,
  createUserCapacity,
  updateUserCapacity,
  deleteUserCapacity,
} from 'services/user';

import {
  formatDate,
  parseInputDate,
  formatInputDate,
} from 'services/date';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Button from 'components/CustomButtons/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { ConsoleContext } from 'services/context';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '100%',
  },
  checkboxMargin: {
    marginLeft: '-3px',
    marginTop: '20px',
  },
  addDialogRoot: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
}));

export default function UserCapacity({ userId }) {
  useEffect(() => {
    async function fetchData() {
      try {
        const capacity = await getUserCapacity(userId);

        const rows = capacity.map((item) => ({ ...item }));

        setRows(rows);
      } catch (error) {
        showError(error);
      }
    }

    fetchData();
  }, [userId]);

  const classes = useStyles();

  const { showError, showSuccess, showConfirm } = useContext(ConsoleContext);

  const [rows, setRows] = useState([]);

  const [activeCapacity, setActiveCapacity] = useState({
    startAt: '',
    dailyHours: 8,
    partTime: false,
    trial: false,
  });

  const [openAddDialog, setOpenAddDialog] = useState(false);

  const handleAddCapacity = () => {
    setActiveCapacity({
      startAt: '',
      dailyHours: 8,
      partTime: false,
      trial: false,
    });

    setOpenAddDialog(true);
  };

  useEffect(() => {
    if (activeCapacity.partTime) {
      handleCapacityChange('trial', false);
    }
  }, [activeCapacity.partTime]);

  const handleEditCapacity = (capacity) => {
    setActiveCapacity({
      ...capacity,
      startAt: formatInputDate(capacity.startAt),
      endAt: capacity.endAt ? formatInputDate(capacity.endAt) : '',
      dailyHours: capacity.dailyHours || '',
      originalStartDate: capacity.originalStartDate ? formatInputDate(capacity.originalStartDate) : '',
    });

    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  const handleAddDialogSubmit = async () => {
    const data = { ...activeCapacity };

    if (!data.startAt || !data.dailyHours) {
      return;
    }

    data.startAt = parseInputDate(data.startAt);
    data.endAt = data.endAt ? parseInputDate(data.endAt) : null;
    data.originalStartDate = data.originalStartDate ? parseInputDate(data.originalStartDate) : null;

    const changedRows = [...rows];
    let newCapacity;

    try {
      if (data._id) {
        newCapacity = await updateUserCapacity(userId, data);

        const index = changedRows.findIndex((item) => item._id === newCapacity._id);
        changedRows[index] = newCapacity;

        showSuccess('Capacity updated');
      } else {
        const newCapacity = await createUserCapacity(userId, data);

        changedRows.push(newCapacity);

        showSuccess('Capacity created');
      }

      setRows(changedRows);
      setOpenAddDialog(false);
    } catch (e) {
      showError(e);
    }
  };

  const handleCapacityChange = (field, value) => {
    const data = { ...activeCapacity };
    data[field] = value;

    setActiveCapacity(data);
  };

  const handleDeleteCapacity = async (date) => {
    showConfirm(async () => {
      try {
        await deleteUserCapacity(userId, date);
      } catch (e) {
        showError(e);
        return;
      }

      const changedRows = [...rows];

      const index = changedRows.findIndex((item) => item._id === date._id);
      changedRows.splice(index, 1);

      setRows(changedRows);

      showSuccess('Capacity deleted');
    });
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Button color="primary" style={{ float: 'right' }} onClick={handleAddCapacity}>+ Create Capacity</Button>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>Daily Hours</TableCell>
                  <TableCell>Full Time</TableCell>
                  <TableCell>Trial</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell>
                      {formatDate(row.startAt)}
                      { row.originalStartDate ? (
                        <div>
                          {'Original: '}
                          { formatDate(row.originalStartDate) }
                        </div>
                      ) : '' }
                    </TableCell>
                    <TableCell>{formatDate(row.endAt)}</TableCell>
                    <TableCell>{row.dailyHours}</TableCell>
                    <TableCell>{row.partTime ? 'no' : 'yes'}</TableCell>
                    <TableCell>{row.trial ? 'yes' : 'no'}</TableCell>
                    <TableCell>
                      <Button onClick={() => handleEditCapacity(row)}>Edit</Button>
                      <IconButton className={classes.margin} onClick={() => handleDeleteCapacity(row)}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </GridItem>
      </GridContainer>
      <Dialog
        maxWidth="xs"
        fullWidth
        open={openAddDialog}
        onClose={handleCloseAddDialog}
      >
        <DialogTitle>Add Daily Capacity</DialogTitle>
        <DialogContent className={classes.addDialogRoot}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                required
                fullWidth
                label="Start Date"
                type="date"
                value={activeCapacity.startAt}
                onChange={(e) => handleCapacityChange('startAt', e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                label="End Date"
                type="date"
                value={activeCapacity.endAt}
                onChange={(e) => handleCapacityChange('endAt', e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <TextField
                required
                fullWidth
                label="Hours"
                value={activeCapacity.dailyHours}
                onChange={(e) => handleCapacityChange('dailyHours', e.target.value)}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={5}>
              <FormControlLabel
                className={classes.checkboxMargin}
                control={(
                  <Checkbox
                    checked={activeCapacity.partTime}
                    onChange={(e) => handleCapacityChange('partTime', e.target.checked)}
                    name="partTime"
                    color="primary"
                  />
                )}
                label="Part-time"
              />
            </GridItem>
            { !activeCapacity.partTime ? (
              <GridItem xs={12} sm={12} md={3}>
                <FormControlLabel
                  className={classes.checkboxMargin}
                  control={(
                    <Checkbox
                      checked={activeCapacity.trial}
                      onChange={(e) => handleCapacityChange('trial', e.target.checked)}
                      name="trial"
                      color="primary"
                      disabled={activeCapacity.partTime}
                    />
                  )}
                  label="Trial"
                />
              </GridItem>
            ) : null }
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                fullWidth
                label="Original Start Date"
                type="date"
                value={activeCapacity.originalStartDate}
                onChange={(e) => handleCapacityChange('originalStartDate', e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddDialog}>
            Cancel
          </Button>
          <Button onClick={handleAddDialogSubmit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
