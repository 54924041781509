import React, {
  useState, useEffect, useContext, Fragment,
} from 'react';

import {
  formatClientInterviewType,
  createClientInterviewFeedback,
} from 'services/opportunity';

import { ConsoleContext } from 'services/context';

import { makeStyles } from '@material-ui/core/styles';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import TextField from '@material-ui/core/TextField';
import Button from '../../../../components/CustomButtons/Button';

const useStyles = makeStyles(() => ({
  grid: {
    marginTop: '15px',
  },
}));

export default function CandidateClientInterviewResults({
  opportunity, opportunityCandidate, onFeedbackSave, onLoading,
}) {
  const classes = useStyles();

  const {
    showError, showSuccess, can,
  } = useContext(ConsoleContext);

  const [requiredInterviews, setRequiredInterviews] = useState([]);

  const [activeFeedback, setActiveFeedback] = useState();

  const [canCreateFeedback, setCanCreateFeedback] = useState(false);

  useEffect(() => {
    if (!opportunity.requiredClientInterviews) {
      return;
    }

    const clientInterviewFeedback = opportunityCandidate.clientInterviewFeedback || [];

    const requiredInterviews = opportunity.requiredClientInterviews.map((type) => {
      const feedback = clientInterviewFeedback.find((item) => item.type === type);

      return {
        type,
        feedback: feedback ? feedback.message : null,
      };
    });

    setRequiredInterviews(requiredInterviews);

    (async () => {
      const canEdit = await can('sales:opportunities:edit');

      const canCreateFeedback = canEdit && (opportunityCandidate.status === 'client_interview');

      setCanCreateFeedback(canCreateFeedback);
    })();
  }, [opportunityCandidate]);

  const handleAdd = (type) => {
    setActiveFeedback({
      type,
      message: '',
    });
  };

  const handleCancel = () => {
    setActiveFeedback(null);
  };

  const handleChange = (type, message) => {
    const data = { ...activeFeedback };

    data.message = message;

    setActiveFeedback(data);
  };

  const handleSubmit = async () => {
    const data = { ...activeFeedback };

    onLoading(true);

    try {
      const feedback = await createClientInterviewFeedback(opportunity._id, opportunityCandidate.id, data);

      setActiveFeedback(null);
      onFeedbackSave(feedback);
      showSuccess('Feedback saved');
    } catch (e) {
      showError(e);
    }

    onLoading(false);
  };

  if (!requiredInterviews.length) {
    return (
      <>
        Position has no client interviews required
      </>
    );
  }

  return (
    <>
      { requiredInterviews.map((item, key) => (
        <GridContainer key={key} className={key > 0 ? classes.grid : null}>
          <GridItem xs={12} sm={12} md={12}>
            { key > 0 ? (
              <>
                <hr />
                <br />
              </>
            ) : null }

            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <strong>{ formatClientInterviewType(item.type) }</strong>
              </GridItem>
            </GridContainer>

            <GridContainer className={classes.grid}>
              <GridItem xs={12} sm={12} md={12}>
                {item.feedback ? item.feedback : (
                  <>
                    { canCreateFeedback ? (
                      <>
                        { activeFeedback && (activeFeedback.type === item.type) ? (
                          <>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={12}>
                                <TextField
                                  fullWidth
                                  label="Feedback"
                                  multiline
                                  rows={4}
                                  variant="outlined"
                                  value={activeFeedback.message}
                                  onChange={(e) => handleChange(activeFeedback.type, e.target.value)}
                                />
                              </GridItem>
                            </GridContainer>

                            <GridContainer>
                              <GridItem xs={12} sm={12} md={12}>
                                <Button size="sm" onClick={handleSubmit} color="info">
                                  Save
                                </Button>

                                <Button size="sm" onClick={handleCancel}>
                                  Cancel
                                </Button>
                              </GridItem>
                            </GridContainer>
                          </>
                        ) : (
                          <Button size="sm" onClick={() => handleAdd(item.type)} color="info">
                            Add Feedback
                          </Button>
                        ) }
                      </>
                    ) : 'No feedback' }
                  </>
                )}
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      )) }
    </>
  );
}
