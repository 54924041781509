import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import {
  getUser,
  createUser,
  updateUser,
  updateUserStatus,
} from 'services/user';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInput';
import CardFooter from 'components/Card/CardFooter';

import { ConsoleContext } from 'services/context';
import UserCustomDates from './components/UserCustomDates';
import UserCapacity from './components/UserCapacity';

const useStyles = makeStyles(() => ({
  grid: {
    marginTop: '15px',
  },
  actions: {
    '& button': {
      marginRight: '10px',
    },
  },
}));

export default function UserDetails({ match: { params: { id } } }) {
  const classes = useStyles();

  const {
    showError, showSuccess, showLoader, showConfirm, t,
  } = useContext(ConsoleContext);

  const [user, setUser] = useState();

  const [changedUser, setChangedUser] = useState({});

  const [redirectId, setRedirectId] = useState('');

  useEffect(() => {
    async function fetchData() {
      showLoader(true);

      try {
        let user;

        if (id) {
          user = await getUser(id);
        } else {
          user = {};
        }

        setUser({
          email: '',
          firstName: '',
          lastName: '',
          raUserName: '',
          jiraUserName: '',
          telegram: '',
          ...user,
        });
      } catch (error) {
        showError(error);
      }

      showLoader(false);
    }

    fetchData();
  }, [id]);

  const handleValueChange = (field, value) => {
    const data = { ...changedUser };
    data[field] = value;

    setChangedUser(data);
  };

  const handleTelegramChange = (value) => {
    value = value.replace('@', '');

    handleValueChange('telegram', value);
  };

  const handleChangeStatus = (status) => {
    showConfirm(async () => {
      try {
        const changedUser = await updateUserStatus(user._id, status);

        showSuccess('User status changed');
        setUser(changedUser);
      } catch (e) {
        showError(e);
      }
    });
  };

  const handleSubmit = async () => {
    const data = { ...user, ...changedUser };

    try {
      if (user._id) {
        const newUser = await updateUser(data);

        setUser(newUser);

        showSuccess('User saved');
      } else {
        const newUser = await createUser(data);

        showSuccess('User created');

        setRedirectId(newUser._id);
      }
    } catch (e) {
      showError(e);
    }
  };

  return (
    <div>
      { redirectId ? <Redirect to={`/people/partners/${redirectId}`} /> : ''}

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          { user ? (
            <Card>
              <CardHeader color="success">
                <h4>{user._id ? 'Edit Partner' : 'Create Partner'}</h4>
                {user._id ? `${user.firstName} ${user.lastName}` : ''}
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Email *"
                      id="email-address"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'email',
                        defaultValue: user.email,
                        onChange: (e) => handleValueChange('email', e.target.value),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="First Name *"
                      id="first-name"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        defaultValue: user.firstName,
                        onChange: (e) => handleValueChange('firstName', e.target.value),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Last Name *"
                      id="last-name"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        defaultValue: user.lastName,
                        onChange: (e) => handleValueChange('lastName', e.target.value),
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="RA table user name"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        defaultValue: user.raUserName,
                        onChange: (e) => handleValueChange('raUserName', e.target.value),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="JIRA display name"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        defaultValue: user.jiraUserName,
                        onChange: (e) => handleValueChange('jiraUserName', e.target.value),
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Telegram"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        defaultValue: user.telegram,
                        onChange: (e) => handleTelegramChange(e.target.value),
                      }}
                    />
                  </GridItem>

                </GridContainer>
              </CardBody>

              <CardFooter>
                <GridContainer className={`${classes.grid} ${classes.actions}`}>
                  <GridItem xs={12} sm={12} md={12}>
                    <Button color="primary" onClick={handleSubmit}>{user._id ? 'Update' : 'Create'}</Button>
                    {user.status === 'archived'
                      ? (
                        <Button color="success" onClick={() => handleChangeStatus('active')}>
                          {t('Make Active')}
                        </Button>
                      )
                      : (
                        <Button color="danger" onClick={() => handleChangeStatus('archived')}>
                          {t('Archive')}
                        </Button>
                      )}
                  </GridItem>
                </GridContainer>
              </CardFooter>

            </Card>
          ) : ''}
        </GridItem>
      </GridContainer>
      { id ? (
        <>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="info">
                  <h4>Daily Capacity</h4>
                </CardHeader>
                <CardBody>
                  <UserCapacity userId={id} />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="warning">
                  <h4>Work Calendar</h4>
                </CardHeader>
                <CardBody>
                  <UserCustomDates userId={id} />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </>
      ) : null }
    </div>
  );
}
