import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

import {
  formatDateTime,
} from 'services/date';

import {
  getUsers,
  formatUserNameById,
} from 'services/user';

import {
  addOpportunityCandidate,
  formatRateRange,
  getOpportunities,
  formatCandidateState,
  updateOpportunityPriority,
  getPriorityOptions,
  sortOpportunityCandidates,
  updateOpportunityCandidateMatching,
} from 'services/opportunity';

import {
  getTechStack,
} from 'services/techStack';

import {
  formatCandidateRate,
} from 'services/candidate';

import { ConsoleContext } from 'services/context';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import Button from 'components/CustomButtons/Button';
import PagedTable from 'components/PagedTable/PagedTable';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import CandidateEditDialog from '../Candidate/components/CandidateEditDialog';
import CandidateDialog from './components/CandidateDialog';
import OpportunityFilters from './components/OpportunityFilters';
import CandidateName from '../Candidate/components/CandidateName';

const useStyles = makeStyles(() => ({
  priority: {
    display: 'block',
    marginBottom: '5px',
  },
  candidateArchived: {
    opacity: 0.5,
  },
}));

export default function OpportunityList() {
  const classes = useStyles();

  const {
    showError, showLoader, can, getSessionStorage,
  } = useContext(ConsoleContext);

  const [rows, setRows] = useState([]);

  const [opportunities, setOpportunities] = useState([]);

  const [filteredOpportunities, setFilteredOpportunities] = useState([]);

  const [users, setUsers] = useState([]);

  const [canEdit, setCanEdit] = useState(false);

  const [canEditPriority, setCanEditPriority] = useState(false);

  const [activeOpportunity, setActiveOpportunity] = useState();

  const [activeOpportunityCandidate, setActiveOpportunityCandidate] = useState();

  const [openAddCandidate, setOpenAddCandidate] = useState(false);

  const [openCandidate, setOpenCandidate] = useState(false);

  const [showMoreCandidatesOpportunityId, setShowMoreCandidatesOpportunityId] = useState();

  const [techStack, setTechStack] = useState([]);

  const [filters, setFilters] = useState();

  const [priorityChange, setPriorityChange] = useState(false);

  const [filtersPriorityAndPartner, setFiltersPriorityAndPartner] = useState();

  const storage = getSessionStorage('sales');

  const columns = [
    { id: 'name', label: 'Name' },
    { id: 'rateRange', label: 'Rate range for position' },
    { id: 'createdAt', label: 'Creation Date', width: '100px' },
    { id: 'manager', label: 'Position Closer' },
    { id: 'newCandidates', label: 'New Candidates' },
    { id: 'interviewCandidates', label: 'Internal Interview Arrangement' },
    { id: 'clientInterviewCandidates', label: 'Client Interview Arrangement' },
    { id: 'offerCandidates', label: 'Offer' },
    { id: 'rejectedCandidates', label: 'Rejected/Canceled Candidates' },
  ];

  const findPriority = (priorities) => {
    if (priorities.length) {
      return priorities.find((o) => {
        if (o.userId && filtersPriorityAndPartner.partner && o.type === filtersPriorityAndPartner.priority) {
          return o.userId.toString() === filtersPriorityAndPartner.partner.toString();
        }

        if (!o.userId) return o.type === filtersPriorityAndPartner.priority;
        return null;
      })?.priority || '';
    }
    return '';
  };

  function sortOpportunities(a, b) {
    if (findPriority(a.priorities) > findPriority(b.priorities)) return 1;
    if (findPriority(a.priorities) < findPriority(b.priorities)) return -1;
    return 0;
  }

  useEffect(() => {
    (async () => {
      setCanEdit(await can('sales:opportunities:edit'));
      setCanEditPriority(await can('sales:opportunities:prioritize'));

      try {
        const users = await getUsers();
        const techStack = await getTechStack();

        setUsers(users);
        setTechStack(techStack);
      } catch (e) {
        showError(e);
      }
    })();
  }, []);

  useEffect(() => {
    if (!filters) {
      return;
    }

    (async () => {
      showLoader(true);

      try {
        const opportunities = await getOpportunities(filters);

        setOpportunities(opportunities);
      } catch (e) {
        showError(e);
      }

      setPriorityChange(false);
      showLoader(false);
    })();
  }, [filters, priorityChange]);

  useEffect(() => {
    const filterOpportunities = opportunities.filter((row) => {
      const projectManagerId = row.managerId;
      const projectSearchType = row.searchType;

      if (filters.status === 'active') {
        if (filters.name) {
          const regex = new RegExp(filters.name, 'i');
          if (!regex.test(row.name)) {
            return false;
          }
        }

        switch (filtersPriorityAndPartner.priority) {
          case 'sales': return projectManagerId === filtersPriorityAndPartner.partner;
          case 'recruiter': return projectSearchType === filtersPriorityAndPartner.priority || projectSearchType === 'all';
          case 'domestic_partner': return projectSearchType === 'partner' || projectSearchType === 'all';
          default: break;
        }
      }

      return true;
    });
    setFilteredOpportunities(filterOpportunities);
  }, [filtersPriorityAndPartner, priorityChange]);

  useEffect(() => {
    let opportunitiesCopy = opportunities;

    if (filters && filters.status === 'active') {
      opportunitiesCopy = filteredOpportunities;
      opportunitiesCopy.sort(sortOpportunities);
    } else if (filteredOpportunities.length) {
      opportunitiesCopy = filteredOpportunities;
    }

    populateRows(opportunitiesCopy, users);
  }, [opportunities, filteredOpportunities, users, showMoreCandidatesOpportunityId]);

  const handleAddCandidate = (opportunity) => {
    setActiveOpportunity(opportunity);
    setActiveOpportunityCandidate({
      position: opportunity.position,
    });
    setOpenAddCandidate(true);
  };

  const handleShowMoreCandidates = (opportunity) => {
    setShowMoreCandidatesOpportunityId(opportunity.id);
  };

  const handleShowLessCandidates = () => {
    setShowMoreCandidatesOpportunityId();
  };

  const handleCandidateAdded = async (candidate) => {
    const opportunityId = activeOpportunity._id;

    const opportunityCandidate = await addOpportunityCandidate(opportunityId, candidate._id);

    const changedOpportunities = [...opportunities];

    const opportunity = changedOpportunities.find((opportunity) => opportunity._id === opportunityId);

    if (!opportunity.candidates) {
      opportunity.candidates = [];
    }

    opportunity.candidates.push({
      ...opportunityCandidate,
      candidate,
    });

    setOpportunities(changedOpportunities);
    setOpenAddCandidate(false);
    setActiveOpportunity(null);
  };

  const handleCandidateUpdated = (opportunityCandidate, closeDialog = true) => {
    const changedOpportunities = [...opportunities];

    const opportunity = changedOpportunities.find((opportunity) => opportunity._id === activeOpportunity._id);

    const changedCandidateIndex = opportunity.candidates.findIndex((item) => item.id === opportunityCandidate.id);

    const { candidate } = opportunity.candidates[changedCandidateIndex];

    opportunity.candidates[changedCandidateIndex] = {
      ...opportunityCandidate,
      candidate,
    };

    setActiveOpportunityCandidate(opportunity.candidates[changedCandidateIndex]);
    setOpportunities(changedOpportunities);

    if (closeDialog) {
      setOpenAddCandidate(false);
      setOpenCandidate(false);
      setActiveOpportunity(null);
    }
  };

  const handleCloseAddCandidateDialog = () => {
    setOpenAddCandidate(false);
  };

  const handleCloseCandidateDialog = () => {
    setOpenCandidate(false);
  };

  const handleViewCandidate = (e, opportunity, candidateItem) => {
    e.preventDefault();
    setActiveOpportunity(opportunity);
    setActiveOpportunityCandidate(candidateItem);
    setOpenCandidate(true);
  };

  const handleFiltersChange = (filters) => {
    if ((filters.status || filters.status === '') || (filters.name || filters.name === '')) {
      const { priority, partner } = filters;
      setFilters(filters);
      setFiltersPriorityAndPartner({ priority, partner });
    } else {
      setFiltersPriorityAndPartner(filters);
    }
  };

  const handlePriorityChange = async (priority, opportunity) => {
    showLoader(true);
    const { priority: type, partner: userId } = filtersPriorityAndPartner;
    try {
      await updateOpportunityPriority(opportunity._id, priority, type, userId);

      // reload opportunities
      setFilters({ ...filters });
      setPriorityChange(true);
    } catch (e) {
      showError(e);
    }

    showLoader(false);
  };

  const handleCandidateMatchingToggle = async (opportunity, field) => {
    const candidateMatching = opportunity.candidateMatching || {};

    candidateMatching[field] = !candidateMatching[field];

    showLoader(true);

    try {
      await updateOpportunityCandidateMatching(opportunity._id, candidateMatching);

      // reload opportunities
      setFilters({ ...filters });
    } catch (e) {
      showError(e);
    }

    showLoader(false);
  };

  const populateRows = (opportunities, users) => {
    const rows = opportunities.map((opportunity) => {
      const {
        _id: id,
        status,
        rateRange,
        createdAt,
        managerId,
      } = opportunity;

      return {
        id,
        name: renderName(opportunity, opportunities),
        manager: formatUserNameById(managerId, users),
        status,
        newCandidates: renderCandidates(opportunity, 'added'),
        interviewCandidates: renderCandidates(opportunity, 'interview'),
        clientInterviewCandidates: renderCandidates(opportunity, 'client_interview'),
        offerCandidates: renderCandidates(opportunity, 'offer'),
        rejectedCandidates: renderCandidates(opportunity, 'rejected'),
        rateRange: formatRateRange(rateRange),
        createdAt: formatDateTime(createdAt),
      };
    });

    setRows(rows);
  };

  const renderCandidates = (opportunity, status) => {
    const { candidates = [] } = opportunity;

    const items = sortOpportunityCandidates(candidates.filter((candidate) => {
      if (candidate.status !== status) {
        return false;
      }

      if (status === 'added') {
        if (opportunity.candidateMatching
          && !opportunity.candidateMatching.ignoreExperience
          && candidate.ignoredExperience
        ) {
          return false;
        }

        if (!opportunity.candidateMatching || !opportunity.candidateMatching.archived) {
          return candidate.candidate.status === 'available';
        }
      }

      return true;
    }));

    const showMoreCandidatesButton = (status === 'rejected') && items.length > 3;

    const renderShowButtons = (() => {
      if (status !== 'rejected' || items.length <= 3) {
        return null;
      }

      if (showMoreCandidatesOpportunityId === opportunity.id) {
        return <Button color="info" size="sm" onClick={() => handleShowLessCandidates(opportunity)}>show less</Button>;
      }
      if (showMoreCandidatesButton) {
        return <Button color="info" size="sm" onClick={() => handleShowMoreCandidates(opportunity)}>show more</Button>;
      }

      return null;
    });

    const renderCandidate = ((item, key) => {
      const renderCandidate = () => (
        <div
          key={key}
          style={{ marginBottom: '5px' }}
          className={item.candidate.status === 'archived' ? classes.candidateArchived : null}
        >
          <a href="#" onClick={(e) => handleViewCandidate(e, opportunity, item)}>
            <CandidateName candidate={item.candidate} />
          </a>
          &nbsp;
          (
          {formatCandidateRate(item.candidate, opportunity.rateRange.type)}

          {item.candidate.location ? `, ${item.candidate.location}` : null}

          {item.state ? (
            <span style={{ textTransform: 'lowercase' }}>
              {`, ${formatCandidateState(item.state)}`}
            </span>
          ) : null}
          )
        </div>
      );

      if (status !== 'rejected'
          || !showMoreCandidatesButton
          || (showMoreCandidatesButton && opportunity.id === showMoreCandidatesOpportunityId)
          || (showMoreCandidatesButton && key <= 2 && opportunity.id !== showMoreCandidatesOpportunityId)
      ) {
        return (
          renderCandidate()
        );
      }

      return null;
    });

    return (
      <>
        { items.map((item, key) => (
          renderCandidate(item, key)
        )) }

        { status === 'added' ? (
          <Button color="info" size="sm" onClick={() => handleAddCandidate(opportunity)}>+ Add</Button>
        ) : items.length ? null : '-' }

        {renderShowButtons()}
      </>
    );
  };

  const renderName = (opportunity, opportunities) => {
    const {
      id,
      clientName,
      name,
      priorities,
      status,
    } = opportunity;

    const { priority: type, partner: userId } = filtersPriorityAndPartner;

    const { name: filterByName } = filters;

    let priority = '';

    if (priorities.length) {
      priority = findPriority(priorities);
    }

    const priorityReplace = priority.toString().replace(1000, '-');

    const priorityOptions = getPriorityOptions(opportunities, opportunity, type, userId);

    return (
      <>
        <div className={classes.priority}>
          {status === 'active'
            ? (canEditPriority && !filterByName)
              ? (
                <Select
                  value={priority}
                  onChange={(e) => handlePriorityChange(e.target.value, opportunity)}
                >
                  {priorityOptions.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              )
              : <span>{priorityReplace}</span>
            : ''}

        </div>

        <Link to={`/sales/opportunities/${id}`} title={clientName || '-'}>{name}</Link>

        <div style={{ marginTop: '10px' }}>
          <strong>Candidates:</strong>
          <br />

          <Chip
            label="archived"
            color={opportunity.candidateMatching && opportunity.candidateMatching.archived ? 'primary' : 'default'}
            onClick={() => handleCandidateMatchingToggle(opportunity, 'archived')}
          />

          <Chip
            style={{ marginTop: '5px' }}
            label="any rate & exp"
            color={opportunity.candidateMatching && opportunity.candidateMatching.ignoreExperience ? 'primary' : 'default'}
            onClick={() => handleCandidateMatchingToggle(opportunity, 'ignoreExperience')}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={8}>
                  <OpportunityFilters
                    storage={storage}
                    opportunities={opportunities}
                    onChange={handleFiltersChange}
                  />
                </GridItem>

                { canEdit ? (
                  <GridItem xs={12} sm={12} md={4}>
                    <Link to="/sales/opportunities/create">
                      <Button color="info" style={{ float: 'right' }}>+ Create Position</Button>
                    </Link>
                  </GridItem>
                ) : null }
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <PagedTable
                    containerProps={{ style: { maxHeight: '600px' } }}
                    tableProps={{ stickyHeader: true, style: { tableLayout: 'fixed' } }}
                    columns={columns}
                    rows={rows}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      { activeOpportunity ? (
        <>
          <CandidateEditDialog
            candidate={activeOpportunityCandidate}
            open={openAddCandidate}
            techStack={techStack}
            onSave={handleCandidateAdded}
            onClose={() => handleCloseAddCandidateDialog()}
          />

          <CandidateDialog
            open={openCandidate}
            opportunity={activeOpportunity}
            opportunityCandidate={activeOpportunityCandidate}
            techStack={techStack}
            users={users}
            onSave={handleCandidateUpdated}
            onClose={handleCloseCandidateDialog}
          />
        </>
      ) : null }
    </>
  );
}
