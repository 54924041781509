import {
  CUSTOM_DATES_URL,
} from '../utils/apiRouting';

import {
  get, post, httpDelete, formatQuery,
} from '../utils/http';

export async function getCustomDates(query = {}) {
  return get(CUSTOM_DATES_URL + formatQuery(query));
}

export async function createCustomDate(date) {
  return post(CUSTOM_DATES_URL, date);
}

export async function deleteCustomDate(date) {
  return httpDelete(`${CUSTOM_DATES_URL}/${date._id}`);
}
