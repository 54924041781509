import React, {
  useState, useEffect, Fragment,
} from 'react';

import {
  formatProjectAge,
  formatProjectEndIn,
  formatProjectCommunicationType,
  formatProjectDevelopmentMethodology,
  formatProjectDevelopmentStage,
} from 'services/opportunity';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';

const useStyles = makeStyles(() => ({
  grid: {
    marginTop: '15px',
  },
  whiteSpace: {
    whiteSpace: 'pre-wrap',
  },
}));

export default function OpportunityProject({ project }) {
  const classes = useStyles();

  const [fields, setFields] = useState();

  const renderDescription = (description) => <span className={classes.whiteSpace}>{ description }</span>;

  const columns = [
    { id: 'name', name: 'Name' },
    { id: 'description', name: 'Description' },
    { id: 'ageYears', name: 'How old' },
    { id: 'contractYears', name: 'Estimated Project End In' },
    { id: 'developmentStage', name: 'Development Methodology' },
    { id: 'teamDescription', name: 'Team Description' },
    { id: 'clientTeamDescription', name: 'Client Team Description' },
    { id: 'timeTracker', name: 'Time Tracker' },
    { id: 'communicationType', name: 'Communication Type' },
  ];

  useEffect(() => {
    if (project) {
      const {
        name,
        description,
        ageYears,
        contractYears,
        developmentStage,
        developmentMethodology,
        teamDescription,
        clientTeamDescription,
        timeTracker,
        communicationType,
      } = project;

      const fields = {
        name,
        description: description ? renderDescription(description) : null,
        ageYears: ageYears ? formatProjectAge(ageYears) : null,
        contractYears: contractYears ? formatProjectEndIn(contractYears) : null,
        developmentStage: developmentStage ? formatProjectDevelopmentStage(developmentStage) : null,
        developmentMethodology: developmentMethodology ? formatProjectDevelopmentMethodology(developmentMethodology) : null,
        teamDescription,
        clientTeamDescription,
        timeTracker: timeTracker ? 'Yes' : (timeTracker === false ? 'No' : null),
        communicationType: communicationType ? formatProjectCommunicationType(communicationType) : null,
      };

      const completedField = columns.find((item) => Boolean(fields[item.id]));

      setFields(completedField ? fields : null);
    }
  }, [project]);

  if (!fields) {
    return 'No info';
  }

  return (
    <>
      { columns.map((column) => (
        <Fragment key={column.id}>
          { fields[column.id] || (fields[column.id] === false) ? (
            <GridContainer key={column.id} className={classes.grid}>
              <GridItem xs={12} sm={12} md={12}>
                { column.name ? (
                  <strong>
                    {column.name}
                    :
                    {' '}
                  </strong>
                ) : null }
                { fields[column.id]}
              </GridItem>
            </GridContainer>
          ) : null }
        </Fragment>
      )) }
    </>
  );
}
