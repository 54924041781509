import React, { useState, useEffect, useContext } from 'react';

import {
  createProjectLog,
  updateProjectLog,
  formatLogSource,
} from 'services/project';

import {
  formatHours,
  formatInputDate,
  parseInputDate,
} from 'services/date';

import { ConsoleContext } from 'services/context';

// core components
import { makeStyles } from '@material-ui/core/styles';
import Button from 'components/CustomButtons/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from 'components/CustomDialog/CustomDialog';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(() => ({
  addDateComment: {
    marginTop: '15px',
  },
  checkboxMargin: {
    paddingTop: '10px',
  },
  grid: {
    marginTop: '10px',
  },
}));

export default function ProjectLogEditDialog({
  open, log, project, formatMemberName, onClose, onSave,
}) {
  const classes = useStyles();

  const { showError, showSuccess } = useContext(ConsoleContext);

  const [openDialog, setOpenDialog] = useState(false);

  const [changedLog, setChangedLog] = useState({
    hours: '',
    comment: '',
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  useEffect(() => {
    setChangedLog({
      comment: '',
      ...log,
      workedAt: formatInputDate(log.workedAt),
      hours: log.timeSpentSeconds ? formatHours(log.timeSpentSeconds) : '',
    });
  }, [log]);

  const handleCloseDialog = () => {
    onClose();
  };

  const handleChange = (field, value) => {
    const data = { ...changedLog };
    data[field] = value;

    setChangedLog(data);
  };

  const handleSubmit = async () => {
    const data = {
      ...changedLog,
      projectId: log.projectId,
      workedAt: parseInputDate(changedLog.workedAt),
    };

    if (!data.hours) {
      return;
    }

    data.timeSpentSeconds = data.hours ? Number(data.hours) * 3600 : null;

    delete data.hours;

    let updatedLog;

    setLoading(true);

    try {
      if (data._id) {
        updatedLog = await updateProjectLog(log.projectId, data);

        showSuccess('Log updated');
      } else {
        updatedLog = await createProjectLog(log.projectId, data);

        showSuccess('Log created');
      }

      onSave(updatedLog);
    } catch (e) {
      showError(e);
    }

    setLoading(false);
  };

  return (
    <>
      <Dialog
        loading={loading}
        maxWidth="xs"
        fullWidth
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>
          {formatMemberName({ userId: log.userId })}
          {' '}
          (
          { formatLogSource(log) }
          {' '}
          log)
        </DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                required
                fullWidth
                label="Date"
                type="date"
                value={changedLog.workedAt}
                onChange={(e) => handleChange('workedAt', e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
            {project.billable && (
              <GridItem xs={12} sm={12} md={6}>
                <FormControlLabel
                  className={classes.checkboxMargin}
                  control={(
                    <Checkbox
                      checked={changedLog.billable}
                      onChange={(e) => handleChange('billable', e.target.checked)}
                      name="billable"
                      color="primary"
                    />
                  )}
                  label="Billable By Client"
                />
              </GridItem>
            )}
          </GridContainer>
          <GridContainer className={classes.grid}>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                disabled={!!changedLog.jiraLogId}
                required
                fullWidth
                label="Hours"
                value={changedLog.hours}
                onChange={(e) => handleChange('hours', e.target.value)}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                disabled={!!changedLog.jiraLogId}
                fullWidth
                className={classes.addDateComment}
                id="outlined-multiline-static"
                label="Comment"
                multiline
                rows={4}
                variant="outlined"
                value={changedLog.comment}
                onChange={(e) => handleChange('comment', e.target.value)}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
