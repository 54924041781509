import React, { useState, useEffect, useContext } from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import {
  syncLogs,
  getLastSyncJob,
} from 'services/jira';

import {
  formatDateTime,
} from 'services/date';

import { can } from 'services/permission';

// core components
import Button from 'components/CustomButtons/Button.js';
import Alert from '@material-ui/lab/Alert';

import { ConsoleContext } from 'services/context';

const useStyles = makeStyles((theme) => ({
  infoText: {
    marginRight: '10px',
    display: 'inline-block',
  },
}));

export default function LogsSync({ year, month, onSync }) {
  const classes = useStyles();

  const {
    showError, showSuccess, showConfirm, showLoader,
  } = useContext(ConsoleContext);

  const [canSyncLogs, setCanSyncLogs] = useState(false);

  const [lastSyncJob, setLastSyncJob] = useState();

  useEffect(() => {
    (async () => {
      setCanSyncLogs(await can('production:report:syncLogs'));
    })();
  }, []);

  useEffect(() => {
    if (!year) {
      return;
    }

    (async () => {
      try {
        const lastSyncJob = await getLastSyncJob(year, month);

        setLastSyncJob(lastSyncJob);
      } catch (e) {
        showError(e);
      }
    })();
  }, [year, month]);

  const handleSyncUp = async () => {
    showLoader(true);

    try {
      const job = await syncLogs(year, month);

      setLastSyncJob(job);

      if (onSync) {
        onSync(job.lastFinishedAt);
      }

      showSuccess('Sync Finished');
    } catch (e) {
      showError(e);
    }

    showLoader(false);
  };

  const renderLastRun = (lastSyncJob) => {
    if (!lastSyncJob) {
      return 'No info';
    }

    return formatDateTime(lastSyncJob.lastFinishedAt);
  };

  return (
    <>
      <Alert icon={false} severity="info" className={classes.infoText}>
        Last Sync:
        {' '}
        { renderLastRun(lastSyncJob) }
      </Alert>
      { canSyncLogs ? (
        <Button color="primary" size="sm" onClick={handleSyncUp} className={classes.syncUpButton}>Sync Up</Button>
      ) : null }
    </>
  );
}
