import React, { useState, useEffect, useContext } from 'react';
import clone from 'just-clone';

import { ConsoleContext } from 'services/context';

import {
  createOpportunity,
  getSearchTypeOptions,
  getOpportunityManagerOptions,
  getPositionOptions,
  getEnglishLevelOptions,
  getInterviewOptions,
  getExperienceYearsOptions,
  updateOpportunity,
  getProjectAgeOptions,
  getProjectDevelopmentStageOptions,
  getProjectDevelopmentMethodologyOptions,
  getProjectCommunicationTypeOptions,
  getDeadlineOptions,
  getCandidateLocationOptions,
  getClientInterviewOptions,
  getProjectEndInOptions,
} from 'services/opportunity';

import {
  parseInputNumber,
} from 'services/finance';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button';

import CustomSelect from 'components/CustomSelect/CustomSelect';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { findTemplateByPosition, getTemplates } from 'services/opportunityTemplate';
import TechStackSelect from '../../components/TechStackSelect';
import TemplateDialog from './TemplateDialog';

const useStyles = makeStyles(() => ({
  actions: {
    marginTop: '15px',
    textAlign: 'center',
  },
  grid: {
    marginTop: '15px',
  },
  button: {
    float: 'right',
    marginTop: '-15px',
  },
}));

export default function OpportunityEdit({
  opportunity,
  onSave,
  onCancel,
  techStack,
}) {
  const classes = useStyles();

  const {
    showLoader, showError, showSuccess, t, sessionUser, can,
  } = useContext(ConsoleContext);

  const [changedOpportunity, setChangedOpportunity] = useState();
  const [positionManagerOptions, setPositionManagerOptions] = useState([]);

  const [openTemplateDialog, setOpenTemplateDialog] = useState(false);
  const [createTemplateForField, setCreateTemplateForField] = useState('');
  const [canEditTemplates, setCanEditTemplates] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [changedFields, setChangedFields] = useState();

  let positionManagerDisabled = false;
  if (opportunity.status === 'archived' || opportunity.status === 'completed') {
    positionManagerDisabled = true;
  }

  const rateTypeOptions = [
    { id: 'hourly', name: 'Hourly' },
    { id: 'monthly', name: 'Monthly' },
  ];

  useEffect(() => {
    const changedOpportunity = {
      name: '',
      managerId: sessionUser.id,
      clientName: '',
      searchType: '',
      position: '',
      englishLevel: '',
      rateRange: {},
      techStack: [],
      requiredInterviews: [],
      requiredInterviewsOptions: {
        englishSkipAdvanced: null,
      },
      requiredClientInterviews: [],
      project: {},
      deadline: '',
      candidateLocation: '',
      peopleRequired: 1,
      candidateMatching: {},
      ...opportunity,
      requirements: formatRequirements(opportunity.requirements),
      responsibilities: formatRequirements(opportunity.responsibilities),
    };

    changedOpportunity.rateRange = {
      type: '',
      from: '',
      to: '',
      ...opportunity.rateRange,
    };

    changedOpportunity.project = {
      name: '',
      description: '',
      ageYears: '',
      contractYears: '',
      developmentStage: '',
      developmentMethodology: '',
      teamDescription: '',
      clientTeamDescription: '',
      timeTracker: false,
      communicationType: '',
      ...opportunity.project,
    };

    changedOpportunity.candidateMatching = {
      archived: false,
      ignoreExperience: false,
      ...opportunity.candidateMatching,
    };

    setChangedFields(
      {
        requirements: !!changedOpportunity.requirements,
        responsibilities: !!changedOpportunity.responsibilities,
      },
    );
    setChangedOpportunity(changedOpportunity);
  }, [opportunity]);

  useEffect(() => {
    (async () => {
      const options = await getOpportunityManagerOptions();
      setCanEditTemplates(await can('sales:opportunityTemplates:edit'));
      setTemplates(await getTemplates());
      setPositionManagerOptions(options);
    })();
  }, []);

  useEffect(() => {
    if (changedOpportunity && changedOpportunity.position) {
      changeRequirementsAndResponsibilities(
        templates, changedOpportunity.position, techStack, changedOpportunity.techStack,
      );
    }
  }, [templates.length]);

  const formatRequirements = (requirements) => (requirements ? requirements.join('\n') : '');

  const parseRequirements = (requirements) => requirements.trim().split('\n');

  const changeRequirementsAndResponsibilities = (
    templates, position, techStack, selectedTechnologies, data = changedOpportunity,
  ) => {
    const templateByPosition = findTemplateByPosition(templates, position, techStack, selectedTechnologies, data.englishLevel);

    setChangedOpportunity({
      ...data,
      requirements: changedFields.requirements ? changedOpportunity.requirements : templateByPosition.requirements,
      responsibilities: changedFields.responsibilities ? changedOpportunity.responsibilities : templateByPosition.responsibilities,
    });
  };

  const handleChange = (field, value) => {
    const data = clone(changedOpportunity);
    data[field] = value;

    setChangedOpportunity(data);

    setChangedFields({ ...changedFields, [field]: true });

    switch (field) {
      case 'position':
        changeRequirementsAndResponsibilities(templates, value, techStack, changedOpportunity.techStack, data);
        break;
      case 'techStack':
        changeRequirementsAndResponsibilities(templates, changedOpportunity.position, techStack, value, data, changedOpportunity.englishLevel);
        break;
      case 'englishLevel':
        changeRequirementsAndResponsibilities(
          templates, changedOpportunity.position, techStack, changedOpportunity.techStack, data, changedOpportunity.englishLevel,
        );
        break;
      default:
        break;
    }
  };

  const handleManageTemplates = (field) => {
    setCreateTemplateForField(field);
    setOpenTemplateDialog(true);
  };

  const handleCloseTemplateDialog = async () => {
    setOpenTemplateDialog(false);
    setTemplates(await getTemplates());
  };

  const handlePeopleRequiredChange = (value) => {
    if (value !== '') {
      value = Number(value);

      if (value < 1) {
        value = 0.5;
      } else {
        value = Math.floor(value);
      }
    }

    handleChange('peopleRequired', value);
  };

  const handleRateRangeChange = (field, value) => {
    const data = clone(changedOpportunity);
    data.rateRange[field] = value;

    setChangedOpportunity(data);
  };

  const handleRequiredInterviewsChange = (id, checked) => {
    const data = clone(changedOpportunity);

    const index = data.requiredInterviews.indexOf(id);

    if (checked && (index === -1)) {
      data.requiredInterviews.push(id);
    } else if (!checked && (index > -1)) {
      data.requiredInterviews.splice(index, 1);
    }

    setChangedOpportunity(data);
  };

  const handleRequiredInterviewsOptionsChange = (field, value) => {
    const data = clone(changedOpportunity);

    data.requiredInterviewsOptions[field] = value;

    setChangedOpportunity(data);
  };

  const handleRequiredClientInterviewsChange = (id, checked) => {
    const data = clone(changedOpportunity);

    const index = data.requiredClientInterviews.indexOf(id);

    if (checked && (index === -1)) {
      data.requiredClientInterviews.push(id);
    } else if (!checked && (index > -1)) {
      data.requiredClientInterviews.splice(index, 1);
    }

    setChangedOpportunity(data);
  };

  const handleProjectChange = (field, value) => {
    const data = clone(changedOpportunity);
    data.project[field] = value;

    setChangedOpportunity(data);
  };

  const handleCandidateMatchingChange = (field, value) => {
    const data = clone(changedOpportunity);

    data.candidateMatching[field] = value;

    setChangedOpportunity(data);
  };

  const handleSubmit = async () => {
    const data = clone(changedOpportunity);

    // prepare data
    if (data.rateRange) {
      if (!data.rateRange.type) {
        delete data.rateRange.type;
      }

      data.rateRange.from = parseInputNumber(data.rateRange.from) || 0;
      data.rateRange.to = parseInputNumber(data.rateRange.to);
    }

    if (data.requirements) {
      data.requirements = parseRequirements(data.requirements);
    } else {
      delete data.requirements;
    }

    if (data.responsibilities) {
      data.responsibilities = parseRequirements(data.responsibilities);
    } else {
      delete data.responsibilities;
    }

    if (!data.deadline) {
      delete data.deadline;
    }

    if (!data.candidateLocation) {
      delete data.candidateLocation;
    }

    const projectFields = ['ageYears', 'contractYears', 'developmentStage', 'developmentMethodology',
      'teamDescription', 'clientTeamDescription', 'communicationType'];

    projectFields.forEach((field) => {
      if (data.project[field] === '') {
        delete data.project[field];
      }
    });

    showLoader(true);

    try {
      let newOpportunity;

      if (data._id) {
        newOpportunity = await updateOpportunity(data);
        showSuccess('Position updated');
      } else {
        newOpportunity = await createOpportunity(data);
        showSuccess('Position created');
      }

      onSave(newOpportunity);
    } catch (e) {
      showError(e);
    }

    showLoader(false);
  };

  const handleCancel = () => {
    onCancel();
  };

  const checkTechStackRequired = (item) => (item.position === 'developer') || (item.position === 'tech_lead');

  if (!changedOpportunity) {
    return null;
  }

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={8}>
              <TextField
                fullWidth
                label="Name"
                required
                value={changedOpportunity.name}
                onChange={(e) => handleChange('name', e.target.value)}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={4}>
              <CustomSelect
                disabled={!!positionManagerDisabled}
                required
                label="Position Closer"
                value={changedOpportunity.managerId}
                options={positionManagerOptions}
                onChange={(value) => handleChange('managerId', value)}
              />
            </GridItem>
          </GridContainer>

          <GridContainer className={classes.grid}>
            <GridItem xs={12} sm={12} md={4}>
              <CustomSelect
                required
                label="Position"
                value={changedOpportunity.position}
                options={getPositionOptions()}
                onChange={(value) => handleChange('position', value)}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={4}>
              <CustomSelect
                required
                label="English Level"
                value={changedOpportunity.englishLevel}
                options={getEnglishLevelOptions()}
                onChange={(value) => handleChange('englishLevel', value)}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={4}>
              <CustomSelect
                required
                label="Search Type"
                value={changedOpportunity.searchType}
                options={getSearchTypeOptions()}
                onChange={(value) => handleChange('searchType', value)}
              />
            </GridItem>
          </GridContainer>

          <GridContainer className={classes.grid}>
            <GridItem xs={12} sm={12} md={5}>
              <TextField
                fullWidth
                label="Number of people required"
                required
                type="number"
                helperText="From 0,5 to 500"
                value={changedOpportunity.peopleRequired}
                onChange={(e) => handlePeopleRequiredChange(e.target.value)}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={7}>
              <TextField
                fullWidth
                label="Lead/Client Name"
                value={changedOpportunity.clientName}
                onChange={(e) => handleChange('clientName', e.target.value)}
              />
            </GridItem>
          </GridContainer>

          <GridContainer className={classes.grid}>
            <GridItem xs={12} sm={12} md={12}>
              { checkTechStackRequired(changedOpportunity) ? (
                <>
                  <h4>Tech Stack *</h4>
                  <span>At least one technology is required</span>
                </>
              ) : (
                <>
                  <h4>Tech Stack</h4>
                  <span>Technology is optional</span>
                </>
              ) }
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TechStackSelect
                techStack={techStack}
                values={changedOpportunity.techStack}
                experienceOptions={getExperienceYearsOptions()}
                onChange={(values) => handleChange('techStack', values)}
              />
            </GridItem>
          </GridContainer>

          <GridContainer className={classes.grid}>
            <GridItem xs={12} sm={12} md={12}>
              <h4>Required Internal Interviews</h4>
            </GridItem>
          </GridContainer>

          <GridContainer>
            { getInterviewOptions().map((option) => (
              <GridItem key={option.id} xs={12} sm={12} md={4}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={changedOpportunity.requiredInterviews.indexOf(option.id) > -1}
                      onChange={(e) => handleRequiredInterviewsChange(option.id, e.target.checked)}
                      color="primary"
                    />
                  )}
                  label={option.name}
                />

                { (option.id === 'english') && (changedOpportunity.requiredInterviews.indexOf(option.id) > -1) ? (
                  <div>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={changedOpportunity.requiredInterviewsOptions.englishSkipAdvanced}
                          onChange={(e) => handleRequiredInterviewsOptionsChange('englishSkipAdvanced', e.target.checked)}
                          color="primary"
                        />
                      )}
                      label="Skip for Advanced"
                    />
                  </div>
                ) : null }
              </GridItem>
            )) }
          </GridContainer>

          <GridContainer className={classes.grid}>
            <GridItem xs={12} sm={12} md={12}>
              <h4>Required Client Interviews</h4>
            </GridItem>
          </GridContainer>

          <GridContainer>
            { getClientInterviewOptions().map((option) => (
              <GridItem key={option.id} xs={12} sm={12} md={3}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={changedOpportunity.requiredClientInterviews.indexOf(option.id) > -1}
                      onChange={(e) => handleRequiredClientInterviewsChange(option.id, e.target.checked)}
                      color="primary"
                    />
                  )}
                  label={option.name}
                />
              </GridItem>
            )) }
          </GridContainer>

          <GridContainer className={classes.grid}>
            <GridItem xs={12} sm={12} md={4}>
              <CustomSelect
                required
                label="Rate Type"
                value={changedOpportunity.rateRange.type}
                options={rateTypeOptions}
                onChange={(value) => handleRateRangeChange('type', value)}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={4}>
              <TextField
                fullWidth
                label="Rate From"
                value={changedOpportunity.rateRange.from}
                onChange={(e) => handleRateRangeChange('from', e.target.value)}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={4}>
              <TextField
                required
                fullWidth
                label="Rate To"
                value={changedOpportunity.rateRange.to}
                onChange={(e) => handleRateRangeChange('to', e.target.value)}
              />
            </GridItem>
          </GridContainer>

          <GridContainer className={classes.grid}>
            <GridItem xs={12} sm={12} md={4}>
              <CustomSelect
                required
                label="Hiring Deadline"
                value={changedOpportunity.deadline}
                options={getDeadlineOptions()}
                onChange={(value) => handleChange('deadline', value)}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={4}>
              <CustomSelect
                label="Candidate Location"
                value={changedOpportunity.candidateLocation}
                options={getCandidateLocationOptions()}
                onChange={(value) => handleChange('candidateLocation', value)}
              />
            </GridItem>
          </GridContainer>

          <GridContainer className={classes.grid}>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                fullWidth
                label="Requirements"
                multiline
                rows={8}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                value={changedOpportunity.requirements}
                helperText="One requirement per line"
                onChange={(e) => handleChange('requirements', e.target.value)}
              />
            </GridItem>

            { canEditTemplates ? (
              <GridItem xs={12} sm={12} md={12}>
                <Button
                  className={classes.button}
                  color="primary"
                  size="sm"
                  onClick={() => handleManageTemplates('requirements')}
                >
                  {t('Manage Templates')}
                </Button>
              </GridItem>
            ) : null }

          </GridContainer>

          <GridContainer className={classes.grid}>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                fullWidth
                label="Responsibilities"
                multiline
                rows={8}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                value={changedOpportunity.responsibilities}
                helperText="One responsibility per line"
                onChange={(e) => handleChange('responsibilities', e.target.value)}
              />
            </GridItem>

            { canEditTemplates ? (
              <GridItem xs={12} sm={12} md={12}>
                <Button
                  className={classes.button}
                  color="primary"
                  size="sm"
                  onClick={() => handleManageTemplates('responsibilities')}
                >
                  {t('Manage Templates')}
                </Button>
              </GridItem>
            ) : null }

          </GridContainer>

        </GridItem>

        <GridItem xs={12} sm={12} md={6}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h4 style={{ margin: 0 }}>Project</h4>
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                fullWidth
                label="Name"
                value={changedOpportunity.project.name}
                onChange={(e) => handleProjectChange('name', e.target.value)}
              />
            </GridItem>
          </GridContainer>

          <GridContainer className={classes.grid}>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                fullWidth
                label="Description"
                multiline
                rows={8}
                variant="outlined"
                value={changedOpportunity.project.description}
                onChange={(e) => handleProjectChange('description', e.target.value)}
              />
            </GridItem>
          </GridContainer>

          <GridContainer className={classes.grid}>
            <GridItem xs={12} sm={12} md={6}>
              <CustomSelect
                label="How Old"
                value={changedOpportunity.project.ageYears}
                options={getProjectAgeOptions()}
                onChange={(value) => handleProjectChange('ageYears', value)}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={6}>
              <CustomSelect
                label="Estimated Project End In"
                value={changedOpportunity.project.contractYears}
                options={getProjectEndInOptions()}
                onChange={(value) => handleProjectChange('contractYears', value)}
              />
            </GridItem>
          </GridContainer>

          <GridContainer className={classes.grid}>
            <GridItem xs={12} sm={12} md={6}>
              <CustomSelect
                label="Development Stage"
                value={changedOpportunity.project.developmentStage}
                options={getProjectDevelopmentStageOptions()}
                onChange={(value) => handleProjectChange('developmentStage', value)}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={6}>
              <CustomSelect
                label="Development Methodology"
                value={changedOpportunity.project.developmentMethodology}
                options={getProjectDevelopmentMethodologyOptions()}
                onChange={(value) => handleProjectChange('developmentMethodology', value)}
              />
            </GridItem>
          </GridContainer>

          <GridContainer className={classes.grid}>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                fullWidth
                label="Team Description"
                multiline
                rows={4}
                variant="outlined"
                value={changedOpportunity.project.teamDescription}
                onChange={(e) => handleProjectChange('teamDescription', e.target.value)}
              />
            </GridItem>
          </GridContainer>

          <GridContainer className={classes.grid}>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                fullWidth
                label="Client Team Description"
                multiline
                rows={4}
                variant="outlined"
                value={changedOpportunity.project.clientTeamDescription}
                onChange={(e) => handleProjectChange('clientTeamDescription', e.target.value)}
              />
            </GridItem>
          </GridContainer>

          <GridContainer className={classes.grid}>
            <GridItem xs={12} sm={12} md={6}>
              <CustomSelect
                label="Communication Type"
                value={changedOpportunity.project.communicationType}
                options={getProjectCommunicationTypeOptions()}
                onChange={(value) => handleProjectChange('communicationType', value)}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={6}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={changedOpportunity.project.timeTracker}
                    onChange={(e) => handleProjectChange('timeTracker', e.target.checked)}
                    color="primary"
                  />
                )}
                label="Time Tracker"
              />
            </GridItem>
          </GridContainer>

          <GridContainer className={classes.grid}>
            <GridItem xs={12} sm={12} md={12}>
              <h4>Candidate Matching</h4>
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem xs={12} sm={12} md={5}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={changedOpportunity.candidateMatching.archived}
                    onChange={(e) => handleCandidateMatchingChange('archived', e.target.checked)}
                    color="primary"
                  />
                )}
                label="Include Archived"
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={7}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={changedOpportunity.candidateMatching.ignoreExperience}
                    onChange={(e) => handleCandidateMatchingChange('ignoreExperience', e.target.checked)}
                    color="primary"
                  />
                )}
                label="Include any Rate & Experience"
              />
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>

      <GridContainer className={classes.actions}>
        <GridItem xs={12} sm={12} md={12}>
          { opportunity._id ? (
            <>
              <Button color="info" onClick={handleSubmit} style={{ marginRight: '10px' }}>
                {t('Update')}
              </Button>
              <Button onClick={handleCancel}>{t('Cancel')}</Button>
            </>
          ) : (
            <Button color="info" onClick={handleSubmit}>
              {t('Create')}
            </Button>
          ) }
        </GridItem>
      </GridContainer>

      <TemplateDialog
        open={openTemplateDialog}
        position={changedOpportunity.position}
        field={createTemplateForField}
        onClose={handleCloseTemplateDialog}
        templates={templates}
      />
    </>
  );
}
