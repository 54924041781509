import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import {
  projectStatuses,
  getProjects,
} from 'services/project';

import {
  getUsers,
} from 'services/user';

import { can } from 'services/permission';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Button from 'components/CustomButtons/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import { ConsoleContext } from 'services/context';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: '100%',
  },
  checkbox: {
    marginTop: '24px',
  },
  padding: {
    padding: '0px 9px 0 9px',
  },
}));

export default function Projects() {
  useEffect(() => {
    async function fetchData() {
      showLoader(true);

      setCanEdit(await can('production:projects:edit'));

      try {
        const projects = await getProjects();
        const users = await getUsers();

        const projectManagers = {};
        const accountManagers = {};

        const rows = sortProjects(projects).map((item) => {
          const projectManager = item.managerId ? item.managerId : null;
          let accountManager = null;

          if (projectManager) {
            projectManagers[projectManager._id] = projectManager;
          }

          if (item.clientId && item.clientId.managerId) {
            accountManager = users.find((user) => user._id === item.clientId.managerId);
            accountManagers[accountManager._id] = accountManager;
          }

          return {
            id: item._id,
            name: item.name,
            status: item.status,
            statusName: projectStatuses.find((status) => status.id === item.status).name,
            clientName: item.clientId ? item.clientId.companyName : '',
            client: item.clientId,
            accountManagerId: accountManager ? accountManager._id : null,
            accountManagerName: formatUserName(accountManager),
            managerId: projectManager ? projectManager._id : null,
            managerName: formatUserName(projectManager),
            members: item.members
              .filter((member) => !member.disabled)
              .map((member) => {
                const userName = formatUserName(member.userId);
                const roles = member.roleIds.map((role) => role.name).join(', ');

                return `${userName} (${roles})`;
              })
              .join(', '),
          };
        });

        setInitRows(rows);

        const managers = Object.values({ ...projectManagers, ...accountManagers })
          .sort((m1, m2) => (m1.firstName > m2.firstName ? 1 : -1));

        managers.forEach((manager) => {
          manager.titles = [];

          if (projectManagers[manager._id]) {
            manager.titles.push('PM');
          }
          if (accountManagers[manager._id]) {
            manager.titles.push('AM');
          }
        });

        setManagers(managers);

        if (projectManagers[sessionUser.id] || accountManagers[sessionUser.id]) {
          filterRows(rows, {
            ...filters,
            managerId: sessionUser.id,
          });
        } else {
          filterRows(rows, {
            ...filters,
            managerId: '_all',
          });
        }
      } catch (error) {
        showError(error);
      }

      showLoader(false);
    }
    fetchData();
  }, []);

  const classes = useStyles();

  const {
    showError, showLoader, sessionUser,
  } = useContext(ConsoleContext);

  const [canEdit, setCanEdit] = useState(false);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [initRows, setInitRows] = useState([]);

  const [rows, setRows] = useState([]);

  const [managers, setManagers] = useState([]);

  const [filters, setFilters] = useState({
    managerId: '_all',
    showArchived: false,
  });

  const columns = [
    { id: 'name', label: 'Title' },
    { id: 'managerName', label: 'Manager' },
    { id: 'clientName', label: 'Client' },
    { id: 'accountManagerName', label: 'Account Manager' },
    { id: 'statusName', label: 'Status' },
    { id: 'members', label: 'Members' },
  ];

  const handleChangePage = (event, newPage) => (setPage(newPage));

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const formatUserName = (user) => {
    if (!user) {
      return '-';
    }

    return `${user.firstName} ${user.lastName}`;
  };

  const handleFilterChange = (name, value) => {
    const data = { ...filters };
    data[name] = value;

    filterRows(initRows, data);
  };

  const filterRows = (initRows, filters) => {
    const rows = initRows.filter((row) => {
      const projectManagerId = row.managerId;
      const accountManagerId = row.client ? row.client.managerId : null;

      if (!filters.showArchived && row.status === 'archived') {
        return false;
      }

      if (filters.managerId !== '_all') {
        return projectManagerId === filters.managerId
          || accountManagerId === filters.managerId;
      }

      return true;
    });

    setFilters(filters);
    setRows(rows);
    setPage(0);
  };

  const sortProjects = (projects) => {
    const statusMap = {};

    // eslint-disable-next-line no-return-assign
    projectStatuses.forEach((status) => statusMap[status.id] = status.order);

    return projects.sort((p1, p2) => {
      if (statusMap[p1.status] === statusMap[p2.status]) {
        return moment(p1.createdAt) > moment(p2.createdAt) ? -1 : 1;
      }
      return statusMap[p1.status] < statusMap[p2.status] ? -1 : 1;
    });
  };

  return (
    <div>
      <GridContainer>
        { canEdit ? (
          <GridItem xs={12} sm={12} md={12}>
            <Link to="/production/projects/create">
              <Button color="primary" style={{ float: 'right' }}>+ Create Project</Button>
            </Link>
          </GridItem>
        ) : null }
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <FormControl className={classes.formControl}>
                    <InputLabel>Manager</InputLabel>
                    <Select
                      value={filters.managerId}
                      onChange={(e) => handleFilterChange('managerId', e.target.value)}
                    >
                      <MenuItem value="_all">
                        All Managers
                      </MenuItem>

                      { managers
                        .map((manager) => (
                          <MenuItem key={manager._id} value={manager._id}>
                            {manager.firstName}
                            {' '}
                            {manager.lastName}
                            {' '}
                            (
                            {manager.titles.join(', ')}
                            )
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>

                <GridItem xs={12} sm={12} md={3}>
                  <FormControlLabel
                    className={classes.checkbox}
                    control={(
                      <Checkbox
                        checked={filters.showArchived}
                        onChange={(e) => handleFilterChange('showArchived', e.target.checked)}
                        color="primary"
                        className={classes.padding}
                      />
                      )}
                    label="Show Archived"
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              style={column.width ? { width: column.width } : {}}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row) => (
                            <TableRow key={row.id}>
                              {columns.map((column) => {
                                const value = row[column.id];

                                switch (column.id) {
                                  case 'name':
                                    return (
                                      <TableCell key={column.id}>
                                        <Link to={`/production/projects/${row.id}`}>{value}</Link>
                                      </TableCell>
                                    );

                                  default:
                                    return (
                                      <TableCell key={column.id}>
                                        { value }
                                      </TableCell>
                                    );
                                }
                              })}
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[20, 50, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
