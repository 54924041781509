import React, {
  useState, useEffect, useCallback,
} from 'react';

import debounce from 'lodash.debounce';

import {
  getCandidateStatusOptions,
} from 'services/candidate';

// @material-ui/core components

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';

import CustomSelect from 'components/CustomSelect/CustomSelect';
import TextField from '@material-ui/core/TextField';
import { getPositionOptions } from 'services/opportunity';

export default function CandidateFilters({ onChange, storage, techStack }) {
  const [filters, setFilters] = useState(storage.getValue('candidateFilters', {
    status: 'available',
    position: [],
    techStack: [],
    name: '',
  }));

  const onChangeDebounced = useCallback(debounce(onChange, 300), []);

  useEffect(() => () => {
    onChangeDebounced.cancel();
  }, []);

  useEffect(() => {
    const data = {};

    Object.keys(filters).forEach((key) => {
      if (filters[key] && (!Array.isArray(filters[key]) || filters[key].length)) {
        data[key] = filters[key];
      }
    });

    onChangeDebounced(data);
  }, [filters]);

  const handleFilterChange = (filter, value) => {
    const data = { ...filters };
    data[filter] = value;

    setFilters(data);

    if (storage) {
      storage.setValue('candidateFilters', data);
    }
  };

  const getTechStackOptions = (techStack) => techStack.map((item) => ({
    id: item._id,
    name: item.name,
  }));

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={3}>
        <CustomSelect
          label="Status"
          value={filters.status}
          options={getCandidateStatusOptions()}
          onChange={(value) => handleFilterChange('status', value)}
        />
      </GridItem>

      <GridItem xs={12} sm={12} md={3}>
        <CustomSelect
          selectAll
          label="Position"
          value={filters.position}
          options={getPositionOptions()}
          onChange={(value) => handleFilterChange('position', value)}
        />
      </GridItem>

      <GridItem xs={12} sm={12} md={3}>
        <CustomSelect
          multiple
          selectAll
          label="Tech Stack"
          value={filters.techStack}
          options={getTechStackOptions(techStack)}
          onChange={(value) => handleFilterChange('techStack', value)}
        />
      </GridItem>

      <GridItem xs={12} sm={12} md={3}>
        <TextField
          fullWidth
          label="Candidate"
          value={filters.name}
          onChange={(e) => handleFilterChange('name', e.target.value)}
        />
      </GridItem>
    </GridContainer>
  );
}
