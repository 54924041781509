import React, { useState, useEffect, useContext } from 'react';

import {
  getClientRevenueReport,
} from 'services/pnlReport';

import {
  formatMoney,
  getDefaultCurrency,
} from 'services/finance';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import TableReport from 'components/TableReport/TableReport';

import { ConsoleContext } from 'services/context';

const useStyles = makeStyles((theme) => ({
}));

export default function ClientRevenueReport({ date }) {
  const classes = useStyles();

  const { t, showError } = useContext(ConsoleContext);

  const currency = getDefaultCurrency();

  const columns = [
    { id: 'client', label: t('Client') },
    { id: 'revenue', label: `${t('P&L Revenue')}, ${currency}` },
  ];

  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (!date.year) {
      return;
    }

    (async () => {
      try {
        const report = await getClientRevenueReport(date.year, date.month);

        setRows(prepareRows(report));
      } catch (e) {
        showError(e);
      }
    })();
  }, [date]);

  const prepareRows = (report) => {
    const rows = [];

    rows.push({
      id: '_total',
      client: 'Total',
      revenue: formatMoney(report.totalAmount, false),
      _styles: {
        backgroundColor: '#ccc',
      },
    });

    report.records.forEach((record) => {
      const {
        client,
        revenue,
        hasMissingContracts,
      } = record;

      rows.push({
        id: client._id,
        client: client.companyName,
        revenue: hasMissingContracts ? '-' : formatMoney(revenue, false),
      });
    });

    return rows;
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <TableReport
          columns={columns}
          rows={rows}
        />
      </GridItem>
    </GridContainer>
  );
}
