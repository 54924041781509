import React, { useState, useEffect, useContext } from 'react';
import clone from 'just-clone';

import { ConsoleContext } from 'services/context';

import {
  createPartnerCompany,
  updatePartnerCompany,
} from 'services/partnerCompany';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button';

import TechStackSelect from '../../components/TechStackSelect';

const useStyles = makeStyles(() => ({
  actions: {
    marginTop: '15px',
    textAlign: 'center',
  },
  grid: {
    marginTop: '15px',
  },
}));

export default function PartnerCompanyEdit({
  company,
  onSave,
  onCancel,
  techStack,
}) {
  const classes = useStyles();

  const {
    showLoader, showError, showSuccess, t,
  } = useContext(ConsoleContext);

  const [changedCompany, setChangedCompany] = useState();

  useEffect(() => {
    const changedCompany = {
      name: '',
      techStack: [],
      contacts: [],
      ...clone(company),
    };

    const emptyContacts = {
      name: '',
      telegram: '',
      skype: '',
      phone: '',
      email: '',
    };

    if (!changedCompany.contacts.length) {
      changedCompany.contacts.push({ ...emptyContacts });
    } else {
      changedCompany.contacts.forEach((contact, index) => {
        changedCompany.contacts[index] = {
          ...emptyContacts,
          ...contact,
        };
      });
    }

    setChangedCompany(changedCompany);
  }, [company]);

  const handleChange = (field, value) => {
    const data = clone(changedCompany);
    data[field] = value;

    setChangedCompany(data);
  };

  const handleContactsChange = (contactIndex, field, value) => {
    const data = clone(changedCompany);

    data.contacts[contactIndex][field] = value;

    setChangedCompany(data);
  };

  const handleSubmit = async () => {
    const data = clone(changedCompany);

    data.contacts = data.contacts.filter((item) => {
      const fields = ['telegram', 'phone', 'skype', 'email'];

      let empty = true;

      fields.forEach((field) => {
        if (field === 'telegram') {
          item[field] = item[field].replace('@', '');
        }

        if (item[field]) {
          empty = false;
        } else {
          delete item[field];
        }
      });

      if (!item.name) {
        delete item.name;
      }

      return !empty;
    });

    showLoader(true);

    try {
      let newCompany;

      if (data._id) {
        newCompany = await updatePartnerCompany(data);
        showSuccess('Company updated');
      } else {
        newCompany = await createPartnerCompany(data);
        showSuccess('Company created');
      }

      onSave(newCompany);
    } catch (e) {
      showError(e);
    }

    showLoader(false);
  };

  const handleCancel = () => {
    onCancel();
  };

  if (!changedCompany) {
    return null;
  }

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <TextField
                fullWidth
                required
                label="Company Name"
                value={changedCompany.name}
                onChange={(e) => handleChange('name', e.target.value)}
              />
            </GridItem>
          </GridContainer>

          <GridContainer className={classes.grid}>
            <GridItem xs={12} sm={12} md={12}>
              <h4>Contacts *</h4>
              <span>At least one contact is required. Name is optional</span>
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              { changedCompany.contacts.map((contact, index) => (
                <GridContainer key={index}>
                  <GridItem xs={12} sm={12} md={2}>
                    <TextField
                      fullWidth
                      label="Name"
                      value={contact.name}
                      onChange={(e) => handleContactsChange(index, 'name', e.target.value)}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={2}>
                    <TextField
                      fullWidth
                      label="Telegram ID"
                      value={contact.telegram}
                      onChange={(e) => handleContactsChange(index, 'telegram', e.target.value)}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={2}>
                    <TextField
                      fullWidth
                      label="Phone"
                      value={contact.phone}
                      onChange={(e) => handleContactsChange(index, 'phone', e.target.value)}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={2}>
                    <TextField
                      fullWidth
                      label="Skype ID"
                      value={contact.skype}
                      onChange={(e) => handleContactsChange(index, 'skype', e.target.value)}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <TextField
                      fullWidth
                      label="Email"
                      value={contact.email}
                      onChange={(e) => handleContactsChange(index, 'email', e.target.value)}
                    />
                  </GridItem>
                </GridContainer>
              ))}
            </GridItem>
          </GridContainer>

          <GridContainer className={classes.grid}>
            <GridItem xs={12} sm={12} md={12}>
              <h4>Tech Stack</h4>
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <TechStackSelect
                techStack={techStack}
                values={changedCompany.techStack}
                experienceOptions={[]}
                onChange={(values) => handleChange('techStack', values)}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>

      <GridContainer className={classes.actions}>
        <GridItem xs={12} sm={12} md={12}>
          { company._id ? (
            <>
              <Button color="info" onClick={handleSubmit} style={{ marginRight: '10px' }}>
                {t('Update')}
              </Button>
              <Button onClick={handleCancel}>{t('Cancel')}</Button>
            </>
          ) : (
            <Button color="info" onClick={handleSubmit}>
              {t('Create')}
            </Button>
          ) }
        </GridItem>
      </GridContainer>
    </>
  );
}
