import React, { useState, useEffect, useContext } from 'react';

import { ConsoleContext } from 'services/context';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(() => ({
  formControl: {
    display: 'block',
  },
}));

export default function CustomSelect({
  label,
  value,
  selectAll = false,
  selectAllLabel = 'All',
  options = [],
  translateOptions = false,
  onChange,
  required = false,
  disabled = false,
  multiple = false,
}) {
  const classes = useStyles();

  const { t } = useContext(ConsoleContext);

  const [currentValue, setCurrentValue] = useState(multiple ? [] : '');

  const SELECT_ALL = '_all';

  useEffect(() => {
    if (multiple) {
      if (!value.length) {
        value = selectAll ? [SELECT_ALL] : [];
      }
    } else if (!value) {
      value = selectAll ? SELECT_ALL : '';
    }

    setCurrentValue(value);
  }, [value]);

  useEffect(() => {
    if ((currentValue === SELECT_ALL) || (currentValue === '') || multiple) {
      return;
    }

    const selectedOption = options.find((option) => option.id === currentValue);

    // drop selection if value is not in options
    if (!selectedOption) {
      onChange(null);
    }
  }, [options]);

  const handleChange = (value) => {
    if (multiple) {
      const selectAllIndex = value.indexOf(SELECT_ALL);

      if (selectAllIndex > -1) {
        if (value.length > 1) {
          if (selectAllIndex === 0) {
            value.splice(selectAllIndex, 1);
          } else {
            value = [];
          }
        } else {
          value = [];
        }
      }
    } else if (value === SELECT_ALL) {
      value = null;
    }

    if (onChange) {
      onChange(value);
    }
  };

  const getCurrentValue = () => {
    if (currentValue === SELECT_ALL || currentValue === '' || multiple) {
      return currentValue;
    }

    const selectedOption = options.find((option) => option.id === currentValue);

    if (!selectedOption) {
      return '';
    }

    return currentValue;
  };

  return (
    <FormControl className={classes.formControl}>
      { label ? (
        <InputLabel>{t(label) + (required ? ' *' : '')}</InputLabel>
      ) : null }

      <Select
        multiple={multiple}
        disabled={disabled}
        fullWidth
        value={getCurrentValue()}
        onChange={(e) => handleChange(e.target.value)}
      >
        { selectAll ? (
          <MenuItem value={SELECT_ALL}>
            {t(selectAllLabel)}
          </MenuItem>
        ) : (!required && !multiple) ? (
          <MenuItem value=""><em>None</em></MenuItem>
        ) : null }

        { options
          .map((option) => (
            <MenuItem key={option.id} value={option.id}>
              { translateOptions ? t(option.name) : option.name }
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
