import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import {
  getClient,
} from 'services/client';

import {
  getInvoice,
  createInvoice,
  updateInvoice,
  getNextStatuses,
} from 'services/invoice';

import {
  getClientContractCurrencies,
} from 'services/clientContract';

import {
  formatDate,
  formatDateTime,
  formatInputDate,
  parseInputDate,
} from 'services/date';

import {
  formatMoney,
  getDefaultCurrency,
  getCurrencyExchangeRates,
  parseInputNumber,
} from 'services/finance';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Button from 'components/CustomButtons/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import { ConsoleContext } from 'services/context';

import ArrowButton from 'components/ArrowButton/ArrowButton';
import InvoiceStatus from './components/InvoiceStatus';
import ChangeStatusDialog from './components/ChangeStatusDialog';
import InvoicePaymentDetails from './components/InvoicePaymentDetails';
import CurrencyConverter from './components/CurrencyConverter';
import ExchangeRates from '../components/ExchangeRates';

const useStyles = makeStyles(() => ({
  grid: {
    marginTop: '15px',
  },
  actions: {
    marginTop: '15px',
    textAlign: 'center',
  },
  formControl: {
    minWidth: '100%',
  },
  helperText: {
    color: '#9c26b0',
    fontSize: '0.75rem',
  },
}));

export default function ClientInvoiceDetails({ match: { params: { clientId, id } } }) {
  const classes = useStyles();

  const {
    showError, showSuccess, showLoader, can, t,
  } = useContext(ConsoleContext);

  const [client, setClient] = useState();

  const [invoice, setInvoice] = useState();

  const [changedInvoice, setChangedInvoice] = useState({});

  const [canEdit, setCanEdit] = useState(false);

  const [redirectId, setRedirectId] = useState('');

  const [editMode, setEditMode] = useState(!id);

  const [openChangeStatusDialog, setOpenChangeStatusDialog] = useState(false);

  const [exchangeRates, setExchangeRates] = useState();

  const fields = [
    { id: 'key', label: 'Invoice Number' },
    { id: 'billingStartAt', label: 'Start Date' },
    { id: 'billingEndAt', label: 'End Date' },
    { id: 'currency', label: 'Currency' },
    { id: 'billingPeriodAmount', label: 'Billing Period Amount' },
    { id: 'lastInvoiceDebt', label: 'Previous Payment Debt' },
    { id: 'discount', label: 'Discount' },
    { id: 'additionalAmount', label: 'Additional Amount' },
    { id: 'serviceCompensation', label: 'Service Compensation' },
    { id: 'totalAmount', label: 'Total Amount' },
    { id: 'payedAmount', label: 'Payed Amount' },
  ];

  const statusFields = [
    { id: 'status', label: 'Status' },
    { id: 'createdAt', label: 'Created Date' },
    { id: 'sentAt', label: 'Sent Date' },
    { id: 'completedAt', label: 'Completed Date' },
    { id: 'rejectedAt', label: 'Rejected Date' },
  ];

  useEffect(() => {
    (async () => {
      showLoader(true);

      try {
        const client = await getClient(clientId);

        let invoice;

        if (id) {
          invoice = await getInvoice(id);
        } else {
          invoice = { clientId };
        }

        setInvoice(invoice);
        setChangedInvoice(prepareInvoice(invoice));
        setClient(client);
      } catch (error) {
        showError(error);
      }

      setCanEdit(await can('finance:invoices:edit'));

      showLoader(false);
    })();
  }, [clientId, id]);

  useEffect(() => {
    (async () => {
      try {
        const exchangeRates = await getCurrencyExchangeRates();

        setExchangeRates(exchangeRates);
      } catch (e) {
        showError(e);
      }
    })();
  }, []);

  const prepareInvoice = (invoice) => ({
    discount: 0,
    discountComment: '',
    additionalAmount: 0,
    additionalAmountComment: '',
    serviceCompensation: 0,
    serviceCompensationComment: '',
    ...invoice,
    billingStartAt: invoice.billingStartAt ? formatInputDate(invoice.billingStartAt) : '',
    billingEndAt: invoice.billingEndAt ? formatInputDate(invoice.billingEndAt) : '',
    currency: invoice.currency ? invoice.currency : getDefaultCurrency(),
  });

  const handleSubmit = async () => {
    const data = { ...changedInvoice };

    data.billingStartAt = parseInputDate(data.billingStartAt);
    data.billingEndAt = parseInputDate(data.billingEndAt);
    data.discount = parseInputNumber(data.discount);
    data.additionalAmount = parseInputNumber(data.additionalAmount);
    data.serviceCompensation = parseInputNumber(data.serviceCompensation);

    let newInvoice;

    showLoader(true);

    try {
      if (data._id) {
        newInvoice = await updateInvoice(data);

        showSuccess('Invoice updated');

        setInvoice(newInvoice);

        setEditMode(false);
      } else {
        const newInvoice = await createInvoice(data);

        showSuccess('Invoice created');

        setRedirectId(newInvoice._id);
      }
    } catch (e) {
      showError(e);
    }

    showLoader(false);
  };

  const handleInvoiceChange = (field, value) => {
    const data = { ...changedInvoice };
    data[field] = value;

    setChangedInvoice(data);
  };

  const handleEdit = () => {
    setChangedInvoice(prepareInvoice(invoice));
    setEditMode(true);
  };

  const handleEditCancel = () => {
    setEditMode(false);
  };

  const handleChangeStatus = () => {
    setOpenChangeStatusDialog(true);
  };

  const handleChangeStatusClose = () => {
    setOpenChangeStatusDialog(false);
  };

  const handleChangeStatusSave = ({
    status, payedAmount, sentAt, completedAt, rejectedAt,
  }) => {
    const data = {
      ...invoice,
      status,
      payedAmount,
      sentAt,
      completedAt,
      rejectedAt,
    };

    setInvoice(data);

    setOpenChangeStatusDialog(false);
  };

  const handleRecalculate = async () => {
    showLoader(true);

    try {
      const changedInvoice = await updateInvoice(invoice);

      setInvoice(changedInvoice);
    } catch (e) {
      showError(e);
    }

    showLoader(false);
  };

  const renderField = (invoice, field) => {
    let value = invoice[field];

    switch (field) {
      case 'status':
        return (<InvoiceStatus status={invoice.status} />);

      case 'billingStartAt':
      case 'billingEndAt':
        return formatDate(value);

      case 'createdAt':
      case 'sentAt':
      case 'completedAt':
      case 'rejectedAt':
        return formatDateTime(value);
      case 'totalAmount':
      case 'lastInvoiceDebt':
      case 'discount':
      case 'additionalAmount':
      case 'serviceCompensation':
      case 'payedAmount':
      case 'billingPeriodAmount':
        if (!value && value !== 0) {
          return '-';
        }

        if (value < 0) {
          value = (<span style={{ color: 'red' }}>{formatMoney(value, invoice.currency)}</span>);
        } else {
          value = formatMoney(value, invoice.currency);
        }

        return value;

      case 'currency':
        return (
          <>
            {value}
            <br />
            {' '}
            { renderCurrencyExchangeInfo(invoice)}
          </>
        );

      default:
        return value;
    }
  };

  const canChangeStatus = (status) => {
    if (!status) {
      return false;
    }

    return Boolean(getNextStatuses(status).length);
  };

  const canChangeInvoice = (invoice) => invoice.status === 'created';

  const renderCurrencyExchangeInfo = (invoice) => (
    <ExchangeRates
      baseCurrency={invoice.currency}
      currencyExchangeDate={invoice.currencyExchangeDate}
      currencyExchangeRates={invoice.currencyExchangeRates}
    />
  );

  return (
    <>
      { redirectId ? <Redirect to={`/finance/invoices/${clientId}/${redirectId}`} /> : ''}

      { client ? (
        <GridContainer>
          <GridItem>
            <ArrowButton to={`/finance/invoices/${clientId}`}>
              { t('Back to {{client.companyName}} invoices', { client }) }
            </ArrowButton>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4>{client.companyName}</h4>
                {t(invoice._id ? (editMode ? 'Edit Invoice' : 'View Invoice') : 'Create Invoice')}
              </CardHeader>
              <CardBody>
                { editMode ? (
                  <>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={8}>
                        <TableContainer>
                          <Table>
                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  {t('Billing Period')}
                                  {' '}
                                  *
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    fullWidth
                                    label={t('Start Date')}
                                    type="date"
                                    value={changedInvoice.billingStartAt}
                                    onChange={(e) => handleInvoiceChange('billingStartAt', e.target.value)}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    fullWidth
                                    label={t('End Date')}
                                    type="date"
                                    value={changedInvoice.billingEndAt}
                                    onChange={(e) => handleInvoiceChange('billingEndAt', e.target.value)}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  {t('Currency')}
                                  {' '}
                                  *
                                </TableCell>
                                <TableCell>
                                  <FormControl className={classes.formControl}>
                                    <Select
                                      fullWidth
                                      value={changedInvoice.currency}
                                      onChange={(e) => handleInvoiceChange('currency', e.target.value)}
                                    >

                                      { getClientContractCurrencies().map((currency) => (
                                        <MenuItem key={currency} value={currency}>
                                          { currency }
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </TableCell>
                                <TableCell>&nbsp;</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  {t('Discount')}
                                  {' '}
                                  (
                                  {changedInvoice.currency}
                                  )

                                  <div className={classes.helperText}>{t('Will be added to P&L')}</div>
                                </TableCell>
                                <TableCell>
                                  <GridContainer>
                                    <GridItem xs={12} sm={12} md={3}>
                                      <CurrencyConverter
                                        currencyTo={changedInvoice.currency}
                                        exchangeRates={exchangeRates}
                                        onApply={(amount) => handleInvoiceChange('discount', amount)}
                                      />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={9}>
                                      <TextField
                                        value={changedInvoice.discount}
                                        helperText={t('Should be negative or 0')}
                                        onChange={(e) => handleInvoiceChange('discount', e.target.value)}
                                      />
                                    </GridItem>
                                  </GridContainer>
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    label={t('Comment')}
                                    variant="outlined"
                                    value={changedInvoice.discountComment}
                                    onChange={(e) => handleInvoiceChange('discountComment', e.target.value)}
                                  />
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  {t('Additional Amount')}
                                  {' '}
                                  (
                                  {changedInvoice.currency}
                                  )

                                  <div className={classes.helperText}>{t('Will be added to P&L')}</div>
                                </TableCell>
                                <TableCell>
                                  <GridContainer>
                                    <GridItem xs={12} sm={12} md={3}>
                                      <CurrencyConverter
                                        currencyTo={changedInvoice.currency}
                                        exchangeRates={exchangeRates}
                                        onApply={(amount) => handleInvoiceChange('additionalAmount', amount)}
                                      />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={9}>
                                      <TextField
                                        value={changedInvoice.additionalAmount}
                                        helperText={t('Should be 0 or larger')}
                                        onChange={(e) => handleInvoiceChange('additionalAmount', e.target.value)}
                                      />
                                    </GridItem>
                                  </GridContainer>
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    fullWidth
                                    label={t('Comment')}
                                    variant="outlined"
                                    value={changedInvoice.additionalAmountComment}
                                    onChange={(e) => handleInvoiceChange('additionalAmountComment', e.target.value)}
                                  />
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  {t('Service Compensation')}
                                  {' '}
                                  (
                                  {changedInvoice.currency}
                                  )

                                  <div className={classes.helperText}>{t('Will NOT be added to P&L')}</div>
                                </TableCell>
                                <TableCell>
                                  <GridContainer>
                                    <GridItem xs={12} sm={12} md={3}>
                                      <CurrencyConverter
                                        currencyTo={changedInvoice.currency}
                                        exchangeRates={exchangeRates}
                                        onApply={(amount) => handleInvoiceChange('serviceCompensation', amount)}
                                      />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={9}>
                                      <TextField
                                        value={changedInvoice.serviceCompensation}
                                        helperText={t('Should be 0 or larger')}
                                        onChange={(e) => handleInvoiceChange('serviceCompensation', e.target.value)}
                                      />
                                    </GridItem>
                                  </GridContainer>
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    fullWidth
                                    label={t('Comment')}
                                    variant="outlined"
                                    value={changedInvoice.serviceCompensationComment}
                                    onChange={(e) => handleInvoiceChange('serviceCompensationComment', e.target.value)}
                                  />
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </GridItem>
                    </GridContainer>
                    <GridContainer className={classes.actions}>
                      <GridItem xs={12} sm={12} md={8}>
                        { changedInvoice._id ? (
                          <>
                            <Button color="primary" onClick={handleSubmit} style={{ marginRight: '10px' }}>{t('Update')}</Button>
                            <Button onClick={handleEditCancel}>{t('Cancel')}</Button>
                          </>
                        ) : (
                          <Button color="primary" onClick={handleSubmit}>{t('Create')}</Button>
                        )}
                      </GridItem>
                    </GridContainer>
                  </>
                ) : (
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <h4>{t('Summary')}</h4>
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <TableContainer>
                            <Table>
                              <TableBody>
                                { fields.map((field) => (
                                  <TableRow key={field.id}>
                                    <TableCell><strong>{ t(field.label) }</strong></TableCell>
                                    <TableCell>{ renderField(invoice, field.id) }</TableCell>
                                  </TableRow>
                                )) }
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </GridItem>
                      </GridContainer>
                      { canEdit && canChangeInvoice(invoice) ? (
                        <GridContainer className={classes.actions}>
                          <GridItem xs={12} sm={12} md={12}>
                            <Button color="primary" onClick={handleEdit}>{t('Edit')}</Button>
                            <Button
                              color="warning"
                              onClick={handleRecalculate}
                              style={{ marginLeft: '10px' }}
                            >
                              {t('Recalculate')}
                            </Button>
                          </GridItem>
                        </GridContainer>
                      ) : null }
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <h4>{t('Status')}</h4>
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <TableContainer>
                            <Table>
                              <TableBody>
                                { statusFields.map((field) => (
                                  <TableRow key={field.id}>
                                    <TableCell><strong>{ t(field.label) }</strong></TableCell>
                                    <TableCell>{ renderField(invoice, field.id) }</TableCell>
                                  </TableRow>
                                )) }
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </GridItem>
                      </GridContainer>
                      { canEdit && canChangeStatus(invoice.status) ? (
                        <GridContainer className={classes.actions}>
                          <GridItem xs={12} sm={12} md={12}>
                            <Button color="success" onClick={handleChangeStatus}>{t('Change Status')}</Button>
                          </GridItem>
                        </GridContainer>
                      ) : null }
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12}>
                      <InvoicePaymentDetails invoice={invoice} client={client} />
                    </GridItem>

                    <ChangeStatusDialog
                      invoice={invoice}
                      open={openChangeStatusDialog}
                      onClose={handleChangeStatusClose}
                      onSave={handleChangeStatusSave}
                    />
                  </GridContainer>
                ) }
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      ) : null }
    </>
  );
}
