import React, {
  useState, useEffect, useCallback,
} from 'react';

import debounce from 'lodash.debounce';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';

import CustomSelect from 'components/CustomSelect/CustomSelect';
import TextField from '@material-ui/core/TextField';

export default function PartnerCompanyFilters({ onChange, techStack }) {
  const [filters, setFilters] = useState({
    techStack: [],
    contacts: '',
  });

  const onChangeDebounced = useCallback(debounce(onChange, 300), []);

  useEffect(() => () => {
    onChangeDebounced.cancel();
  }, []);

  useEffect(() => {
    const data = {};

    Object.keys(filters).forEach((key) => {
      if (filters[key] && (!Array.isArray(filters[key]) || filters[key].length)) {
        data[key] = filters[key];
      }
    });

    onChangeDebounced(data);
  }, [filters]);

  const handleFilterChange = (filter, value) => {
    const data = { ...filters };
    data[filter] = value;

    setFilters(data);
  };

  const getTechStackOptions = (techStack) => techStack.map((item) => ({
    id: item._id,
    name: item.name,
  }));

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={4}>
        <CustomSelect
          multiple
          selectAll
          label="Tech Stack"
          value={filters.techStack}
          options={getTechStackOptions(techStack)}
          onChange={(value) => handleFilterChange('techStack', value)}
        />
      </GridItem>

      <GridItem xs={12} sm={12} md={4}>
        <TextField
          fullWidth
          label="Contacts"
          value={filters.contacts}
          onChange={(e) => handleFilterChange('contacts', e.target.value)}
        />
      </GridItem>
    </GridContainer>
  );
}
