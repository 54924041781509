import React, { useState, useEffect, useContext } from 'react';

import { ConsoleContext } from 'services/context';

import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from 'components/CustomDialog/CustomDialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Button from 'components/CustomButtons/Button';
import TextField from '@material-ui/core/TextField';

import {
  updateOpportunityStatus,
} from 'services/opportunity';

const useStyles = makeStyles(() => ({
  grid: {
    marginTop: '15px',
  },
}));

export default function OpportunityArchiveDialog({
  opportunity, open, onClose, onSave,
}) {
  const classes = useStyles();

  const {
    showError, showSuccess, t,
  } = useContext(ConsoleContext);

  const [openDialog, setOpenDialog] = useState(open);

  const [changedReason, setChangedReason] = useState();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setOpenDialog(open);
    setChangedReason('');
  }, [open]);

  const handleCloseDialog = () => {
    onClose();
  };

  const handleChange = (value) => {
    setChangedReason(value);
  };

  const handleSubmit = async () => {
    const data = {
      _id: opportunity._id,
      status: 'archived',
      archivedReason: changedReason,
    };

    try {
      const changedOpportunity = await updateOpportunityStatus(data);
      showSuccess('Opportunity archived');
      setLoading(true);

      onSave({ ...changedOpportunity, candidates: opportunity.candidates, partnerCompanies: opportunity.partnerCompanies });
    } catch (e) {
      showError(e);
    }

    setLoading(false);
  };

  return (
    <Dialog
      loading={loading}
      maxWidth="xs"
      fullWidth
      open={openDialog}
    >
      <DialogTitle>Archive Reason</DialogTitle>
      <DialogContent>
        <GridContainer className={classes.grid}>
          <GridItem xs={12} sm={12} md={12}>
            <TextField
              required
              fullWidth
              label="Comment"
              multiline
              rows={2}
              variant="outlined"
              value={changedReason}
              onChange={(e) => handleChange(e.target.value)}
            />
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>
          {t('Cancel')}
        </Button>

        <Button onClick={handleSubmit} color="info">
          {t('Save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
