import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import {
  getRole,
  createRole,
  updateRole,
} from 'services/role';

import { getUsers, filterActiveUsers } from 'services/user';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Button from 'components/CustomButtons/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import CardFooter from 'components/Card/CardFooter';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControl from '@material-ui/core/FormControl';
import { ConsoleContext } from 'services/context';

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: '100%',
  },
  userName: {
    marginTop: '20px',
  },
}));

export default function RoleDetails(props) {
  const { circleId, id } = props.match.params;

  useEffect(() => {
    async function fetchData() {
      showLoader(true);

      try {
        let role;

        if (id) {
          role = await getRole(id);
        } else {
          role = {
            circleId,
            name: '',
            userIds: [],
          };
        }

        const users = await getUsers();
        const activeUsers = filterActiveUsers(users);

        setUsers(users);
        setActiveUsers(activeUsers);
        setRole(role);
      } catch (error) {
        showError(error);
      }

      showLoader(false);
    }

    fetchData();
  }, [id]);

  const classes = useStyles();

  const {
    showError, showSuccess, showConfirm, showLoader,
  } = useContext(ConsoleContext);

  const [role, setRole] = useState();

  const [changedRole, setChangedRole] = useState({});

  const [users, setUsers] = useState([]);
  const [activeUsers, setActiveUsers] = useState([]);

  const [newUser, setNewUser] = useState('');

  const [redirectId, setRedirectId] = useState('');

  const handleValueChange = (field, value) => {
    const data = { ...changedRole };
    data[field] = value;

    setChangedRole(data);
  };

  const handleSubmit = async () => {
    const data = { ...role, ...changedRole };

    let newRole;

    try {
      if (role._id) {
        newRole = await updateRole(data);

        showSuccess('Role updated');

        setRole(newRole);
      } else {
        newRole = await createRole(data);

        showSuccess('Role created');

        setRedirectId(newRole._id);
      }
    } catch (e) {
      showError(e);
    }
  };

  const handleAddUser = () => {
    const userIds = [...role.userIds];

    if (newUser) {
      userIds.push(newUser);

      setRole({ ...role, userIds });

      setNewUser('');
    }
  };

  const handleDeleteUser = (userId) => {
    showConfirm(() => {
      const userIds = [...role.userIds];

      const index = userIds.findIndex((itemId) => itemId === userId);

      userIds.splice(index, 1);

      setRole({ ...role, userIds });
      setChangedRole({ ...changedRole, userIds });
    });
  };

  function formatUserName(userId) {
    const user = users.find((user) => user._id === userId);

    return `${user.firstName} ${user.lastName}`;
  }

  return (
    <div>
      { redirectId ? <Redirect to={`/${circleId}/roles/${redirectId}`} /> : ''}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          { role ? (
            <Card>
              <CardHeader color="success">
                <h4>{role._id ? 'Edit Role' : 'Create Role'}</h4>
                {role._id ? role.name : ''}
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <TextField
                      fullWidth
                      required
                      label="Name"
                      defaultValue={role.name}
                      onChange={(e) => handleValueChange('name', e.target.value)}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={2}>
                    <h3>Users</h3>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={2}>
                    <FormControl className={classes.formControl}>
                      <InputLabel>User *</InputLabel>
                      <Select
                        value={newUser}
                        onChange={(e) => setNewUser(e.target.value)}
                      >
                        { activeUsers
                          .filter((user) => role.userIds.indexOf(user._id) === -1)
                          .map((user) => (
                            <MenuItem key={`user-${user._id}`} value={user._id}>
                              {`${user.firstName} ${user.lastName}`}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <Button color="info" onClick={handleAddUser}>+ Add User</Button>
                  </GridItem>
                </GridContainer>
                { role.userIds.map((userId) => (
                  <GridContainer key={userId}>
                    <GridItem xs={12} sm={12} md={2}>
                      <div className={classes.userName}>{formatUserName(userId)}</div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={1}>
                      <IconButton aria-label="delete" className={classes.margin} onClick={() => handleDeleteUser(userId)}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </GridItem>
                  </GridContainer>
                )) }
              </CardBody>
              <CardFooter>
                <Button color="primary" onClick={handleSubmit}>{role._id ? 'Update' : 'Create'}</Button>
              </CardFooter>
            </Card>
          ) : ''}
        </GridItem>
      </GridContainer>
    </div>
  );
}
