import moment from 'moment';
import clone from 'just-clone';
import {
  OPPORTUNITY_MANAGERS_URL,
  OPPORTUNITY_URL,
} from '../utils/apiRouting';

import {
  get, post, put, httpDelete, formatQuery,
} from '../utils/http';

import { snakeToCamelCase, formatOptionItem } from './text';
import { getRoles } from './role';
import { formatUserName } from './user';

export async function getOpportunities(params = {}) {
  const query = {};

  if (params.status) {
    query.status = params.status;
  }

  if ((params.status !== 'active') && params.name) {
    query.name = params.name;
  }

  return get(OPPORTUNITY_URL + formatQuery(query));
}

export async function createOpportunity(opportunity) {
  return post(OPPORTUNITY_URL, opportunity);
}

export async function getOpportunity(id) {
  return get(`${OPPORTUNITY_URL}/${id}`);
}

export async function updateOpportunity(opportunity) {
  return put(`${OPPORTUNITY_URL}/${opportunity._id}`, opportunity);
}

export async function updateOpportunityStatus({ _id, status, archivedReason }) {
  return put(`${OPPORTUNITY_URL}/${_id}/status`, { status, archivedReason });
}

export async function addOpportunityCandidate(opportunityId, candidateId) {
  return post(`${OPPORTUNITY_URL}/${opportunityId}/candidates`, { id: candidateId });
}

export async function updateOpportunityCandidateStatus(opportunityId, opportunityCandidate) {
  return put(`${OPPORTUNITY_URL}/${opportunityId}/candidates/${opportunityCandidate.id}/status`, opportunityCandidate);
}

export async function createClientInterviewFeedback(opportunityId, candidateId, feedback) {
  return post(`${OPPORTUNITY_URL}/${opportunityId}/candidates/${candidateId}/client-interviews`, feedback);
}

export async function updateOpportunityCandidateState(opportunityId, candidateId, { state, stateNote }) {
  return put(`${OPPORTUNITY_URL}/${opportunityId}/candidates/${candidateId}/state`, { state, stateNote });
}

export async function deleteOpportunityCandidateState(opportunityId, candidateId) {
  return httpDelete(`${OPPORTUNITY_URL}/${opportunityId}/candidates/${candidateId}/state`);
}

export async function updateOpportunityPriority(opportunityId, priority, type, userId = '') {
  return put(`${OPPORTUNITY_URL}/${opportunityId}/priority`, { priority, type, userId });
}

export async function updateOpportunityCandidateMatching(opportunityId, candidateMatching) {
  return put(`${OPPORTUNITY_URL}/${opportunityId}/candidate-matching`, candidateMatching);
}

export async function updateOpportunityPartnerCompanyStatus(opportunityId, { id, status }) {
  return put(`${OPPORTUNITY_URL}/${opportunityId}/partner-companies/${id}/status`, { status });
}

export async function getOpportunityManagers() {
  return get(OPPORTUNITY_MANAGERS_URL);
}

export function getPartnerCompanyStatusOptions() {
  return [{
    id: 'added',
    name: 'Not Contacted',
  }, {
    id: 'contacted',
    name: 'Contacted',
  }, {
    id: 'not_responding',
    name: 'Not Responding',
  }, {
    id: 'received_resume',
    name: 'Received CV',
  }, {
    id: 'no_candidates',
    name: 'No Candidates',
  }, {
    id: 'ignore',
    name: 'Ignore',
  }];
}

export function formatPartnerCompanyStatus(status) {
  return formatOptionItem(status, getPartnerCompanyStatusOptions());
}

export function getPriorityOptions(opportunities, opportunity, type, userId) {
  const options = [];
  const lowestPriority = 1000;
  let index = 0;

  const getPriorityFromOpportunity = (opportunity) => {
    if (opportunity.priorities.length) {
      return opportunity.priorities.find((o) => {
        if (o.userId && userId && o.type === type) return o.userId.toString() === userId.toString();
        if (!o.userId) return o.type === type;
        return false;
      })?.priority;
    }

    return null;
  };

  opportunities.forEach((opportunity) => {
    const priority = getPriorityFromOpportunity(opportunity);
    if (priority && (priority !== lowestPriority) && (index < opportunities.length)) {
      index++;
      options.push({ id: index, name: index });
    }
  });

  const priority = getPriorityFromOpportunity(opportunity);

  if (!priority || (priority === lowestPriority)) {
    index++;
    options.push({ id: index, name: index });
  }

  options.push({ id: lowestPriority, name: '-' }); // lowest priority or no priority
  return options;
}

export function getSearchTypeOptions() {
  return [{
    id: 'all',
    name: 'All',
  }, {
    id: 'partner',
    name: 'Domestic Partners',
  }, {
    id: 'recruiter',
    name: 'Recruiter',
  }];
}

export function formatSearchType(type) {
  return formatOptionItem(type, getSearchTypeOptions());
}

export function getPositionOptions() {
  return [{
    id: 'developer',
    name: 'Developer',
  }, {
    id: 'tech_lead',
    name: 'Tech Lead',
  }, {
    id: 'database_engineer',
    name: 'Database Engineer',
  }, {
    id: 'qa',
    name: 'QA',
  }, {
    id: 'aqa',
    name: 'AQA',
  }, {
    id: 'devops',
    name: 'DevOps',
  }, {
    id: 'pm',
    name: 'Project Manager',
  }, {
    id: 'ba',
    name: 'Business Analyst',
  }];
}

export function formatPosition(position) {
  return formatOptionItem(position, getPositionOptions());
}

export function getEnglishLevelOptions() {
  return [{
    id: 'pre_intermediate',
    name: 'Pre Intermediate',
  }, {
    id: 'intermediate',
    name: 'Intermediate',
  }, {
    id: 'upper_intermediate',
    name: 'Upper Intermediate',
  }, {
    id: 'advanced',
    name: 'Advanced',
  }];
}

export function formatEnglishLevel(level) {
  return formatOptionItem(level, getEnglishLevelOptions());
}

export function formatCandidateLocation(candidateLocation) {
  return formatOptionItem(candidateLocation, getCandidateLocationOptions());
}

export function getStatusOptions() {
  return [{
    id: 'active',
    name: 'Active',
  }, {
    id: 'paused',
    name: 'Paused',
  }, {
    id: 'completed',
    name: 'Completed',
  }, {
    id: 'archived',
    name: 'Archived',
  }];
}

export function getPriorityFilterOptions() {
  return [{
    id: 'sales',
    name: 'Sales',
  }, {
    id: 'recruiter',
    name: 'Recruiter',
  }, {
    id: 'domestic_partner',
    name: 'Domestic Partner',
  }];
}

export function formatStatus(status) {
  return formatOptionItem(status, getStatusOptions());
}

export function getInterviewOptions() {
  return [{
    id: 'english',
    name: 'English',
  }, {
    id: 'tech',
    name: 'Tech',
  }, {
    id: 'soft_skills',
    name: 'Soft Skills',
  }];
}

export function formatInterviewType(type) {
  return formatOptionItem(type, getInterviewOptions());
}

export function getClientInterviewOptions() {
  return [{
    id: 'english',
    name: 'Pre-screen / English',
  }, {
    id: 'test_task',
    name: 'Test Task',
  }, {
    id: 'tech',
    name: 'Tech',
  }, {
    id: 'soft_skills',
    name: 'Soft Skills',
  }];
}

export function formatClientInterviewType(type) {
  return formatOptionItem(type, getClientInterviewOptions());
}

export function getExperienceYearsOptions() {
  return [
    { id: -1, name: 'undefined' },
    { id: 0.5, name: '6+ months' },
    { id: 1, name: '1+ year' },
    { id: 1.5, name: '1.5+ year' },
    { id: 2, name: '2+ years' },
    { id: 3, name: '3+ years' },
    { id: 4, name: '4+ years' },
    { id: 5, name: '5+ years' },
  ];
}

export function formatExperienceYears(years) {
  return formatOptionItem(years, getExperienceYearsOptions());
}

export function getProjectAgeOptions() {
  return [
    { id: -1, name: 'New' },
    { id: 0.5, name: '6+ months' },
    { id: 1, name: '1+ year' },
    { id: 2, name: '3+ years' },
  ];
}

export function formatProjectAge(age) {
  return formatOptionItem(age, getProjectAgeOptions());
}

export function getProjectEndInOptions() {
  return [
    { id: 0.25, name: '3+ months' },
    { id: 0.5, name: '6+ months' },
    { id: 1, name: '1+ year' },
    { id: 2, name: '3+ years' },
  ];
}

export function formatProjectEndIn(term) {
  return formatOptionItem(term, getProjectEndInOptions());
}

export function getProjectDevelopmentStageOptions() {
  return [
    { id: 'new', name: 'New' },
    { id: 'in_progress', name: 'In Progress' },
  ];
}

export function formatProjectDevelopmentStage(developmentStage) {
  return formatOptionItem(developmentStage, getProjectDevelopmentStageOptions());
}

export function getProjectDevelopmentMethodologyOptions() {
  return [
    { id: 'scrum', name: 'Scrum' },
    { id: 'kanban', name: 'Kanban' },
    { id: 'other', name: 'Other' },
  ];
}

export function formatProjectDevelopmentMethodology(developmentMethodology) {
  return formatOptionItem(developmentMethodology, getProjectDevelopmentMethodologyOptions());
}

export function getProjectCommunicationTypeOptions() {
  return [
    { id: 'direct', name: 'Direct with Client' },
    { id: 'manager', name: 'Manager' },
  ];
}

export function formatProjectCommunicationType(communicationType) {
  return formatOptionItem(communicationType, getProjectCommunicationTypeOptions());
}

export function getDeadlineOptions() {
  return [
    { id: 'now', name: 'Now' },
    { id: '2_3_days', name: 'In 2-3 days' },
    { id: '1_week', name: 'In 1 week' },
    { id: '2_weeks', name: 'In 2 weeks' },
    { id: '1_month', name: 'In 1 month' },
    { id: '2_3_months', name: 'In 2-3 months' },
    { id: '3_more_months', name: 'In 3+ months' },
  ];
}

export function getCandidateLocationOptions() {
  return [
    { id: 'remote', name: 'Remote' },
    { id: 'relocate', name: 'Relocate' },
  ];
}

export function formatDeadline(deadline) {
  return formatOptionItem(deadline, getDeadlineOptions());
}

export async function getRecruiterOptions() {
  const roles = await getRoles({ name: 'Recruiter' });
  let recruters;
  if (roles.length && roles[0].userIds.length) {
    recruters = roles[0].userIds;
    recruters = recruters.map((user) => (
      {
        id: user._id,
        name: formatUserName(user),
      }
    ));
  }
  return recruters;
}

export async function getOpportunityManagerOptions() {
  let managers = await getOpportunityManagers();
  managers = managers.map((user) => (
    {
      id: user._id,
      name: `${user.firstName} ${user.lastName}`,
    }
  ));
  return managers;
}

export function formatRateRange(rateRange) {
  if (!rateRange) {
    return '-';
  }

  const { type, from, to } = rateRange;

  let range = '';

  const period = type === 'hourly' ? 'h' : 'm';

  if (from && to) {
    range = `$${from}-${to}`;
  } else if (!to) {
    range = `from $${from}`;
  } else if (!from) {
    range = `to $${to}`;
  }

  range += `/${period}`;

  return range;
}

export function getCandidateStatusOptions() {
  return [
    { id: 'added', name: 'Added' },
    { id: 'interview', name: 'Internal Interview' },
    { id: 'client_interview', name: 'Client Interview' },
    { id: 'offer', name: 'Offer' },
    { id: 'hired', name: 'Hired' },
    { id: 'rejected', name: 'Rejected' },
  ];
}

export function formatCandidateStatus(status) {
  return formatOptionItem(status, getCandidateStatusOptions());
}

export function getCandidateStateOptions() {
  return [
    { id: 'on_hold', name: 'On Hold' },
    { id: 'ill', name: 'Sick' },
    { id: 'interview_arrangement', name: 'Internal Interview Arrangement' },
    { id: 'interview_arranged', name: 'Internal Interview Arranged' },
    { id: 'not_responding', name: 'Not Responding' },
    { id: 'client_interview_arrangement', name: 'Client Interview Arrangement' },
    { id: 'client_interview_arranged', name: 'Client Interview Arranged' },
    { id: 'other', name: 'Other' },
  ];
}

export function formatCandidateState(state) {
  return formatOptionItem(state, getCandidateStateOptions());
}

export function getCandidateRejectedReasonOptions() {
  return [
    { id: 'lack_experience', name: 'Not enough experience' },
    { id: 'client_lack_experience', name: 'Not enough experience for Client' },
    { id: 'high_price', name: 'High Price' },
    { id: 'not_responding', name: 'Not Responding/Missing' },
    { id: 'not_available', name: 'Already not Available' },
    { id: 'soft_skills', name: 'Soft Skills' },
    { id: 'other', name: 'Other' },
  ];
}

export function createOpportunityCopy(opportunity) {
  const {
    requiredInterviews,
    requiredClientInterviews,
    requirements,
    responsibilities,
    fullTime,
    name,
    position,
    englishLevel,
    rateRange,
    requiredInterviewsOptions,
    searchType,
    techStack,
    project,
    deadline,
    clientName,
    candidateLocation,
    candidateMatching,
  } = clone(opportunity);
  return {
    requiredInterviews,
    requiredClientInterviews,
    requirements,
    responsibilities,
    fullTime,
    name,
    position,
    englishLevel,
    rateRange,
    requiredInterviewsOptions,
    searchType,
    techStack,
    project,
    deadline,
    clientName,
    candidateLocation,
    candidateMatching,
  };
}

export function formatCandidateRejectedReason(reason) {
  return formatOptionItem(reason, getCandidateRejectedReasonOptions());
}

export function getCommunicationEfficiencyOptions() {
  return [
    { id: 'high', name: 'High skills' },
    { id: 'middle', name: 'Middle skills' },
    { id: 'low', name: 'Less than middle skills' },
    { id: 'poor', name: 'Poor skills' },
  ];
}

export function formatCommunicationEfficiency(efficiency) {
  return formatOptionItem(efficiency, getCommunicationEfficiencyOptions());
}

export function getStatusDateField(status) {
  return `${snakeToCamelCase(status)}At`;
}

export function sortOpportunityCandidates(candidates) {
  return candidates.sort((a, b) => {
    const archived1 = (a.candidate.status === 'archived');
    const archived2 = (b.candidate.status === 'archived');

    if (archived1 && !archived2) {
      return 1;
    } if (!archived1 && archived2) {
      return -1;
    } if (archived1 && archived2) {
      return moment.utc(a.candidate.archivedAt).isBefore(b.candidate.archivedAt) ? 1 : -1;
    }
    return moment.utc(a.addedAt).isBefore(b.addedAt) ? 1 : -1;
  });
}

// Public API
export async function getPublicOpportunity(id) {
  return get(`${OPPORTUNITY_URL}/public/${id}`);
}
