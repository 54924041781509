import React, { useContext } from 'react';

import { ConsoleContext } from 'services/context';

import { formatDate } from 'services/date';
import { formatMoney } from 'services/finance';

export default function PayrollExchangeRates({
  paymentCurrency, currencyExchangeDate, currencyExchangeRates, inline,
}) {
  const { t } = useContext(ConsoleContext);

  const currencies = currencyExchangeRates ? Object.keys(currencyExchangeRates) : [];

  return (
    <span>
      { currencies.length ? (
        <>
          <strong>
            {t('Date')}
            :
          </strong>
          {' '}
          { formatDate(currencyExchangeDate) }
          { inline ? ' ' : (<br />)}
          <strong>
            {t('Exchange Rates')}
            :
            {' '}
          </strong>
          { currencies.map((currency, index) => (
            <span key={currency}>
              1
              {' '}
              {currency}
              {' '}
              =&nbsp;
              {formatMoney(currencyExchangeRates[currency], paymentCurrency, 4)}
              { index < currencies.length - 1 ? ', ' : null }
            </span>
          )) }
        </>
      ) : t('No currency exchange was performed') }
    </span>
  );
}
