import React, { useState, useEffect, useContext } from 'react';

import {
  syncProjectLogs,
} from 'services/jira';

import { can } from 'services/permission';

// core components
import { makeStyles } from '@material-ui/core/styles';
import Button from 'components/CustomButtons/Button.js';

import { ConsoleContext } from 'services/context';

const useStyles = makeStyles((theme) => ({
}));

export default function ProjectLogsSync({ project, date, onSync }) {
  const classes = useStyles();

  const { showError, showSuccess, showLoader } = useContext(ConsoleContext);

  const [canSyncLogs, setCanSyncLogs] = useState(false);

  useEffect(() => {
    (async () => {
      setCanSyncLogs(await can('production:report:syncLogs'));
    })();
  }, []);

  const handleSyncUp = async () => {
    showLoader(true);

    try {
      await syncProjectLogs(date.year, date.month, project._id);

      if (onSync) {
        onSync();
      }

      showSuccess('Sync Finished');
    } catch (e) {
      showError(e);
    }

    showLoader(false);
  };

  if (!project.jiraId) {
    return null;
  }

  return (
    <>
      { canSyncLogs ? (
        <Button color="primary" onClick={handleSyncUp}>Sync JIRA</Button>
      ) : null }
    </>
  );
}
