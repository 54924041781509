import moment from 'moment';

export const DATE_FORMAT = 'DD.MM.YYYY';
export const TIME_FORMAT = 'HH:mm';

export function formatDate(date) {
  if (!date) {
    return '-';
  }

  return moment.utc(date).format(DATE_FORMAT);
}

export function formatTime(date) {
  if (!date) {
    return '-';
  }

  return moment(date).format(TIME_FORMAT);
}

export function formatDateTime(date) {
  if (!date) {
    return '-';
  }

  return moment(date).format(`${DATE_FORMAT} ${TIME_FORMAT}`);
}

export function formatHours(milliseconds) {
  if (!milliseconds) {
    return 0;
  }

  return Math.round(milliseconds / 36) / 100;
}

export function formatInputDate(date) {
  return moment.utc(date).format('YYYY-MM-DD');
}

export function parseInputDate(date) {
  return moment.utc(date, 'YYYY-MM-DD');
}

export function formatServerDate(date) {
  return moment.utc(date).format('YYYY-MM-DD');
}
