import React, { useState, useEffect, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Button from 'components/CustomButtons/Button';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import {
  formatMoney,
  formatNumber,
} from 'services/finance';

import { ConsoleContext } from 'services/context';

const useStyles = makeStyles((theme) => ({
}));

export default function PayrollRecordDebtDetails({ record, open, onClose }) {
  const classes = useStyles();

  const { t } = useContext(ConsoleContext);

  const [openDialog, setOpenDialog] = useState(open);

  const handleCloseDialog = () => {
    onClose();
  };

  const fields = [
    { id: 'billableHolidayHours', label: 'Billable By Company Dayoff Hours' },
    { id: 'nonBillableHolidayHours', label: 'Non Billable By Company Dayoff Hours' },
    { id: 'overtimeHours', label: 'Overtime Hours' },
    { id: 'overtimeAmount', label: 'Overtime Amount' },
    { id: 'workedHours', label: 'Worked Hours' },
    { id: 'billableHours', label: 'Billable By Company Hours' },
    { id: 'billableAmount', label: 'Billable By Company Amount' },
    { id: 'totalAmount', label: 'Total Amount' },
  ];

  const [fieldValues, setFieldValues] = useState([]);

  useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  useEffect(() => {
    if (!record.previousRecordDebt) {
      return;
    }

    const {
      currency,
    } = record;

    const {
      workedHours,
      billableHolidayHours,
      nonBillableHolidayHours,
      billableHours,
      overtimeHours,
      overtimeAmount,
      billableAmount,
      totalAmount,
    } = record.previousRecordDiff;

    setFieldValues({
      workedHours: formatHours(workedHours),
      billableHolidayHours: formatHours(billableHolidayHours),
      nonBillableHolidayHours: formatHours(nonBillableHolidayHours),
      billableHours: formatHours(billableHours),
      overtimeHours: formatHours(overtimeHours),
      overtimeAmount: formatMoney(overtimeAmount),
      billableAmount: formatMoney(billableAmount, currency),
      totalAmount: formatMoney(totalAmount, currency),
    });
  }, [record]);

  const formatHours = (hours) => `${formatNumber(hours)} ${t('hours')}`;

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={openDialog}
      onClose={handleCloseDialog}
    >
      <DialogTitle>{t('Previous Payment Debt Details')}</DialogTitle>
      <DialogContent>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <TableContainer>
              <Table>
                <TableBody>
                  { fields.map((field) => (
                    <TableRow key={field.id}>
                      <TableCell><strong>{ t(field.label) }</strong></TableCell>
                      <TableCell>{ fieldValues[field.id] }</TableCell>
                    </TableRow>
                  )) }
                </TableBody>
              </Table>
            </TableContainer>
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
