import React, {
  useState, useEffect, useContext, Fragment, useRef,
} from 'react';

import moment from 'moment';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import {
  getProjects,
  getMonthlySummary,
} from 'services/project';

import {
  formatUserName,
} from 'services/user';

// core components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import UpdateIcon from '@material-ui/icons/Update';

import MonthPicker from 'components/MonthPicker/MonthPicker';
import { ConsoleContext } from 'services/context';
import { formatNumber } from 'services/finance';
import LogsSync from './components/LogsSync';
import ProjectFilters from './components/ProjectFilters';
import BillableByCompanyDialog from './components/BillableByCompanyDialog';
import ProjectLogsDialog from '../Project/components/ProjectLogsDialog';

const useStyles = makeStyles(() => ({
  cellLastRow: {
    borderBottom: '1px solid black!important',
  },
  cellLastColumn: {
    borderRight: '1px solid black!important',
  },
  timesheetTable: {
    '& th, & td': {
      border: '1px solid #d4d5d5',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      padding: '6px 0!important',
      overflow: 'hidden',
    },
    '& table': {
      tableLayout: 'fixed',
    },
  },
  timesheetToday: {
    backgroundColor: '#11b5c9',
    color: '#fff',
  },
  stickyHeader: {
    position: 'relative',
    backgroundColor: '#fff',
    zIndex: 2,
    borderBottom: '2px solid #000',
  },
  connectedJira: {
    fontSize: '14px',
    top: '3px',
    left: '5px',
    position: 'relative',
    color: '#008000',
  },
  dayNonBillableByCompany: {
    textDecoration: 'underline',
    textDecorationColor: 'red',
  },
  syncUpButton: {
    position: 'absolute',
    right: '20px',
    top: '20px',
  },
  grid: {
    marginTop: '15px',
  },
  cellLog: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.6,
    },
  },
}));

export default function ProjectReport() {
  const classes = useStyles();

  const {
    showError, showLoader, getSessionStorage,
  } = useContext(ConsoleContext);

  const [members, setMembers] = useState([]);
  const [summary, setSummary] = useState();
  const [initSummary, setInitSummary] = useState();

  const [date, setDate] = useState({
    year: 0,
    month: 0,
  });

  const [days, setDays] = useState([]);
  const [weeks, setWeeks] = useState([]);

  const [total, setTotal] = useState({});

  const [logsSyncDate, setLogsSyncDate] = useState();

  const [projects, setProjects] = useState([]);

  const [activeProjects, setActiveProjects] = useState([]);

  const [filters, setFilters] = useState({});

  const [openLogsDialog, setOpenLogsDialog] = useState(false);

  const [openBillableByCompanyDialog, setOpenBillableByCompanyDialog] = useState(false);

  const [activeCell, setActiveCell] = useState({});

  const [activeBillableByCompanyCell, setActiveBillableByCompanyCell] = useState({});

  const storage = getSessionStorage('production');

  const bgColors = {
    plan: '#ffe599',
    logBillable: '#efefef',
    logNonBillable: '#d9d9d9',
    logBillableOverdue: '#ff0202',
    logBillableUndertime: '#fcff04',
    overtimeBillable: '#fff',
    overtimeNonBillable: '#f3f3f3',
    fact: '#b6d7a9',
  };

  const tableRef = useRef(null);
  const headerRef = useRef(null);
  const memberHeaderRef = useRef(null);

  const handleShowInfoBillableByCompany = async (userId, projectId, weekIndex) => {
    setActiveBillableByCompanyCell({
      userId, projectId, weekIndex,
    });
    setOpenBillableByCompanyDialog(true);
  };

  const handleCloseReportDialog = () => {
    setOpenBillableByCompanyDialog(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleWindowScroll, true);

    return () => {
      window.removeEventListener('scroll', handleWindowScroll, true);
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const projects = await getProjects();

        setProjects(projects);
      } catch (e) {
        showError(e);
      }
    })();
  }, []);

  useEffect(() => {
    const { year, month } = date;

    if (!year || !projects.length) {
      return;
    }

    const days = [];

    const startDate = moment.utc(`${year} ${month + 1} 1`, 'YYYY M D');
    const endDate = moment(startDate).endOf('month');

    const weeks = [[]];
    let weekIndex = 0;

    for (let d = moment(startDate); d < endDate; d.add(1, 'days')) {
      days.push({
        date: moment(d),
        name: d.format('ddd D'),
      });

      weeks[weekIndex].push({
        date: moment(d),
        name: d.format('ddd D'),
      });

      const endOfMonth = moment(d).endOf('month').isSame(d, 'day');

      if ((d.isoWeekday() === 7) || endOfMonth) {
        weekIndex++;

        if (!endOfMonth) {
          weeks[weekIndex] = [];
        }
      }
    }

    setDays(days);
    setWeeks(weeks);

    showLoader(true);

    (async () => {
      try {
        const summary = await getMonthlySummary(year, month);

        setActiveProjects(prepareActiveProjects(projects, summary));

        setInitSummary(summary);
      } catch (e) {
        showError(e);
      }

      showLoader(false);
    })();
  }, [projects, date, logsSyncDate]);

  useEffect(() => {
    if (initSummary) {
      applyFilters(initSummary, projects, weeks);
    }
  }, [initSummary, filters]);

  useEffect(() => {
    if (!tableRef.current) {
      return;
    }

    const today = moment();

    const currentDate = moment(`${date.year}-${date.month + 1}`, 'YYYY-MM');

    if (!currentDate.isSame(today, 'month')) {
      tableRef.current.scrollLeft = 0;
      return;
    }

    const weekIndex = Object.values(weeks).findIndex((week) => week.find((day) => today.isSame(day.date, 'day')));

    if (weekIndex < 0) {
      return;
    }

    const dayIndex = today.format('D') - today.day();

    const left = dayIndex * 150 + weekIndex * 450 - 1;

    tableRef.current.scrollLeft = left;
  }, [weeks, tableRef.current]);

  const applyFilters = (initSummary, projects, weeks) => {
    let summary = [...initSummary];

    if (filters.projectIds && filters.projectIds.length) {
      summary = summary.filter((item) => filters.projectIds.indexOf(item.projectId) > -1);
    }

    const preparedSummary = prepareSummary(summary);

    setMembers(prepareMembers(projects, preparedSummary));
    setTotal(prepareTotal(preparedSummary, weeks));
    setSummary(preparedSummary);
  };

  const handleMonthChange = async ({ year, month }) => {
    setDate({ year, month });
  };

  const handleCellClick = ({
    projectId, userId, date, billable, overtimeValue,
  }) => {
    setActiveCell({
      projectId, userId, date, billable, overtimeValue,
    });
    setOpenLogsDialog(true);
  };

  const handleCloseLogsDialog = () => {
    setOpenLogsDialog(false);
  };

  const prepareActiveProjects = (projects, initSummary) => {
    const activeProjectIds = {};

    initSummary.forEach((date) => {
      const hasHours = date.planBillableHours
        || date.planNonBillableHours
        || date.logBillableHours
        || date.logNonBillableHours
        || date.overtimeBillableHours
        || date.overtimeNonBillableHours;

      if (hasHours) {
        activeProjectIds[date.projectId] = true;
      }
    });

    return projects.filter((project) => activeProjectIds[project._id]);
  };

  const prepareSummary = (summary) => {
    const groupedSummary = {};

    function prepareDay(item, userId, projectId, date) {
      if (!groupedSummary[userId]) {
        groupedSummary[userId] = {};
      }

      if (!groupedSummary[userId][projectId]) {
        groupedSummary[userId][projectId] = {};
      }

      if (!groupedSummary[userId][projectId][date]) {
        groupedSummary[userId][projectId][date] = {
          date: item.date,
          planBillable: 0,
          planNonBillable: 0,
          factBillable: 0,
          factNonBillable: 0,
          overtimeBillableX10: 0,
          overtimeBillableX15: 0,
          overtimeNonBillableX10: 0,
          overtimeNonBillableX15: 0,
          billableByCompany: 0,
        };
      }

      const day = groupedSummary[userId][projectId][date];

      if (item.logOverdue) {
        day.logOverdue = true;
      }

      if (item.holiday && (userId !== 'total')) {
        day.holiday = item.holiday;
      }

      day.planBillable += item.planBillableHours;
      day.planNonBillable += item.planNonBillableHours;
      day.factBillable += item.logBillableHours;
      day.factNonBillable += item.logNonBillableHours;

      if (item.overtimeBillableHours) {
        if (item.overtimeBillableRateByCompany === 1) {
          day.overtimeBillableX10 += item.overtimeBillableHours;
        } else {
          day.overtimeBillableX15 += item.overtimeBillableHours;
        }
      }

      if (item.overtimeNonBillableHours) {
        if (item.overtimeNonBillableRateByCompany === 1) {
          day.overtimeNonBillableX10 += item.overtimeNonBillableHours;
        } else {
          day.overtimeNonBillableX15 += item.overtimeNonBillableHours;
        }
      }

      day.partTime = item.partTime;
      day.isBillableByCompany = item.billableByCompany;
      day.potential = item.potentialHours;
    }

    summary.forEach((item) => {
      item = {
        planBillableHours: 0,
        planNonBillableHours: 0,
        logBillableHours: 0,
        logNonBillableHours: 0,
        ...item,
        date: moment.utc(item.date),
      };

      const { userId } = item;
      const { projectId } = item;
      const date = item.date.format('D');

      if (item.date.isBefore(moment(), 'day')) {
        if (item.planBillableHours && !item.logBillableHours) {
          item.logBillableHours = item.planBillableHours;
          item.logOverdue = true;
        }
      }

      prepareDay(item, userId, projectId, date);
      prepareDay(item, userId, 'total', date);
      prepareDay(item, 'total', 'total', date);
    });

    Object.keys(groupedSummary).forEach((userId) => {
      if (userId === 'total') {
        return;
      }

      Object.keys(groupedSummary[userId].total).forEach((date) => {
        const day = groupedSummary[userId].total[date];

        const overtime = day.overtimeBillableX10
                      + day.overtimeBillableX15
                      + day.overtimeNonBillableX10
                      + day.overtimeNonBillableX15;

        const billableByCompany = day.isBillableByCompany || Boolean(overtime);

        if (!day.date.isBefore(moment(), 'day') || !billableByCompany) {
          return;
        }

        const factTotalHours = day.factBillable + day.factNonBillable;

        let hours = 0;

        if (day.partTime) {
          hours += factTotalHours;
        } else if (day.planBillable
              || day.planNonBillable
              || day.factBillable
              || day.factNonBillable
              || day.overtimeBillableX10
              || day.overtimeBillableX15
              || day.overtimeNonBillableX10
              || day.overtimeNonBillableX15
        ) {
          hours += day.isBillableByCompany ? day.potential + overtime : overtime;
        }

        if (!hours) {
          return;
        }

        day.billableByCompany = hours;

        const dayTotal = groupedSummary.total.total[date];

        dayTotal.billableByCompany += day.billableByCompany;
      });
    });

    return groupedSummary;
  };

  const prepareMembers = (projects, summary) => {
    const groupedMembers = {};

    projects.forEach((project) => {
      const projectId = project._id;

      project.members.forEach((member) => {
        const userId = member.userId._id;

        if (!groupedMembers[userId]) {
          groupedMembers[userId] = {
            ...member.userId,
            projects: [],
          };
        }

        // check if any record in summary
        let hasHours = false;

        if (summary[userId] && summary[userId][projectId]) {
          const activeDate = Object.values(summary[userId][projectId]).find((date) => date.planBillable
              || date.planNonBillable
              || date.factBillable
              || date.factNonBillable
              || date.overtimeBillableX10
              || date.overtimeBillableX15
              || date.overtimeNonBillableX10
              || date.overtimeNonBillableX15);

          hasHours = Boolean(activeDate);
        }

        if (hasHours) {
          groupedMembers[userId].projects.push(project);
        }
      });
    });

    return Object
      .values(groupedMembers)
      .filter((member) => member.projects.length > 0)
      .sort((a, b) => (a.firstName >= b.firstName ? 1 : -1));
  };

  const prepareTotal = (summary, weeks) => {
    const total = {};

    const dayWeekMap = {};

    function prepareWeek(summaryDay, userId, projectId, weekIndex) {
      if (!total[userId][projectId][weekIndex]) {
        total[userId][projectId][weekIndex] = {
          planBillable: 0,
          planNonBillable: 0,
          factBillable: 0,
          factNonBillable: 0,
          overtimeBillableX10: 0,
          overtimeBillableX15: 0,
          overtimeNonBillableX10: 0,
          overtimeNonBillableX15: 0,
          billableByCompany: 0,
        };
      }

      const totalItem = total[userId][projectId][weekIndex];

      totalItem.planBillable += summaryDay.planBillable;
      totalItem.planNonBillable += summaryDay.planNonBillable;
      totalItem.factBillable += summaryDay.factBillable;
      totalItem.factNonBillable += summaryDay.factNonBillable;
      totalItem.overtimeBillableX10 += summaryDay.overtimeBillableX10;
      totalItem.overtimeBillableX15 += summaryDay.overtimeBillableX15;
      totalItem.overtimeNonBillableX10 += summaryDay.overtimeNonBillableX10;
      totalItem.overtimeNonBillableX15 += summaryDay.overtimeNonBillableX15;
      totalItem.billableByCompany += summaryDay.billableByCompany;
    }

    weeks.forEach((days, index) => {
      days.forEach((day) => {
        dayWeekMap[day.date.format('D')] = index;
      });
    });

    Object.keys(summary).forEach((userId) => {
      if (!total[userId]) {
        total[userId] = {};
      }

      Object.keys(summary[userId]).forEach((projectId) => {
        if (!total[userId][projectId]) {
          total[userId][projectId] = {};
        }

        Object.values(summary[userId][projectId]).forEach((summaryDay) => {
          const weekIndex = dayWeekMap[moment(summaryDay.date).format('D')];

          prepareWeek(summaryDay, userId, projectId, weekIndex);
        });

        Object.values(total[userId][projectId]).forEach((totalWeek) => {
          prepareWeek(totalWeek, userId, projectId, 'total');
        });
      });
    });

    return total;
  };

  const renderTimeCell = (userId, projectId, date, type, billable, lastRow) => {
    const day = date.format('D');

    if (!summary[userId] || !summary[userId][projectId] || !summary[userId][projectId][day]) {
      return (
        <TableCell>-</TableCell>
      );
    }

    const summaryDay = summary[userId][projectId][day];

    const { holiday } = summaryDay;
    const isTotal = projectId === 'total';

    let value;
    let bgColor;
    let handleClick = false;
    let className = (isTotal || lastRow) ? classes.cellLastRow : '';
    let overtimeValue;

    switch (type) {
      case 'plan': {
        value = summaryDay.planBillable || 0;

        if ((type === 'plan') && summaryDay.planNonBillable) {
          value += `/${summaryDay.planNonBillable}`;
        }

        if (holiday && !value) {
          value = '';
        } else {
          value = isTotal ? (<strong>{value}</strong>) : value;
        }

        bgColor = bgColors.plan;
        break;
      }

      case 'log': {
        const field = billable ? 'factBillable' : 'factNonBillable';

        value = summaryDay[field] || 0;

        overtimeValue = billable
          ? (summaryDay.overtimeBillableX10 || summaryDay.overtimeBillableX15)
          : (summaryDay.overtimeNonBillableX10 || summaryDay.overtimeNonBillableX15);

        if (isTotal) {
          overtimeValue = 0;
        }

        if (holiday && !value && !overtimeValue) {
          value = '';
        }

        bgColor = billable ? bgColors.logBillable : bgColors.logNonBillable;

        if (value || overtimeValue) {
          handleClick = !isTotal;

          if (billable && value < summaryDay.planBillable) {
            bgColor = bgColors.logBillableUndertime;
          }

          value = (
            <>
              {formatNumber(value)}
              {overtimeValue ? (
                <span style={{ color: 'red' }}>
                  (
                  {overtimeValue}
                  )
                </span>
              ) : null}
            </>
          );

          if (billable && summaryDay.logOverdue && !isTotal) {
            bgColor = bgColors.logBillableOverdue;
          } else if (!isTotal && !summaryDay.isBillableByCompany && !overtimeValue) {
            value = (<span title="Non billable by company" className={classes.dayNonBillableByCompany}>{value}</span>);
          }
        } else if (billable && summaryDay.planBillable && date.isBefore(moment(), 'day')) {
          bgColor = bgColors.logBillableOverdue;
        }

        if (isTotal) {
          value = (<strong>{value}</strong>);
        }

        if (bgColor === bgColors.logBillableOverdue) {
          handleClick = false;
        }
        break;
      }

      default:
        break;
    }

    if (handleClick) {
      className += ` ${classes.cellLog}`;
    }

    return (
      <TableCell
        className={className}
        style={{ backgroundColor: bgColor }}
        onClick={() => {
          if (handleClick) {
            handleCellClick({
              userId, projectId, date, billable, overtimeValue,
            });
          }
        }}
      >
        {value}
      </TableCell>
    );
  };

  const renderTotal = (userId, projectId, weekIndex, lastRow) => {
    const isWeekTotal = projectId === 'total';
    let className = (isWeekTotal || lastRow) ? classes.cellLastRow : '';

    if (!total || !total[userId] || !total[userId][projectId] || !total[userId][projectId][weekIndex]) {
      return (<TableCell colSpan={9} className={className}>-</TableCell>);
    }

    const fields = [
      { bgColor: 'plan', name: 'planBillable' },
      { bgColor: 'plan', name: 'planNonBillable' },
      { bgColor: 'fact', name: 'factBillable' },
      { bgColor: 'fact', name: 'factNonBillable' },
      { bgColor: 'fact', name: 'billableByCompany' },
      { bgColor: 'overtimeBillable', name: 'overtimeBillableX15' },
      { bgColor: 'overtimeBillable', name: 'overtimeBillableX10' },
      { bgColor: 'overtimeNonBillable', name: 'overtimeNonBillableX15' },
      { bgColor: 'overtimeNonBillable', name: 'overtimeNonBillableX10' },
    ];

    function renderField(userId, projectId, weekIndex, fieldName) {
      let value = formatNumber(total[userId][projectId][weekIndex][fieldName]);

      if (fieldName === 'billableByCompany' && projectId !== 'total') {
        value = value || '-';
      }

      return isWeekTotal ? (<strong>{value}</strong>) : value;
    }

    const RenderCell = ({ field, index }) => {
      let onClick = () => {};

      if (index === fields.length - 1) {
        className += ` ${classes.cellLastColumn}`;
      }

      if (field.name === 'billableByCompany' && userId !== 'total' && formatNumber(total[userId][projectId][weekIndex].billableByCompany) > 0) {
        onClick = () => handleShowInfoBillableByCompany(userId, projectId, weekIndex);
        className += ` ${classes.cellLog}`;
      }

      return (
        <TableCell
          className={className}
          style={{ backgroundColor: bgColors[field.bgColor] }}
          onClick={onClick}
        >
          {renderField(userId, projectId, weekIndex, field.name)}
        </TableCell>
      );
    };

    return (
      <>
        { fields.map((field, index) => (
          <RenderCell field={field} index={index} key={field.name} />
        ))}
      </>
    );
  };

  const renderCompletedPercentage = (weekIndex) => {
    if (!total || !total.total || !total.total.total || !total.total.total[weekIndex]) {
      return '0%';
    }

    const item = total.total.total[weekIndex];

    const value = item.planBillable ? Math.round((item.factBillable / item.planBillable) * 1000) / 10 : 100;

    return (
      <>
        <strong>
          (
          {value}
          %)
        </strong>
      </>
    );
  };

  const formatMemberName = ({ userId }) => {
    const user = members.find((user) => user._id === userId);

    return formatUserName(user);
  };

  const calcTableWidth = () => 150 * days.length + 450 * weeks.length + 450;

  const handleWindowScroll = () => {
    if (!tableRef.current || !headerRef.current || !memberHeaderRef.current) {
      return;
    }

    let { top } = tableRef.current.getBoundingClientRect();

    if (top <= 0) {
      top *= -1;
    } else {
      top = 0;
    }

    headerRef.current.style.top = `${top}px`;
    memberHeaderRef.current.style.top = `${top}px`;
  };

  const checkCurrentDay = (day) => moment(day.date).isSame(moment(), 'day');

  const handleLogsSync = (date) => {
    setLogsSyncDate(date);
  };

  const handleFiltersChange = (filters) => {
    setFilters(filters);
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={2}>
                  <MonthPicker
                    storage={storage}
                    onChange={handleMonthChange}
                  />

                  <div className={classes.syncUpButton}>
                    <LogsSync year={date.year} month={date.month} onSync={handleLogsSync} />
                  </div>
                </GridItem>
              </GridContainer>

              <GridContainer className={classes.grid}>
                <GridItem xs={12} sm={12} md={12}>
                  <ProjectFilters
                    storage={storage}
                    activeProjects={activeProjects}
                    onChange={handleFiltersChange}
                  />
                </GridItem>
              </GridContainer>

              { summary ? (
                <GridContainer className={classes.grid}>
                  <GridItem xs={12} sm={12} md={3}>
                    <TableContainer>
                      <Table size="small" ref={memberHeaderRef} className={classes.stickyHeader}>
                        <TableBody>
                          <TableRow>
                            <TableCell style={{ borderColor: '#fff' }}>&nbsp;</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ borderColor: '#fff' }}>&nbsp;</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>&nbsp;</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      <Table size="small">
                        <TableBody>
                          <TableRow>
                            <TableCell><strong>Members Total</strong></TableCell>
                          </TableRow>
                          { members.map((member) => (
                            <Fragment key={member._id}>
                              <TableRow>
                                <TableCell><strong>{formatMemberName({ userId: member._id })}</strong></TableCell>
                              </TableRow>

                              { member.projects.map((project) => (
                                <TableRow key={project._id}>
                                  <TableCell>
                                    &nbsp;
                                    {project.name}

                                    { project.jiraId ? (
                                      <span title="JIRA Connected">
                                        <PlaylistAddCheckIcon className={classes.connectedJira} />
                                      </span>
                                    ) : null }

                                    { project.autoLogBillablePlan ? (
                                      <span title="Auto Log Billable Plan">
                                        <UpdateIcon className={classes.connectedJira} />
                                      </span>
                                    ) : null }
                                  </TableCell>
                                </TableRow>
                              ))}
                            </Fragment>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={9}>
                    <TableContainer className={classes.timesheetTable} ref={tableRef}>
                      <Table
                        size="small"
                        style={{ width: `${calcTableWidth()}px` }}
                        ref={headerRef}
                        className={classes.stickyHeader}
                      >
                        <TableBody>
                          <TableRow>
                            { weeks.map((days, index) => (
                              <Fragment key={index}>
                                <TableCell colSpan={days.length * 3}>
                                  Week
                                  {index + 1}
                                </TableCell>
                                <TableCell className={classes.cellLastColumn} colSpan={9}>
                                  Week Summary
                                  {' '}
                                  {renderCompletedPercentage(index)}
                                </TableCell>
                              </Fragment>
                            ))}

                            <TableCell className={classes.cellLastColumn} colSpan={9}>
                              Month Summary
                              {' '}
                              {renderCompletedPercentage('total')}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            { weeks.map((days, index) => (
                              <Fragment key={index}>
                                {days.map((day) => (
                                  <TableCell
                                    key={day.name}
                                    colSpan={3}
                                    width="150px"
                                    className={checkCurrentDay(day) ? classes.timesheetToday : null}
                                  >
                                    {day.name}
                                  </TableCell>
                                ))}

                                <TableCell colSpan={2} style={{ backgroundColor: bgColors.plan }}>Plan</TableCell>
                                <TableCell colSpan={3} style={{ backgroundColor: bgColors.fact }}>Fact</TableCell>
                                <TableCell
                                  colSpan={2}
                                  title="Over Billable By Client"
                                  style={{ backgroundColor: bgColors.overtimeBillable }}
                                >
                                  Over B
                                </TableCell>
                                <TableCell
                                  className={classes.cellLastColumn}
                                  colSpan={2}
                                  title="Over Non Billable By Client"
                                  style={{ backgroundColor: bgColors.overtimeNonBillable }}
                                >
                                  Over NB
                                </TableCell>
                              </Fragment>
                            ))}

                            <TableCell colSpan={2} style={{ backgroundColor: bgColors.plan }}>Plan</TableCell>
                            <TableCell colSpan={3} style={{ backgroundColor: bgColors.fact }}>Fact</TableCell>
                            <TableCell
                              colSpan={2}
                              title="Over Billable By Client"
                              style={{ backgroundColor: bgColors.overtimeBillable }}
                            >
                              Over B
                            </TableCell>
                            <TableCell
                              className={classes.cellLastColumn}
                              colSpan={2}
                              title="Over Non Billable By Client"
                              style={{ backgroundColor: bgColors.overtimeNonBillable }}
                            >
                              Over NB
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            { weeks.map((days, index) => (
                              <Fragment key={index}>
                                {days.map((day) => (
                                  <Fragment key={day.name}>
                                    <TableCell
                                      style={{ backgroundColor: bgColors.plan }}
                                      title="Billable/Non Billable Plan"
                                    >
                                      P
                                    </TableCell>
                                    <TableCell
                                      style={{ backgroundColor: bgColors.logBillable }}
                                      title="Billable By Client"
                                    >
                                      B
                                    </TableCell>
                                    <TableCell
                                      style={{ backgroundColor: bgColors.logNonBillable }}
                                      title="Non Billable By Client"
                                    >
                                      NB
                                    </TableCell>
                                  </Fragment>
                                ))}

                                <TableCell
                                  style={{ backgroundColor: bgColors.plan }}
                                  title="Billable By Client"
                                >
                                  B
                                </TableCell>
                                <TableCell
                                  style={{ backgroundColor: bgColors.plan }}
                                  title="Non Billable By Client"
                                >
                                  NB
                                </TableCell>

                                <TableCell style={{ backgroundColor: bgColors.fact }} title="Billable By Client">B</TableCell>
                                <TableCell style={{ backgroundColor: bgColors.fact }} title="Non Billable By Client">NB</TableCell>
                                <TableCell style={{ backgroundColor: bgColors.fact }} title="Billable By Company">BC</TableCell>

                                <TableCell style={{ backgroundColor: bgColors.overtimeBillable }} title="Rate by Company">x1.5</TableCell>
                                <TableCell style={{ backgroundColor: bgColors.overtimeBillable }} title="Rate by Company">x1</TableCell>

                                <TableCell style={{ backgroundColor: bgColors.overtimeNonBillable }} title="Rate by Company">x1.5</TableCell>
                                <TableCell
                                  className={classes.cellLastColumn}
                                  style={{ backgroundColor: bgColors.overtimeNonBillable }}
                                  title="Rate by Company"
                                >
                                  x1
                                </TableCell>
                              </Fragment>
                            ))}

                            <TableCell style={{ backgroundColor: bgColors.plan }} title="Billable By Client">B</TableCell>
                            <TableCell style={{ backgroundColor: bgColors.plan }} title="Non Billable By Client">NB</TableCell>

                            <TableCell style={{ backgroundColor: bgColors.fact }} title="Billable By Client">B</TableCell>
                            <TableCell style={{ backgroundColor: bgColors.fact }} title="Non Billable By Client">NB</TableCell>
                            <TableCell style={{ backgroundColor: bgColors.fact }} title="Billable By Company">BC</TableCell>

                            <TableCell style={{ backgroundColor: bgColors.overtimeBillable }} title="Rate by Company">x1.5</TableCell>
                            <TableCell style={{ backgroundColor: bgColors.overtimeBillable }} title="Rate by Company">x1</TableCell>

                            <TableCell style={{ backgroundColor: bgColors.overtimeNonBillable }} title="Rate by Company">x1.5</TableCell>
                            <TableCell
                              className={classes.cellLastColumn}
                              style={{ backgroundColor: bgColors.overtimeNonBillable }}
                              title="Rate by Company"
                            >
                              x1
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      <Table size="small" style={{ width: `${calcTableWidth()}px` }} ref={tableRef}>
                        <TableBody>
                          <TableRow>
                            { weeks.map((days, index) => (
                              <Fragment key={index}>
                                {days.map((day) => (
                                  <Fragment key={day.name}>
                                    {renderTimeCell('total', 'total', day.date, 'plan')}
                                    {renderTimeCell('total', 'total', day.date, 'log', true)}
                                    {renderTimeCell('total', 'total', day.date, 'log', false)}
                                  </Fragment>
                                ))}

                                { renderTotal('total', 'total', index) }
                              </Fragment>
                            ))}

                            { renderTotal('total', 'total', 'total') }
                          </TableRow>

                          { members.map((member) => (
                            <Fragment key={member._id}>
                              <TableRow>
                                { weeks.map((days, index) => (
                                  <Fragment key={index}>
                                    {days.map((day) => (
                                      <Fragment key={day.name}>
                                        {renderTimeCell(member._id, 'total', day.date, 'plan')}
                                        {renderTimeCell(member._id, 'total', day.date, 'log', true)}
                                        {renderTimeCell(member._id, 'total', day.date, 'log', false)}
                                      </Fragment>
                                    ))}

                                    { renderTotal(member._id, 'total', index) }
                                  </Fragment>
                                ))}

                                { renderTotal(member._id, 'total', 'total') }
                              </TableRow>

                              { member.projects.map((project, projectIndex) => (
                                <TableRow key={project._id}>
                                  { weeks.map((days, index) => (
                                    <Fragment key={index}>
                                      {days.map((day) => (
                                        <Fragment key={day.name}>
                                          {renderTimeCell(
                                            member._id,
                                            project._id,
                                            day.date,
                                            'plan',
                                            true,
                                            projectIndex === member.projects.length - 1,
                                          )}
                                          {renderTimeCell(
                                            member._id,
                                            project._id,
                                            day.date,
                                            'log',
                                            true,
                                            projectIndex === member.projects.length - 1,
                                          )}
                                          {renderTimeCell(
                                            member._id,
                                            project._id,
                                            day.date,
                                            'log',
                                            false,
                                            projectIndex === member.projects.length - 1,
                                          )}
                                        </Fragment>
                                      ))}

                                      { renderTotal(member._id, project._id, index, projectIndex === member.projects.length - 1) }
                                    </Fragment>
                                  ))}

                                  { renderTotal(member._id, project._id, 'total', projectIndex === member.projects.length - 1) }
                                </TableRow>
                              ))}
                            </Fragment>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </GridItem>
                </GridContainer>
              ) : null }
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      {openBillableByCompanyDialog
          && (
          <BillableByCompanyDialog
            open={openBillableByCompanyDialog}
            formatMemberName={formatMemberName}
            {...activeBillableByCompanyCell}
            summary={summary}
            weeks={weeks}
            onClose={handleCloseReportDialog}
          />
          )}

      <ProjectLogsDialog
        open={openLogsDialog}
        formatMemberName={formatMemberName}
        {...activeCell}
        onClose={handleCloseLogsDialog}
      />
    </>
  );
}
