import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import Alert from '@material-ui/lab/Alert';
import ErrorIcon from '@material-ui/icons/Error';

import PagedTable from 'components/PagedTable/PagedTable';
import MonthPicker from 'components/MonthPicker/MonthPicker';

import {
  formatDate,
} from 'services/date';

import {
  formatMoney,
  formatNumber,
  getDefaultCurrency,
} from 'services/finance';

import {
  createPayroll,
  getPaymentCurrency,
  getPayrollPaymentMethods,
  getPayrollPaymentTargets,
} from 'services/payroll';

import {
  formatUserName,
} from 'services/user';

import { ConsoleContext } from 'services/context';
import PayrollFilters from './components/PayrollFilters';
import PaymentMethodSummary from './components/PaymentMethodSummary';
import PayrollRecordStatus from './components/PayrollRecordStatus';
import PayrollExchangeRates from './components/PayrollExchangeRates';

const useStyles = makeStyles(() => ({
  grid: {
    marginTop: '15px',
  },
  userName: {
    '& .MuiSvgIcon-root': {
      marginLeft: '5px',
      position: 'relative',
      top: '5px',
    },
  },
}));

export default function Payroll() {
  const classes = useStyles();

  const {
    showError, showLoader, t, getSessionStorage,
  } = useContext(ConsoleContext);

  const [date, setDate] = useState({
    year: 0,
    month: 0,
  });

  const [rows, setRows] = useState([]);

  const [payroll, setPayroll] = useState();

  const [paymentMethods, setPaymentMethods] = useState([]);

  const [paymentTargets, setPaymentTargets] = useState([]);

  const [filters, setFilters] = useState({});

  const storage = getSessionStorage('finance');
  const payrollPagination = getSessionStorage('payrollPagination');

  const baseCurrency = getDefaultCurrency();
  const paymentCurrency = getPaymentCurrency();

  const columns = [
    { id: 'userName', label: 'Partner', width: '100px' },
    { id: 'status', label: 'Status', width: '100px' },
    { id: 'paymentTo', label: 'Payment To' },
    { id: 'monthlyRate', label: `${t('Monthly Rate')}, ${baseCurrency}` },
    { id: 'hourlyRate', label: `${t('Hourly Rate')}, ${baseCurrency}` },
    { id: 'billableHours', label: 'Billable Hours' },
    { id: 'totalAmount', label: `${t('Total Amount')}, ${baseCurrency}` },
    { id: 'paymentTotalAmount', label: `${t('Total Amount')}, ${paymentCurrency}` },
    { id: 'paymentTaxCompensation', label: `${t('Tax Compensation')}, ${paymentCurrency}` },
    { id: 'paymentFinalAmount', label: `${t('Payment Amount')}, ${paymentCurrency}` },
  ];

  useEffect(() => {
    (async () => {
      const paymentMethods = await getPayrollPaymentMethods();
      const paymentTargets = await getPayrollPaymentTargets();

      setPaymentMethods(paymentMethods);
      setPaymentTargets(paymentTargets);
    })();
  }, []);

  useEffect(() => {
    const { year, month } = date;

    if (!year) {
      return;
    }

    (async () => {
      showLoader(true);

      try {
        const payroll = await createPayroll(year, month);
        setPayroll(payroll);
      } catch (error) {
        showError(error);
      }

      showLoader(false);
    })();
  }, [date]);

  useEffect(() => {
    if (payroll) {
      setRows(prepareRows(payroll, filters, paymentTargets));
    }
  }, [payroll, filters, paymentTargets]);

  const prepareRows = (payroll, filters, paymentTargets) => {
    if (!payroll) {
      return [];
    }
    const { paymentFinalAmount, ...filtersCopy } = { ...filters };
    const rows = payroll.records
      .filter((record) => paymentFinalAmount || record.paymentFinalAmount > 0 || record.noContractDates.length !== 0)
      .filter((record) => {
        let match = true;

        Object.keys(filtersCopy).forEach((key) => {
          if (record[key] !== filtersCopy[key]) {
            match = false;
          }
        });

        return match;
      })
      .map((record) => {
        const {
          _id,
          status,
          paymentTargetId,
          monthlyRate,
          hourlyRate,
          billableHours,
          totalAmount,
          paymentTotalAmount,
          paymentTaxCompensation,
          paymentFinalAmount,
        } = record;

        return {
          id: _id,
          user: record.user,
          userName: renderUserName(record),
          status: (<PayrollRecordStatus status={status} />),
          paymentTo: formatPaymentTarget(paymentTargetId, paymentTargets),
          monthlyRate: formatMoney(monthlyRate, false),
          hourlyRate: formatMoney(hourlyRate, false),
          billableHours: formatNumber(billableHours),
          totalAmount: formatMoney(totalAmount, false),
          paymentTotalAmount: formatMoney(paymentTotalAmount, false),
          paymentTaxCompensation: formatMoney(paymentTaxCompensation, false),
          paymentFinalAmount: formatMoney(paymentFinalAmount, false),
        };
      });

    const payrollRows = rows.map(({ id, user }) => ({ id, userName: formatUserName(user) }));

    storage.setValue('payrollRows', payrollRows);

    return rows;
  };

  const renderUserName = (record) => {
    const {
      _id,
      user,
      noContractDates,
    } = record;

    return (
      <div className={classes.userName}>
        <Link to={`payroll/${_id}`}>{formatUserName(user)}</Link>

        { noContractDates && noContractDates.length ? (
          <ErrorIcon color="secondary" titleAccess="Missing Contracts" />
        ) : null }
      </div>
    );
  };

  const formatPaymentTarget = (targetId, paymentTargets) => {
    const target = paymentTargets.find((item) => item._id === targetId);

    if (!target) {
      return '-';
    }

    return target.name;
  };

  const handleMonthChange = async ({ year, month }) => {
    setDate({ year, month });
  };

  const handleAddPaymentMethod = (method) => {
    setPaymentMethods([...paymentMethods, method]);
  };

  const handleChangePaymentMethod = (changedMethod) => {
    const methods = [...paymentMethods];

    const index = methods.findIndex((method) => method._id === changedMethod._id);

    methods[index] = changedMethod;

    setPaymentMethods(methods);
  };

  const handleDeletePaymentMethod = (deletedMethod) => {
    const methods = [...paymentMethods];

    const index = methods.findIndex((method) => method._id === deletedMethod._id);

    methods.splice(index, 1);

    setPaymentMethods(methods);
  };

  const handleFiltersChange = (filters) => {
    setFilters(filters);
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={2}>
                  <MonthPicker
                    storage={storage}
                    onChange={handleMonthChange}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4} />

                { payroll ? (
                  <>
                    <GridItem xs={12} sm={12} md={3}>
                      <Alert icon={false} color="info">
                        <strong>{t('Payment Date')}</strong>
                        :
                        { formatDate(payroll.paymentDate) }
                      </Alert>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <Alert icon={false} color="info">
                        <strong>{t('Working Hours')}</strong>
                        :
                        { payroll.hoursInMonth }
                      </Alert>
                    </GridItem>
                  </>
                ) : null }
              </GridContainer>

              <GridContainer className={classes.grid}>
                <GridItem xs={12} sm={12} md={12}>
                  <PayrollFilters
                    storage={storage}
                    paymentMethods={paymentMethods}
                    paymentTargets={paymentTargets}
                    onChange={handleFiltersChange}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      { payroll ? (
        <>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Alert icon={false} color="info">
                <PayrollExchangeRates
                  paymentCurrency={paymentCurrency}
                  currencyExchangeDate={payroll.currencyExchangeDate}
                  currencyExchangeRates={payroll.currencyExchangeRates}
                  inline
                />
              </Alert>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <PagedTable
                        containerProps={{ style: { maxHeight: '600px' } }}
                        tableProps={{ stickyHeader: true, style: { tableLayout: 'fixed' } }}
                        columns={columns}
                        storage={payrollPagination}
                        rows={rows}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <PaymentMethodSummary
                payroll={payroll}
                paymentMethods={paymentMethods}
                onAdd={handleAddPaymentMethod}
                onChange={handleChangePaymentMethod}
                onDelete={handleDeletePaymentMethod}
              />
            </GridItem>
          </GridContainer>
        </>
      ) : null }
    </>
  );
}
