import React, { useState, useEffect, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { ConsoleContext } from 'services/context';

import {
  updatePayrollRecord,
  bonusOptions,
  createPayrollPaymentTarget,
  formatPayrollPaymentMethodOptions,
  formatPayrollPaymentTargetOptions,
} from 'services/payroll';

import {
  getUserContractCurrencies,
} from 'services/userContract';

import {
  formatInputDate,
  parseInputDate,
} from 'services/date';

import {
  parseInputNumber,
} from 'services/finance';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from 'components/CustomButtons/Button';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

import CustomSelect from 'components/CustomSelect/CustomSelect';

const useStyles = makeStyles(() => ({
  actions: {
    marginTop: '15px',
    textAlign: 'center',
  },
  grid: {
    marginTop: '15px',
  },
  formControl: {
    minWidth: '100%',
  },
}));

export default function PayrollRecordEdit({
  record,
  paymentMethods,
  paymentTargets,
  onSave,
  onCancel,
  onAddPaymentTarget,
}) {
  const classes = useStyles();

  const {
    showError, showSuccess, showLoader, t,
  } = useContext(ConsoleContext);

  const [changedRecord, setChangedRecord] = useState();

  const [openPaymentTargetDialog, setOpenPaymentTargetDialog] = useState(false);

  const [activePaymentTarget, setActivePaymentTarget] = useState({});

  const currencies = getUserContractCurrencies();

  useEffect(() => {
    const {
      prepayments = [],
      bonuses = [],
      paymentMethodId,
      paymentTargetId,
    } = record;

    prepayments.forEach((prepayment) => {
      prepayment.date = formatInputDate(prepayment.date);
    });

    setChangedRecord({
      ...record,
      prepayments,
      bonuses,
      paymentMethodId: paymentMethodId || '',
      paymentTargetId: paymentTargetId || '',
    });
  }, [record]);

  const handleRecordChange = (field, value) => {
    const data = { ...changedRecord };
    data[field] = value;

    setChangedRecord(data);
  };

  const handleEditCancel = () => {
    onCancel();
  };

  const handleSubmit = async () => {
    const data = { ...changedRecord };

    data.holidayCompensationHours = parseInputNumber(data.holidayCompensationHours);
    data.paymentTaxCompensation = parseInputNumber(data.paymentTaxCompensation);

    if (!data.paymentMethodId) {
      delete data.paymentMethodId;
    }

    if (!data.paymentTargetId) {
      delete data.paymentTargetId;
    }

    const prepayments = [];

    data.prepayments.forEach((prepayment) => {
      const item = { ...prepayment };

      item.amount = item.amount ? parseInputNumber(item.amount) : 0;
      item.date = item.date ? parseInputDate(item.date) : null;

      if (item.amount) {
        prepayments.push(item);
      }
    });

    data.prepayments = prepayments;

    const bonuses = [];

    data.bonuses.forEach((bonus) => {
      const item = { ...bonus };

      item.amount = item.amount ? parseInputNumber(item.amount) : 0;

      if (item.amount) {
        bonuses.push(item);
      }
    });

    data.bonuses = bonuses;

    showLoader(true);

    try {
      const newRecord = await updatePayrollRecord(data);

      showSuccess('Payroll Record updated');

      onSave(newRecord);
    } catch (e) {
      showError(e);
    }

    showLoader(false);
  };

  const handleAddPrepayment = () => {
    const prepayments = [...changedRecord.prepayments];

    prepayments.push({
      currency: record.currency,
      amount: '',
      comment: '',
    });

    setChangedRecord({ ...changedRecord, prepayments });
  };

  const handlePrepaymentChange = (prepayment, field, value) => {
    const prepayments = [...changedRecord.prepayments];

    const data = prepayments.find((item) => item === prepayment);

    data[field] = value;

    setChangedRecord({ ...changedRecord, prepayments });
  };

  const handleDeletePrepayment = (prepayment) => {
    const prepayments = [...changedRecord.prepayments];

    const index = prepayments.findIndex((item) => item === prepayment);

    prepayments.splice(index, 1);

    setChangedRecord({ ...changedRecord, prepayments });
  };

  const handleAddPaymentTarget = () => {
    setActivePaymentTarget({
      name: '',
    });

    setOpenPaymentTargetDialog(true);
  };

  const handlePaymentTargetChange = (field, value) => {
    const data = { ...activePaymentTarget };

    data[field] = value;

    setActivePaymentTarget(data);
  };

  const handleClosePaymentTargetDialog = () => {
    setOpenPaymentTargetDialog(false);
  };

  const handleSubmitPaymentTarget = async () => {
    const data = { ...activePaymentTarget };

    try {
      const newTarget = await createPayrollPaymentTarget(data);

      showSuccess('Payment To added');
      setOpenPaymentTargetDialog(false);
      onAddPaymentTarget(newTarget);

      handleRecordChange('paymentTargetId', newTarget._id);
    } catch (e) {
      showError(e);
    }
  };

  const handleBonusChange = (bonus, amount) => {
    const bonuses = [...changedRecord.bonuses];

    const type = bonus.id;

    const data = bonuses.find((item) => item.type === type);

    if (!data) {
      bonuses.push({
        amount,
        type,
      });
    } else {
      data.amount = amount;
    }

    setChangedRecord({ ...changedRecord, bonuses });
  };

  const getBonusAmount = (record, type) => {
    const data = changedRecord.bonuses.find((item) => item.type === type);

    return data ? data.amount : 0;
  };

  if (!changedRecord) {
    return null;
  }

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell style={{ width: '200px' }}>{t('Payment Method')}</TableCell>
                  <TableCell>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={3}>
                        <CustomSelect
                          label="Payment From"
                          value={changedRecord.paymentMethodId}
                          options={formatPayrollPaymentMethodOptions(paymentMethods)}
                          onChange={(value) => handleRecordChange('paymentMethodId', value)}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3}>
                        <CustomSelect
                          label="Payment To"
                          value={changedRecord.paymentTargetId}
                          options={formatPayrollPaymentTargetOptions(paymentTargets)}
                          onChange={(value) => handleRecordChange('paymentTargetId', value)}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={3}>
                        <Button
                          size="sm"
                          color="primary"
                          style={{ marginTop: '10px' }}
                          onClick={handleAddPaymentTarget}
                        >
                          +
                          {t('Add Payment To')}
                        </Button>
                      </GridItem>

                    </GridContainer>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t('Prepayment')}</TableCell>
                  <TableCell>
                    { changedRecord.prepayments.map((prepayment, index) => (
                      <GridContainer key={index} className={classes.grid}>
                        <GridItem xs={12} sm={12} md={2}>
                          <FormControl className={classes.formControl}>
                            <InputLabel>{t('Currency')}</InputLabel>
                            <Select
                              fullWidth
                              value={prepayment.currency}
                              onChange={(e) => handlePrepaymentChange(prepayment, 'currency', e.target.value)}
                            >

                              { currencies.map((currency) => (
                                <MenuItem key={currency} value={currency}>
                                  { currency }
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                          <TextField
                            fullWidth
                            required
                            type="date"
                            label={t('Date')}
                            value={prepayment.date}
                            onChange={(e) => handlePrepaymentChange(prepayment, 'date', e.target.value)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2}>
                          <TextField
                            fullWidth
                            required
                            label={`${t('Amount')}, ${prepayment.currency}`}
                            value={prepayment.amount}
                            onChange={(e) => handlePrepaymentChange(prepayment, 'amount', e.target.value)}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <TextField
                            fullWidth
                            required
                            label={t('Comment')}
                            value={prepayment.comment}
                            onChange={(e) => handlePrepaymentChange(prepayment, 'comment', e.target.value)}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={1}>
                          <IconButton onClick={() => handleDeletePrepayment(prepayment)}>
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </GridItem>
                      </GridContainer>
                    ))}

                    <GridContainer className={classes.grid}>
                      <GridItem xs={12} sm={12} md={12}>
                        <Button size="sm" color="primary" onClick={handleAddPrepayment}>{t('Add')}</Button>
                      </GridItem>
                    </GridContainer>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t('Bonuses')}</TableCell>
                  <TableCell>
                    { bonusOptions.map((bonus) => (
                      <GridContainer key={bonus.id} className={classes.grid}>
                        <GridItem xs={12} sm={12} md={3} style={{ lineHeight: '48px' }}>
                          { bonus.name }
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                          <TextField
                            fullWidth
                            label={`${t('Amount')}, ${record.currency}`}
                            value={getBonusAmount(changedRecord, bonus.id)}
                            onChange={(e) => handleBonusChange(bonus, e.target.value)}
                          />
                        </GridItem>
                      </GridContainer>
                    )) }
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t('Holiday Compensation Hours')}</TableCell>
                  <TableCell>
                    <TextField
                      value={changedRecord.holidayCompensationHours}
                      onChange={(e) => handleRecordChange('holidayCompensationHours', e.target.value)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {t('Tax Compensation')}
                    ,
                    {' '}
                    { record.paymentCurrency }
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={changedRecord.paymentTaxCompensation}
                      onChange={(e) => handleRecordChange('paymentTaxCompensation', e.target.value)}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </GridItem>
      </GridContainer>
      <GridContainer className={classes.actions}>
        <GridItem xs={12} sm={12} md={8}>
          <Button color="primary" onClick={handleSubmit} style={{ marginRight: '10px' }}>{t('Update')}</Button>
          <Button onClick={handleEditCancel}>{t('Cancel')}</Button>
        </GridItem>
      </GridContainer>

      <Dialog
        maxWidth="xs"
        fullWidth
        open={openPaymentTargetDialog}
        onClose={handleClosePaymentTargetDialog}
      >
        <DialogTitle>{t('Add Payment To')}</DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                fullWidth
                required
                label={t('Name')}
                value={activePaymentTarget.name}
                onChange={(e) => handlePaymentTargetChange('name', e.target.value)}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePaymentTargetDialog}>
            {t('Cancel')}
          </Button>
          <Button onClick={handleSubmitPaymentTarget} color="primary">
            {t('Save')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
