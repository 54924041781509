import moment from 'moment';

import {
  CLIENT_CONTRACT_URL,
  CLIENT_HOLIDAY_CONFIG_URL, CLIENT_HOLIDAY_CONTRACT,
} from '../utils/apiRouting';

import {
  get, post, put, formatQuery, httpDelete,
} from '../utils/http';

export async function getClientContracts(query = {}) {
  return get(CLIENT_CONTRACT_URL + formatQuery(query));
}

export async function createClientContract(contract) {
  return post(CLIENT_CONTRACT_URL, contract);
}

export async function updateClientContract(contract) {
  return put(`${CLIENT_CONTRACT_URL}/${contract._id}`, contract);
}

export async function deleteClientContract(contract) {
  return httpDelete(`${CLIENT_CONTRACT_URL}/${contract._id}`);
}

export function getClientContractPeriod(clientId, contracts) {
  let startAt;
  let endAt;
  let hasEnd = true;

  contracts.forEach((contract) => {
    if (contract.clientId !== clientId) {
      return;
    }

    if (!startAt || moment(contract.startAt).isBefore(startAt)) {
      startAt = contract.startAt;
    }

    if (!contract.endAt) {
      hasEnd = false;
      endAt = null;
    } else if (hasEnd && (!endAt || moment(contract.endAt).isAfter(endAt))) {
      endAt = contract.endAt;
    }
  });

  return { startAt, endAt };
}

export function getClientContractCurrencies() {
  return ['USD', 'EUR', 'CAD', 'GBP', 'UAH'];
}

export async function getClientHolidayConfig() {
  return get(CLIENT_HOLIDAY_CONFIG_URL);
}

export function getPreviousHolidayContract(activeContract, contracts) {
  const currentIndex = contracts.findIndex((contract) => contract._id === activeContract._id);

  if ((currentIndex > 0) && contracts[currentIndex - 1].holidayBillable) {
    return contracts[currentIndex - 1].holidayContract;
  }

  return null;
}

export async function getClientHolidayContractTakenHolidays(holidayContractId) {
  return get(`${CLIENT_HOLIDAY_CONTRACT}/${holidayContractId}/taken-holidays`);
}
