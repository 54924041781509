import {
  TECH_STACK_URL,
} from '../utils/apiRouting';

import {
  get,
} from '../utils/http';

export async function getTechStack() {
  return get(TECH_STACK_URL);
}

export function formatTechStack(itemId, techStack) {
  return techStack.find((item) => item._id === itemId).name;
}

export function formatTechStackList(items, techStack) {
  if (!items.length) {
    return '-';
  }

  const techStackTitles = {};

  techStack.forEach((item) => {
    techStackTitles[item._id] = item.name;
  });

  const titles = items.map((item) => {
    let { experienceYears } = item;

    if (item.experienceYears === -1) {
      experienceYears = '';
    } else if (item.experienceYears > 5) {
      experienceYears = '5+';
    }

    if (experienceYears) {
      techStackTitles[item.id] += `-${experienceYears}`;
    }

    return techStackTitles[item.id];
  });

  return titles.join(', ');
}

export const removeTechStacksDuplicate = (arr) => arr.filter((value, index, self) => index === self.findIndex((t) => (
  t.id === value.id
)));
