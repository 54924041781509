import {
  USER_CONTRACT_URL,
} from '../utils/apiRouting';

import {
  get, post, put, formatQuery, httpDelete,
} from '../utils/http';

export async function getUserContracts(query = {}) {
  return get(USER_CONTRACT_URL + formatQuery(query));
}

export async function createUserContract(contract) {
  return post(USER_CONTRACT_URL, contract);
}

export async function updateUserContract(contract) {
  return put(`${USER_CONTRACT_URL}/${contract._id}`, contract);
}

export async function deleteUserContract(contract) {
  return httpDelete(`${USER_CONTRACT_URL}/${contract._id}`);
}

export function getUserContractCurrencies() {
  return ['USD', 'EUR', 'UAH'];
}
