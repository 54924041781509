import React, { useState, useEffect } from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import {
  getLocales,
  getCurrentLocaleCode,
  setCurrentLocaleCode,
} from 'services/locale';

export default function LocalePicker() {
  const [localeCode, setLocaleCode] = useState('');
  const [locales, setLocales] = useState([]);

  useEffect(() => {
    (async () => {
      const locales = await getLocales();

      const currentLocaleCode = getCurrentLocaleCode();

      setLocales(locales);

      setLocaleCode(currentLocaleCode);
    })();
  }, []);

  const handleChangeLocale = (localeCode) => {
    setLocaleCode(localeCode);
    setCurrentLocaleCode(localeCode);
  };

  return (
    <>
      { localeCode ? (
        <Select
          value={localeCode}
          onChange={(e) => handleChangeLocale(e.target.value)}
        >
          { locales.map((locale) => (
            <MenuItem key={locale._id} value={locale.code}>
              {locale.code}
            </MenuItem>
          ))}
        </Select>
      ) : null }
    </>
  );
}
