import React from 'react';

import { Link } from 'react-router-dom';

import ItStockLogo from './ItStockLogo';

export default function CandidateName({ candidate, link }) {
  const {
    _id,
    name,
    itStockId,
  } = candidate;

  const renderName = () => (
    <>
      { itStockId ? (<ItStockLogo style={{ marginRight: '5px', verticalAlign: 'middle' }} />) : null }
      {name}
    </>
  );
  return (
    <>
      { link ? (
        <Link to={`/sales/candidates/${_id}`} {...link}>
          { renderName() }
        </Link>
      ) : renderName() }
    </>
  );
}
