import React, { useState, useEffect, useContext } from 'react';

import {
  deleteProjectLog,
  deleteProjectOvertime,
  getProjectLogs,
  getProjectOvertime,
  formatLogSource,
} from 'services/project';

import {
  formatDate,
  formatHours,
} from 'services/date';

import { ConsoleContext } from 'services/context';

// core components
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from 'components/CustomButtons/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from 'components/CustomDialog/CustomDialog';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(() => ({
}));

export default function ProjectLogsDialog({
  open,
  projectId,
  userId,
  date,
  billable,
  overtimeValue,
  formatMemberName,
  canEdit,
  onClose,
  onEditLog,
  onAddLog,
  onDeleteLog,
  onAddOvertime,
  onEditOvertime,
  onDeleteOvertime,
}) {
  const classes = useStyles();

  const { showError, showSuccess, showConfirm } = useContext(ConsoleContext);

  const [openLogsDialog, setOpenLogsDialog] = useState(false);
  const [logs, setLogs] = useState([]);
  const [overtime, setOvertime] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!open) {
      setOpenLogsDialog(false);
      return;
    }

    setLoading(true);
    setOpenLogsDialog(true);

    (async () => {
      const logs = await getProjectLogs(projectId, { userId, workedAt: date, billable });

      let overtime = [];
      if (overtimeValue) {
        overtime = await getProjectOvertime(projectId, { userId, date, billableByClient: billable });
      }

      setLogs(logs);
      setOvertime(overtime);
      setLoading(false);
    })();
  }, [open, projectId, userId, date, billable]);

  const handleCloseLogsDialog = () => {
    onClose();
  };

  const handleAddLog = async () => {
    onAddLog({ date, billable, userId });
  };

  const handleEditLog = async (log) => {
    onEditLog(log);
  };

  const handleDeleteLog = async (log) => {
    showConfirm(async () => {
      try {
        await deleteProjectLog(projectId, log);
      } catch (e) {
        showError(e);
        return;
      }

      showSuccess('Log deleted');

      onDeleteLog(log);
    });
  };

  const handleAddOvertime = async () => {
    onAddOvertime();
  };

  const handleEditOvertime = async (item) => {
    onEditOvertime(item);
  };

  const handleDeleteOvertime = (item) => {
    showConfirm(async () => {
      await deleteProjectOvertime(projectId, item);

      showSuccess('Overtime deleted');

      onDeleteOvertime(item);
    });
  };

  const IssueTooltip = withStyles(() => ({
    tooltip: {
      fontSize: 13,
    },
  }))(Tooltip);

  return (
    <>
      <Dialog
        loading={loading}
        maxWidth="sm"
        fullWidth
        open={openLogsDialog}
        onClose={handleCloseLogsDialog}
      >
        <DialogTitle>
          {formatMemberName({ userId })}
          {' '}
          {formatDate(date)}
          {' '}
          { billable ? 'Billable' : 'Non Billable' }
        </DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Hours</TableCell>
                      <TableCell>Source</TableCell>
                      <TableCell>Comment</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { logs.map((log) => (
                      <TableRow key={log._id}>
                        <TableCell>{formatHours(log.timeSpentSeconds)}</TableCell>
                        <TableCell>
                          {formatLogSource(log)}
                          {log.jiraWorkedAtIgnore ? (<span title={`Logged in JIRA on ${formatDate(log.jiraWorkedAt)}`}>*</span>) : ''}
                        </TableCell>
                        <TableCell>
                          {log.jiraIssueKey ? (
                            <>
                              <IssueTooltip title={log.jiraIssueTitle} arrow>
                                <a href={log.jiraIssueLink} target="_blank" rel="noreferrer">{log.jiraIssueKey}</a>
                              </IssueTooltip>
                              {log.comment ? ': ' : null}
                            </>
                          ) : null}
                          {log.comment}
                        </TableCell>
                        <TableCell style={{ whiteSpace: 'nowrap' }}>
                          { canEdit ? (
                            <>
                              { log.jiraLogId ? (
                                <Button size="sm" onClick={() => handleEditLog(log)}>Edit</Button>
                              ) : (
                                <>
                                  <Button size="sm" onClick={() => handleEditLog(log)}>Edit</Button>
                                  <IconButton className={classes.margin} onClick={() => handleDeleteLog(log)}>
                                    <DeleteIcon fontSize="small" />
                                  </IconButton>
                                </>
                              )}
                            </>
                          ) : null }
                        </TableCell>
                      </TableRow>
                    )) }
                  </TableBody>
                </Table>
              </TableContainer>

              { overtime.length ? (
                <>
                  <br />
                  <GridContainer>
                    <GridItem>Overtime</GridItem>
                  </GridContainer>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Hours</TableCell>
                          <TableCell>X By Company</TableCell>
                          { billable ? (<TableCell>X By Client</TableCell>) : null }
                          <TableCell>Comment</TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        { overtime.map((item) => (
                          <TableRow key={item._id}>
                            <TableCell>{formatHours(item.timeSpentSeconds)}</TableCell>
                            <TableCell>{item.rateByCompany}</TableCell>
                            { billable ? (<TableCell>{item.rateByClient}</TableCell>) : null }
                            <TableCell>{item.comment}</TableCell>
                            <TableCell style={{ whiteSpace: 'nowrap' }}>
                              { canEdit ? (
                                <>
                                  <Button size="sm" onClick={() => handleEditOvertime(item)}>Edit</Button>
                                  <IconButton onClick={() => handleDeleteOvertime(item)}>
                                    <DeleteIcon fontSize="small" />
                                  </IconButton>
                                </>
                              ) : null }
                            </TableCell>
                          </TableRow>
                        )) }
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              ) : null }
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          { canEdit ? (
            <>
              <Button onClick={() => handleAddLog()} color="primary">
                Log Time
              </Button>
              { overtime.length ? null : (
                <Button onClick={handleAddOvertime} color="warning">
                  Log Overtime
                </Button>
              )}
            </>
          ) : null }

          <Button onClick={handleCloseLogsDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
