import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import {
  getClients,
  isClientActive,
} from 'services/client';

import {
  getRecentInvoices,
} from 'services/invoice';

import {
  formatDate,
} from 'services/date';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import Button from 'components/CustomButtons/Button';

import PagedTable from 'components/PagedTable/PagedTable';
import { ConsoleContext } from 'services/context';
import ClientFilters from 'views/General/Client/components/ClientFilters';
import InvoiceStatus from './components/InvoiceStatus';

const useStyles = makeStyles(() => ({
  invoice: {
    marginBottom: '10px',
    '& span': {
      marginRight: '10px',
    },
  },
  actionButton: {
    float: 'right',
  },
}));

export default function ClientList() {
  const classes = useStyles();

  const {
    showError, showLoader, can, t,
  } = useContext(ConsoleContext);

  const [rows, setRows] = useState([]);
  const [filters, setFilters] = useState({ status: 'active' });

  const columns = [
    { id: 'companyName', label: 'Client' },
    { id: 'recentInvoices', label: 'Recent Invoices' },
    { id: 'actions', label: '' },
  ];

  useEffect(() => {
    async function fetchData() {
      showLoader(true);

      const canEdit = await can('finance:invoices:edit');

      try {
        const clients = await getClients();
        const invoices = await getRecentInvoices();

        const filteredClients = clients.filter(
          (client) => ((filters.status === 'active' && isClientActive(client))
          ) || (client.status === 'archived' && filters.status === 'archived'),
        );

        const rows = filteredClients.map((client) => {
          const clientInvoices = invoices.filter((invoice) => invoice.clientId === client._id);

          return {
            id: client._id,
            companyName: (<Link to={`invoices/${client._id}`} title={t('Click to view Invoices')}>{client.companyName}</Link>),
            recentInvoices: formatRecentInvoices(clientInvoices),
            actions: canEdit ? formatActions(client) : null,
          };
        });

        setRows(rows);
      } catch (error) {
        showError(error);
      }

      showLoader(false);
    }

    fetchData();
  }, [filters]);

  const formatRecentInvoices = (invoices) => {
    if (!invoices.length) {
      return '-';
    }

    return invoices.map((invoice) => (
      <div className={classes.invoice} key={invoice._id}>
        <Link to={`invoices/${invoice.clientId}/${invoice._id}`} title={t('Click to view Invoice')}>
          <span>
            {formatDate(invoice.billingStartAt)}
            {' '}
            -
            {formatDate(invoice.billingEndAt)}
          </span>
        </Link>
        <InvoiceStatus status={invoice.status} />
      </div>
    ));
  };

  const formatActions = (client) => (
    <Link to={`invoices/${client._id}/create`} className={classes.actionButton}>
      <Button color="primary">{t('Create Invoice')}</Button>
    </Link>
  );

  const handleFiltersChange = React.useCallback((newFilters) => {
    setFilters(newFilters);
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={8}>
                  <ClientFilters
                    onChange={handleFiltersChange}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <PagedTable columns={columns} rows={rows} />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
