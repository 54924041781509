import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import routes from 'publicRoutes.js';

import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useTranslation, Trans } from 'react-i18next';

import { ConsoleContext } from 'services/context';
import { container, transition } from '../assets/jss/material-dashboard-react';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    top: '0',
    height: '100vh',
  },
  mainPanel: {
    overflow: 'auto',
    position: 'relative',
    ...transition,
    maxHeight: '100%',
    width: '100%',
    overflowScrolling: 'touch',
  },
  container: {
    ...container,
    ...{
      maxWidth: '800px',
      margin: '0 auto',
    },
  },
  cardFooter: {
    justifyContent: 'center',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function Public({ ...rest }) {
  // styles
  const classes = useStyles();

  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmDialogMessage, setConfirmDialogMessage] = useState();
  const [confirmDialogCallback, setConfirmDialogCallback] = useState();
  const [loading, setLoading] = React.useState(false);

  const showError = (error) => {
    let message = '';

    if (typeof error === 'string') {
      message = t(error);
    } else if (typeof error === 'object') {
      if (error.message) {
        message = t(error.message);
      } else if (error.errors) {
        message = (error.errors.map((value) => <div key={value.param}>{t(value.msg)}</div>));
      }
    }

    setErrorMessage(message || t('Unknown Error'));
    setLoading(false);
  };

  const showSuccess = (message) => {
    setSuccessMessage(t(message));
  };

  const showConfirm = (callback, message = t('Are you sure?')) => {
    setConfirmDialogMessage(message);
    setOpenConfirmDialog(true);

    setConfirmDialogCallback(() => callback);
  };

  const showLoader = (loading) => {
    setLoading(loading);
  };

  const handleConfirm = () => {
    confirmDialogCallback();
    setOpenConfirmDialog(false);
  };

  const handleConfirmDialogCancel = () => {
    setOpenConfirmDialog(false);
  };

  const showPageTitle = (title, basePath) => {
    title = t(title);

    if (basePath) {
      const baseRoute = routes.find((route) => route.path === `/${basePath}`);

      if (baseRoute) {
        title += ` | ${t(baseRoute.name)}`;
      }
    }

    title += ' | Fluvius';

    document.title = title;
  };

  return (
    <div className={classes.wrapper}>
      <ConsoleContext.Provider value={{
        showError,
        showSuccess,
        showConfirm,
        showLoader,
        t,
        Trans,
        showPageTitle,
      }}
      >
        <div className={classes.mainPanel}>
          <div className={classes.container}>
            <Switch>
              {routes.map((prop, key) => (
                <Route
                  path={`/public${prop.path}`}
                  component={prop.component}
                  key={key}
                />
              ))}
            </Switch>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={!!errorMessage}
          autoHideDuration={3000}
          onClose={() => setErrorMessage('')}
        >
          <Alert elevation={6} variant="filled" severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={!!successMessage}
          autoHideDuration={3000}
          onClose={() => setSuccessMessage('')}
        >
          <Alert elevation={6} variant="filled" severity="success">
            {successMessage}
          </Alert>
        </Snackbar>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="xs"
          aria-labelledby="confirmation-dialog-title"
          open={openConfirmDialog}
        >
          <DialogTitle id="confirmation-dialog-title">{t('Confirm')}</DialogTitle>
          <DialogContent dividers>
            {confirmDialogMessage}
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleConfirmDialogCancel} color="primary">
              {t('Cancel')}
            </Button>
            <Button onClick={handleConfirm} color="primary">
              {t('Ok')}
            </Button>
          </DialogActions>
        </Dialog>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </ConsoleContext.Provider>
    </div>
  );
}
