import React from 'react';
import MaterialTooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

export default function Tooltip(props) {
  const { children, ...rest } = props;

  const CustomTooltip = withStyles((theme) => ({
    tooltip: {
      fontSize: 13,
    },
  }))(MaterialTooltip);

  return (<CustomTooltip arrow {...rest}><div>{children}</div></CustomTooltip>);
}
