import PublicInterviewPage from 'views/Sales/Candidate/PublicInterviewFeedback';
import PublicOpportunityPage from 'views/Sales/Opportunity/PublicOpportunityDetails';

const routes = [
  {
    path: '/interviews/:id',
    name: 'Interview',
    component: PublicInterviewPage,
  },
  {
    path: '/vacancies/:id',
    name: 'Vacancy',
    component: PublicOpportunityPage,
  },
];

export default routes;
