import React, { useState, useEffect, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Button from 'components/CustomButtons/Button';
import MenuItem from '@material-ui/core/MenuItem';

import {
  getNextStatuses,
  statusOptions,
  updatePayrollRecordStatus,
} from 'services/payroll';

import { ConsoleContext } from 'services/context';

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
    minWidth: '100%',
  },
}));

export default function ChangeStatusDialog({
  open, record, onClose, onSave,
}) {
  const classes = useStyles();

  const { showError, showSuccess, t } = useContext(ConsoleContext);

  const [openDialog, setOpenDialog] = useState(open);

  const [status, setStatus] = useState('');

  const [description, setDescription] = useState(null);

  const nextStatuses = getNextStatuses(record.status);

  const options = statusOptions.filter((option) => nextStatuses.indexOf(option.id) > -1);

  useEffect(() => {
    setStatus('');
    setDescription(null);
    setOpenDialog(open);
  }, [open]);

  const handleChangeStatus = (value) => {
    setStatus(value);

    const option = options.find((option) => option.id === value);

    setDescription(option.description);
  };

  const handleCloseDialog = () => {
    onClose();
  };

  const handleSubmit = async () => {
    const data = {
      _id: record._id,
      status,
    };

    try {
      const savedRecord = await updatePayrollRecordStatus(data);

      showSuccess('Status changed');
      setStatus('');
      onSave(savedRecord);
    } catch (e) {
      showError(e);
    }
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={openDialog}
      onClose={handleCloseDialog}
    >
      <DialogTitle>{t('Change Status')}</DialogTitle>
      <DialogContent>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <FormControl className={classes.formControl}>
              <InputLabel>
                {t('Status')}
                {' '}
                *
              </InputLabel>
              <Select
                fullWidth
                value={status}
                onChange={(e) => handleChangeStatus(e.target.value)}
              >
                { options.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    { t(option.name) }
                  </MenuItem>
                )) }
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            {t(description)}
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>
          {t('Cancel')}
        </Button>
        <Button onClick={handleSubmit} color="primary">
          {t('Save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
