import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

import {
  formatDateTime,
} from 'services/date';

import {
  formatUserName,
} from 'services/user';

import {
  formatInterviewType,
} from 'services/opportunity';

import { ConsoleContext } from 'services/context';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import PagedTable from 'components/PagedTable/PagedTable';

const useStyles = makeStyles((theme) => ({
}));

export default function InterviewList({ interviews, users, opportunities }) {
  const classes = useStyles();

  const {
    showError, showLoader, can, t,
  } = useContext(ConsoleContext);

  const [rows, setRows] = useState([]);

  const columns = [
    { id: 'type', label: 'Type' },
    { id: 'interviewer', label: 'Interviewer' },
    { id: 'opportunities', label: 'Opportunities' },
    { id: 'feedback', label: 'Feedback', width: '200px' },
    { id: 'completedAt', label: 'Completed At' },
  ];

  useEffect(() => {
    const rows = interviews.map((interview) => {
      const {
        _id: id,
        type,
        interviewerId,
        opportunityIds,
        feedback,
        completedAt,
      } = interview;

      const interviewer = users.find((user) => user._id === interviewerId);

      return {
        id,
        type: formatInterviewType(type),
        interviewer: formatUserName(interviewer),
        opportunities: renderOpportunities(interview, opportunityIds, opportunities),
        feedback: renderFeedback(interview),
        completedAt: formatDateTime(completedAt),
      };
    });

    setRows(rows);
  }, [interviews, opportunities]);

  const renderOpportunities = (interview, opportunityIds, opportunities) => {
    const items = opportunities.filter((opportunity) => opportunityIds.find((id) => id === opportunity._id));

    items.forEach((item) => {
      if (interview.feedback) {
        item.opportunityFeedback = interview.feedback.opportunities.find((opportunity) => opportunity.id === item._id);
      }
    });

    return (
      <>
        { items.map((item) => (
          <div key={item._id}>
            <Link to={`/sales/opportunities/${item._id}`}>{item.name}</Link>
            { interview.status === 'completed' ? (
              <div>
                <strong>Recommend:</strong>
                {' '}
                { item.opportunityFeedback.recommend ? 'Yes' : 'No' }
                <div>{ item.opportunityFeedback.comment }</div>
              </div>
            ) : null }
          </div>
        )) }
      </>
    );
  };

  const renderFeedback = (interview) => (
    <>
      <div>
        <Link to={`/public/interviews/${interview._id}`} target="_blank">Link</Link>
      </div>
      <div>
        <strong>Summary: </strong>
        {interview.feedback.summary || '-'}
      </div>
    </>
  );

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <PagedTable
          containerProps={{ style: { maxHeight: '600px' } }}
          tableProps={{ stickyHeader: true, style: { tableLayout: 'fixed' } }}
          columns={columns}
          rows={rows}
        />
      </GridItem>
    </GridContainer>
  );
}
