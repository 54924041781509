import React, { useState, useEffect, useContext } from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import moment from 'moment';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import { ConsoleContext } from 'services/context';
import CustomSelect from '../CustomSelect/CustomSelect';

const useStyles = makeStyles((theme) => ({
}));

export default function MonthPicker({ onChange, storage }) {
  const classes = useStyles();

  const { t } = useContext(ConsoleContext);

  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');
  const [options, setOptions] = useState([]);

  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  useEffect(() => {
    let year = moment().year();
    let month = moment().month();

    if (storage) {
      year = storage.getValue('year', year);
      month = storage.getValue('month', month);
    }

    const years = [year - 1, year, year + 1];

    const options = [];

    years.forEach((year) => {
      months.forEach((month, monthNumber) => {
        options.push({
          id: `${year}-${monthNumber}`,
          name: `${t(month)} ${year}`,
        });
      });
    });

    setOptions(options);

    setYear(year);
    setMonth(month);
  }, []);

  useEffect(() => {
    if (onChange && year) {
      onChange({ year, month });
    }
  }, [year, month]);

  const handleChange = async (value) => {
    if (!value) {
      return;
    }

    const [year, month] = value.split('-').map((value) => Number(value));

    setYear(year);
    setMonth(month);

    if (storage) {
      storage.setValue('year', year);
      storage.setValue('month', month);
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CustomSelect
          required
          label="Month"
          value={`${year}-${month}`}
          options={options}
          onChange={handleChange}
        />
      </GridItem>
    </GridContainer>
  );
}
