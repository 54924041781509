import React, { useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

import {
  statusOptions,
} from 'services/payroll';

import { ConsoleContext } from 'services/context';

const useStyles = makeStyles(() => ({
  alert: {
    display: 'inline-block',

    '& .MuiAlert-message': {
      padding: 0,
    },
  },
}));

export default function PayrollStatusStatus({ status }) {
  const classes = useStyles();

  const { t } = useContext(ConsoleContext);

  const colors = {
    created: null,
    pending: 'info',
    sent_for_approval: 'error',
    approved: 'warning',
    invoice_signed: 'warning',
    sent_fop: 'warning',
    invoice_added_finmap: 'success',
    completed: 'success',
  };

  const option = statusOptions.find((option) => option.id === status);

  let label = status;

  if (option) {
    label = option.name;
  }

  let style;

  if (status === 'created') {
    style = {
      backgroundColor: '#ccc',
      color: '#000',
    };
  }

  return (
    <Alert
      icon={false}
      color={colors[status]}
      className={classes.alert}
      style={style}
    >
      {t(label)}
    </Alert>
  );
}
