import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

// @material-ui/core components

import {
  getUsers,
} from 'services/user';

import { can } from 'services/permission';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Button from 'components/CustomButtons/Button';

import { ConsoleContext } from 'services/context';
import UserFilters from './components/UserFilters';

export default function UserList() {
  const { showError, showLoader } = useContext(ConsoleContext);

  const [canEdit, setCanEdit] = useState(false);

  const [users, setUsers] = useState([]);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [rows, setRows] = useState([]);

  const [filters, setFilters] = useState({});

  const columns = [
    { id: 'name', label: 'Name' },
    { id: 'email', label: 'Email' },
  ];

  useEffect(() => {
    async function fetchData() {
      showLoader(true);

      setCanEdit(await can('people:users:edit'));

      try {
        const users = await getUsers();

        setUsers(users);
      } catch (error) {
        showError(error);
      }

      showLoader(false);
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (filters.status && users.length) {
      const usersFiltered = users.filter(
        (user) => ((filters.status === 'active' && (user.status === 'active' || !user.status))
        ) || (user.status === 'archived' && filters.status === 'archived'),
      );

      const rows = usersFiltered.map((item) => ({
        id: item._id,
        name: `${item.firstName} ${item.lastName}`,
        email: item.email,
      }));

      setRows(rows);
    }
  }, [users, filters]);

  const handleFiltersChange = (filters) => {
    setFilters(filters);
    setPage(0);
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={8}>
                  <UserFilters
                    onChange={handleFiltersChange}
                  />
                </GridItem>

                { canEdit ? (
                  <GridItem xs={12} sm={12} md={4}>
                    <Link to="/people/partners/create">
                      <Button color="primary" style={{ float: 'right' }}>+ Add Partner</Button>
                    </Link>
                  </GridItem>
                ) : null }
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => {
                            switch (column.id) {
                              default:
                                return (
                                  <TableCell
                                    key={column.id}
                                    style={column.width ? { width: column.width } : {}}
                                  >
                                    {column.label}
                                  </TableCell>
                                );
                            }
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                          <TableRow key={row.id}>
                            {columns.map((column) => {
                              const value = row[column.id];

                              switch (column.id) {
                                case 'name':
                                  return (
                                    <TableCell key={column.id}>
                                      { canEdit ? (
                                        <Link to={`/people/partners/${row.id}`}>{value}</Link>
                                      ) : value }
                                    </TableCell>
                                  );

                                default:
                                  return (
                                    <TableCell key={column.id}>
                                      { value }
                                    </TableCell>
                                  );
                              }
                            })}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[20, 50, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={(event, newPage) => {
                      setPage(newPage);
                    }}
                    onChangeRowsPerPage={(event) => {
                      setRowsPerPage(+event.target.value);
                      setPage(0);
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
