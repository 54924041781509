import React, { useState, useEffect, useContext } from 'react';
import {
  Link, Switch, Route, Redirect,
} from 'react-router-dom';

import { can } from 'services/permission';

import { ConsoleContext } from 'services/context';

// @material-ui/core components
import { withStyles } from '@material-ui/core/styles';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const CustomTab = withStyles({
  root: {
    minWidth: '150px',
  },
})(Tab);

export default function PageWithTabs(props) {
  const { basePath, entities, location: { pathname } } = props;

  const { t, showPageTitle } = useContext(ConsoleContext);

  const [activeTab, setActiveTab] = useState(0);

  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    async function checkPermissions() {
      let tabs = [...entities];

      await Promise.all(tabs.map(async (tab) => {
        if (tab.can) {
          const allowed = await can(tab.can);

          if (!allowed) {
            tab.hidden = true;
          }
        }
      }));

      tabs = tabs.filter((tab) => !tab.hidden);

      setTabs(tabs);
    }

    checkPermissions();
  }, []);

  useEffect(() => {
    showActiveTab();
  }, [pathname, tabs]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const showActiveTab = () => {
    const index = tabs.findIndex((tab) => pathname.indexOf(tab.path) > -1);
    const activeTab = index > -1 ? index : 0;

    setActiveTab(activeTab);

    const entity = entities[activeTab];

    showPageTitle(entity.name, basePath);
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Paper square>
          <Tabs
            value={activeTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
          >
            { tabs.map((tab) => (
              <CustomTab key={tab.id} label={t(tab.name)} to={`/${basePath}/${tab.path}`} component={Link} />
            ))}
          </Tabs>
        </Paper>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <div style={{ marginTop: '10px' }}>
          <Switch>
            { tabs.map((tab) => (
              tab.components.map((route) => (
                <Route key={route.path} path={route.path} component={route.component} />
              ))
            ))}
            { tabs.length ? (<Redirect to={`/${basePath}/${tabs[0].path}`} />) : null }
          </Switch>
        </div>
      </GridItem>
    </GridContainer>
  );
}
