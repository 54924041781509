import {
  CANDIDATE_URL,
} from '../utils/apiRouting';

import {
  get, post, put, uploadFile, formatJsonQuery, httpDelete,
} from '../utils/http';

export function getExperienceYearsOptions() {
  return [
    { id: -1, name: 'undefined' },
    { id: 0.5, name: '6 months' },
    { id: 1, name: '1 year' },
    { id: 1.5, name: '1.5 year' },
    { id: 2, name: '2 years' },
    { id: 3, name: '3 years' },
    { id: 4, name: '4 years' },
    { id: 5, name: '5 years' },
    { id: 6, name: '5+ years' },
  ];
}

export function getTechStackLevelOptions() {
  return [
    { id: 'unknown', name: 'Not verified' },
    { id: 'trainee', name: 'Trainee' },
    { id: 'junior', name: 'Junior' },
    { id: 'strong_junior', name: 'Strong Junior' },
    { id: 'middle', name: 'Middle' },
    { id: 'strong_middle', name: 'Strong Middle' },
    { id: 'senior', name: 'Senior' },
    { id: 'lead', name: 'Lead' },
  ];
}

export function getContactTypeOptions() {
  return [
    { id: 'direct', name: 'Direct candidate contact' },
    { id: 'middleman', name: 'Middleman' },
    { id: 'internal', name: 'Internal Candidate' },
  ];
}

export function getCandidateStatusOptions() {
  return [
    { id: 'available', name: 'Available' },
    { id: 'archived', name: 'Archived' },
  ];
}

export function formatTechStackLevel(level) {
  return formatOptionItem(level, getTechStackLevelOptions());
}

export function formatExperienceYears(years) {
  return formatOptionItem(years, getExperienceYearsOptions());
}

export function formatContactType(type) {
  return formatOptionItem(type, getContactTypeOptions());
}

export function formatCandidateStatus(status) {
  return formatOptionItem(status, getCandidateStatusOptions());
}

export async function createCandidate(candidate) {
  return post(CANDIDATE_URL, candidate);
}

export async function updateCandidate(candidate) {
  return put(`${CANDIDATE_URL}/${candidate._id}`, candidate);
}

export async function getCandidates(query = {}) {
  return get(CANDIDATE_URL + formatJsonQuery(query));
}

export async function getCandidate(id) {
  return get(`${CANDIDATE_URL}/${id}`);
}

export async function uploadCV(file) {
  return uploadFile(`${CANDIDATE_URL}/files`, file);
}

export async function createCandidateInterview(candidateId, interview) {
  return post(`${CANDIDATE_URL}/${candidateId}/interviews`, interview);
}

export async function deleteCandidateInterview(candidateId, id) {
  return httpDelete(`${CANDIDATE_URL}/${candidateId}/interviews/${id}`)
}

export async function getCandidateOpportunities(candidateId) {
  return get(`${CANDIDATE_URL}/${candidateId}/opportunities`);
}

export async function getCandidateInterviews(candidateId) {
  return get(`${CANDIDATE_URL}/${candidateId}/interviews`);
}

export async function updateCandidateStatus(candidateId, status) {
  return put(`${CANDIDATE_URL}/${candidateId}/status`, { status });
}

export function formatCandidateRate(candidate, type) {
  let { rateType, rateAmount } = candidate;

  if (type && (rateType !== type)) {
    const hoursInMonth = 168;

    if ((type === 'monthly') && (rateType === 'hourly')) {
      rateAmount *= hoursInMonth; // average hours in month
    } else {
      rateAmount = Math.round(rateAmount / hoursInMonth * 10) / 10;
    }

    rateType = type;
  }

  return `$${rateAmount}/${rateType === 'hourly' ? 'h' : 'm'}`;
}

function formatOptionItem(value, options) {
  if (!value) {
    return '-';
  }

  const option = options.find((item) => item.id === value);

  return option.name;
}

// Public API
export function getPublicInterview(id) {
  return get(`${CANDIDATE_URL}/public/interviews/${id}`);
}

export function updatePublicInterview(interview) {
  const { type, feedback } = interview;

  return put(`${CANDIDATE_URL}/public/interviews/${interview._id}`, { type, feedback });
}

export async function parseCV(file) {
  return uploadFile(`${CANDIDATE_URL}/parse-cv`, file);
}
