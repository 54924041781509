import React, { useState, useEffect, useContext } from 'react';
import clone from 'just-clone';

import {
  formatDate,
} from 'services/date';

import {
  formatUserNameById,
} from 'services/user';

import {
  formatCandidateRate,
} from 'services/candidate';

import {
  formatCandidateStatus,
} from 'services/opportunity';

import { ConsoleContext } from 'services/context';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import PagedTable from 'components/PagedTable/PagedTable';

import CandidateDialog from './CandidateDialog';
import CandidateName from '../../Candidate/components/CandidateName';

export default function CandidateList({
  opportunity, techStack, onChange, activeCandidateId, users,
}) {
  const { showError, showLoader } = useContext(ConsoleContext);

  const [rows, setRows] = useState([]);

  const [activeCandidate, setActiveCandidate] = useState();

  const [openCandidate, setOpenCandidate] = useState(false);

  const columns = [
    { id: 'name', label: 'Name' },
    { id: 'rate', label: 'Rate' },
    { id: 'recruiter', label: 'Recruiter' },
    { id: 'status', label: 'Status' },
    { id: 'addedAt', label: 'Added At' },
  ];

  useEffect(() => {
    if (!users.length) {
      return;
    }

    (async () => {
      showLoader(true);

      try {
        const rows = opportunity.candidates
          .map((opportunityCandidate) => {
            const {
              candidate,
              status,
              addedAt,
            } = opportunityCandidate;

            return {
              id: candidate._id,
              name: renderCandidateName(opportunityCandidate),
              rate: formatCandidateRate(candidate),
              recruiter: formatUserNameById(candidate.recruiterId, users),
              addedAt: formatDate(addedAt),
              status: formatCandidateStatus(status),
            };
          });

        setRows(rows.reverse());
      } catch (e) {
        showError(e);
      }

      showLoader(false);
    })();
  }, [opportunity, users]);

  useEffect(() => {
    if (activeCandidateId && users.length) {
      const opportunityCandidate = opportunity.candidates.find((item) => item.id === activeCandidateId);

      if (!opportunityCandidate) {
        showError('Unable to open the candidate');
        return;
      }

      setActiveCandidate(opportunityCandidate);
      setOpenCandidate(true);
    }
  }, [opportunity, activeCandidateId, users]);

  const handleView = (e, opportunityCandidate) => {
    e.preventDefault();

    setActiveCandidate(opportunityCandidate);
    setOpenCandidate(true);
  };

  const handleCandidateUpdated = (opportunityCandidate, closeDialog = true) => {
    const changedOpportunity = clone(opportunity);
    const changedCandidateIndex = changedOpportunity.candidates.findIndex((item) => item.id === opportunityCandidate.id);

    const { candidate } = changedOpportunity.candidates[changedCandidateIndex];

    changedOpportunity.candidates[changedCandidateIndex] = {
      ...opportunityCandidate,
      candidate,
    };

    onChange(changedOpportunity);

    if (closeDialog) {
      handleCloseCandidateDialog();
    } else {
      setActiveCandidate(changedOpportunity.candidates[changedCandidateIndex]);
    }
  };

  const handleCloseCandidateDialog = () => {
    setOpenCandidate(false);
    setActiveCandidate(null);
  };

  const renderCandidateName = (opportunityCandidate) => (
    <a href="#" onClick={(e) => handleView(e, opportunityCandidate)}>
      <CandidateName candidate={opportunityCandidate.candidate} />
    </a>
  );

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <PagedTable
            containerProps={{ style: { maxHeight: '600px' } }}
            tableProps={{ stickyHeader: true, style: { tableLayout: 'fixed' } }}
            columns={columns}
            rows={rows}
          />
        </GridItem>
      </GridContainer>

      <CandidateDialog
        open={openCandidate}
        opportunity={opportunity}
        opportunityCandidate={activeCandidate}
        techStack={techStack}
        users={users}
        onSave={handleCandidateUpdated}
        onClose={handleCloseCandidateDialog}
      />
    </>
  );
}
