import React, { useState, useEffect, useContext } from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import {
  getAccesses,
  createAccess,
  updateAccess,
} from 'services/jira';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from 'components/CustomButtons/Button.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import { ConsoleContext } from 'services/context';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '100%',
  },
  checkboxMargin: {
    marginLeft: '-3px',
    marginTop: '20px',
  },
  addDialogRoot: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
}));

export default function JiraAccess() {
  useEffect(() => {
    async function fetchData() {
      try {
        const accesses = await getAccesses();

        setAccesses(accesses);

        const rows = accesses.map((item) => ({ ...item }));

        setRows(rows);
      } catch (error) {
        showError(error);
      }
    }

    fetchData();
  }, []);

  const classes = useStyles();

  const { showError, showSuccess, showConfirm } = useContext(ConsoleContext);

  const [accesses, setAccesses] = useState([]);

  const [rows, setRows] = useState([]);

  const [activeAccess, setActiveAccess] = useState({
    name: '',
    host: '',
    email: '',
    token: '',
  });

  const [openAddDialog, setOpenAddDialog] = useState(false);

  const handleAddAccess = () => {
    setActiveAccess({
      name: '',
      host: '',
      email: '',
      token: '',
    });

    setOpenAddDialog(true);
  };

  const handleEditAccess = (access) => {
    setActiveAccess({ ...access, token: '' });

    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  const handleAddDialogSubmit = async () => {
    const data = { ...activeAccess };

    const changedRows = [...rows];
    let newAccess;

    try {
      let newAccess;

      if (data._id) {
        newAccess = await updateAccess(data);

        const index = changedRows.findIndex((item) => item._id === newAccess._id);
        changedRows[index] = newAccess;

        showSuccess('Jira Access updated');
      } else {
        newAccess = await createAccess(data);

        changedRows.push(newAccess);

        showSuccess('Jira Access created');
      }

      setRows(changedRows);
      setOpenAddDialog(false);
    } catch (e) {
      showError(e);
    }
  };

  const handleAccessChange = (field, value) => {
    const data = { ...activeAccess };
    data[field] = value;

    setActiveAccess(data);
  };

  const columns = [
    { id: 'name', label: 'Name' },
    { id: 'host', label: 'Host' },
    { id: 'email', label: 'Email' },
  ];

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Button color="primary" style={{ float: 'right' }} onClick={handleAddAccess}>+ Create Access</Button>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id}>{column.label}</TableCell>
                  ))}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row._id}>
                    {columns.map((column) => (
                      <TableCell key={column.id}>{row[column.id]}</TableCell>
                    ))}
                    <TableCell>
                      <Button onClick={() => handleEditAccess(row)}>Edit</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </GridItem>
      </GridContainer>
      <Dialog
        maxWidth="xs"
        fullWidth
        open={openAddDialog}
        onClose={handleCloseAddDialog}
      >
        <DialogTitle>
          { activeAccess._id ? 'Edit' : 'Add' }
          {' '}
          JIRA Access
        </DialogTitle>
        <DialogContent className={classes.addDialogRoot}>
          <GridContainer>
            <GridItem>
              <a
                href="https://support.atlassian.com/atlassian-account/docs/manage-api-tokens-for-your-atlassian-account/"
                target="_blank"
                rel="noreferrer"
              >
                Instruction how to create API token
              </a>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                required
                fullWidth
                label="Name"
                value={activeAccess.name}
                onChange={(e) => handleAccessChange('name', e.target.value)}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                required
                fullWidth
                label="Host"
                value={activeAccess.host}
                onChange={(e) => handleAccessChange('host', e.target.value)}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                required
                fullWidth
                label="Email"
                value={activeAccess.email}
                onChange={(e) => handleAccessChange('email', e.target.value)}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            { activeAccess._id ? (
              <GridItem xs={12} sm={12} md={12}>
                <TextField
                  fullWidth
                  label="Token"
                  value={activeAccess.token}
                  onChange={(e) => handleAccessChange('token', e.target.value)}
                  helperText="Leave empty if the token not changing"
                />
              </GridItem>
            ) : (
              <GridItem xs={12} sm={12} md={12}>
                <TextField
                  required
                  fullWidth
                  label="Token"
                  value={activeAccess.token}
                  onChange={(e) => handleAccessChange('token', e.target.value)}
                />
              </GridItem>
            )}
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddDialog}>
            Cancel
          </Button>
          <Button onClick={handleAddDialogSubmit} color="primary">
            { activeAccess._id ? 'Save' : 'Add' }
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
