import React, { useState, useEffect } from 'react';
import clone from 'just-clone';

import { makeStyles } from '@material-ui/core/styles';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';

const useStyles = makeStyles(() => ({
  grid: {
    marginTop: '15px',
  },
}));

export default function TechStackSelect({
  techStack, values, experienceOptions, onChange,
}) {
  const classes = useStyles();

  const [changedValues, setChangedValues] = useState([]);

  const techStackCount = 5;

  useEffect(() => {
    const changedValues = clone(values);

    if (changedValues.length < techStackCount) {
      for (let i = changedValues.length; i < techStackCount; i++) {
        changedValues.push({
          id: '',
          experienceYears: '',
        });
      }
    }

    setChangedValues(changedValues);
  }, [values]);

  const handleChange = (index, field, value) => {
    const data = clone(changedValues);

    data[index][field] = value;

    setChangedValues(data);

    const updatedValues = [];

    data.forEach((item) => {
      if (item.id || item.experienceYears) {
        if (!item.experienceYears) {
          delete item.experienceYears;
        } else {
          item.experienceYears = Number(item.experienceYears);
        }

        updatedValues.push(item);
      }
    });

    onChange(updatedValues);
  };

  const getTechStackOptions = (techStack, techStackIndex) => techStack.map((item) => ({
    id: item._id,
    name: item.name,
  }))
    .filter((item) => !values.some(
      (value, valueIndex) => !((valueIndex === techStackIndex && value.id === item.id) || value.id !== item.id),
    ));

  return (
    <>
      { changedValues.map((item, index) => (
        <GridContainer key={`item-${index}`} className={index ? classes.grid : ''}>
          <GridItem xs={12} sm={12} md={6}>
            <CustomSelect
              label="Technology"
              value={item.id}
              options={getTechStackOptions(techStack, index)}
              onChange={(value) => handleChange(index, 'id', value)}
            />
          </GridItem>

          { experienceOptions && experienceOptions.length ? (
            <GridItem xs={12} sm={12} md={6}>
              <CustomSelect
                label="Experience"
                value={item.experienceYears}
                options={experienceOptions}
                onChange={(value) => handleChange(index, 'experienceYears', value)}
              />
            </GridItem>
          ) : null }
        </GridContainer>
      )) }
    </>
  );
}
