import React, {
  useState, Fragment, useContext,
} from 'react';
import { Link } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Tooltip from 'components/Tooltip/Tooltip';
import { ConsoleContext } from 'services/context';
import ContractIssuesDialog from './ContractIssuesDialog';
import { getFilterSummary } from '../../../../services/profitReport';

const useStyles = makeStyles(() => ({
  cellLastRow: {
    borderBottom: '1px solid black!important',
  },
  cellLastColumn: {
    borderRight: '1px solid black!important',
  },
  table: {
    '& th, & td': {
      border: '1px solid #d4d5d5',
      textAlign: 'center',
      overflow: 'hidden',
    },
    '& th': {
      padding: '6px 2px!important',
    },
    '& td': {
      padding: '6px 0!important',
    },
    '& table': {
      tableLayout: 'fixed',
    },
    maxHeight: '600px',
  },
  totalRow: {
    backgroundColor: '#ccc',
  },
}));

const prepareSummary = (summary, options = {}) => {
  let filteredSummary = getFilterSummary(summary);

  if (options.excludeClientId) {
    filteredSummary = filteredSummary.filter((p) => p.clientId !== options.excludeClientId);
  }

  const groupedSummary = {};

  function prepareRow(item, clientId, projectId) {
    if (!groupedSummary[clientId]) {
      groupedSummary[clientId] = {};
    }
    if (!groupedSummary[clientId][projectId]) {
      groupedSummary[clientId][projectId] = {
        planRevenue: 0,
        factRevenue: 0,
        factOvertimeRevenue: 0,
        planBillableCosts: 0,
        factBillableCosts: 0,
        planNonBillableCosts: 0,
        factNonBillableCosts: 0,
        factOvertimeCosts: 0,
        planRevenueWeight: 0,
        factRevenueWeight: 0,
        planProfit: 0,
        factProfit: 0,
      };
    }

    const row = groupedSummary[clientId][projectId];

    Object.keys(row).forEach((field) => {
      if (item[field]) {
        row[field] += item[field];
      }
    });
  }

  let planTotalProfit = 0;
  let factTotalProfit = 0;

  filteredSummary.forEach((item) => {
    const { clientId, projectId } = item;

    prepareRow(item, clientId, projectId);
    prepareRow(item, clientId, 'total');
    prepareRow(item, 'total', 'total');

    planTotalProfit += item.planProfit;
    factTotalProfit += item.factProfit;
  });

  Object.keys(groupedSummary).forEach((clientId) => {
    Object.keys(groupedSummary[clientId]).forEach((projectId) => {
      const row = groupedSummary[clientId][projectId];

      const {
        planRevenue,
        factRevenue,
        planBillableCosts,
        factBillableCosts,
        planNonBillableCosts,
        factNonBillableCosts,
        planProfit,
        factProfit,
      } = row;

      row.planGrossMargin = planRevenue ? (1 - (planBillableCosts + planNonBillableCosts) / planRevenue) : 0;
      row.factGrossMargin = factRevenue ? (1 - (factBillableCosts + factNonBillableCosts) / factRevenue) : 0;

      row.planProfitWeight = planProfit / planTotalProfit;
      row.factProfitWeight = factProfit / factTotalProfit;
    });
  });

  return groupedSummary;
};

const prepareClients = (projects, summary) => {
  const groupedClients = {};
  projects.forEach((project) => {
    const projectId = project._id;
    const clientId = project.clientId._id;

    if (!groupedClients[clientId]) {
      groupedClients[clientId] = {
        ...project.clientId,
        projects: [],
        planProfit: 0,
      };
    }

    if (summary[clientId] && summary[clientId][projectId]) {
      groupedClients[clientId].projects.push(project);

      groupedClients[clientId].planProfit += summary[clientId][projectId].planProfit;
    }
  });

  return Object
    .values(groupedClients)
    .filter((client) => client.projects.length > 0)
    .sort((a, b) => (a.planProfit >= b.planProfit ? -1 : 1));
};

const prepareContractIssues = (summary) => {
  const issues = {};

  summary.forEach((item) => {
    const {
      projectId, userId, noClientContractDays, noUserContractDays,
    } = item;

    if (!issues[projectId]) {
      issues[projectId] = {
        noClientContractDays: [],
        noUserContractDays: [],
      };
    }

    if (noClientContractDays && noClientContractDays.length) {
      issues[projectId].noClientContractDays.push({ userId, days: noClientContractDays });
    }

    if (noUserContractDays && noUserContractDays.length) {
      issues[projectId].noUserContractDays.push({ userId, days: noUserContractDays });
    }
  });

  return issues;
};

export default function ClientReport({
  summary, projects, minGrossMargin, excludeClientId = '',
}) {
  const classes = useStyles();
  const { t } = useContext(ConsoleContext);

  const contractIssues = prepareContractIssues(summary);
  summary = prepareSummary(summary, { excludeClientId });

  const clients = prepareClients(projects, summary);

  const [contractIssuesDialog, setContractIssuesDialog] = useState();

  const formatPercent = (number) => `${formatNumber(number * 100)}%`;

  const bgColors = {
    plan: '#ffe599',
    fact: '#b6d7a9',
  };

  const columns = [
    {
      id: 'planProfitWeight', name: 'Client Weight by GP Plan', color: bgColors.plan, format: formatPercent,
    },
    {
      id: 'factProfitWeight', name: 'Client Weight by GP Fact', color: bgColors.fact, format: formatPercent,
    },
    { id: 'planRevenue', name: 'Revenue Plan', color: bgColors.plan },
    { id: 'factRevenue', name: 'Revenue Fact', color: bgColors.fact },
    { id: 'factOvertimeRevenue', name: 'Revenue for Overtime', color: bgColors.fact },
    { id: 'planBillableCosts', name: 'Billable Direct Costs Plan', color: bgColors.plan },
    { id: 'factBillableCosts', name: 'Billable Direct Costs Fact', color: bgColors.fact },
    { id: 'planNonBillableCosts', name: 'Non Billable Direct Costs Plan', color: bgColors.plan },
    { id: 'factNonBillableCosts', name: 'Non Billable Direct Costs Fact', color: bgColors.fact },
    { id: 'factOvertimeCosts', name: 'Costs for Overtime Fact', color: bgColors.fact },
    { id: 'planProfit', name: 'GP Plan', color: bgColors.plan },
    { id: 'factProfit', name: 'GP Fact', color: bgColors.fact },
    {
      id: 'planGrossMargin', name: 'GPM Plan', color: bgColors.plan, format: formatPercent,
    },
    {
      id: 'factGrossMargin', name: 'GPM Fact', color: bgColors.fact, format: formatPercent,
    },
  ];

  const formatNumber = (number) => Math.round(number * 100) / 100;

  const renderSummaryCell = (clientId, projectId, field, format) => {
    const isTotal = (projectId === 'total');

    let value = '-';

    const style = {};

    if (summary[clientId] && summary[clientId][projectId]) {
      value = summary[clientId][projectId][field];

      if (!value && value !== 0) {
        value = '-';
      } else {
        if ((field === 'planGrossMargin' || field === 'factGrossMargin') && (value !== 0)) {
          if (value < 0) {
            style.backgroundColor = '#d70202';
          } else if (value < minGrossMargin) {
            style.backgroundColor = '#11b5c9';
          }
        }

        if ((field === 'planProfit' || field === 'factProfit') && value < 0) {
          style.color = 'red';
        }

        value = format ? format(value) : formatNumber(value);

        value = isTotal ? (<strong>{value}</strong>) : value;
      }
    }

    return (<TableCell key={field} style={style}>{value}</TableCell>);
  };

  const renderProjectCell = (project, issues) => {
    const style = {};
    let tooltip;

    if (issues) {
      if (issues.noClientContractDays.length || issues.noUserContractDays.length) {
        style.backgroundColor = 'red';

        tooltip = 'Click to resolve issues';
      }
    }

    const handleClick = () => {
      setContractIssuesDialog({ project, issues });
    };

    return (
      <TableCell style={style}>
        { tooltip ? (
          <Tooltip title={t(tooltip)} arrow>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div onClick={handleClick} style={{ cursor: 'pointer' }}>{project.name}</div>
          </Tooltip>
        ) : project.name }
      </TableCell>
    );
  };

  const handleCloseContractIssuesDialog = () => {
    setContractIssuesDialog(null);
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <TableContainer className={classes.table}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '150px' }}>
                    {t('Client')}
                    /
                    {t('Project')}
                    <br />
                    USD
                  </TableCell>
                  { columns.map((column) => (
                    <TableCell key={column.id} style={{ backgroundColor: column.color }}>{t(column.name)}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell><strong>Total</strong></TableCell>

                  { columns.map((column) => renderSummaryCell('total', 'total', column.id, column.format)) }
                </TableRow>
                { clients.map((client) => (
                  <Fragment key={client._id}>
                    <TableRow className={classes.totalRow}>
                      <TableCell>
                        <Link to={`client-contracts/${client._id}`} title={t('Click to view Client Contracts')}>
                          <strong>{client.companyName}</strong>
                        </Link>
                      </TableCell>

                      { columns.map((column) => renderSummaryCell(client._id, 'total', column.id, column.format)) }
                    </TableRow>
                    { client.projects.map((project) => (
                      <TableRow key={project._id}>
                        { renderProjectCell(project, contractIssues[project._id]) }

                        { columns.map((column) => renderSummaryCell(client._id, project._id, column.id, column.format)) }
                      </TableRow>
                    ))}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </GridItem>
      </GridContainer>

      { contractIssuesDialog && <ContractIssuesDialog {...contractIssuesDialog} onClose={handleCloseContractIssuesDialog} />}
    </>
  );
}
