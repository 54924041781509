import React from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles((theme) => ({
  cellLastRow: {
    borderBottom: '1px solid black!important',
  },
  cellLastColumn: {
    borderRight: '1px solid black!important',
  },
  table: {
    '& th, & td': {
      border: '1px solid #d4d5d5',
      textAlign: 'center',
      overflow: 'hidden',
    },
    '& th': {
      padding: '6px 2px!important',
    },
    '& td': {
      padding: '6px 0!important',
    },
    '& table': {
      tableLayout: 'fixed',
    },
    maxHeight: '600px',
  },
}));

export default function TableReport({ columns, rows }) {
  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <TableContainer className={classes.table}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                { columns.map((column) => (
                  <TableCell
                    key={column.id}
                    style={column._styles ? column._styles : {}}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              { rows.map((row) => (
                <TableRow key={row.id} style={row._styles ? row._styles : {}}>
                  { columns.map((column) => (
                    <TableCell key={column.id}>
                      {row[column.id]}
                    </TableCell>
                  ))}
                </TableRow>
              )) }
            </TableBody>
          </Table>
        </TableContainer>
      </GridItem>
    </GridContainer>
  );
}
