import React, { useContext, useEffect } from 'react';

import { ConsoleContext } from 'services/context';

import { formatMoney } from 'services/finance';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  message: {
    '& strong': {
      whiteSpace: 'nowrap',
    },
  },
}));

export default function ConfirmContractRate({ contract, onConfirm }) {
  const classes = useStyles();

  const { showConfirm, t, Trans } = useContext(ConsoleContext);

  const ranges = {
    hourly: { min: 1, max: 99 },
    monthly: { min: 100, max: 10000 },
  };

  useEffect(() => {
    let type;
    let rate;

    if (contract.type === 'hourly') {
      if (contract.hourlyRate && (contract.hourlyRate < ranges.hourly.min || contract.hourlyRate > ranges.hourly.max)) {
        type = t('Hourly Rate');
        rate = formatMoney(contract.hourlyRate, contract.currency);
      }
    } else if (contract.type === 'monthly') {
      if (contract.monthlyRate && (contract.monthlyRate < ranges.monthly.min || contract.monthlyRate > ranges.monthly.max)) {
        type = t('Monthly Rate');
        rate = formatMoney(contract.monthlyRate, contract.currency);
      }
    }

    if (type) {
      showConfirm(
        onConfirm, (
          <div className={classes.message}>
            <Trans
              i18nKey="Are you sure <1>{{type}}</1> is <1>{{rate}}</1>?"
              type={type}
              rate={rate}
            >
              Are you sure
              {' '}
              <strong>{{ type }}</strong>
              {' '}
              is
              {' '}
              <strong>{{ rate }}</strong>
              ?
            </Trans>
          </div>
        ),
      );
    } else {
      onConfirm();
    }
  }, [contract]);

  return (
    <></>
  );
}
