import React, { useContext } from 'react';

import { ConsoleContext } from 'services/context';

import { formatDate } from 'services/date';
import { formatMoney, getDefaultCurrency } from 'services/finance';

export default function ExchangeRates({
  baseCurrency, currencyExchangeDate, currencyExchangeRates, inline,
}) {
  const { t } = useContext(ConsoleContext);

  const currencies = currencyExchangeRates ? Object.keys(currencyExchangeRates) : [];

  // fix for the old currencies format
  if (currencyExchangeRates && currencyExchangeRates[baseCurrency] && currencyExchangeRates[baseCurrency] !== 1) {
    baseCurrency = getDefaultCurrency();
  }

  return (
    <span>
      { currencies.length ? (
        <>
          <strong>
            {t('Date')}
            :
          </strong>
          {' '}
          { formatDate(currencyExchangeDate) }
          { inline ? ' ' : (<br />)}
          <strong>{t('Exchange Rates')}</strong>
          <span>
            : 1
            {baseCurrency}
            {' '}
            =&nbsp;
          </span>
          { currencies.map((currency, index) => (
            <span key={currency}>
              {formatMoney(currencyExchangeRates[currency], currency, 4)}
              { index < currencies.length - 1 ? ', ' : null }
            </span>
          )) }
        </>
      ) : t('No currency exchange was performed') }
    </span>
  );
}
