import {
  USER_URL,
} from '../utils/apiRouting';

import {
  get, post, put, formatQuery, httpDelete,
} from '../utils/http';

export async function getUsers(query = {}) {
  return get(USER_URL + formatQuery(query));
}

export async function getUser(id) {
  return get(`${USER_URL}/${id}`);
}

export async function createUser(user) {
  return post(USER_URL, user);
}

export async function updateUser(user) {
  return put(`${USER_URL}/${user._id}`, user);
}

export async function updateUserStatus(userId, status) {
  return put(`${USER_URL}/${userId}/status`, { status });
}

export async function getUserCustomDates(userId, query = {}) {
  return get(`${USER_URL}/${userId}/custom-dates${formatQuery(query)}`);
}

export async function createUserCustomDate(userId, date) {
  return post(`${USER_URL}/${userId}/custom-dates`, date);
}

export async function deleteUserCustomDate(userId, date) {
  return httpDelete(`${USER_URL}/${userId}/custom-dates/${date._id}`);
}

export async function getUserCapacity(userId, query = {}) {
  return get(`${USER_URL}/${userId}/capacity${formatQuery(query)}`);
}

export async function createUserCapacity(userId, capacity) {
  return post(`${USER_URL}/${userId}/capacity`, capacity);
}

export async function updateUserCapacity(userId, capacity) {
  return put(`${USER_URL}/${userId}/capacity/${capacity._id}`, capacity);
}

export async function deleteUserCapacity(userId, capacity) {
  return httpDelete(`${USER_URL}/${userId}/capacity/${capacity._id}`);
}

export async function getRemainingBillableHolidays(userId) {
  return get(`${USER_URL}/${userId}/remaining-billable-holidays`);
}

export function formatUserName(user) {
  if (!user) {
    return '-';
  }

  return `${user.firstName} ${user.lastName}`;
}

export function formatUserNameById(userId, users) {
  const user = users.find((user) => user._id === userId);

  return formatUserName(user);
}

export function getUserStatusOptions() {
  return [
    {
      id: 'active',
      name: 'Active',
    },
    {
      id: 'archived',
      name: 'Archived',
    },
  ];
}

export function filterActiveUsers(users) {
  return users.filter((user) => isUserActive(user));
}

export function isUserActive(user) {
  return user.status === 'active' || !user.status;
}
