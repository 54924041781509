import {
  FINMAP_URL,
} from '../utils/apiRouting';

import {
  get, post,
} from '../utils/http';

export async function getFinmapLastSync(year, month) {
  return get(`${FINMAP_URL}/last-sync/${year}/${month}`);
}

export async function syncFinmap(year, month) {
  return post(`${FINMAP_URL}/sync`, { year, month });
}
