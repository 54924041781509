import React, { useState, useEffect, useContext } from 'react';

import {
  addOpportunityCandidate,
  getOpportunities,
} from 'services/opportunity';

import { ConsoleContext } from 'services/context';

import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Button from 'components/CustomButtons/Button';
import CustomSelect from 'components/CustomSelect/CustomSelect';

export default function AddToOpportunityDialog({
  candidate,
  assignedOpportunities,
  open,
  onClose,
  onSave,
}) {
  const { showError, showSuccess, t } = useContext(ConsoleContext);

  const [openDialog, setOpenDialog] = useState(open);

  const [opportunities, setOpportunities] = useState([]);

  const [candidateOpportunities, setCandidateOpportunities] = useState([]);

  const [opportunityId, setOpportunityId] = useState('');

  useEffect(() => {
    (async () => {
      try {
        const opportunities = await getOpportunities({ status: 'active' });

        setOpportunities(opportunities);
      } catch (e) {
        showError(e);
      }
    })();
  }, []);

  useEffect(() => {
    if (!opportunities.length) {
      return;
    }

    const assignedOpportunityIds = assignedOpportunities.map((item) => item._id);

    const candidateOpportunities = opportunities
      .filter((opportunity) => assignedOpportunityIds.indexOf(opportunity._id) === -1);

    setCandidateOpportunities(candidateOpportunities);
  }, [assignedOpportunities, opportunities]);

  useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  const handleCloseDialog = () => {
    setOpportunityId('');
    onClose();
  };

  const handleChange = (id) => {
    setOpportunityId(id);
  };

  const handleSubmit = async () => {
    try {
      if (!opportunityId) {
        throw (new Error('Select Position'));
      }
      await addOpportunityCandidate(opportunityId, candidate._id);

      const opportunity = opportunities.find((item) => item._id === opportunityId);

      showSuccess('Candidate added to the position');
      onSave(opportunity);
    } catch (e) {
      showError(e);
    }
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={openDialog}
      onClose={handleCloseDialog}
    >
      <DialogTitle>Add to Position</DialogTitle>
      <DialogContent>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            { candidateOpportunities.length ? (
              <CustomSelect
                required
                label="Position"
                value={opportunityId}
                options={candidateOpportunities}
                onChange={(value) => handleChange(value)}
              />
            ) : 'No opportunities' }
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>
          {t('Cancel')}
        </Button>

        <Button onClick={handleSubmit} color="info">
          {t('Add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
