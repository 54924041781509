import RBAC from 'easy-rbac';

import {
  PERMISSION_URL,
} from '../utils/apiRouting';

import {
  get, put, formatQuery,
} from '../utils/http';

import { getRoles } from './role';

let rbac;
const userRoleIds = [];

export async function loadUserPermissions(user) {
  const rolePermissions = {};

  if (user.admin) {
    rolePermissions.admin = { can: ['*'] };

    userRoleIds.push('admin');
  } else {
    const roles = await getRoles({ userIds: user._id });

    roles.forEach((role) => {
      userRoleIds.push(role._id);
      rolePermissions[role._id] = { can: role.permissions };
    });
  }

  rbac = new RBAC(rolePermissions);
}

export async function can(subject) {
  let allowed = false;

  await Promise.all(userRoleIds.map(async (roleId) => {
    try {
      if (await rbac.can(roleId, subject)) {
        allowed = true;
      }
    } catch (e) {

    }
  }));

  return allowed;
}

export async function getPermissions(query = {}) {
  return get(PERMISSION_URL + formatQuery(query));
}

export async function updateRolePermissions(roleId, permissions) {
  return put(`${PERMISSION_URL}/roles/${roleId}`, { permissions });
}
