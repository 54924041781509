import React from 'react';

import TelegramLink from '../../components/TelegramLink';

export default function PartnerCompanyContacts({ contacts }) {
  if (!contacts.length) {
    return '-';
  }

  const fields = ['name', 'telegram', 'phone', 'skype', 'email'];

  const list = contacts.map((contact) => {
    const values = {};

    fields.forEach((field) => {
      let value = contact[field];

      if (value) {
        switch (field) {
          case 'telegram':
            value = (<TelegramLink telegram={value} />);
            break;

          case 'skype':
            value = `skype: ${value}`;
            break;

          default:
            break;
        }

        values[field] = value;
      }
    });

    return values;
  });

  return list.map((item, index) => (
    <div key={index}>
      <ul style={{ listStyle: 'none', padding: 0 }}>
        { fields.map((field) => (
          <li key={field}>
            {item[field]}
          </li>
        )) }
      </ul>
    </div>
  ));
}
