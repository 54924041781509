import React from 'react';

import {
  getExportProjectLogsLink,
} from 'services/project';

// core components
import { makeStyles } from '@material-ui/core/styles';
import Button from 'components/CustomButtons/Button.js';

const useStyles = makeStyles((theme) => ({
}));

export default function ProjectLogsExport({ project, date, onSync }) {
  const classes = useStyles();

  return (
    <Button
      color="warning"
      href={getExportProjectLogsLink(project._id, date.year, date.month)}
    >
      Download Billable
    </Button>
  );
}
