import { CURRENCY_EXCHANGE_RATES_URL } from '../utils/apiRouting';

import { get } from '../utils/http';

const DEFAULT_CURRENCY = 'USD';

const splitNumberOnThousands = (number) => {
  const parts = number.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join('.');
};

export const formatMoney = (
  value,
  currency = DEFAULT_CURRENCY,
  fractionDigits = 2,
) => {
  let multiplier = 1;
  for (let i = 0; i < fractionDigits; i++) {
    multiplier *= 10;
  }

  value = (Math.round(value * multiplier) / multiplier).toFixed(fractionDigits);

  value = splitNumberOnThousands(value);

  return `${value}${currency ? ` ${currency}` : ''}`;
};

export const formatNumber = (number) => Math.round(number * 100) / 100;

export const parseInputNumber = (value) => {
  if ((typeof value === 'string') || (value instanceof String)) {
    value = parseFloat(value.replace(',', '.'));

    return Number.isNaN(value) ? null : value;
  }

  return value;
};

export const getDefaultCurrency = () => DEFAULT_CURRENCY;

export function getContractTypes() {
  return [
    { id: 'monthly', name: 'Monthly' },
    { id: 'hourly', name: 'Hourly' },
  ];
}

export function formatContractType(contractType) {
  const type = getContractTypes().find((type) => type.id === contractType);
  return type ? type.name : '-';
}

export async function getCurrencyExchangeRates() {
  return get(CURRENCY_EXCHANGE_RATES_URL);
}

export function convertCurrency(
  amount,
  currencyFrom,
  currencyTo,
  currencyExchangeRates = {},
) {
  if (currencyFrom !== currencyTo) {
    if (currencyFrom !== DEFAULT_CURRENCY) {
      const rate = currencyExchangeRates[currencyFrom];

      amount /= rate;
    }

    if (currencyTo !== DEFAULT_CURRENCY) {
      const rate = currencyExchangeRates[currencyTo];

      amount *= rate;
    }
  }

  return formatNumber(amount);
}
