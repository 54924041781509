import React, { useState, useEffect, useContext } from 'react';

import { ConsoleContext } from 'services/context';

import {
  getPositionOptions,
} from 'services/opportunity';

import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from 'components/CustomDialog/CustomDialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Button from 'components/CustomButtons/Button';
import TextField from '@material-ui/core/TextField';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import { updateTemplate, createTemplate } from 'services/opportunityTemplate';
import { capitalizeFirstLetter } from 'services/text';

const useStyles = makeStyles(() => ({
  grid: {
    marginTop: '15px',
  },
}));

export default function TemplateDialog({
  open, position, field, onClose, templates,
}) {
  const classes = useStyles();

  const {
    showError, showSuccess, t,
  } = useContext(ConsoleContext);

  const [openDialog, setOpenDialog] = useState(open);

  const [changedTemplate, setChangedTemplate] = useState({ });

  const handleChangeTemplate = (position) => {
    const findTemplate = () => templates.find((t) => t.position === position);

    if (templates.length) {
      const template = findTemplate();
      if (template) {
        setChangedTemplate({
          ...changedTemplate,
          responsibilities: template.responsibilities,
          requirements: template.requirements,
          position: template.position,
          _id: template._id,
        });
      } else {
        setChangedTemplate({
          ...changedTemplate,
          position,
          responsibilities: '',
          requirements: '',
          _id: null,
        });
      }
    }
  };

  useEffect(() => {
    if (changedTemplate.position) {
      handleChangeTemplate(changedTemplate.position);
    }
  }, [changedTemplate.position]);

  useEffect(() => {
    setOpenDialog(open);
    handleChangeTemplate(position);
  }, [open]);

  const handleCloseDialog = () => {
    onClose();
  };

  const handleChange = (field, value) => {
    setChangedTemplate({
      ...changedTemplate,
      [field]: value,
    });
  };

  const handleSubmit = async () => {
    const data = {
      ...changedTemplate,
    };

    try {
      if (changedTemplate._id) {
        await updateTemplate(data);
        showSuccess('Template updated');
      } else {
        await createTemplate(data);
        showSuccess('Template created');
      }

      onClose();
    } catch (e) {
      showError(e);
    }
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={openDialog}
    >
      <DialogTitle>
        {`Create Template for ${capitalizeFirstLetter(field)}`}
      </DialogTitle>
      <DialogContent>
        <GridContainer className={classes.grid}>

          <GridItem xs={12} sm={12} md={12}>
            <CustomSelect
              required
              label="Position"
              value={changedTemplate.position}
              options={getPositionOptions()}
              onChange={(value) => handleChange('position', value)}
            />
          </GridItem>
        </GridContainer>
        <GridContainer className={classes.grid}>
          <GridItem xs={12} sm={12} md={12}>
            <TextField
              required
              fullWidth
              label={capitalizeFirstLetter(field)}
              multiline
              rows={8}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={changedTemplate[field]}
              onChange={(e) => handleChange([field], e.target.value)}
            />
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>
          {t('Cancel')}
        </Button>

        <Button onClick={handleSubmit} color="info">
          {t('Save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
