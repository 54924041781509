import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

import { ConsoleContext } from 'services/context';

import {
  formatTechStack,
  getTechStack,
} from 'services/techStack';

import {
  formatEnglishLevel,
  formatPosition,
} from 'services/opportunity';

import {
  getCandidate,
  formatExperienceYears,
  formatCandidateRate,
  getCandidateOpportunities,
  getCandidateInterviews,
  formatContactType,
  updateCandidateStatus,
} from 'services/candidate';

import {
  formatUserName,
  getUsers,
} from 'services/user';

import {
  formatDateTime,
} from 'services/date';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Button from 'components/CustomButtons/Button';
import Table from 'components/Table/Table';

import ArrangeInterviewDialog from './components/ArrangeInterviewDialog';
import InterviewList from './components/InterviewList';
import CandidateEditDialog from './components/CandidateEditDialog';
import AddToOpportunityDialog from './components/AddToOpportunityDialog';
import CandidateStatus from './components/CandidateStatus';
import CandidateName from './components/CandidateName';
import TelegramLink from '../components/TelegramLink';

const useStyles = makeStyles(() => ({
  grid: {
    marginTop: '15px',
  },
  actions: {
    '& button': {
      marginRight: '10px',
    },
  },
}));

export default function CandidateDetails({ match: { params: { id } } }) {
  const classes = useStyles();

  const {
    showLoader, showError, showSuccess, showConfirm, t,
  } = useContext(ConsoleContext);

  const [candidate, setCandidate] = useState();

  const [techStack, setTechStack] = useState([]);

  const [fields, setFields] = useState();

  const [users, setUsers] = useState([]);

  const [opportunities, setOpportunities] = useState([]);

  const [openArrangeInterview, setOpenArrangeInterview] = useState(false);

  const [interviews, setInterviews] = useState([]);

  const [openCandidateDialog, setOpenCandidateDialog] = useState(false);

  const [openOpportunityDialog, setOpenOpportunityDialog] = useState(false);

  const columns = [
    { id: 'status', name: 'Status' },
    { id: 'recruiter', name: 'Recruiter' },
    { id: 'position', name: 'Position' },
    { id: 'rate', name: 'Rate' },
    { id: 'englishLevel', name: 'English Level' },
    { id: 'location', name: 'Location' },
    { id: 'createdAt', name: 'Date Created' },
    { id: 'cvLink', name: 'CV' },
    { id: 'cvLinkPrepared', name: 'Internal CV' },
    { id: 'techStack', name: '' },
    { id: 'contacts', name: '' },
  ];

  useEffect(() => {
    (async () => {
      try {
        const usersData = await getUsers();

        setUsers(usersData);
      } catch (e) {
        showError(e);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      showLoader(true);

      try {
        const techStackData = await getTechStack();
        const candidateData = await getCandidate(id);
        const opportunitiesData = await getCandidateOpportunities(id);
        const interviewsData = await getCandidateInterviews(id);

        setTechStack(techStackData);
        setCandidate(candidateData);
        setOpportunities(opportunitiesData);
        setInterviews(interviewsData);
      } catch (e) {
        showError(e);
      }

      showLoader(false);
    })();
  }, [id]);

  useEffect(() => {
    if (candidate) {
      const {
        name,
        englishLevel,
        cvLink,
        cvLinkPrepared,
        location,
        position,
        createdAt,
      } = candidate;

      setFields({
        name,
        status: renderStatus(),
        englishLevel: formatEnglishLevel(englishLevel),
        rate: formatCandidateRate(candidate),
        techStack: renderTechStack(candidate.techStack),
        contacts: renderContacts(candidate),
        cvLink: cvLink ? (<a href={cvLink} target="_blank" rel="noreferrer">Download</a>) : '-',
        cvLinkPrepared: cvLinkPrepared ? (<a href={cvLinkPrepared} target="_blank" rel="noreferrer">{cvLinkPrepared}</a>) : '-',
        recruiter: formatRecruiter(),
        location: location || '-',
        position: formatPosition(position),
        createdAt: formatDateTime(createdAt),
      });
    }
  }, [candidate]);

  const handleSave = (changedCandidate) => {
    setCandidate(changedCandidate);
    setOpenCandidateDialog(false);
  };

  const handleEdit = () => {
    setOpenCandidateDialog(true);
  };

  const handleEditCancel = () => {
    setOpenCandidateDialog(false);
  };

  const handleArrangeInterview = () => {
    setOpenArrangeInterview(true);
  };

  const handleCancelInterview = () => {
    setOpenArrangeInterview(false);
  };

  const handleCreateInterview = (interview) => {
    setOpenArrangeInterview(false);

    setInterviews([interview, ...interviews]);
  };

  const handleAddToOpportunity = () => {
    setOpenOpportunityDialog(true);
  };

  const handleCancelOpportunity = () => {
    setOpenOpportunityDialog(false);
  };

  const handleSaveOpportunity = (opportunity) => {
    const changedOpportunities = [...opportunities];

    changedOpportunities.push(opportunity);

    setOpportunities(changedOpportunities);

    setOpenOpportunityDialog(false);
  };

  const handleChangeStatus = (status) => {
    showConfirm(async () => {
      try {
        const changedCandidate = await updateCandidateStatus(candidate._id, status);

        showSuccess('Candidate status changed');

        setCandidate(changedCandidate);
      } catch (e) {
        showError(e);
      }
    });
  };

  const formatRecruiter = () => {
    const recruiter = users.find((user) => user._id === candidate.recruiterId);

    return formatUserName(recruiter);
  };

  const renderStatus = () => (<CandidateStatus candidate={candidate} />);

  const renderTechStack = (candidateTechStack) => {
    if (!candidateTechStack.length) {
      return null;
    }

    const tableHead = ['Technology', 'Experience'];

    const tableData = candidateTechStack.map((item) => [
      formatTechStack(item.id, techStack),
      formatExperienceYears(item.experienceYears),
    ]);

    return (<Table tableHead={tableHead} tableData={tableData} />);
  };

  const renderContacts = (candidate) => {
    const { contacts, partnerCompanyId } = candidate;

    if (!contacts) {
      return null;
    }

    const contactColumns = [
      { id: 'type', name: 'Type' },
      { id: 'phone', name: 'Phone' },
      { id: 'telegram', name: 'Telegram ID' },
      { id: 'skype', name: 'Skype ID' },
    ];

    const tableData = [];

    contactColumns.forEach(({ id: fieldId, name }) => {
      let value = contacts[fieldId];

      if (value) {
        switch (fieldId) {
          case 'type':
            value = formatContactType(value);

            if (partnerCompanyId) {
              value = (
                <>
                  <span>
                    {value}
                    {' '}
                  </span>
                  <Link to={`/sales/partner-companies/${partnerCompanyId}`} target="_blank">
                    (open)
                  </Link>
                </>
              );
            }
            break;

          case 'telegram':
            value = (<TelegramLink telegram={value} />);
            break;

          default:
            break;
        }

        tableData.push([
          name,
          value,
        ]);
      }
    });

    return (
      <>
        <h4>Contacts</h4>
        <Table tableData={tableData} />
      </>
    );
  };

  if (!candidate) {
    return null;
  }

  return (
    <>
      <GridContainer>
        { fields ? (
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="success">
                    <h4>
                      Candidate&nbsp;
                      <CandidateName candidate={candidate} />
                    </h4>
                  </CardHeader>

                  <CardBody>
                    { columns.map((column) => (
                      <GridContainer key={column.id} className={classes.grid}>
                        <GridItem xs={12} sm={12} md={8}>
                          { column.name ? (
                            <strong>
                              {column.name}
                              :
                              {' '}
                            </strong>
                          ) : null }
                          {fields[column.id]}
                        </GridItem>
                      </GridContainer>
                    )) }

                    <GridContainer className={`${classes.grid} ${classes.actions}`}>
                      <GridItem xs={12} sm={12} md={8}>
                        <Button color="primary" onClick={() => handleEdit()}>
                          {t('Edit')}
                        </Button>

                        { candidate.status === 'available' ? (
                          <>
                            <Button color="info" onClick={() => handleAddToOpportunity()}>
                              {t('Add to Position')}
                            </Button>

                            <Button color="warning" onClick={() => handleArrangeInterview()}>
                              {t('Arrange Interview')}
                            </Button>

                            <Button color="danger" onClick={() => handleChangeStatus('archived')}>
                              {t('Archive')}
                            </Button>
                          </>
                        ) : (
                          <Button color="success" onClick={() => handleChangeStatus('available')}>
                            {t('Make Available')}
                          </Button>
                        ) }
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="warning">
                    <h4>Interviews</h4>
                  </CardHeader>

                  <CardBody>
                    <InterviewList
                      interviews={interviews}
                      users={users}
                      opportunities={opportunities}
                    />
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </GridItem>
        ) : null }
      </GridContainer>

      <ArrangeInterviewDialog
        candidate={candidate}
        users={users}
        opportunities={opportunities}
        open={openArrangeInterview}
        onSave={handleCreateInterview}
        onClose={handleCancelInterview}
      />

      <CandidateEditDialog
        candidate={candidate}
        open={openCandidateDialog}
        techStack={techStack}
        onSave={handleSave}
        onClose={() => handleEditCancel()}
      />

      <AddToOpportunityDialog
        candidate={candidate}
        assignedOpportunities={opportunities}
        open={openOpportunityDialog}
        onSave={handleSaveOpportunity}
        onClose={handleCancelOpportunity}
      />
    </>
  );
}
