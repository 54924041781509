import React from 'react';

// core components
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import Dialog from 'components/CustomDialog/CustomDialog';
import TableBody from '@material-ui/core/TableBody';
import DialogActions from '@material-ui/core/DialogActions';
import moment from 'moment/moment';
import TableCell from '@material-ui/core/TableCell';
import Button from 'components/CustomButtons/Button';
import TableHead from '@material-ui/core/TableHead';
import { formatNumber } from 'services/finance';

export default function BillableByCompanyDialog({
  open,
  formatMemberName,
  userId,
  projectId,
  weekIndex,
  summary,
  weeks,
  onClose,
}) {
  const dialogTitle = `${formatMemberName({ userId })} BC hours`;

  const prepareTotal = (summary, weeks) => {
    const total = {};
    const dayWeekMap = {};

    function prepareWeek(summaryDay, userId, projectId, weekIndex) {
      if (!total[userId][projectId][weekIndex]) {
        total[userId][projectId][weekIndex] = {
          factBillable: 0,
          factNonBillable: 0,
          holiday: 0,
          bench: 0,
          billableByCompany: 0,
        };
      }

      const totalItem = total[userId][projectId][weekIndex];

      totalItem.factBillable += summaryDay.factBillable;
      totalItem.factNonBillable += summaryDay.factNonBillable;
      if (summaryDay.holiday && !summaryDay.partTime && summaryDay.isBillableByCompany && summaryDay.date.isBefore(moment(), 'day')) {
        totalItem.holiday += summaryDay.potential;
      } else if (
        summaryDay.isBillableByCompany
          && !summaryDay.holiday
          && !summaryDay.partTime
          && summaryDay.factBillable < summaryDay.potential
          && summaryDay.factNonBillable < summaryDay.potential
          && summaryDay.date.isBefore(moment(), 'day')
      ) {
        const totalBillable = (summaryDay.factBillable + summaryDay.factNonBillable);
        if (totalBillable < summaryDay.potential) {
          totalItem.bench += (summaryDay.potential - totalBillable);
        } else if (totalBillable !== summaryDay.potential) {
          totalItem.bench += summaryDay.potential;
        }
      }
      totalItem.billableByCompany += summaryDay.billableByCompany;
    }

    weeks.forEach((days, index) => {
      days.forEach((day) => {
        dayWeekMap[day.date.format('D')] = index;
      });
    });

    if (!total[userId]) {
      total[userId] = {};
    }

    Object.keys(summary[userId]).forEach((projectId) => {
      if (!total[userId][projectId]) {
        total[userId][projectId] = {};
      }

      Object.values(summary[userId][projectId]).forEach((summaryDay) => {
        const weekIndex = dayWeekMap[moment(summaryDay.date).format('D')];

        prepareWeek(summaryDay, userId, projectId, weekIndex);
      });

      Object.values(summary[userId][projectId]).forEach((totalWeek) => {
        prepareWeek(totalWeek, userId, projectId, 'total');
      });
    });

    return total;
  };

  const total = prepareTotal(summary, weeks);

  const handleCloseReportDialog = () => {
    onClose();
  };

  const fields = [
    { id: 'factBillable', name: 'Billable' },
    { id: 'factNonBillable', name: 'Non billable' },
    { id: 'holiday', name: 'Vacation & Dayoffs' },
    { id: 'bench', name: 'Bench' },
    { id: 'billableByCompany', name: 'Total' },
  ];

  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={handleCloseReportDialog}
      >
        <DialogTitle>
          {dialogTitle}
        </DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>Hours</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { fields.map((field) => (
                      <TableRow key={field.id}>
                        <TableCell>
                          {field.name === 'Total' ? (
                            <strong>
                              {field.name}
                            </strong>
                          ) : field.name}
                        </TableCell>
                        <TableCell>
                          {field.name === 'Total' ? (
                            <strong>
                              {formatNumber(total[userId][projectId][weekIndex][field.id])}
                            </strong>
                          ) : formatNumber(total[userId][projectId][weekIndex][field.id]) }
                        </TableCell>
                      </TableRow>
                    )) }
                  </TableBody>
                </Table>
              </TableContainer>

            </GridItem>
          </GridContainer>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseReportDialog}>
            Close
          </Button>
        </DialogActions>

      </Dialog>
    </>
  );
}
