// @material-ui/icons
import Dashboard from '@material-ui/icons/Dashboard';
import CodeIcon from '@material-ui/icons/Code';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import SettingsIcon from '@material-ui/icons/Settings';
import PeopleIcon from '@material-ui/icons/People';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';

// core components/views for Admin layout
import DashboardPage from 'views/Dashboard/Dashboard';
import ProductionPage from 'views/Production/Production';
import GeneralPage from 'views/General/General';
import AdminPage from 'views/Admin/Admin';
import FinancePage from 'views/Finance/Finance';
import PeoplePage from 'views/People/People';
import SalesPage from 'views/Sales/Sales';

const dashboardRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: Dashboard,
    component: DashboardPage,
    layout: '',
  },
  {
    path: '/general',
    name: 'GCC',
    icon: BubbleChartIcon,
    component: GeneralPage,
    layout: '',
    can: 'general',
  },
  {
    path: '/production',
    name: 'Production',
    icon: CodeIcon,
    component: ProductionPage,
    layout: '',
    can: 'production',
  },
  {
    path: '/people',
    name: 'People',
    icon: PeopleIcon,
    component: PeoplePage,
    layout: '',
    can: 'people',
  },
  {
    path: '/finance',
    name: 'Finance',
    icon: AttachMoneyIcon,
    component: FinancePage,
    layout: '',
    can: 'finance',
  },
  {
    path: '/sales',
    name: 'Sales',
    icon: TransferWithinAStationIcon,
    component: SalesPage,
    layout: '',
    can: 'sales',
  },
  {
    path: '/admin',
    name: 'Admin',
    icon: SettingsIcon,
    component: AdminPage,
    layout: '',
    can: 'admin',
  },
];

export default dashboardRoutes;
