import React, { useState, useEffect, useContext } from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import { getRole } from 'services/role';

import { getPermissions, updateRolePermissions } from 'services/permission';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody';
import Button from 'components/CustomButtons/Button.js';
import Checkbox from '@material-ui/core/Checkbox';
import CardFooter from 'components/Card/CardFooter.js';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { ConsoleContext } from 'services/context';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '100%',
  },
  userName: {
    marginTop: '20px',
  },
}));

export default function PermissionDetails(props) {
  const { roleId } = props.match.params;

  useEffect(() => {
    async function fetchData() {
      showLoader(true);

      try {
        const role = await getRole(roleId);

        if (!role.permissions) {
          role.permissions = [];
        }

        const permissions = await getPermissions();

        setRole(role);
        setPermissions(permissions);
      } catch (error) {
        showError(error);
      }

      showLoader(false);
    }

    fetchData();
  }, [roleId]);

  const classes = useStyles();

  const {
    showError, showSuccess, showConfirm, showLoader,
  } = useContext(ConsoleContext);

  const [role, setRole] = useState();

  const [permissions, setPermissions] = useState([]);

  const handlePermissionChange = (permission, enabled) => {
    const { name } = permission;
    const permissions = [...role.permissions];

    if (enabled) {
      permissions.push(name);
    } else {
      permissions.splice(permissions.indexOf(name), 1);
    }

    setRole({ ...role, permissions });
  };

  const handleSubmit = async () => {
    const permissions = [...role.permissions];

    try {
      await updateRolePermissions(roleId, permissions);

      showSuccess('Permissions updated');
    } catch (e) {
      showError(e);
    }
  };

  const checkEnabled = (permission) => role.permissions.indexOf(permission.name) > -1;

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          { role ? (
            <Card>
              <CardHeader color="success">
                <h4>{`Edit "${role.name}" Permissions`}</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Enabled</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {permissions.map((permission) => (
                            <TableRow key={permission.name}>
                              <TableCell>{permission.name}</TableCell>
                              <TableCell>
                                <Checkbox
                                  checked={checkEnabled(permission)}
                                  onChange={(e) => handlePermissionChange(permission, e.target.checked)}
                                  color="primary"
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="primary" onClick={handleSubmit}>Update</Button>
              </CardFooter>
            </Card>
          ) : null}
        </GridItem>
      </GridContainer>
    </div>
  );
}
