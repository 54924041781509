import React, { useState, useEffect, useContext } from 'react';

import { ConsoleContext } from 'services/context';

import {
  getPartnerCompanyStatusOptions,
  formatPartnerCompanyStatus,
  updateOpportunityPartnerCompanyStatus,
} from 'services/opportunity';

import { makeStyles } from '@material-ui/core/styles';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(() => ({
  alert: {
    display: 'block',
    '& .MuiAlert-message': {
      padding: 0,
    },
    '& .MuiInput-underline::before': {
      content: 'none',
    },
  },
}));

export default function PartnerCompanyStatus({ opportunity, opportunityCompany, onLoading }) {
  const classes = useStyles();

  const {
    showError, showSuccess, can,
  } = useContext(ConsoleContext);

  const [changedStatus, setChangedStatus] = useState(opportunityCompany.status);

  const [canEdit, setCanEdit] = useState(false);

  const colors = {
    added: 'info',
    contacted: 'warning',
    received_resume: 'success',
    not_responding: 'error',
    no_candidates: 'error',
    ignore: 'error',
  };

  const options = getPartnerCompanyStatusOptions().filter((item) => item.id !== 'received_resume');

  useEffect(() => {
    (async () => {
      setCanEdit(await can('sales:partnerCompanies:edit') && (changedStatus !== 'received_resume'));
    })();
  }, []);

  const handleChange = async (status) => {
    try {
      onLoading(true);

      await updateOpportunityPartnerCompanyStatus(opportunity._id, {
        id: opportunityCompany.id,
        status,
      });

      setChangedStatus(status);

      showSuccess('Status changed');
    } catch (e) {
      showError(e);
    }

    onLoading(false);
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        { canEdit ? (
          <Alert
            icon={false}
            color={colors[changedStatus]}
            className={classes.alert}
          >
            <CustomSelect
              required
              value={changedStatus}
              options={options}
              onChange={(value) => handleChange(value)}
            />
          </Alert>
        ) : (
          <Alert
            icon={false}
            color={colors[changedStatus]}
          >
            { formatPartnerCompanyStatus(opportunityCompany.status) }
          </Alert>
        ) }
      </GridItem>
    </GridContainer>
  );
}
