import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';

import { ConsoleContext } from 'services/context';

import {
  formatTechStackList,
  getTechStack,
} from 'services/techStack';

import {
  formatPartnerCompanyStatus,
  getPartnerCompany,
  updatePartnerCompanyStatus,
} from 'services/partnerCompany';

import {
  formatUserName,
} from 'services/user';

import {
  formatDate,
} from 'services/date';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Button from 'components/CustomButtons/Button';
import Table from 'components/Table/Table';

import PartnerCompanyEdit from './components/PartnerCompanyEdit';
import TelegramLink from '../components/TelegramLink';

const useStyles = makeStyles(() => ({
  grid: {
    marginTop: '15px',
  },
  actions: {
    '& button': {
      marginRight: '10px',
    },
  },
}));

export default function PartnerCompanyDetails({ match: { params: { id } } }) {
  const classes = useStyles();

  const {
    showLoader, showError, t, can, showConfirm, showSuccess,
  } = useContext(ConsoleContext);

  const [company, setCompany] = useState();

  const [editMode, setEditMode] = useState(!id);

  const [canEdit, setCanEdit] = useState(false);

  const [techStack, setTechStack] = useState([]);

  const [redirectId, setRedirectId] = useState();

  const [fields, setFields] = useState();

  const columns = [
    { id: 'name', name: 'Company Name' },
    { id: 'status', name: 'Status' },
    { id: 'techStack', name: 'Tech Stack' },
    { id: 'manager', name: 'Domestic Partner' },
    { id: 'createdAt', name: 'Creation Date' },
    { id: 'contacts', name: '' },
  ];

  useEffect(() => {
    (async () => {
      showLoader(true);

      setCanEdit(await can('sales:partnerCompanies:edit'));

      try {
        let company = {};

        const techStack = await getTechStack();

        if (id) {
          company = await getPartnerCompany(id);
        }

        setTechStack(techStack);
        setCompany(company);
      } catch (e) {
        showError(e);
      }

      showLoader(false);
    })();
  }, [id]);

  useEffect(() => {
    if (company && company._id) {
      const {
        name,
        manager,
        techStack: companyTechStack,
        contacts,
        createdAt,
        status,
      } = company;

      setFields({
        name,
        manager: formatUserName(manager),
        contacts: renderContacts(contacts),
        techStack: formatTechStackList(companyTechStack, techStack),
        createdAt: formatDate(createdAt),
        status: formatPartnerCompanyStatus(status),
      });
    }
  }, [company]);

  const handleSave = (changedCompany) => {
    if (!company._id) {
      setRedirectId(changedCompany._id);
    } else {
      setCompany(changedCompany);
      setEditMode(false);
    }
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleEditCancel = () => {
    setEditMode(false);
  };

  const handleStatusChange = (status) => {
    showConfirm(async () => {
      showLoader(true);

      const data = { ...company };

      data.status = status;

      try {
        await updatePartnerCompanyStatus(data);

        showSuccess(`Company ${status}`);
        setCompany(data);
      } catch (e) {
        showError(e);
      }

      showLoader(false);
    });
  };

  const renderContacts = (contacts) => {
    if (!contacts) {
      return null;
    }

    const columns = ['Name', 'Telegram ID', 'Phone', 'Skype ID', 'Email'];

    const tableData = [];

    contacts.forEach((contact) => {
      const {
        name,
        telegram,
        phone,
        skype,
        email,
      } = contact;

      tableData.push([
        name || '-',
        (<TelegramLink telegram={telegram} />),
        phone || '-',
        skype || '-',
        email || '-',
      ]);
    });

    return (
      <>
        <h4>Contacts</h4>
        <Table tableHead={columns} tableData={tableData} />
      </>
    );
  };

  if (redirectId) {
    return (<Redirect to="/sales/partner-companies" />);
  }

  if (!company) {
    return null;
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        { editMode ? (
          <Card>
            <CardHeader color="success">
              <h4>{ company._id ? 'Edit Company' : 'Create Company' }</h4>
            </CardHeader>
            <CardBody>
              <PartnerCompanyEdit
                company={company}
                techStack={techStack}
                onSave={handleSave}
                onCancel={handleEditCancel}
              />
            </CardBody>
          </Card>
        ) : (
          <>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="success">
                    <h4>
                      Company &quot;
                      {company.name}
                      &quot;
                    </h4>
                  </CardHeader>

                  { fields ? (
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          { columns.map((column) => (
                            <GridContainer key={column.id} className={classes.grid}>
                              <GridItem xs={12} sm={12} md={12}>
                                { column.name ? (
                                  <strong>
                                    {column.name}
                                    :
                                    {' '}
                                  </strong>
                                ) : null }
                                {fields[column.id]}
                              </GridItem>
                            </GridContainer>
                          )) }
                        </GridItem>
                      </GridContainer>

                      { canEdit ? (
                        <GridContainer className={`${classes.grid} ${classes.actions}`}>
                          <GridItem xs={12} sm={12} md={12}>
                            <Button color="primary" onClick={() => handleEdit()}>
                              {t('Edit')}
                            </Button>

                            { company.status === 'active' ? (
                              <Button color="danger" onClick={() => handleStatusChange('archived')}>
                                {t('Archive')}
                              </Button>
                            ) : (
                              <Button color="success" onClick={() => handleStatusChange('active')}>
                                {t('Activate')}
                              </Button>
                            ) }
                          </GridItem>
                        </GridContainer>
                      ) : null }
                    </CardBody>
                  ) : null }
                </Card>
              </GridItem>
            </GridContainer>
          </>
        ) }
      </GridItem>
    </GridContainer>
  );
}
