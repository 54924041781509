import React, { useContext, useEffect, useState } from 'react';

import { ConsoleContext } from 'services/context';

import {
  formatDateTime,
} from 'services/date';

import {
  getFinmapLastSync,
  syncFinmap,
} from 'services/finmap';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Alert from '@material-ui/lab/Alert';

import MonthPicker from 'components/MonthPicker/MonthPicker';
import Button from 'components/CustomButtons/Button';

export default function Finmap() {
  const {
    showSuccess, showError, showLoader, t,
  } = useContext(ConsoleContext);

  const [date, setDate] = useState({
    year: 0,
    month: 0,
  });

  const [lastSyncDate, setLastSyncDate] = useState();

  useEffect(() => {
    const { year, month } = date;

    if (!year) {
      return;
    }

    (async () => {
      showLoader(true);

      try {
        const response = await getFinmapLastSync(year, month);

        setLastSyncDate(response.syncAt);
      } catch (e) {
        setLastSyncDate(null);
      }

      showLoader(false);
    })();
  }, [date]);

  const handleMonthChange = async ({ year, month }) => {
    setDate({ year, month });
  };

  const handleSync = async () => {
    const { year, month } = date;

    showLoader(true);

    try {
      const response = await syncFinmap(year, month);

      showSuccess(`Sync Finished. Created ${response.recordsCount} records`);

      setLastSyncDate(new Date());
    } catch (e) {
      showError(e);
    }

    showLoader(false);
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <h4>Finmap</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={2}>
                <MonthPicker
                  onChange={handleMonthChange}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={7}>
                <Button color="primary" onClick={handleSync}>{t('Sync')}</Button>
              </GridItem>

              <GridItem xs={12} sm={12} md={3}>
                <Alert icon={false} color="info">
                  <strong>{t('Last Sync')}</strong>
                  { ': ' }
                  { formatDateTime(lastSyncDate) }
                </Alert>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
