import React from 'react';

export default function TelegramLink({ telegram }) {
  return telegram ? (
    <a href={`https://t.me/${telegram}`} target="_blank" rel="noreferrer">
      @
      {telegram}
    </a>
  ) : '-';
}
