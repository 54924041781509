import React, { useState, useEffect, useContext } from 'react';

import {
  createCandidateInterview,
} from 'services/candidate';

import {
  formatUserName,
} from 'services/user';

import {
  getInterviewOptions,
} from 'services/opportunity';

import { ConsoleContext } from 'services/context';

import { makeStyles } from '@material-ui/core/styles';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Button from 'components/CustomButtons/Button';
import CustomSelect from 'components/CustomSelect/CustomSelect';

const useStyles = makeStyles(() => ({
  grid: {
    marginTop: '15px',
  },
}));
export default function CandidateArrangeInterview({
  open,
  users,
  opportunity,
  opportunityCandidate,
  onClose,
  onLoading,
  onArrange,
}) {
  const classes = useStyles();

  const { showError, showSuccess, t } = useContext(ConsoleContext);

  const [interviewers, setInterviewers] = useState([]);

  const [interview, setInterview] = useState();

  const requiredInterviews = getInterviewOptions().filter((item) => opportunity.requiredInterviews.includes(item.id));

  useEffect(() => {
    const initialInterview = {
      candidateId: opportunityCandidate.id,
      opportunityIds: [opportunity.id],
      type: requiredInterviews.length === 1 ? requiredInterviews[0].id : '',
      interviewerId: (requiredInterviews.length === 1 && requiredInterviews[0].id === 'english' ? opportunity.managerId : ''),
    };
    setInterview(initialInterview);
  }, [open, opportunityCandidate]);

  useEffect(() => {
    const interviewers = users.map((user) => ({
      id: user._id,
      name: formatUserName(user),
    }));

    setInterviewers(interviewers);
  }, [users]);

  const handleCloseDialog = () => {
    onClose();
  };

  const handleChange = (field, value) => {
    const data = { ...interview };

    data[field] = value;

    if (value === 'english') {
      data.interviewerId = opportunity.managerId;
    }

    setInterview(data);
  };

  const handleSubmit = async () => {
    onLoading(true);
    const data = { ...interview };

    try {
      await createCandidateInterview(opportunityCandidate.id, data);

      showSuccess('Interview arranged');
      onClose();
      onArrange();
    } catch (e) {
      showError(e);
    }
    onLoading(false);
  };

  if (!interview) {
    return null;
  }

  return open && (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <CustomSelect
            required
            label="Type"
            value={interview.type}
            options={requiredInterviews}
            onChange={(value) => handleChange('type', value)}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={6}>
          <CustomSelect
            required
            label="Interviewer"
            value={interview.interviewerId}
            options={interviewers}
            onChange={(value) => handleChange('interviewerId', value)}
          />
        </GridItem>
      </GridContainer>
      <GridContainer className={classes.grid}>
        <GridItem xs={12} sm={12} md={12}>
          <Button color="info" size="sm" onClick={handleSubmit}>
            {t('Save')}
          </Button>
          <Button size="sm" onClick={handleCloseDialog}>
            {t('Cancel')}
          </Button>
        </GridItem>
      </GridContainer>
    </>
  );
}
