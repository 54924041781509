import React, {
  useState, useEffect, useContext, Fragment,
} from 'react';
import { Link } from 'react-router-dom';
import clone from 'just-clone';

import {
  getPublicInterview,
  updatePublicInterview,
  getTechStackLevelOptions,
  formatTechStackLevel,
} from 'services/candidate';

import {
  formatUserName,
} from 'services/user';

import {
  formatCommunicationEfficiency,
  formatEnglishLevel,
  formatInterviewType,
  getCommunicationEfficiencyOptions,
  getEnglishLevelOptions,
} from 'services/opportunity';

import {
  formatTechStack,
  getTechStack,
} from 'services/techStack';

import { ConsoleContext } from 'services/context';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import TextField from '@material-ui/core/TextField';

import CustomSelect from 'components/CustomSelect/CustomSelect';
import Table from 'components/Table/Table';

const useStyles = makeStyles(() => ({
  grid: {
    marginTop: '10px',
  },
  techName: {
    lineHeight: '75px',
  },
}));

const formatName = (name) => {
  const [firstName, lastName] = name.split(' ');
  return `${firstName} ${lastName ? `${lastName.charAt(0)}.` : ''}`;
};

export default function PublicInterviewFeedback(props) {
  const { id } = props.match.params;

  const {
    showLoader, showError, showSuccess, showPageTitle,
  } = useContext(ConsoleContext);

  showPageTitle('Interview Feedback');

  // styles
  const classes = useStyles();

  const [interview, setInterview] = useState();

  const [fields, setFields] = useState({});

  const [columns, setColumns] = useState([]);

  const [techStack, setTechStack] = useState([]);

  const recommendOptions = [
    { id: 'yes', name: 'Yes' },
    { id: 'no', name: 'No' },
  ];

  useEffect(() => {
    showLoader(true);

    (async () => {
      try {
        const interview = await getPublicInterview(id);

        let techStack = [];

        if (interview.type === 'tech') {
          techStack = await getTechStack();

          setTechStack(techStack);
        }

        if (interview.status !== 'completed') {
          interview.feedback = {
            summary: '',
            callRecordingLink: '',
            opportunities: interview.opportunities.map((opportunity) => ({
              id: opportunity._id,
              comment: '',
              recommend: null,
            })),
          };

          switch (interview.type) {
            case 'tech':
              prepareTechInterview(interview, techStack);
              break;

            case 'english':
              prepareEnglishInterview(interview);
              break;

            case 'soft_skills':
              prepareSoftSkillsInterview(interview);
              break;

            default:
              break;
          }
        }

        setInterview(interview);
      } catch (e) {
        showError(e);
      }

      showLoader(false);
    })();
  }, [id]);

  useEffect(() => {
    if (!interview) {
      return;
    }

    const columns = [
      { id: 'candidate', name: 'Candidate' },
      { id: 'interviewer', name: 'Interviewer' },
    ];

    const fields = {
      candidate: formatName(interview.candidate.name),
      interviewer: formatName(formatUserName(interview.interviewer)),
    };

    if (interview.status === 'completed') {
      columns.push({ id: 'summary', name: 'Summary' });

      fields.summary = interview.feedback.summary;

      switch (interview.type) {
        case 'tech':
          columns.push({ id: 'techStack', name: '' });
          columns.push({ id: 'additionalTechStack', name: 'Additional Tech Skills' });

          fields.techStack = renderTechStack(interview.feedback.techStack, techStack);
          fields.additionalTechStack = renderAdditionalTechStack(interview.feedback.additionalTechStack);
          break;

        case 'english':
          columns.push({ id: 'englishLevel', name: 'English Level' });
          columns.push({ id: 'communicationEfficiency', name: 'Communication Efficiency' });

          fields.englishLevel = formatEnglishLevel(interview.feedback.englishLevel);
          fields.communicationEfficiency = formatCommunicationEfficiency(interview.feedback.communicationEfficiency);
          break;

        case 'soft_skills':
          columns.push({ id: 'transferInfo', name: 'Time to Transfer' });
          columns.push({ id: 'hasOtherOffers', name: 'Has Other Offers' });
          columns.push({ id: 'hasFop', name: 'Has FOP' });

          fields.transferInfo = interview.feedback.transferInfo || '-';
          fields.hasOtherOffers = formatBoolean(interview.feedback.hasOtherOffers);
          fields.hasFop = formatBoolean(interview.feedback.hasFop);

          if (interview.feedback.hasFop === false) {
            columns.push({ id: 'readyToOpenFop', name: 'Ready to open FOP' });

            fields.readyToOpenFop = formatBoolean(interview.feedback.readyToOpenFop);
          }
          break;

        default:
          break;
      }

      columns.push({ id: 'callRecordingLink', name: 'Call Recording' });

      fields.callRecordingLink = renderCallRecordingLink(interview.feedback.callRecordingLink);

      columns.push({ id: 'opportunities', name: '' });

      fields.opportunities = formatOpportunities(interview);
    }

    setColumns(columns);

    setFields(fields);
  }, [interview]);

  const prepareTechInterview = (interview) => {
    const techStackIds = {};

    interview.opportunities.forEach((item) => {
      const itemTechStack = item.techStack;

      itemTechStack.forEach((tech) => {
        techStackIds[tech.id] = true;
      });
    });

    interview.feedback.techStack = Object.keys(techStackIds).map((id) => ({
      id,
      level: '',
      comment: '',
    }));

    interview.feedback.additionalTechStack = '';
  };

  const prepareEnglishInterview = (interview) => {
    interview.feedback.englishLevel = '';
    interview.feedback.communicationEfficiency = '';
  };

  const prepareSoftSkillsInterview = (interview) => {
    interview.feedback.hasOtherOffers = null;
    interview.feedback.hasFop = null;
    interview.feedback.readyToOpenFop = null;
    interview.feedback.startInfo = '';
  };

  const handleChange = (field, value) => {
    const data = clone(interview);

    data.feedback[field] = value;

    if ((field === 'hasFop') && (value === 'yes')) {
      data.feedback.readyToOpenFop = '';
    }

    setInterview(data);
  };

  const handleOpportunityChange = (opportunityId, field, value) => {
    const data = clone(interview);

    const opportunity = data.feedback.opportunities.find((item) => item.id === opportunityId);

    opportunity[field] = value;

    setInterview(data);
  };

  const handleSubmit = async () => {
    const data = clone(interview);

    const prepareBoolean = (item, field) => {
      if (!item[field]) {
        delete item[field];
      } else {
        item[field] = item[field] === 'yes';
      }
    };

    data.feedback.opportunities.forEach((item) => prepareBoolean(item, 'recommend'));

    prepareBoolean(data.feedback, 'hasFop');
    prepareBoolean(data.feedback, 'readyToOpenFop');
    prepareBoolean(data.feedback, 'hasOtherOffers');

    const prepareLink = (item, field) => {
      if (item[field] && item[field].indexOf('http') === -1) {
        item[field] = `https://${item[field]}`;
      }
    };

    prepareLink(data.feedback, 'callRecordingLink');

    showLoader(true);

    try {
      const changedInterview = await updatePublicInterview(data);

      setInterview(changedInterview);
      showSuccess('Feedback saved');
    } catch (error) {
      showError(error);
    }

    showLoader(false);
  };

  const handleTechStackChange = (techId, field, value) => {
    const data = clone(interview);

    const item = data.feedback.techStack.find((item) => item.id === techId);

    item[field] = value;

    setInterview(data);
  };

  const formatOpportunities = (interview) => (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <h4>Vacancies</h4>
        </GridItem>
      </GridContainer>

      { interview.feedback.opportunities.map((opportunity) => (
        <div key={opportunity.id}>
          <GridContainer className={classes.grid}>
            <GridItem xs={12} sm={12} md={12}>
              <strong>
                { formatOpportunityName(opportunity, interview.opportunities) }
              </strong>
            </GridItem>
          </GridContainer>

          <GridContainer className={classes.grid}>
            <GridItem xs={12} sm={12} md={12}>
              <strong>Recommend:</strong>
              {' '}
              {formatBoolean(opportunity.recommend) }
            </GridItem>
          </GridContainer>

          { opportunity.comment ? (
            <GridContainer className={classes.grid}>
              <GridItem xs={12} sm={12} md={12}>
                <strong>Comment:</strong>
                {' '}
                { opportunity.comment }
              </GridItem>
            </GridContainer>
          ) : null }
        </div>
      )) }
    </>
  );

  const formatOpportunityName = (feedbackOpportunity, opportunities) => {
    const opportunity = opportunities.find((item) => item._id === feedbackOpportunity.id);

    return (
      <Link to={`/public/vacancies/${opportunity._id}`} target="_blank">
        { opportunity.name }
      </Link>
    );
  };

  const formatBoolean = (value) => (value === true ? 'Yes' : (value === false ? 'No' : '-'));

  const renderTechStackForm = (interviewTechStack, techStack) => (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <h4>Tech Skills *</h4>
        </GridItem>
      </GridContainer>

      { interviewTechStack.map((item) => (
        <GridContainer key={item.id} className={classes.grid}>
          <GridItem xs={12} sm={12} md={2}>
            <strong className={classes.techName}>
              { formatTechStack(item.id, techStack) }
            </strong>
          </GridItem>

          <GridItem xs={12} sm={12} md={3}>
            <CustomSelect
              required
              label="Level"
              value={item.level}
              options={getTechStackLevelOptions()}
              onChange={(value) => handleTechStackChange(item.id, 'level', value)}
            />
          </GridItem>

          <GridItem xs={12} sm={12} md={7}>
            <TextField
              fullWidth
              label="Comment"
              multiline
              rows={2}
              variant="outlined"
              value={item.comment}
              onChange={(e) => handleTechStackChange(item.id, 'comment', e.target.value)}
            />
          </GridItem>
        </GridContainer>
      )) }
    </>
  );

  const renderTechStack = (interviewTechStack, techStack) => {
    if (!interviewTechStack.length) {
      return null;
    }

    const tableHead = ['Technology', 'Level', 'Comment'];

    const tableData = interviewTechStack.map((item) => [
      formatTechStack(item.id, techStack),
      formatTechStackLevel(item.level),
      item.comment,
    ]);

    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <h4 style={{ marginBottom: 0 }}>Tech Skills</h4>
          </GridItem>
        </GridContainer>

        <GridContainer className={classes.grid}>
          <GridItem xs={12} sm={12} md={12}>
            <Table tableHead={tableHead} tableData={tableData} className={classes.techTable} />
          </GridItem>
        </GridContainer>
      </>
    );
  };

  const renderAdditionalTechStack = (additionalTechStack) => {
    if (!additionalTechStack) {
      return '-';
    }

    return (
      <ul>
        { additionalTechStack.split('\n').map((item, key) => (
          <Fragment key={key}>
            { item ? (
              <li>{item}</li>
            ) : null }
          </Fragment>
        )) }
      </ul>
    );
  };

  const renderCallRecordingLink = (link) => (link
    ? (<a href={link} target="_blank" rel="noreferrer">{link}</a>)
    : '-');

  if (!interview) {
    return null;
  }

  if ((interview.type === 'tech') && !techStack.length) {
    return null;
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardBody>
            <h4>
              {formatInterviewType(interview.type)}
              {' '}
              Interview Feedback
            </h4>

            { columns.map((column) => (
              <GridContainer key={column.id} className={classes.grid}>
                <GridItem xs={12} sm={12} md={12}>
                  { column.name ? (
                    <strong>
                      {column.name}
                      :
                      {' '}
                    </strong>
                  ) : null }

                  { fields[column.id] }
                </GridItem>
              </GridContainer>
            )) }

            { interview.status === 'arranged' ? (
              <>
                { interview.type === 'tech' ? (
                  <>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        { renderTechStackForm(interview.feedback.techStack, techStack) }
                      </GridItem>
                    </GridContainer>

                    <GridContainer className={classes.grid}>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          fullWidth
                          label="Additional Tech Skills"
                          multiline
                          rows={4}
                          variant="outlined"
                          value={interview.feedback.additionalTechStack}
                          onChange={(e) => handleChange('additionalTechStack', e.target.value)}
                        />
                      </GridItem>
                    </GridContainer>
                  </>
                ) : interview.type === 'english' ? (
                  <GridContainer className={classes.grid}>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomSelect
                        required
                        label="English Level"
                        value={interview.feedback.englishLevel}
                        options={getEnglishLevelOptions()}
                        onChange={(value) => handleChange('englishLevel', value)}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4}>
                      <CustomSelect
                        required
                        label="Communication Efficiency"
                        value={interview.feedback.communicationEfficiency}
                        options={getCommunicationEfficiencyOptions()}
                        onChange={(value) => handleChange('communicationEfficiency', value)}
                      />
                    </GridItem>
                  </GridContainer>
                ) : interview.type === 'soft_skills' ? (
                  <GridContainer className={classes.grid}>
                    <GridItem xs={12} sm={12} md={3}>
                      <TextField
                        fullWidth
                        label="Time to Transfer"
                        value={interview.feedback.transferInfo}
                        onChange={(e) => handleChange('transferInfo', e.target.value)}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3}>
                      <CustomSelect
                        label="Has Other Offers"
                        value={interview.feedback.hasOtherOffers}
                        options={recommendOptions}
                        onChange={(value) => handleChange('hasOtherOffers', value)}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3}>
                      <CustomSelect
                        label="Has FOP"
                        value={interview.feedback.hasFop}
                        options={recommendOptions}
                        onChange={(value) => handleChange('hasFop', value)}
                      />
                    </GridItem>

                    { interview.feedback.hasFop === 'no' ? (
                      <GridItem xs={12} sm={12} md={3}>
                        <CustomSelect
                          label="Ready to open FOP"
                          value={interview.feedback.readyToOpenFop}
                          options={recommendOptions}
                          onChange={(value) => handleChange('readyToOpenFop', value)}
                        />
                      </GridItem>
                    ) : null }
                  </GridContainer>
                ) : null }

                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h4>Summary *</h4>
                  </GridItem>
                </GridContainer>

                <GridContainer className={classes.grid}>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      required
                      fullWidth
                      multiline
                      rows={6}
                      variant="outlined"
                      value={interview.feedback.summary}
                      onChange={(e) => handleChange('summary', e.target.value)}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer className={classes.grid}>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      fullWidth
                      label="Call Recording Link (Noota, etc)"
                      value={interview.feedback.callRecordingLink}
                      onChange={(e) => handleChange('callRecordingLink', e.target.value)}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h4>Vacancies *</h4>
                  </GridItem>
                </GridContainer>

                { interview.feedback.opportunities.map((opportunity, index) => (
                  <div key={opportunity.id} className={classes.grid}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <strong>
                          { index + 1 }
                          )
                          {' '}
                          { formatOpportunityName(opportunity, interview.opportunities) }
                        </strong>
                      </GridItem>
                    </GridContainer>

                    <GridContainer className={classes.grid}>
                      <GridItem xs={12} sm={12} md={3}>
                        <CustomSelect
                          required
                          label="Recommend"
                          value={opportunity.recommend}
                          options={recommendOptions}
                          onChange={(value) => handleOpportunityChange(opportunity.id, 'recommend', value)}
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer className={classes.grid}>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          fullWidth
                          label="Comment"
                          multiline
                          rows={4}
                          variant="outlined"
                          value={opportunity.comment}
                          onChange={(e) => handleOpportunityChange(opportunity.id, 'comment', e.target.value)}
                        />
                      </GridItem>
                    </GridContainer>
                  </div>
                )) }
              </>
            ) : null }
          </CardBody>

          { interview.status === 'arranged' ? (
            <CardFooter className={classes.cardFooter}>
              <Button color="info" type="submit" onClick={() => handleSubmit()}>Submit</Button>
            </CardFooter>
          ) : null }
        </Card>
      </GridItem>
    </GridContainer>
  );
}
