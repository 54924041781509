import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

import {
  formatDate,
} from 'services/date';

import {
  formatUserNameById,
  getUsers,
} from 'services/user';

import {
  formatTechStackList,
  getTechStack,
} from 'services/techStack';

import {
  formatPartnerCompanyStatus,
  getPartnerCompanies,
} from 'services/partnerCompany';

import { ConsoleContext } from 'services/context';

// @material-ui/core components

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import Button from 'components/CustomButtons/Button';
import PagedTable from 'components/PagedTable/PagedTable';

import PartnerCompanyFilters from './components/PartnerCompanyFilters';
import PartnerCompanyContacts from './components/PartnerCompanyContacts';

export default function PartnerCompanyList() {
  const {
    showError, can,
  } = useContext(ConsoleContext);

  const [canEdit, setCanEdit] = useState(false);

  const [users, setUsers] = useState([]);
  const [techStack, setTechStack] = useState([]);

  const [loadRows, setLoadRows] = useState();

  const [filters, setFilters] = useState();

  const columns = [
    { id: 'name', label: 'Company Name' },
    { id: 'techStack', label: 'Tech Stack' },
    { id: 'manager', label: 'Domestic Partner' },
    { id: 'contacts', label: 'Contacts' },
    { id: 'status', label: 'Status' },
    { id: 'createdAt', label: 'Added At' },
  ];

  useEffect(() => {
    (async () => {
      setCanEdit(await can('sales:partnerCompanies:edit'));

      try {
        const users = await getUsers();
        const techStack = await getTechStack();

        setUsers(users);
        setTechStack(techStack);
      } catch (e) {
        showError(e);
      }
    })();
  }, []);

  useEffect(() => {
    if (!filters || !users.length || !techStack.length) {
      return;
    }

    populateLoadRows();
  }, [users, techStack, filters]);

  const handleFiltersChange = (filters) => {
    setFilters(filters);
  };

  const prepareRows = (companies) => companies.map((company) => {
    const {
      _id,
      managerId,
      contacts,
      techStack: companyTechStack,
      createdAt,
      status,
    } = company;

    return {
      id: _id,
      name: renderName(company),
      manager: formatUserNameById(managerId, users),
      contacts: renderContacts(contacts),
      techStack: formatTechStackList(companyTechStack, techStack),
      createdAt: formatDate(createdAt),
      status: formatPartnerCompanyStatus(status),
    };
  });

  const populateLoadRows = () => {
    const loadRows = async (page, limit) => {
      const result = await getPartnerCompanies({ ...filters, page: page + 1, limit });

      const rows = prepareRows(result.docs);

      return { rows, totalItems: result.totalDocs };
    };

    setLoadRows(() => loadRows);
  };

  const renderName = (company) => (<Link to={`/sales/partner-companies/${company._id}`}>{company.name}</Link>);

  const renderContacts = (contacts) => (<PartnerCompanyContacts contacts={contacts} />);

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={8}>
                  <PartnerCompanyFilters
                    onChange={handleFiltersChange}
                    techStack={techStack}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={4}>
                  { canEdit ? (
                    <Link to="/sales/partner-companies/create">
                      <Button color="info" style={{ float: 'right' }}>+ Create Company</Button>
                    </Link>
                  ) : null }
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <PagedTable
                    containerProps={{ style: { maxHeight: '600px' } }}
                    tableProps={{ stickyHeader: true, style: { tableLayout: 'fixed' } }}
                    columns={columns}
                    loadRows={loadRows}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
