import React from 'react';

// @material-ui/core components
import Alert from '@material-ui/lab/Alert';

import { getDefaultCurrency } from 'services/finance';
import ExchangeRates from '../../components/ExchangeRates';

export default function CurrencyExchangeInfo({ report }) {
  return (
    <Alert icon={false} color="info">
      <ExchangeRates
        baseCurrency={getDefaultCurrency()}
        currencyExchangeDate={report.currencyExchangeDate}
        currencyExchangeRates={report.currencyExchangeRates}
        inline
      />
    </Alert>
  );
}
