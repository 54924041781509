import moment from 'moment';

import {
  INVOICE_URL,
} from '../utils/apiRouting';

import {
  get, post, put, formatQuery,
} from '../utils/http';

export const statusOptions = [
  { id: 'created', name: 'Created' },
  { id: 'sent', name: 'Sent' },
  { id: 'completed', name: 'Completed' },
  { id: 'rejected', name: 'Rejected' },
];

export const invoiceDebtReason = [
  { id: 'unknown', name: 'Unknown' },
  { id: 'log_change', name: 'Log changed' },
  { id: 'overtime_change', name: 'Overtime changed' },
  { id: 'include_not_prepayment', name: 'Was not included in previous invoice prepayment' },
  { id: 'rate_change', name: 'Rate changed' },
  { id: 'allocation_change', name: 'Allocation plan changed' },
  { id: 'custom_date_change', name: 'Work calendar changed' },
  { id: 'user_custom_date_change', name: 'Partner work calendar changed' },
];

export function getDebtReasonName(id) {
  const reason = invoiceDebtReason.find((reason) => reason.id === id);
  return reason ? reason.name : id;
}

export async function getInvoices(query = {}) {
  return get(INVOICE_URL + formatQuery(query));
}

export async function getInvoice(id) {
  return get(`${INVOICE_URL}/${id}`);
}

export async function createInvoice(invoice) {
  return post(INVOICE_URL, invoice);
}

export async function updateInvoice(invoice) {
  return put(`${INVOICE_URL}/${invoice._id}`, invoice);
}

export async function updateInvoiceStatus({ _id, status, payedAmount }) {
  return put(`${INVOICE_URL}/${_id}/status`, { status, payedAmount });
}

export function getNextStatuses(currentStatus) {
  const statusFlow = {
    created: ['sent', 'rejected'],
    sent: ['completed', 'rejected'],
    completed: [],
    rejected: [],
  };

  return statusFlow[currentStatus];
}

export async function getRecentInvoices() {
  return await getInvoices({
    recent: true,
  });
}

export function formatInvoiceDateRange(startAt, endAt) {
  const startDate = moment.utc(startAt);
  const endDate = moment.utc(endAt);

  let range = '';

  if (startDate.isSame(endDate, 'year')) {
    if (startDate.isSame(endDate, 'month')) {
      range += startDate.format('D');
    } else {
      range += startDate.format('Do of MMMM');
    }
  } else {
    range += startDate.format('Do of MMMM YYYY');
  }

  range += ` - ${endDate.format('Do of MMMM YYYY')}`;

  return range;
}

export async function getClientInvoiceReport(clientId) {
  return get(`${INVOICE_URL}/client-report/${clientId}`);
}
