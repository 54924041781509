import React, { useContext, useState, useEffect } from 'react';

// core components
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import { ConsoleContext } from 'services/context';

const useStyles = makeStyles(() => ({
  tableFixed: {
    tableLayout: 'fixed',
  },
}));

export default function PagedTable({
  columns,
  rows,
  loadRows,
  defaultRowsPerPage = 20,
  tableProps = {},
  containerProps = {},
  tableFixed = false,
  storage,
}) {
  const classes = useStyles();

  const { t, showError, showLoader } = useContext(ConsoleContext);

  const paginationPerPage = storage ? storage.getValue('rowsPerPage', defaultRowsPerPage) : defaultRowsPerPage;

  const [page, setPage] = useState(0);

  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(paginationPerPage);

  const [pageRows, setPageRows] = useState([]);

  useEffect(() => {
    if (rows) {
      setPage(0);

      setCount(rows.length);
    }
  }, [rows]);

  useEffect(() => {
    if (rows) {
      setPageRows(rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage));
    }
  }, [rows, page, rowsPerPage]);

  useEffect(() => {
    if (loadRows) {
      setPage(0);
    }
  }, [loadRows]);

  useEffect(() => {
    if (!loadRows) {
      return;
    }

    (async () => {
      showLoader(true);

      try {
        const data = await loadRows(page, rowsPerPage);

        setPageRows(data.rows);
        setCount(data.totalItems);
      } catch (e) {
        showError(e);
      }

      showLoader(false);
    })();
  }, [page, rowsPerPage, loadRows]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    if (storage) {
      storage.setValue('rowsPerPage', +event.target.value);
    }

    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <TableContainer {...containerProps}>
        <Table {...tableProps} className={tableFixed ? classes.tableFixed : null}>
          <TableHead>
            <TableRow>
              { columns.map((column) => (
                <TableCell key={column.id} style={{ width: column.width || 'auto' }}>
                  {t(column.label)}
                </TableCell>
              )) }
            </TableRow>
          </TableHead>
          <TableBody>
            { pageRows.map((row) => (
              <TableRow key={row.id}>
                {columns.map((column) => (
                  <TableCell key={column.id}>
                    { row[column.id] }
                  </TableCell>
                ))}
              </TableRow>
            )) }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}
