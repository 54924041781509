import React from 'react';

import PageWithTabs from '../../components/PageWithTabs/PageWithTabs';
import UserContractList from './UserContract/UserContractList';
import UserContractDetails from './UserContract/UserContractDetails';
import ClientContractList from './ClientContract/ClientContractList';
import ClientContractDetails from './ClientContract/ClientContractDetails';
import ProfitReport from './ProfitReport/ProfitReport';
import RoleList from '../Role/RoleList';
import RoleDetails from '../Role/RoleDetails';
import ClientList from './Invoice/ClientList';
import ClientInvoiceList from './Invoice/ClientInvoiceList';
import InvoiceDetails from './Invoice/InvoiceDetails';
import Payroll from './Payroll/Payroll';
import PayrollDetails from './Payroll/PayrollDetails';
import Finmap from './Finmap/Finmap';

export default function Finance(props) {
  const entities = [{
    id: 'role',
    path: 'roles',
    name: 'Roles',
    can: 'finance:roles:view',
    components: [{
      path: '/:circleId/roles/create',
      component: RoleDetails,
    }, {
      path: '/:circleId/roles/:id',
      component: RoleDetails,
    }, {
      path: '/:circleId/roles',
      component: RoleList,
    }],
  }, {
    id: 'profitReport',
    path: 'profit-report',
    name: 'PP Report',
    can: 'finance:profitReport:view',
    components: [{
      path: '/finance/profit-report',
      component: ProfitReport,
    }],
  }, {
    id: 'invoices',
    path: 'invoices',
    name: 'Invoices',
    can: 'finance:invoices:view',
    components: [{
      path: '/finance/invoices/:clientId/create',
      component: InvoiceDetails,
    }, {
      path: '/finance/invoices/:clientId/:id',
      component: InvoiceDetails,
    }, {
      path: '/finance/invoices/:clientId',
      component: ClientInvoiceList,
    }, {
      path: '/finance/invoices',
      component: ClientList,
    }],
  }, {
    id: 'payroll',
    path: 'payroll',
    name: 'Payroll',
    can: 'finance:payroll:view',
    components: [{
      path: '/finance/payroll/:id',
      component: PayrollDetails,
    }, {
      path: '/finance/payroll',
      component: Payroll,
    }],
  }, {
    id: 'userContracts',
    path: 'partner-contracts',
    name: 'Partner Contracts',
    can: 'finance:userContracts:view',
    components: [{
      path: '/finance/partner-contracts/:userId',
      component: UserContractDetails,
    }, {
      path: '/finance/partner-contracts',
      component: UserContractList,
    }],
  }, {
    id: 'clientContracts',
    path: 'client-contracts',
    name: 'Client Contracts',
    can: 'finance:clientContracts:view',
    components: [{
      path: '/finance/client-contracts/:clientId',
      component: ClientContractDetails,
    }, {
      path: '/finance/client-contracts',
      component: ClientContractList,
    }],
  }, {
    id: 'finmap',
    path: 'finmap',
    name: 'Finmap',
    can: 'finance:finmap:sync',
    components: [{
      path: '/finance/finmap',
      component: Finmap,
    }],
  }];

  return (
    <PageWithTabs basePath="finance" entities={entities} {...props} />
  );
}
