import React, { useState, useEffect, useContext } from 'react';

// @material-ui/core components

import {
  getProjects,
} from 'services/project';

import {
  getSummaryProfit,
} from 'services/profitReport';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';

import MonthPicker from 'components/MonthPicker/MonthPicker';
import { ConsoleContext } from 'services/context';
import UserReport from './components/UserReport';
import ClientReport from './components/ClientReport';
import FluviusCosts from './components/FluviusCosts';
import ClientRevenueReport from './components/ClientRevenueReport';
import CurrencyExchangeInfo from './components/CurrencyExchangeInfo';

export default function ProfitReport() {
  const {
    showError, showLoader, t, getSessionStorage,
  } = useContext(ConsoleContext);

  const [date, setDate] = useState({
    year: 0,
    month: 0,
  });

  const [projects, setProjects] = useState([]);
  const [projectsLoaded, setProjectsLoaded] = useState(false);

  const [report, setReport] = useState({
    summary: [],
  });

  const [fluviusId, setFluviusId] = useState('');

  const [reportLoaded, setReportLoaded] = useState(false);

  const storage = getSessionStorage('finance');

  const findClientIdByCompanyName = (projects, companyName) => projects.find((p) => p.clientId.companyName === companyName).clientId._id;

  useEffect(() => {
    if (projects.length) setFluviusId(findClientIdByCompanyName(projects, 'Fluvius'));
  }, [projects]);

  useEffect(() => {
    (async () => {
      setProjectsLoaded(false);

      try {
        const projects = await getProjects();

        setProjects(projects);
      } catch (error) {
        showError(error);
      }

      setProjectsLoaded(true);
    })();
  }, []);

  useEffect(() => {
    setReportLoaded(false);

    (async () => {
      setReport({
        summary: [],
      });

      const { year, month } = date;

      if (!year) {
        return;
      }

      try {
        const report = await getSummaryProfit(year, month);

        setReport(report);
      } catch (error) {
        showError(error);
      }

      setReportLoaded(true);
    })();
  }, [date]);

  useEffect(() => {
    const allLoaded = reportLoaded && projectsLoaded;

    showLoader(!allLoaded);
  }, [reportLoaded, projectsLoaded]);

  const handleMonthChange = async ({ year, month }) => {
    setDate({ year, month });
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={2}>
                  <MonthPicker
                    storage={storage}
                    onChange={handleMonthChange}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          { reportLoaded ? (
            <CurrencyExchangeInfo report={report} />
          ) : null }
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <h4>{t('Client Profitability')}</h4>
            </CardHeader>
            <CardBody>
              { report.summary.length && projects.length ? (
                <ClientReport
                  summary={report.summary}
                  projects={projects}
                  minGrossMargin={0.34}
                  excludeClientId={fluviusId}
                />
              ) : t('No data') }
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info">
              <h4>{t('Partner Profitability')}</h4>
            </CardHeader>
            <CardBody>
              { report.summary.length && projects.length ? (
                <UserReport
                  summary={report.summary}
                  projects={projects}
                  minGrossMargin={0.34}
                  excludeClientId={fluviusId}
                />
              ) : t('No data') }
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h4>{t('P&L Revenue')}</h4>
            </CardHeader>
            <CardBody>
              <ClientRevenueReport
                date={date}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4>{t('Fluvius Costs')}</h4>
            </CardHeader>
            <CardBody>
              { report.summary.length && projects.length ? (
                <FluviusCosts
                  summary={report.summary}
                  projects={projects}
                  fluviusId={fluviusId}
                />
              ) : t('No data') }
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
