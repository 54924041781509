import React, { useState, useEffect, useContext } from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// core components
import { ConsoleContext } from 'services/context';
import { getUsers } from 'services/user';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '100%',
  },
}));

export default function ProjectFilters({ activeProjects, onChange, storage }) {
  const classes = useStyles();

  const { t } = useContext(ConsoleContext);

  const SELECT_ALL = '_all';

  const [filters, setFilters] = useState({
    managerId: SELECT_ALL,
    projectIds: [SELECT_ALL],
    clientId: SELECT_ALL,
  });

  const [managers, setManagers] = useState([]);

  const [users, setUsers] = useState([]);

  const [projects, setProjects] = useState([]);

  const [clients, setClients] = useState([]);

  useEffect(() => {
    (async () => {
      const users = await getUsers();

      setUsers(users);
    })();
  }, []);

  useEffect(() => {
    if (!users.length) {
      return;
    }

    populateFilters(activeProjects, users);
  }, [users, activeProjects]);

  useEffect(() => {
    onChange(applyFilters(activeProjects, filters));
  }, [filters]);

  useEffect(() => {
    if (!managers.length) {
      return;
    }

    let activeFilters = { ...filters };

    if (storage) {
      activeFilters = storage.getValue('projectFilters', activeFilters);
    }

    setFilters(activeFilters);
  }, [managers]);

  const populateFilters = (projects, users) => {
    const projectManagers = {};
    const accountManagers = {};
    const clients = {};

    projects.forEach((item) => {
      const projectManager = item.managerId ? item.managerId : null;
      const accountManager = null;

      if (projectManager) {
        projectManagers[projectManager._id] = projectManager;
      }

      if (item.clientId && item.clientId.managerId) {
        accountManagers[item.clientId.managerId] = users.find((user) => user._id === item.clientId.managerId);
      }

      clients[item.clientId._id] = item.clientId;
    });

    const managers = Object.values({ ...projectManagers, ...accountManagers })
      .sort((m1, m2) => (m1.firstName > m2.firstName ? 1 : -1));

    managers.forEach((manager) => {
      manager.titles = [];

      if (projectManagers[manager._id]) {
        manager.titles.push('PM');
      }

      if (accountManagers[manager._id]) {
        manager.titles.push('AM');
      }
    });

    setManagers(managers);
    setClients(Object.values(clients));
    setProjects(projects);
  };

  const applyFilters = (projects, filters) => {
    const appliedFilters = {
      projectIds: [],
    };

    const clients = {};

    const filteredProjects = projects.filter((project) => {
      const projectManagerId = project.managerId ? project.managerId._id : null;
      const accountManagerId = project.clientId ? project.clientId.managerId : null;

      if (filters.managerId !== SELECT_ALL) {
        const correctManager = (projectManagerId === filters.managerId) || (accountManagerId === filters.managerId);

        if (!correctManager) {
          return false;
        }
      }

      if (project.clientId
        && (filters.managerId === SELECT_ALL || filters.managerId === projectManagerId || filters.managerId === accountManagerId)
      ) {
        clients[project.clientId._id] = project.clientId;
      }

      if (filters.clientId !== SELECT_ALL) {
        const correctClient = Boolean(project.clientId && (project.clientId._id === filters.clientId));

        if (!correctClient) {
          return false;
        }
      }

      return true;
    });

    if (filters.projectIds.length && (filters.projectIds.indexOf(SELECT_ALL) === -1)) {
      appliedFilters.projectIds = filters.projectIds;
    } else {
      appliedFilters.projectIds = filteredProjects.map((project) => project._id);
    }

    setClients(Object.values(clients));
    setProjects(filteredProjects);

    return appliedFilters;
  };

  const handleManagerChange = (value) => {
    const data = { ...filters };
    data.managerId = value;

    data.clientId = SELECT_ALL;
    data.projectIds = [SELECT_ALL];

    setFilters(data);

    if (storage) {
      storage.setValue('projectFilters', data);
    }
  };

  const handleClientChange = (value) => {
    const data = { ...filters };
    data.clientId = value;

    data.projectIds = [SELECT_ALL];

    setFilters(data);

    if (storage) {
      storage.setValue('projectFilters', data);
    }
  };

  const handleProjectChange = (value) => {
    const data = { ...filters };
    const oldValue = data.projectIds;

    // handle select all
    const selectAllIndex = value.indexOf(SELECT_ALL);
    const oldSelectAllIndex = oldValue.indexOf(SELECT_ALL);

    if ((value.length > 1) && (selectAllIndex > -1)) {
      if (oldSelectAllIndex > -1) {
        value.splice(selectAllIndex, 1);
      } else {
        value = [SELECT_ALL];
      }
    } else if (!value.length) {
      value = [SELECT_ALL];
    }

    data.projectIds = value;

    setFilters(data);

    if (storage) {
      storage.setValue('projectFilters', data);
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={3}>
        <FormControl className={classes.formControl}>
          <InputLabel>{t('Manager')}</InputLabel>
          <Select
            value={filters.managerId}
            onChange={(e) => handleManagerChange(e.target.value)}
          >
            <MenuItem value={SELECT_ALL}>
              {t('All Managers')}
            </MenuItem>

            { managers
              .map((manager) => (
                <MenuItem key={manager._id} value={manager._id}>
                  {manager.firstName}
                  {' '}
                  {manager.lastName}
                  {' '}
                  (
                  {manager.titles.join(', ')}
                  )
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </GridItem>

      <GridItem xs={12} sm={12} md={3}>
        <FormControl className={classes.formControl}>
          <InputLabel>{t('Client')}</InputLabel>
          <Select
            value={filters.clientId}
            onChange={(e) => handleClientChange(e.target.value)}
          >
            <MenuItem value={SELECT_ALL}>
              {t('All Clients')}
            </MenuItem>

            { clients
              .map((client) => (
                <MenuItem key={client._id} value={client._id}>
                  {client.companyName}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </GridItem>

      <GridItem xs={12} sm={12} md={3} style={{ overflow: 'hidden' }}>
        <FormControl className={classes.formControl}>
          <InputLabel>{t('Project')}</InputLabel>
          <Select
            value={filters.projectIds}
            multiple
            onChange={(e) => handleProjectChange(e.target.value)}
          >
            <MenuItem value={SELECT_ALL}>
              {t('All Projects')}
            </MenuItem>

            { projects
              .map((project) => (
                <MenuItem key={project._id} value={project._id}>
                  {project.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </GridItem>
    </GridContainer>
  );
}
