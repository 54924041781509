import {
  PARTNER_COMPANY_URL,
} from '../utils/apiRouting';

import {
  get, post, put, formatJsonQuery,
} from '../utils/http';

import { formatOptionItem } from './text';

export function getPartnerCompanies(query = {}) {
  return get(PARTNER_COMPANY_URL + formatJsonQuery(query));
}

export async function createPartnerCompany(company) {
  return post(PARTNER_COMPANY_URL, company);
}

export async function getPartnerCompany(id) {
  return get(`${PARTNER_COMPANY_URL}/${id}`);
}

export async function updatePartnerCompany(company) {
  return put(`${PARTNER_COMPANY_URL}/${company._id}`, company);
}

export async function updatePartnerCompanyStatus({ _id, status }) {
  return put(`${PARTNER_COMPANY_URL}/${_id}/status`, { status });
}

export function getPartnerCompanyStatusOptions() {
  return [{
    id: 'active',
    name: 'Active',
  }, {
    id: 'archived',
    name: 'Archived',
  }];
}

export function formatPartnerCompanyStatus(status) {
  return formatOptionItem(status, getPartnerCompanyStatusOptions());
}
