import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';

import { ConsoleContext } from 'services/context';

import {
  formatTechStack,
  getTechStack,
} from 'services/techStack';

import {
  getOpportunity,
  formatEnglishLevel,
  formatExperienceYears,
  updateOpportunityStatus,
  formatSearchType,
  formatPosition,
  formatStatus,
  formatDeadline,
  formatCandidateLocation,
  formatRateRange,
  formatInterviewType,
  formatClientInterviewType,
  createOpportunityCopy,
} from 'services/opportunity';

import {
  formatDateTime,
} from 'services/date';

import {
  getUsers,
  formatUserName,
} from 'services/user';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Button from 'components/CustomButtons/Button';
import Table from 'components/Table/Table';

import OpportunityEdit from './components/OpportunityEdit';
import CandidateList from './components/CandidateList';
import OpportunityProject from './components/OpportunityProject';
import PartnerCompanyList from './components/PartnerCompanyList';
import OpportunityArchiveDialog from './components/OpportunityArchiveDialog';

const useStyles = makeStyles(() => ({
  grid: {
    marginTop: '15px',
  },
  actions: {
    '& button': {
      marginRight: '10px',
    },
  },
}));

export default function OpportunityDetails({ match: { params: { id, candidateId } } }) {
  const classes = useStyles();

  const {
    showLoader, showError, showSuccess, showConfirm, t, can,
  } = useContext(ConsoleContext);

  const [opportunity, setOpportunity] = useState();

  const [opportunityCopyWithoutId, setOpportunityCopyWithoutId] = useState();

  const [openArchiveDialog, setOpenArchiveDialog] = useState(false);

  const [editMode, setEditMode] = useState(!id);

  const [canEdit, setCanEdit] = useState(false);

  const [users, setUsers] = useState([]);
  const [techStack, setTechStack] = useState([]);

  const [redirectId, setRedirectId] = useState();

  const [fields, setFields] = useState();

  const [candidateMatchingFields, setCandidateMatchingFields] = useState();

  const columns = [
    { id: 'status', name: 'Status' },
    { id: 'archivedReason', name: 'Archive Reason' },
    { id: 'position', name: 'Position' },
    { id: 'peopleRequired', name: 'Number of people required' },
    { id: 'manager', name: 'Position Closer' },
    { id: 'englishLevel', name: 'English Level' },
    { id: 'searchType', name: 'Search Type' },
    { id: 'clientName', name: 'Lead/Client Name' },
    { id: 'requiredInterviews', name: 'Required Internal Interviews' },
    { id: 'requiredClientInterviews', name: 'Required Client Interviews' },
    { id: 'rateRange', name: 'Rate Range' },
    { id: 'createdAt', name: 'Creation Date' },
    { id: 'deadline', name: 'Hiring Deadline' },
    { id: 'candidateLocation', name: 'Candidate Location' },
    { id: 'requirements', name: 'Requirements' },
    { id: 'responsibilities', name: 'Responsibilities' },
    { id: 'techStack', name: '' },
    { id: 'publicLink', name: 'Public Link' },
  ];

  const candidateMatchingColumns = [
    { id: 'archived', name: 'Status' },
    { id: 'ignoreExperience', name: 'Rate & Experience' },
  ];

  useEffect(() => {
    (async () => {
      showLoader(true);

      setCanEdit(await can('sales:opportunities:edit'));

      try {
        let opportunity = {};

        const users = await getUsers();
        const techStack = await getTechStack();

        if (id) {
          opportunity = await getOpportunity(id);
        }

        setUsers(users);
        setTechStack(techStack);
        setOpportunity(opportunity);
      } catch (e) {
        showError(e);
      }

      showLoader(false);
    })();
  }, [id]);

  useEffect(() => {
    if (opportunity && opportunity._id) {
      const {
        status,
        archivedReason,
        position,
        englishLevel,
        rateRange,
        searchType,
        requirements,
        responsibilities,
        deadline,
        candidateLocation,
        createdAt,
        manager,
        peopleRequired,
        requiredInterviews,
        requiredInterviewsOptions,
        requiredClientInterviews,
        clientName,
        candidateMatching = {},
      } = opportunity;

      setFields({
        status: formatStatus(status),
        archivedReason: archivedReason || '-',
        position: formatPosition(position),
        englishLevel: formatEnglishLevel(englishLevel),
        rateRange: formatRateRange(rateRange),
        searchType: formatSearchType(searchType),
        techStack: renderTechStack(opportunity.techStack, techStack),
        publicLink: renderPublicLink(opportunity),
        requirements: formatRequirements(requirements),
        responsibilities: formatRequirements(responsibilities),
        deadline: formatDeadline(deadline),
        candidateLocation: formatCandidateLocation(candidateLocation),
        createdAt: formatDateTime(createdAt),
        manager: formatUserName(manager),
        peopleRequired,
        requiredInterviews: formatRequiredInterviews(requiredInterviews, requiredInterviewsOptions),
        requiredClientInterviews: formatRequiredClientInterviews(requiredClientInterviews),
        clientName: clientName || '-',
      });

      const {
        archived,
        ignoreExperience,
      } = candidateMatching;

      const candidateMatchingFields = {
        archived: archived ? 'Include Archived' : 'Only Available',
        ignoreExperience: ignoreExperience ? 'Any' : 'Match',
      };

      setCandidateMatchingFields(candidateMatchingFields);
    }
  }, [opportunity]);

  const handleSave = (changedOpportunity) => {
    if (!opportunity._id || opportunityCopyWithoutId) {
      setRedirectId(changedOpportunity._id);
    } else {
      setOpportunity(changedOpportunity);
      setEditMode(false);
    }
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCopy = () => {
    const opportunityCopy = createOpportunityCopy(opportunity);
    opportunityCopy.name = `Copy ${opportunityCopy.name}`;

    setOpportunityCopyWithoutId(opportunityCopy);
    setEditMode(true);
  };

  const handleEditCancel = () => {
    setEditMode(false);
  };

  const handleArchiveSave = (changedOpportunity) => {
    if (!opportunity._id) {
      setRedirectId(changedOpportunity._id);
    } else {
      setOpportunity(changedOpportunity);
      setOpenArchiveDialog(false);
    }
  };

  const handleStatusChange = (status) => {
    if (status === 'archived') {
      setOpenArchiveDialog(true);
      return;
    }

    showConfirm(async () => {
      showLoader(true);

      const data = { ...opportunity };

      data.status = status;
      delete data.archivedReason;

      try {
        await updateOpportunityStatus(data);

        showSuccess(`Opportunity ${status}`);
        setOpportunity(data);
      } catch (e) {
        showError(e);
      }

      showLoader(false);
    });
  };

  const handleCloseArchiveDialog = () => {
    setOpenArchiveDialog(false);
  };

  const handleChangeCandidateList = (opportunity) => {
    setOpportunity(opportunity);
  };

  const renderTechStack = (opportunityTechStack, techStack) => {
    if (!opportunityTechStack.length) {
      return null;
    }

    const tableHead = ['Technology', 'Experience'];

    const tableData = opportunityTechStack.map((item) => [
      formatTechStack(item.id, techStack),
      formatExperienceYears(item.experienceYears),
    ]);

    return (<Table tableHead={tableHead} tableData={tableData} style={{ marginTop: 0 }} />);
  };

  const formatRequirements = (requirements) => {
    if (!requirements || !requirements.length) {
      return '-';
    }

    return (
      <ul>
        { requirements.map((item, key) => (
          <li key={key}>{item}</li>
        )) }
      </ul>
    );
  };

  const renderPublicLink = (opportunity) => {
    const link = `${window.location.protocol}//${window.location.host}/public/vacancies/${opportunity._id}`;

    return (<a href={link} target="_blank" rel="noreferrer">{link}</a>);
  };

  const formatRequiredInterviews = (interviews, options = {}) => {
    if (!interviews || !interviews.length) {
      return '-';
    }

    return interviews
      .map((type) => {
        let label = formatInterviewType(type);

        if ((type === 'english') && options.englishSkipAdvanced) {
          label += ' (skip for advanced)';
        }

        return label;
      })
      .join(', ');
  };

  const formatRequiredClientInterviews = (interviews) => {
    if (!interviews || !interviews.length) {
      return '-';
    }

    return interviews.map((type) => formatClientInterviewType(type)).join(', ');
  };

  if (redirectId) {
    return (<Redirect to="/sales/opportunities" />);
  }

  if (!opportunity) {
    return null;
  }

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          { editMode ? (
            <Card>
              <CardHeader color="success">
                <h4>{ opportunity._id && !opportunityCopyWithoutId ? 'Edit Position' : 'Create Position'}</h4>
              </CardHeader>
              <CardBody>
                <OpportunityEdit
                  opportunity={opportunityCopyWithoutId || opportunity}
                  techStack={techStack}
                  onSave={handleSave}
                  onCancel={handleEditCancel}
                />
              </CardBody>
            </Card>
          ) : (
            <>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Card>
                    <CardHeader color="success">
                      <h4>
                        Position &quot;
                        {opportunity.name}
                        &quot;
                      </h4>
                    </CardHeader>

                    { fields ? (
                      <CardBody>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            { columns.map((column) => (
                              <GridContainer key={column.id} className={classes.grid}>
                                <GridItem xs={12} sm={12} md={12}>
                                  { column.name ? (
                                    <strong>
                                      {column.name}
                                      :
                                      {' '}
                                    </strong>
                                  ) : null }
                                  {fields[column.id]}
                                </GridItem>
                              </GridContainer>
                            )) }
                          </GridItem>

                          <GridItem xs={12} sm={12} md={6}>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={12}>
                                <h4 style={{ margin: 0 }}>Project</h4>
                              </GridItem>
                            </GridContainer>

                            <GridContainer>
                              <GridItem xs={12} sm={12} md={12}>
                                <OpportunityProject project={opportunity.project} />
                              </GridItem>
                            </GridContainer>

                            <GridContainer>
                              <GridItem xs={12} sm={12} md={12}>
                                <h4 style={{ marginBottom: 0 }}>Candidate Matching</h4>
                              </GridItem>
                            </GridContainer>

                            <GridContainer>
                              <GridItem xs={12} sm={12} md={12}>
                                { candidateMatchingColumns.map((column) => (
                                  <GridContainer key={column.id} className={classes.grid}>
                                    <GridItem xs={12} sm={12} md={12}>
                                      <strong>
                                        {column.name}
                                        :
                                        {' '}
                                      </strong>
                                      {candidateMatchingFields[column.id]}
                                    </GridItem>
                                  </GridContainer>
                                )) }
                              </GridItem>
                            </GridContainer>
                          </GridItem>
                        </GridContainer>

                        { canEdit ? (
                          <GridContainer className={`${classes.grid} ${classes.actions}`}>
                            <GridItem xs={12} sm={12} md={12}>
                              { opportunity.status === 'active' || opportunity.status === 'paused' ? (
                                <Button color="primary" onClick={() => handleEdit()}>
                                  {t('Edit')}
                                </Button>
                              ) : null }

                              { opportunity.status === 'active' ? (
                                <Button color="warning" onClick={() => handleStatusChange('paused')}>
                                  {t('Pause')}
                                </Button>
                              ) : opportunity.status === 'paused' || opportunity.status === 'archived' ? (
                                <Button color="success" onClick={() => handleStatusChange('active')}>
                                  {t('Activate')}
                                </Button>
                              ) : null }

                              { opportunity.status === 'active' || opportunity.status === 'paused' ? (
                                <Button color="danger" onClick={() => handleStatusChange('archived')}>
                                  {t('Archive')}
                                </Button>
                              ) : null }

                              { opportunity._id ? (
                                <Button color="info" onClick={() => handleCopy()}>
                                  {t('Copy')}
                                </Button>
                              ) : null }
                            </GridItem>
                          </GridContainer>
                        ) : null }
                      </CardBody>
                    ) : null }
                  </Card>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Card>
                    <CardHeader color="info">
                      <h4>Candidates</h4>
                    </CardHeader>

                    <CardBody>
                      <CandidateList
                        opportunity={opportunity}
                        techStack={techStack}
                        users={users}
                        activeCandidateId={candidateId}
                        onChange={handleChangeCandidateList}
                      />
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Card>
                    <CardHeader color="warning">
                      <h4>Partner Companies</h4>
                    </CardHeader>

                    <CardBody>
                      <PartnerCompanyList
                        opportunity={opportunity}
                        users={users}
                      />
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </>
          ) }
        </GridItem>
      </GridContainer>

      <OpportunityArchiveDialog
        opportunity={opportunity}
        open={openArchiveDialog}
        onClose={handleCloseArchiveDialog}
        onSave={handleArchiveSave}
      />
    </>
  );
}
