import React, { useState, useEffect, useContext } from 'react';

import {
  getClientHolidayContractTakenHolidays,
} from 'services/clientContract';

import {
  formatDate,
} from 'services/date';

import { ConsoleContext } from 'services/context';

// core components
import Button from 'components/CustomButtons/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from 'components/CustomDialog/CustomDialog';

export default function ClientHolidayContractInfo({
  open,
  onClose,
  holidayContract,
}) {
  const { t } = useContext(ConsoleContext);

  const [loading, setLoading] = useState(false);

  const [takenHolidays, setTakenHolidays] = useState([]);
  const [fieldValues, setFieldValues] = useState({});

  const fields = [
    { id: 'startAt', label: 'Start Date' },
    { id: 'billingStartAt', label: 'Billable From' },
    { id: 'dayOffLimit', label: 'Billable Day-offs' },
    { id: 'vacationLimit', label: 'Billable Vacation Days' },
    { id: 'takenHolidaysCount', label: 'Taken Billable Holidays' },
  ];

  useEffect(() => {
    if (!holidayContract) {
      return;
    }

    setLoading(true);

    (async () => {
      const takenHolidays = await getClientHolidayContractTakenHolidays(holidayContract._id);

      setTakenHolidays(takenHolidays);

      const {
        startAt,
        billingStartAt,
        dayOffLimit,
        vacationLimit,
      } = holidayContract;

      setFieldValues({
        startAt: formatDate(startAt),
        billingStartAt: formatDate(billingStartAt),
        dayOffLimit,
        vacationLimit,
        takenHolidaysCount: takenHolidays.filter((holiday) => holiday.billableByClient).length,
      });

      setLoading(false);
    })();
  }, [open, holidayContract]);

  const handleCloseDialog = () => {
    onClose();
  };

  return (
    <>
      <Dialog
        loading={loading}
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={handleCloseDialog}
      >
        <DialogTitle>
          Holiday Contract
        </DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TableContainer>
                <Table>
                  <TableBody>
                    { fields.map((field) => (
                      <TableRow key={field.id}>
                        <TableCell><strong>{ t(field.label) }</strong></TableCell>
                        <TableCell>{ fieldValues[field.id] }</TableCell>
                      </TableRow>
                    )) }
                  </TableBody>
                </Table>
              </TableContainer>
            </GridItem>
          </GridContainer>
          { takenHolidays.length ? (
            <GridContainer style={{ marginTop: '20px' }}>
              <GridItem xs={12} sm={12} md={12}>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Billable</TableCell>
                        <TableCell>Comment</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      { takenHolidays.map((holiday) => (
                        <TableRow key={holiday._id}>
                          <TableCell>{formatDate(holiday.date)}</TableCell>
                          <TableCell>{holiday.billableByClient ? 'Yes' : 'No'}</TableCell>
                          <TableCell>{holiday.comment}</TableCell>
                        </TableRow>
                      )) }
                    </TableBody>
                  </Table>
                </TableContainer>
              </GridItem>
            </GridContainer>
          ) : null }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
