import React, { useState, useEffect, useContext } from 'react';

import {
  createProjectOvertime,
  updateProjectOvertime,
} from 'services/project';

import {
  formatDate,
  formatHours,
} from 'services/date';

import { ConsoleContext } from 'services/context';

// core components
import { makeStyles } from '@material-ui/core/styles';
import Button from 'components/CustomButtons/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from 'components/CustomDialog/CustomDialog';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: '100%',
  },
  addDateComment: {
    marginTop: '15px',
  },
  checkboxMargin: {
    paddingTop: '10px',
  },
  grid: {
    marginTop: '10px',
  },
}));

export default function ProjectLogEditDialog({
  open, overtime, billable, formatMemberName, onClose, onSave,
}) {
  const classes = useStyles();

  const { showError, showSuccess } = useContext(ConsoleContext);

  const [openDialog, setOpenDialog] = useState(false);

  const [changedOvertime, setChangedOvertime] = useState({
    hours: '',
    comment: '',
    rateByClient: '',
    rateByCompany: '',
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  useEffect(() => {
    setChangedOvertime({
      comment: '',
      rateByClient: 2,
      rateByCompany: 1.5,
      ...overtime,
      hours: overtime.timeSpentSeconds ? formatHours(overtime.timeSpentSeconds) : '',
    });
  }, [overtime]);

  const handleCloseDialog = () => {
    onClose();
  };

  const handleChange = (field, value) => {
    const data = { ...changedOvertime };
    data[field] = value;

    setChangedOvertime(data);
  };

  const handleSubmit = async () => {
    const data = { ...changedOvertime };

    if (!data.hours || !data.rateByCompany) {
      return;
    }

    if (!data.billableByClient) {
      delete data.rateByClient;
    }

    data.timeSpentSeconds = Number(data.hours) * 3600;

    delete data.hours;

    let updatedOvertime;

    setLoading(true);

    try {
      if (data._id) {
        updatedOvertime = await updateProjectOvertime(overtime.projectId, data);

        showSuccess('Overtime updated');
      } else {
        updatedOvertime = await createProjectOvertime(overtime.projectId, data);

        showSuccess('Overtime created');
      }

      onSave(updatedOvertime);
    } catch (e) {
      showError(e);
    }

    setLoading(false);
  };

  return (
    <>
      <Dialog
        loading={loading}
        maxWidth="xs"
        fullWidth
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>
          {formatMemberName({ userId: overtime.userId })}
          {' '}
          {formatDate(overtime.date)}
          {' '}
          { billable ? 'Billable' : 'Non Billable'}
        </DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
              <TextField
                required
                fullWidth
                label="Hours"
                value={changedOvertime.hours}
                onChange={(e) => handleChange('hours', e.target.value)}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={5}>
              <FormControl className={classes.formControl}>
                <InputLabel>X By Company *</InputLabel>
                <Select
                  fullWidth
                  value={changedOvertime.rateByCompany}
                  onChange={(e) => handleChange('rateByCompany', e.target.value)}
                >

                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={1.5}>1.5</MenuItem>
                </Select>
              </FormControl>
            </GridItem>
            { billable ? (
              <GridItem xs={12} sm={12} md={4}>
                <FormControl className={classes.formControl}>
                  <InputLabel>X By Client *</InputLabel>
                  <Select
                    fullWidth
                    value={changedOvertime.rateByClient}
                    onChange={(e) => handleChange('rateByClient', e.target.value)}
                  >

                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={1.5}>1.5</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
            ) : null }
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                fullWidth
                className={classes.addDateComment}
                id="outlined-multiline-static"
                label="Comment"
                multiline
                rows={4}
                variant="outlined"
                value={changedOvertime.comment}
                onChange={(e) => handleChange('comment', e.target.value)}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
