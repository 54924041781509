import React, {
  useState, useEffect, useContext, useCallback,
} from 'react';

import debounce from 'lodash.debounce';

import {
  getOpportunityManagerOptions,
  getPriorityFilterOptions, getRecruiterOptions,
  getStatusOptions,
} from 'services/opportunity';

// @material-ui/core components
import TextField from '@material-ui/core/TextField';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';

import CustomSelect from 'components/CustomSelect/CustomSelect';
import { ConsoleContext } from 'services/context';
import { getRoles } from 'services/role';

export default function OpportunityFilters({
  onChange, opportunities, storage,
}) {
  const [priorityUserOptions, setPriorityUserOptions] = useState([]);
  const [managerOptions, setManagerOptions] = useState([]);
  const [recruiterOptions, setRecruiterOptions] = useState([]);

  const {
    sessionUser,
  } = useContext(ConsoleContext);

  const [filters, setFilters] = useState(storage.getValue('opportunityFilters', {
    status: 'active',
  }));

  const [filtersPriorityAndPartner, setFiltersPriorityAndPartner] = useState({});

  const findSessionUserIdInOption = (option) => {
    const findOptionBySessionUser = option.find((u) => u.id === sessionUser.id);
    if (findOptionBySessionUser) {
      return findOptionBySessionUser.id;
    }
    if (option.length) {
      return option[0].id;
    }
    return null;
  };

  const findRole = (roles) => {
    const recruiterRole = roles.find((role) => role.name === 'Recruiter');
    const domesticPartnersRole = roles.find((role) => role.name === 'Domestic Partners');
    if (recruiterRole) {
      return recruiterRole;
    }
    if (domesticPartnersRole) {
      return domesticPartnersRole;
    }

    return roles[0];
  };

  const getPriorityBySessionUser = (role) => {
    if (role && role.name) {
      return role.name === 'Recruiter'
        ? 'recruiter'
        : role.name === 'Domestic Partners'
          ? 'domestic_partner'
          : 'sales';
    }
    return 'sales';
  };

  const onChangeDebounced = useCallback(debounce(onChange, 300), []);

  useEffect(() => () => {
    onChangeDebounced.cancel();
  }, []);

  useEffect(() => {
    const data = {};

    Object.keys(filters).forEach((key) => {
      if (filters[key] || filters[key] === '') {
        data[key] = filters[key];
      }
    });

    onChangeDebounced(data);

    if (filters.status !== 'active') {
      setFiltersPriorityAndPartner({});
    }
  }, [filters]);

  useEffect(() => {
    if (Object.keys(filtersPriorityAndPartner).length > 0) {
      const data = {};
      Object.keys(filtersPriorityAndPartner).forEach((key) => {
        if (filtersPriorityAndPartner[key]) {
          data[key] = filtersPriorityAndPartner[key];
        }
      });

      if (filtersPriorityAndPartner.priority === 'recruiter') {
        setPriorityUserOptions(recruiterOptions);
      } else {
        setPriorityUserOptions(managerOptions);
      }

      onChange(data);
    }
  }, [filtersPriorityAndPartner]);

  const handleFilterChange = (filter, value) => {
    const data = { ...filters };
    data[filter] = value;

    setFilters(data);

    if (storage) {
      storage.setValue('opportunityFilters', data);
    }
  };

  const handleFiltersChangePriorityAndPartner = (filter, value) => {
    if (value) {
      const data = { ...filtersPriorityAndPartner };
      data[filter] = value;

      if (filter === 'priority') {
        let options = [];
        if (value === 'recruiter') {
          options = recruiterOptions;
        } else if (value === 'sales') {
          options = managerOptions;
        }
        if (options.length && value !== 'domestic_partner') data.partner = findSessionUserIdInOption(options) || options[0].id;
      }
      setFilters({ ...filters, ...data });
      setFiltersPriorityAndPartner(data);
      if (storage) {
        storage.setValue('opportunityFilters', { ...filters, ...data });
      }
    }
  };

  useEffect(() => {
    (async () => {
      const options = await getOpportunityManagerOptions();
      const recruiterOptions = await getRecruiterOptions();

      const roleBySessionUser = await getRoles({ userIds: sessionUser.id });
      const role = findRole(roleBySessionUser);

      const managerOptions = options.filter((item) => {
        const opportunityWithManager = opportunities.find((o) => o.managerId === item.id);
        if (opportunityWithManager) {
          return item;
        }
        return null;
      });

      setRecruiterOptions(recruiterOptions);
      if (managerOptions.length) {
        setManagerOptions(managerOptions);
        setPriorityUserOptions(managerOptions);
      }

      setFiltersPriorityAndPartner(
        {
          priority: filters.priority || getPriorityBySessionUser(role),
          partner: filters.partner ? filters.partner : getPriorityBySessionUser(role) === 'recruiter' ? findSessionUserIdInOption(recruiterOptions)
            : findSessionUserIdInOption(managerOptions),
        },
      );
    })();
  }, [opportunities]);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={3}>
        <CustomSelect
          label="Status"
          value={filters.status}
          options={getStatusOptions()}
          onChange={(value) => handleFilterChange('status', value)}
        />
      </GridItem>

      {filters.status === 'active' && (
        <>
          <GridItem xs={12} sm={12} md={3}>
            <CustomSelect
              required
              label="Priority"
              value={filtersPriorityAndPartner.priority}
              options={getPriorityFilterOptions()}
              onChange={(value) => handleFiltersChangePriorityAndPartner('priority', value)}
            />
          </GridItem>

          { filtersPriorityAndPartner.priority !== 'domestic_partner'
                  && (
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomSelect
                      required
                      label="Partner"
                      value={filtersPriorityAndPartner.partner}
                      options={priorityUserOptions}
                      onChange={(value) => handleFiltersChangePriorityAndPartner('partner', value)}
                    />
                  </GridItem>
                  )}
        </>
      )}

      <GridItem xs={12} sm={12} md={3}>
        <TextField
          fullWidth
          label="Opportunity Name"
          value={filters.name}
          onChange={(e) => handleFilterChange('name', e.target.value)}
        />
      </GridItem>

    </GridContainer>
  );
}
